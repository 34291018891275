<template>
  <v-main class="pl-10">
    <v-dialog
      v-model="showDialog"
      scrollable
      width="80%"
      @click:outside="
        () => {
          this.reloadAll();
        }
      "
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">
          All connected devices to alert
        </v-card-title>

        <v-card-text class="pt-5">
          <v-alert
            v-if="showNoDeviceConnectedAlert"
            border="left"
            type="warning"
            >No device connected to this alert !</v-alert
          >
        
          <v-expansion-panels accordion v-model="devicePanel" multiple>
            <v-expansion-panel v-for="(item, i) in connectedDevices" :key="i">
              <v-expansion-panel-header>
                <v-row no-gutters align="center"
                  ><v-col cols="5"> {{ item.name }}</v-col
                  ><v-col cols="5"> {{ item.groupName }}</v-col></v-row
                >
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <vue-json-pretty :deep="3" :data="item.lastData.data">
                </vue-json-pretty>
              </v-expansion-panel-content>
            </v-expansion-panel> </v-expansion-panels></v-card-text
        ><v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click="
              () => {
                this.reloadAll();
              }
            "
          >
            Cancel
          </v-btn>
        </v-card-actions></v-card
      ></v-dialog
    >

    <v-dialog
      v-model="removeDialog"
      scrollable
      width="90%"
      @click:outside="
        () => {
          this.reloadAll();
        }
      "
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Remove device from alert
        </v-card-title>

        <v-card-text>
          <v-row justify="center"
            ><v-col cols="4">
              <v-row
                ><v-col cols="10"
                  ><div class="text-h6 mb-2" v-text="'Groups'"></div></v-col
              ></v-row>

              <v-card style="height: 650px; overflow-y: scroll" class="pa-4">
                <template v-for="group in groups">
                  <v-card
                    :class="[
                      'mb-3',
                      selectedGroup != null && selectedGroup.id == group.id
                        ? 'selected'
                        : 'unselected',
                    ]"
                    elevation="2"
                    :key="group.id"
                    :ripple="false"
                    @click="selectRemoveGroup(group)"
                  >
                    <v-app-bar flat color="rgba(0, 0, 0, 0)">
                      <v-toolbar-title class="title pl-0">
                        {{ group.name }}
                      </v-toolbar-title>
                    </v-app-bar>
                    <v-card-subtitle class="mb-0 mt-0"
                      ><div>{{ group.id }}</div>
                    </v-card-subtitle>
                    <v-card-text>{{ group.description }} </v-card-text>
                  </v-card>
                </template>
              </v-card></v-col
            ><v-col cols="4">
              <v-row
                ><v-col cols="10"
                  ><div
                    class="text-h6 mb-2"
                    v-text="'Connected devices'"
                  ></div></v-col
                ><v-col cols="2" class="text-right">
                  <v-btn
                    v-if="selectedGroup != null"
                    color="primary"
                    icon
                    @click.stop="removeAllDevice()"
                  >
                    <v-icon>mdi-arrow-collapse-right</v-icon>
                  </v-btn></v-col
                ></v-row
              >

              <v-card style="height: 650px; overflow-y: scroll" class="pa-4">
                <v-alert
                  v-if="this.selectedGroup == null"
                  border="left"
                  type="info"
                  >Select a group</v-alert
                >
                <v-alert
                  v-if="this.devices.length == 0 && this.selectedGroup != null"
                  border="left"
                  type="info"
                  >There is no device connect to this alert in this
                  group</v-alert
                >
                <template v-for="device in devices">
                  <v-card
                    class="mb-3"
                    elevation="2"
                    width="100%"
                    :key="device.id"
                  >
                    <v-app-bar flat color="rgba(0, 0, 0, 0)">
                      <v-card-title class="title pl-0 mt-5">{{
                        device.name
                      }}</v-card-title>

                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        icon
                        @click.stop="removeDevice(device)"
                      >
                        <v-icon>mdi-arrow-right</v-icon>
                      </v-btn>
                    </v-app-bar>
                    <v-card-subtitle class="pb-0"
                      >CheckMeId : {{ device.checkMeId }}</v-card-subtitle
                    >
                    <v-card-subtitle class="pt-0 mb-0"
                      >Registered : {{ device.registered }}
                      <v-expansion-panels flat>
                        <v-expansion-panel>
                          <v-expansion-panel-header>
                            Last data :
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <vue-json-pretty :deep="3" :data="device.lastData">
                            </vue-json-pretty>
                          </v-expansion-panel-content>
                        </v-expansion-panel> </v-expansion-panels
                    ></v-card-subtitle>
                  </v-card>
                </template> </v-card></v-col
            ><v-col cols="4">
              <v-row
                ><v-col cols="10"
                  ><div
                    class="text-h6 mb-2"
                    v-text="'Removed devices from alert'"
                  ></div></v-col
                ><v-col cols="2" class="text-right">
                  <v-btn
                    v-if="selectedGroup != null"
                    color="primary"
                    icon
                    @click.stop="unremoveAllDevice()"
                  >
                    <v-icon>mdi-arrow-collapse-left</v-icon>
                  </v-btn></v-col
                ></v-row
              >
              <v-card style="height: 650px; overflow-y: scroll" class="pa-4">
                <template v-for="device in removedDevices">
                  <v-card
                    class="mb-3"
                    elevation="2"
                    width="100%"
                    :key="device.id"
                  >
                    <v-app-bar flat color="rgba(0, 0, 0, 0)">
                      <v-card-title class="title pl-0 mt-5">{{
                        device.name
                      }}</v-card-title>

                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        icon
                        @click.stop="unremoveDevice(device)"
                      >
                        <v-icon>mdi-arrow-left</v-icon>
                      </v-btn>
                    </v-app-bar>
                    <v-card-subtitle class="pb-0"
                      >CheckMeId : {{ device.checkMeId }}</v-card-subtitle
                    >
                    <v-card-subtitle class="pt-0 mb-0"
                      >Registered : {{ device.registered }}
                      <v-expansion-panels flat>
                        <v-expansion-panel>
                          <v-expansion-panel-header>
                            Last data :
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <vue-json-pretty :deep="3" :data="device.lastData">
                            </vue-json-pretty>
                          </v-expansion-panel-content>
                        </v-expansion-panel> </v-expansion-panels
                    ></v-card-subtitle>
                  </v-card>
                </template> </v-card></v-col
          ></v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click="
              () => {
                this.reloadAll();
              }
            "
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn color="primary" text @click="saveRemovedDevice"> Save </v-btn>
        </v-card-actions>
      </v-card></v-dialog
    >
    <v-dialog
      v-model="addDialog"
      scrollable
      width="90%"
      @click:outside="
        () => {
          this.reloadAll();
        }
      "
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Connect device to alert
        </v-card-title>

        <v-card-text>
          <v-row justify="center"
            ><v-col cols="4">
              <v-row
                ><v-col cols="10">
                  <div class="text-h6 mb-2" v-text="'Groups'"></div></v-col
              ></v-row>

              <v-card style="height: 650px; overflow-y: scroll" class="pa-4">
                <template v-for="group in groups">
                  <v-card
                    :class="[
                      'mb-3',
                      selectedGroup != null && selectedGroup.id == group.id
                        ? 'selected'
                        : 'unselected',
                    ]"
                    elevation="2"
                    :key="group.id"
                    :ripple="false"
                    @click="selectGroup(group)"
                  >
                    <v-app-bar flat color="rgba(0, 0, 0, 0)">
                      <v-toolbar-title class="title pl-0">
                        {{ group.name }}
                      </v-toolbar-title>
                    </v-app-bar>
                    <v-card-subtitle class="mb-0 mt-0"
                      ><div>{{ group.id }}</div>
                    </v-card-subtitle>
                    <v-card-text>{{ group.description }} </v-card-text>
                  </v-card>
                </template>
              </v-card></v-col
            ><v-col cols="4">
              <v-row
                ><v-col cols="10"
                  ><div class="text-h6 mb-2" v-text="'Devices'"></div></v-col
                ><v-col cols="2" class="text-right">
                  <v-btn
                    v-if="selectedGroup != null"
                    color="primary"
                    icon
                    @click.stop="connectAllDevice()"
                  >
                    <v-icon>mdi-arrow-collapse-right</v-icon>
                  </v-btn></v-col
                ></v-row
              >

              <v-card style="height: 650px; overflow-y: scroll" class="pa-4">
                <v-alert
                  v-if="
                    !deviceLoading &&
                    devices.length == 0 &&
                    this.selectedGroup != null
                  "
                  border="left"
                  type="info"
                  >No device registered in this group or all devices in this
                  group are already connected to this alert</v-alert
                >
                <v-alert
                  v-if="this.selectedGroup == null"
                  border="left"
                  type="info"
                  >Select a group</v-alert
                >
                <template v-for="device in devices">
                  <v-card
                    class="mb-3"
                    elevation="2"
                    width="100%"
                    :key="device.id"
                  >
                    <v-app-bar flat color="rgba(0, 0, 0, 0)">
                      <v-card-title class="title pl-0 mt-5">{{
                        device.name
                      }}</v-card-title>

                      <v-spacer></v-spacer>
                      <v-btn
                        v-if="
                          connectedDevices.findIndex((dev) => {
                            return dev.id == device.id;
                          }) < 0
                        "
                        color="primary"
                        icon
                        @click.stop="connectDevice(device)"
                      >
                        <v-icon>mdi-arrow-right</v-icon>
                      </v-btn>
                    </v-app-bar>
                    <v-card-subtitle class="pb-0"
                      >CheckMeId : {{ device.checkMeId }}</v-card-subtitle
                    >
                    <v-card-subtitle class="pt-0 mb-0"
                      >Registered : {{ device.registered }}
                      <v-expansion-panels flat>
                        <v-expansion-panel>
                          <v-expansion-panel-header>
                            Last data :
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <vue-json-pretty :deep="3" :data="device.lastData">
                            </vue-json-pretty>
                          </v-expansion-panel-content>
                        </v-expansion-panel> </v-expansion-panels
                    ></v-card-subtitle>
                  </v-card>
                </template> </v-card></v-col
            ><v-col cols="4">
              <v-row
                ><v-col cols="10"
                  ><div
                    class="text-h6 mb-2"
                    v-text="'Devices to connect with alert'"
                  ></div></v-col
                ><v-col cols="2" class="text-right">
                  <v-btn
                    v-if="selectedGroup != null"
                    color="primary"
                    icon
                    @click.stop="unconnectAllDevice()"
                  >
                    <v-icon>mdi-arrow-collapse-left</v-icon>
                  </v-btn></v-col
                ></v-row
              >
              <v-card style="height: 650px; overflow-y: scroll" class="pa-4">
                <template v-for="device in connectedDevices">
                  <v-card
                    v-if="
                      selectedAlert.devicesId.findIndex((deviceId) => {
                        return deviceId == device.id;
                      }) < 0
                    "
                    class="mb-3"
                    elevation="2"
                    width="100%"
                    :key="device.id"
                  >
                    <v-app-bar flat color="rgba(0, 0, 0, 0)">
                      <v-card-title class="title pl-0 mt-5">{{
                        device.name
                      }}</v-card-title>

                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        icon
                        @click.stop="unconnectDevice(device)"
                      >
                        <v-icon>mdi-arrow-left</v-icon>
                      </v-btn>
                    </v-app-bar>
                    <v-card-subtitle class="pb-0"
                      >CheckMeId : {{ device.checkMeId }}</v-card-subtitle
                    >
                    <v-card-subtitle class="pt-0 mb-0"
                      >Registered : {{ device.registered }}
                      <v-expansion-panels flat>
                        <v-expansion-panel>
                          <v-expansion-panel-header>
                            Last data :
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <vue-json-pretty :deep="3" :data="device.lastData">
                            </vue-json-pretty>
                          </v-expansion-panel-content>
                        </v-expansion-panel> </v-expansion-panels
                    ></v-card-subtitle>
                  </v-card>
                </template> </v-card></v-col
          ></v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click="
              () => {
                this.reloadAll();
              }
            "
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn color="primary" text @click="saveConnectedDevice">
            Save
          </v-btn>
        </v-card-actions>
      </v-card></v-dialog
    >
    <v-row no-gutters
      ><v-col :cols="miniMenu ? 1 : 2"
        ><alertMenu
          @miniMenu="setMiniMenu"
          @actionMenu="actionMenu"
          @switch1="setSwitch1"
          @changeActive="changeActive"
          :alertName="selectedAlert.name"
          :alertActive="selectedAlert.active"
        ></alertMenu> </v-col
      ><v-col class="ml-3" :cols="miniMenu ? 10 : 9">
        <template v-if="alertLoading && !showNoAlertAlert"
          ><div class="mt-5 text-center">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular></div
        ></template>
        <v-alert
          v-if="showNoAlertAlert"
          border="left"
          color="primary"
          icon="mdi-information-outline"
          type="success"
          >No device alert received yet !</v-alert
        >
        <v-alert v-if="showNoDeviceConnectedAlert" border="left" type="warning"
          >No device connected to this alert !</v-alert
        >
        <v-expansion-panels v-if="!switch1" accordion v-model="panel" multiple>
          <v-expansion-panel
            v-for="(item, i) in data"
            :key="i"
            @click="sendNotNew(item)"
          >
            <v-expansion-panel-header>
              <v-row no-gutters align="center"
                ><v-col cols="2">{{
                  new Date(item.date).toLocaleString()
                }}</v-col
                ><v-col cols="5"> {{ item.deviceId }}</v-col
                >
                
                <v-col class="pr-5" cols="2"> {{ item.trigger }}</v-col
                >
                <v-col
                  cols="1"
                 
                >
                  {{ item.data.value }}</v-col
                ><v-col cols="1"
                  ><v-icon :color="item.level == 'alarm' ? 'red' : 'orange'"
                    >mdi-alert-circle</v-icon
                  ></v-col
                ><v-col cols="1"
                  ><v-chip small color="primary" v-if="item.new"
                    >New</v-chip
                  ></v-col
                ></v-row
              >
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <vue-json-pretty :deep="3" :data="item"> </vue-json-pretty>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-expansion-panels v-if="switch1" accordion v-model="panel" multiple>
          <v-expansion-panel v-for="(item, i) in groupById" :key="i">
            <v-expansion-panel-header>
              <v-row no-gutters align="center"
                ><v-col cols="7"> {{ item[0].deviceId }}</v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-expansion-panels accordion>
                <v-expansion-panel
                  v-for="(item2, i) in item"
                  :key="i"
                  @click="sendNotNew(item2)"
                >
                  <v-expansion-panel-header>
                    <v-row no-gutters align="center"
                      ><v-col cols="2">{{
                        new Date(item2.date).toLocaleString()
                      }}</v-col
                      ><v-col
                        cols="2"
                       
                      >
                        {{ item2.data.value }}</v-col
                      >
                      <v-col
                        cols="6"
                       
                      >
                        {{ item2.data.trigger }}</v-col
                      >
                      <v-col cols="1"
                        ><v-icon
                          :color="item2.level == 'alarm' ? 'red' : 'orange'"
                          >mdi-alert-circle</v-icon
                        ></v-col
                      ><v-col cols="1"
                        ><v-chip small color="primary" v-if="item2.new"
                          >New</v-chip
                        ></v-col
                      ></v-row
                    >
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <vue-json-pretty :deep="3" :data="item2"> </vue-json-pretty>
                  </v-expansion-panel-content> </v-expansion-panel
              ></v-expansion-panels>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels> </v-col
    ></v-row>
  </v-main>
</template>

<script>
import axios from "axios";

import tokenStore from "../../store/TokenStore";

import AlertMenu from "./AlertMenu.vue";
import VueJsonPretty from "vue-json-pretty";

export default {
  components: {
    alertMenu: AlertMenu,
    VueJsonPretty,
  },
  data: () => ({
    panel: [],
    data: [],
    miniMenu: true,
    addDialog: false,
    connectedDevices: [],
    groups: [],
    selectedGroup: null,
    devices: [],
    showDialog: false,
    removeDialog: false,
    removedDevices: [],
    devicePanel: [],
    interval: null,
    showNoAlertAlert: false,
    showNoDeviceConnectedAlert: false,
    deviceLoading: false,
    switch1: false,
    groupById: [],
    alertLoading: true,
  }),
  props: {
    selectedAlert: Object,
  },

  beforeMount() {
    this.reloadAll();
    this.interval = setInterval(() => {
      if (!this.showDialog && !this.removeDialog && !this.addDialog) {
        if (this.selectedAlert.devicesId.length == 0) {
          this.showNoDeviceConnectedAlert = true;
        } else {
          this.showNoDeviceConnectedAlert = false;
        }

        axios
          .get(
            process.env.VUE_APP_API_ADDR +
              "/api/alert/" +
              this.selectedAlert.id,
            {
              headers: {
                Authorization: tokenStore.getToken(),
              },
            }
          )
          .then((res) => {
            res.data.alerts.forEach((alertEvent) => {
                         if (
              this.data.findIndex((a) => {
            
                return a.id == alertEvent.id;
              }) < 0
            ) {
              this.data.push(alertEvent);
            }
            });
            if (res.data.alerts.length == 0) {
              this.showNoAlertAlert = true;
            } else {
              this.showNoAlertAlert = false;
            }
          })
          .catch((err) => {
            //
            console.log(err);
          });
        /*let count = 0;
        this.selectedAlert.devicesId.forEach((id) => {
          axios
            .get(process.env.VUE_APP_API_ADDR + "/api/device/" + id, {
              headers: {
                Authorization: tokenStore.getToken(),
              },
            })
            .then((res) => {
              let device = JSON.parse(JSON.stringify(res.data.device));
              axios
                .get(
                  process.env.VUE_APP_API_ADDR +
                    "/api/group/" +
                    device.groups[0],
                  {
                    headers: {
                      Authorization: tokenStore.getToken(),
                    },
                  }
                )
                .then((res2) => {
                  device.groupName = res2.data.group.name;
                  try {
                    device.lastData.data = JSON.parse(device.lastData.data);
                  } catch {
                    //
                  }
                  count++;
                  res.data.device.alerts.forEach((alert) => {
                    if (
                      alert.name ==
                      this.selectedAlert.name + "_" + this.selectedAlert.id
                    ) {
                      let json = JSON.parse(JSON.stringify(alert));
                      delete json.deviceTypeId;
                      delete json.networkId;
                      delete json.alertType;
                      delete json._id;
                      json.deviceName = res.data.device.name;

                      if (
                        this.data.findIndex((data) => {
                          return (
                            data.deviceId == json.deviceId &&
                            data.level == json.level &&
                            data.date == json.date &&
                            data.name == json.name
                          );
                        }) < 0
                      ) {
                        this.data.push(json);
                      }
                    }
                  });
                  if (
                    this.data.length == 0 &&
                    count == this.selectedAlert.devicesId.length
                  ) {
                    this.showNoAlertAlert = true;
                  } else {
                    this.showNoAlertAlert = false;
                  }
                })
                .catch((err) => {
                  console.log(err.response.status);
                });
            })
            .catch((err) => {
              console.log(err.response.status);
            });
        });*/
      }
    }, 5000);
  },

  beforeDestroy() {
    clearInterval(this.interval);
    window.stop();
  },
  methods: {
    setSwitch1(value) {
      this.switch1 = value;
      this.panel = [];
      if (value) {
        this.groupById = [];
        for (let x = 0; x < this.data.length; x++) {
          let item = this.data[x];
          let i = this.groupById.findIndex((arrayOfDevice) => {
            return arrayOfDevice[0].deviceId == item.deviceId;
          });
          if (i >= 0) {
            this.groupById[i].push(item);
          } else {
            this.groupById.push([item]);
          }
        }

        console.log(this.groupById);
      }
    },
    sendNotNew(alert) {
      if (alert.new) {
        alert.new = false;
        axios
          .post(
            process.env.VUE_APP_API_ADDR + "/api/alert/" + alert.id + "/notNew",
            {},
            {
              headers: {
                Authorization: tokenStore.getToken(),
              },
            }
          )
          .then(() => {})
          .catch(() => {
            //console.log(err.response.status)
          });
      }
    },
    reloadAll() {
      this.removeDialog = false;
      this.showDialog = false;
      this.addDialog = false;
      this.devices = [];
      this.data = [];
      this.loadGroups();
      this.selectedGroup = null;
      this.connectedDevices = [];
      this.removedDevices = [];
      this.$emit("reloadAlert");
      //let count = 0;
      if (this.selectedAlert.devicesId.length == 0) {
        this.showNoDeviceConnectedAlert = true;
      } else {
        this.showNoDeviceConnectedAlert = false;
      }
      this.alertLoading = true;
      axios
        .get(
          process.env.VUE_APP_API_ADDR + "/api/alert/" + this.selectedAlert.id,
          {
            headers: {
              Authorization: tokenStore.getToken(),
            },
          }
        )
        .then((res) => {
          console.log("la")
          console.log(res.data.alerts);
          res.data.alerts.forEach((alertEvent) => {
            if (
              this.data.findIndex((a) => {

                return a == alertEvent;
              }) < 0
            ) {
              this.data.push(alertEvent);
            }
          })

          if (res.data.alerts.length == 0) {
            this.showNoAlertAlert = true;
          } else {
            this.showNoAlertAlert = false;
          }
          this.alertLoading = false;
        })
        .catch((err) => {
          //
          console.log(err);
        });

      this.selectedAlert.devicesId.forEach((id) => {
        axios
          .get(process.env.VUE_APP_API_ADDR + "/api/device/" + id, {
            headers: {
              Authorization: tokenStore.getToken(),
            },
          })
          .then((res) => {
            let device = JSON.parse(JSON.stringify(res.data.device));
            console.log(res.data.device);
            axios
              .get(
                process.env.VUE_APP_API_ADDR + "/api/group/" + device.groups[0],
                {
                  headers: {
                    Authorization: tokenStore.getToken(),
                  },
                }
              )
              .then((res2) => {
                device.groupName = res2.data.group.name;
                try {
                  device.lastData.data = JSON.parse(device.lastData.data);
                } catch {
                  //
                }
                this.connectedDevices.push(device);
            
                /*res.data.device.alerts.forEach((alert) => {
                  if (
                    alert.name ==
                    this.selectedAlert.name + "_" + this.selectedAlert.id
                  ) {
                    let json = JSON.parse(JSON.stringify(alert));
                    delete json.deviceTypeId;
                    delete json.networkId;
                    delete json.alertType;
                    delete json._id;
                    json.deviceName = res.data.device.name;
                    this.data.push(json);
                  }
                });

                if (
                  this.data.length == 0 &&
                  count == this.selectedAlert.devicesId.length
                ) {
                  this.showNoAlertAlert = true;
                } else {
                  this.showNoAlertAlert = false;
                }*/
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.log(err.response.status);
          });
      });
    },
    saveConnectedDevice() {
      let tmp = [];
      this.connectedDevices.forEach((dev) => {
        tmp.push(dev.id);
      });
      axios
        .post(
          process.env.VUE_APP_API_ADDR +
            "/api/alert/" +
            this.selectedAlert.id +
            "/devices",
          {
            devicesId: tmp,
          },
          {
            headers: {
              Authorization: tokenStore.getToken(),
            },
          }
        )
        .then(() => {
          this.reloadAll();
        })
        .catch(() => {
          //console.log(err.response.status)
        });
    },
    saveRemovedDevice() {
      let tmp = [];
      this.removedDevices.forEach((dev) => {
        tmp.push(dev.id);
      });
      axios
        .delete(
          process.env.VUE_APP_API_ADDR +
            "/api/alert/" +
            this.selectedAlert.id +
            "/devices",

          {
            data: {
              devicesId: tmp,
            },
            headers: {
              Authorization: tokenStore.getToken(),
            },
          }
        )
        .then(() => {
          this.reloadAll();
        })
        .catch(() => {
          //console.log(err.response.status)
        });
    },
    connectAllDevice() {
      while (this.devices.length > 0) {
        this.connectDevice(this.devices[0]);
      }
    },
    removeAllDevice() {
      while (this.devices.length > 0) {
        this.removeDevice(this.devices[0]);
      }
    },
    unremoveAllDevice() {
      while (this.removedDevices.length > 0) {
        this.unremoveDevice(this.removedDevices[0]);
      }
    },
    unconnectAllDevice() {
      while (this.connectedDevices.length > 0) {
        this.unconnectDevice(this.connectedDevices[0]);
      }

      this.selectedAlert.devicesId.forEach((id) => {
        axios
          .get(process.env.VUE_APP_API_ADDR + "/api/device/" + id, {
            headers: {
              Authorization: tokenStore.getToken(),
            },
          })
          .then((res) => {
            this.connectedDevices.push(res.data.device);
            res.data.device.alerts.forEach((alert) => {
              if (alert.name.startsWith(this.selectedAlert.name)) {
                let json = JSON.parse(JSON.stringify(alert));
                delete json.deviceTypeId;
                delete json.networkId;
                delete json.alertType;
                delete json._id;
                json.deviceName = res.data.device.name;
                this.data.push(json);
              }
            });
          })
          .catch(() => {
            //console.log(err.response.status)
          });
      });
    },
    connectDevice(device) {
      let index = this.devices.findIndex((dev) => {
        return dev == device;
      });

      this.connectedDevices.push(this.devices.splice(index, 1)[0]);
    },
    unconnectDevice(device) {
      let index = this.connectedDevices.findIndex((dev) => {
        return dev == device;
      });
      let i = this.selectedAlert.devicesId.findIndex((id) => {
        return id == device.id;
      });
      if (this.selectedGroup.id == device.groups[0] && i < 0) {
        this.devices.push(this.connectedDevices.splice(index, 1)[0]);
      } else {
        this.connectedDevices.splice(index, 1)[0];
      }
    },
    removeDevice(device) {
      let index = this.devices.findIndex((dev) => {
        return dev == device;
      });

      this.removedDevices.push(this.devices.splice(index, 1)[0]);
    },
    unremoveDevice(device) {
      let index = this.removedDevices.findIndex((dev) => {
        return dev == device;
      });

      if (this.selectedGroup.id == device.groups[0]) {
        this.devices.push(this.removedDevices.splice(index, 1)[0]);
      } else {
        this.removedDevices.splice(index, 1)[0];
      }
    },
    selectGroup(group) {
      this.devices = [];
      this.selectedGroup = group;
      this.deviceLoading = true;
      axios
        .get(
          process.env.VUE_APP_API_ADDR +
            "/api/group/" +
            group.id +
            "/allDevice",
          {
            headers: {
              Authorization: tokenStore.getToken(),
            },
          }
        )
        .then((res) => {
          let count = 0;
          res.data.devices.forEach((dev) => {
            axios
              .get(process.env.VUE_APP_API_ADDR + "/api/device/" + dev, {
                headers: {
                  Authorization: tokenStore.getToken(),
                },
              })
              .then((res2) => {
                count++;
                if (
                  this.connectedDevices.findIndex((dev) => {
                    return dev.id == res2.data.device.id;
                  }) < 0
                ) {
                  res2.data.device.lastData.data = JSON.parse(
                    res2.data.device.lastData.data
                  );
                  this.devices.push(res2.data.device);
                }
                if (count == res.data.devices.length) {
                  this.deviceLoading = false;
                }
              });
          });
        });
    },
    selectRemoveGroup(group) {
      this.devices = [];
      this.selectedGroup = group;
      axios
        .get(
          process.env.VUE_APP_API_ADDR +
            "/api/group/" +
            group.id +
            "/allDevice",
          {
            headers: {
              Authorization: tokenStore.getToken(),
            },
          }
        )
        .then((res) => {
          res.data.devices.forEach((dev) => {
            axios
              .get(process.env.VUE_APP_API_ADDR + "/api/device/" + dev, {
                headers: {
                  Authorization: tokenStore.getToken(),
                },
              })
              .then((res) => {
                if (
                  this.connectedDevices.findIndex((dev) => {
                    return dev.id == res.data.device.id;
                  }) >= 0
                ) {
                  if (
                    this.removedDevices.findIndex((dev) => {
                      return dev.id == res.data.device.id;
                    }) < 0
                  ) {
                    res.data.device.lastData.data = JSON.parse(
                      res.data.device.lastData.data
                    );
                    this.devices.push(res.data.device);
                  }
                }
              });
          });
        });
    },
    loadGroups() {
      this.groups = [];
      axios
        .get(process.env.VUE_APP_API_ADDR + "/api/group/@me", {
          headers: {
            Authorization: tokenStore.getToken(),
          },
        })
        .then((res) => {
          res.data.groups.forEach((group) => {
            if (group.subGroups.length == 0) {
              this.groups.push(group);
            }
          });
        });
    },
    setMiniMenu(value) {
      this.miniMenu = value;
    },
    changeActive(value) {
              axios
          .post(
            process.env.VUE_APP_API_ADDR + "/api/alert/"+this.selectedAlert.id+"/active",
            {active:value},
            {
              headers: {
                Authorization: tokenStore.getToken(),
              },
            }
          )
          .then(() => {
            console.log("ok")
          })
          .catch(() => {
            //console.log(err.response.status)
          });
    },
    actionMenu(value) {
      this.reloadAll();
      switch (value) {
        case "mdi-link-variant-plus":
          this.addDialog = true;
          break;
        case "mdi-link-variant-minus":
          this.removeDialog = true;
          break;
        case "mdi-help-circle-outline":
          this.showDialog = true;
          break;
      }
    },
    test(value) {
      console.log("test", value);
    },
  },
};
</script>

<style scoped>
.selected {
  border: 3px solid #006fb7 !important;
  background-color: rgb(0, 111, 183, 0.1) !important;
  cursor: pointer !important;
}
.unselected {
  border: 3px solid rgb(255, 255, 255) !important;
  cursor: pointer !important;
}
</style>