<template>
  <div>
    <grid-layout
      :layout.sync="layout"
      :col-num="width"
      :row-height="1"
      :is-draggable="false"
      :is-resizable="false"
      :is-mirrored="false"
      :margin="[0, 0]"
      :use-css-transforms="true"
      :verticalCompact="false"
      :preventCollision="true"
      :styleCursor="false"
      style="z-index: 10"

    >

      <grid-item
        v-for="item in layout"
        :minW="100"
        :minH="100"
        :x="item.x"
        :y="item.y"
        :w="item.w"
        :h="item.h"
        :i="item.i"
        :key="item.i"
        style="z-index: 10; transition: none !important"
      >
        <v-card
          class="pb-6"
          width="100%"
          height="100%"
          
        >
          <v-system-bar
            color="primary"
            :rounded="false"

          >

           <span :class="[item.icon, 'mr-1']" style="color: white;font-size:16px" ></span>
            <span
              class="mt-1"
              style="height: 100%; overflow: hidden; color: white"
              >{{ item.name }}</span
            >
            <v-spacer></v-spacer>
            
          </v-system-bar>
          <widget
            :data="item.myData"
            :series="item.mySeries"
            :type="item.type"
            :forceUpdateWidget="false"

          ></widget>
        </v-card>
      </grid-item>
    </grid-layout>
    <br />
  </div>
</template>


<script>
import VueGridLayout from "vue-grid-layout";
import Widget from "../Dashboard/Widget";


export default {
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    widget: Widget,
  },
  data: () => ({

    layout: [],

  }),
  props: {
    width: Number,
    items: Array,
    dashboardName: String,
    dashboardId: String,
  },
  watch: {
    
    items(value) {
      this.layout = [...value];
    },
  },
  methods: {
    
   
    test: function () {
      console.log("test");
    },
    
   
  },
  beforeMount() {
    this.layout = [...this.items];
  },
};
</script>

<style scoped>

.vue-grid-item.vue-grid-placeholder {
  background: #006fb7 !important;
  border-radius: 5px;
}
</style>