<template>
  <v-main>
    <v-dialog
      v-model="createGroupDialog"
      scrollable
      width="600px"
      @click:outside="leaveCreateGroupDialog"
    >
      <v-card>
        <v-card-title class="headline secondary">
          {{
            createGroupType == "root"
              ? "Create Lvl3 Group"
              : createGroupType == "sub"
              ? "Create Lvl2 Group"
              : "Create Lvl1 Group"
          }}
        </v-card-title>
        <v-card-text class="">
          <v-row class="mt-5">
            <v-col class="mt-0 mb-0 pt-0 pb-0" cols="12">
              <v-text-field
                hide-details
                label="Group name"
                v-model="createGroupName"
              ></v-text-field
            ></v-col>
            <v-col cols="12">
              <v-textarea
                auto-grow
                clearable
                rows="1"
                clear-icon="mdi-close-circle"
                v-model="createGroupDescription"
                label="Description"
              ></v-textarea>
            </v-col> </v-row></v-card-text
        ><v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="leaveCreateGroupDialog">
            Cancel
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn
            color="primary"
            text
            @click="saveCreateGroupDialog"
            :disabled="this.createGroupName.length < 3"
          >
            Create
          </v-btn>
        </v-card-actions>
      </v-card></v-dialog
    >

    <v-dialog
      v-model="editGroupDialog"
      scrollable
      width="600px"
      @click:outside="leaveEditGroupDialog"
    >
      <v-card>
        <v-card-title class="headline secondary">
          Edit Group
        </v-card-title>
        <v-card-text class="">
          <v-row class="mt-5">
            <v-col class="mt-0 mb-0 pt-0 pb-0" cols="12">
              <v-text-field
                hide-details
                label="Group name"
                v-model="createGroupName"
              ></v-text-field
            ></v-col>
            <v-col cols="12">
              <v-textarea
                auto-grow
                clearable
                rows="1"
                clear-icon="mdi-close-circle"
                v-model="createGroupDescription"
                label="Description"
              ></v-textarea>
            </v-col> </v-row></v-card-text
        ><v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="leaveEditGroupDialog">
            Cancel
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn
            color="primary"
            text
            @click="saveEditGroupDialog"
            :disabled="this.createGroupName.length < 3"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card></v-dialog
    >

    <v-row class="ml-5 mr-5">
      <v-col cols="4" class="pa-0 pr-2">
        <v-card>
          <v-card-title>
            <v-row align="center">
              <v-col cols="4"> Lvl3 Groups</v-col>
              <v-col cols="3">
                <v-btn @click="createGroup('root')" depressed color="primary"
                  ><v-icon left> mdi-plus </v-icon>
                  Add
                </v-btn></v-col
              >
              <v-col cols="5">
                <v-text-field
                  class="pt-0"
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field
              ></v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            fixed-header
            height="66.1vh"
            :headers="headers"
            :items="rootGroups"
            :items-per-page="15"
            :loading="rootGroupsLoading"
            :search="search"
            :single-select="true"
            item-key="id"
            show-select
          >
            <template v-slot:item="{ item, isSelected, select }">
              <tr
                style="
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: normal;
                "
              >
                <td
                  :style="
                    item == selectedRootGroup
                      ? 'max-width: 100px !important;background-color:#cccccc !important;border-left: 2px solid #006FB7;border-top: 2px solid #006FB7;border-bottom: 2px solid #006FB7;border-top-left-radius: 2px !important;border-bottom-left-radius: 2px !important;'
                      : 'max-width: 100px !important'
                  "
                >
                  {{ item.name }}

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        class="ml-1 mb-1"
                        v-show="item.description != ''"
                        small
                        >mdi-information-outline</v-icon
                      >
                    </template>
                    <span>{{ item.description }}</span>
                  </v-tooltip>
                </td>
                <td
                  :style="
                    item == selectedRootGroup
                      ? 'background-color:#cccccc !important;border-top: 2px solid #006FB7;border-bottom: 2px solid #006FB7;max-width: 130px !important;'
                      : 'max-width: 130px !important'
                  "
                >
                  {{ item.id }}
                </td>
                <td
                  :style="
                    item == selectedRootGroup
                      ? 'background-color:#cccccc !important;border-top: 2px solid #006FB7;border-bottom: 2px solid #006FB7;'
                      : ''
                  "
                  class="text-center"
                >
                  {{ item.subGroups.length }}
                </td>
                <td
                  :style="
                    item == selectedRootGroup
                      ? 'background-color:#cccccc !important;border-top: 2px solid #006FB7;border-bottom: 2px solid #006FB7;'
                      : ''
                  "
                  class="text-center"
                >
                  <v-btn color="primary" icon small @click="editGroup(item)">
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>

                  <confirm
                    type="group"
                    :item="item"
                    @removeConfirmed="deleteGroup(item)"
                  ></confirm>
                </td>
                <td
                  :style="
                    item == selectedRootGroup
                      ? 'background-color:#cccccc !important;border-right: 2px solid #006FB7;border-top: 2px solid #006FB7;border-bottom: 2px solid #006FB7;border-top-right-radius: 2px !important;border-bottom-right-radius: 2px !important;'
                      : ''
                  "
                >
                  <v-row align="center" justify="center">
                    <v-simple-checkbox
                      color="primary"
                      :ripple="false"
                      :value="isSelected"
                      @click="
                        select();
                        selectRootGroup(item);
                      "
                    ></v-simple-checkbox>
                  </v-row>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col cols="4" class="pa-0 pr-2 pl-2">
        <v-card>
          <v-card-title>
            <v-row align="center">
              <v-col cols="4"> Lvl2 Groups</v-col>
              <v-col cols="3">
                <v-btn
                  @click="createGroup('sub')"
                  :disabled="selectedRootGroup == null"
                  depressed
                  color="primary"
                  ><v-icon left> mdi-plus </v-icon>
                  Add
                </v-btn></v-col
              >
              <v-col cols="5">
                <v-text-field
                  class="pt-0"
                  v-model="search2"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field
              ></v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            fixed-header
            height="66.1vh"
            :headers="headers2"
            :items="loadedSubGroups"
            :items-per-page="15"
            :loading="rootGroupsLoading"
            :search="search2"
                        :single-select="true"
            item-key="id"
            show-select
          >
          <template v-slot:item="{ item, isSelected, select }">
              <tr
                style="
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: normal;
                "
              >
                <td
                  :style="
                    item == selectedSubGroup
                      ? 'max-width: 100px !important;background-color:#cccccc !important;border-left: 2px solid #006FB7;border-top: 2px solid #006FB7;border-bottom: 2px solid #006FB7;border-top-left-radius: 2px !important;border-bottom-left-radius: 2px !important;'
                      : 'max-width: 100px !important'
                  "
                >
                  {{ item.name }}

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        class="ml-1 mb-1"
                        v-show="item.description != ''"
                        small
                        >mdi-information-outline</v-icon
                      >
                    </template>
                    <span>{{ item.description }}</span>
                  </v-tooltip>
                </td>
                <td
                  :style="
                    item == selectedSubGroup
                      ? 'background-color:#cccccc !important;border-top: 2px solid #006FB7;border-bottom: 2px solid #006FB7;max-width: 130px !important;'
                      : 'max-width: 130px !important'
                  "
                >
                  {{ item.id }}
                </td>
                <td
                  :style="
                    item == selectedSubGroup
                      ? 'background-color:#cccccc !important;border-top: 2px solid #006FB7;border-bottom: 2px solid #006FB7;'
                      : ''
                  "
                  class="text-center"
                >
                  {{ item.subGroups.length }}
                </td>
                <td
                  :style="
                    item == selectedSubGroup
                      ? 'background-color:#cccccc !important;border-top: 2px solid #006FB7;border-bottom: 2px solid #006FB7;'
                      : ''
                  "
                  class="text-center"
                >
                  <v-btn color="primary" icon small @click="editGroup(item)">
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>

                  <confirm
                    type="group"
                    :item="item"
                    @removeConfirmed="deleteGroup(item)"
                  ></confirm>
                </td>
                <td
                  :style="
                    item == selectedSubGroup
                      ? 'background-color:#cccccc !important;border-right: 2px solid #006FB7;border-top: 2px solid #006FB7;border-bottom: 2px solid #006FB7;border-top-right-radius: 2px !important;border-bottom-right-radius: 2px !important;'
                      : ''
                  "
                >
                  <v-row align="center" justify="center">
                    <v-simple-checkbox
                      color="primary"
                      :ripple="false"
                      :value="isSelected"
                      @click="
                        select();
                        selectSubGroup(item);
                      "
                    ></v-simple-checkbox>
                  </v-row>
                </td>
              </tr>
            </template>
           
          </v-data-table>
        </v-card>
      </v-col>
      <v-col cols="4" class="pa-0 pl-2">
        <v-card>
          <v-card-title>
            <v-row align="center">
              <v-col cols="4"> Lvl1 Groups</v-col>
              <v-col cols="3">
                <v-btn
                  @click="createGroup('final')"
                  :disabled="selectedSubGroup == null"
                  depressed
                  color="primary"
                  ><v-icon left> mdi-plus </v-icon>
                  Add
                </v-btn></v-col
              >
              <v-col cols="5">
                <v-text-field
                  class="pt-0"
                  v-model="search3"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field
              ></v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            fixed-header
            height="66.1vh"
            :headers="headers3"
            :items="loadedFinalGroups"
            :items-per-page="15"
            :loading="rootGroupsLoading"
            :search="search3"
          >
            <template v-slot:item="{ item }">
              <tr
                style="
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: normal;
                "
              >
                <td
                  :style="
                    item == selectedRootGroup
                      ? 'background-color:#cccccc !important;border-left: 2px solid #006FB7;border-top: 2px solid #006FB7;border-bottom: 2px solid #006FB7;border-top-left-radius: 2px !important;border-bottom-left-radius: 2px !important;'
                      : ''
                  "
                >
                  {{ item.name }}
                </td>
                <td
                  :style="
                    item == selectedRootGroup
                      ? 'background-color:#cccccc !important;border-top: 2px solid #006FB7;border-bottom: 2px solid #006FB7;'
                      : ''
                  "
                >
                  {{ item.id }}
                </td>
                <td
                  :style="
                    item == selectedRootGroup
                      ? 'background-color:#cccccc !important;border-top: 2px solid #006FB7;border-bottom: 2px solid #006FB7;'
                      : ''
                  "
                  class="text-center"
                >
                  {{ item.devices.length }}
                </td>
                <td
                  :style="
                    item == selectedRootGroup
                      ? 'background-color:#cccccc !important;border-right: 2px solid #006FB7;border-top: 2px solid #006FB7;border-bottom: 2px solid #006FB7;border-top-right-radius: 2px !important;border-bottom-right-radius: 2px !important;'
                      : ''
                  "
                  class="text-center"
                >
                                  <v-btn color="primary" icon small @click="editGroup(item)">
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>

                  <confirm
                    type="group"
                    :item="item"
                    @removeConfirmed="deleteGroup(item)"
                  ></confirm>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import axios from "axios";
import tokenStore from "../store/TokenStore";

import ConfirmRemove from "./ConfirmRemove";

export default {
  components: {
    confirm: ConfirmRemove,
  },
  data: () => ({
    headers: [
      {
        text: "Name",
        sortable: true,
        value: "name",
        align: "start",
      },
      {
        text: "Id",
        sortable: true,
        value: "id",
        align: "start",
      },

      {
        text: "Lvl2 Groups",
        value: "subGroups.length",
        sortable: true,
        width: "1px",
        align: "center",
      },
      {
        text: "Actions",
        value: "data-table-expand",
        width: "1px",
        sortable: false,
        align: "center",
      },
      {
        text: "Select",
        value: "data-table-select",
        width: "1px",
        sortable: false,
        align: "center",
      },
    ],
    headers2: [
       {
        text: "Name",
        sortable: true,
        value: "name",
        align: "start",
      },
      {
        text: "Id",
        sortable: true,
        value: "id",
        align: "start",
      },

      {
        text: "Lvl1 Groups",
        value: "subGroups.length",
        sortable: true,
        width: "1px",
        align: "center",
      },
      {
        text: "Actions",
        value: "data-table-expand",
        width: "1px",
        sortable: false,
        align: "center",
      },
      {
        text: "Select",
        value: "data-table-select",
        width: "1px",
        sortable: false,
        align: "center",
      },
    ],
    headers3: [
        {
        text: "Name",
        sortable: true,
        value: "name",
        align: "start",
      },
      {
        text: "Id",
        sortable: true,
        value: "id",
        align: "start",
      },

      {
        text: "Devices",
        value: "devices.length",
        sortable: true,
        width: "95px",
        align: "center",
      },
      {
        text: "Actions",
        value: "data-table-expand",
        width: "1px",
        sortable: false,
        align: "center",
      },

   
    ],
    checkbox: "",
    search: "",
    rootGroups: [],
    rootGroupsLoading: false,
    search2: "",
    subGroups: [],
    search3: "",
    finalGroups: [],
    selectedRootGroup: null,
    loadedSubGroups: [],
    selectedSubGroup: null,
    loadedFinalGroups: [],
    createGroupDialog: false,
    editGroupDialog: false,
    createGroupType: "",
    createGroupName: "",
    createGroupDescription: "",
    selectedEditGroup: null,
    rootSelect: null,
  }),
  computed: {},
  beforeMount() {
    setTimeout(() => {
      this.loadGroups();
    }, 1);
  },
  methods: {
    deleteGroup(group) {
      axios
        .delete(process.env.VUE_APP_API_ADDR + "/api/group/" + group.id, {
          headers: {
            Authorization: tokenStore.getToken(),
          },
        })
        .then(() => {
          this.loadGroups();
        });
    },
    editGroup(group) {
      this.createGroupName = group.name;
      this.createGroupDescription = group.description;
      this.selectedEditGroup = group;
      this.editGroupDialog = true;
    },
    saveEditGroupDialog() {
      axios
        .post(
          process.env.VUE_APP_API_ADDR +
            "/api/group/" +
            this.selectedEditGroup.id,
          {
            name: this.createGroupName,
            description: this.createGroupDescription,
          },
          {
            headers: {
              Authorization: tokenStore.getToken(),
            },
          }
        )
        .then(() => {
          this.loadGroups();
          this.leaveEditGroupDialog();
        });
    },
    saveCreateGroupDialog() {
      if (this.createGroupType == "root") {
        axios
          .post(
            process.env.VUE_APP_API_ADDR + "/api/group",
            {
              name: this.createGroupName,
              description: this.createGroupDescription,
            },
            {
              headers: {
                Authorization: tokenStore.getToken(),
              },
            }
          )
          .then(() => {
            this.loadGroups();
            this.leaveCreateGroupDialog();
          });
      } else if (this.createGroupType == "sub") {
        axios
          .post(
            process.env.VUE_APP_API_ADDR + "/api/group",
            {
              name: this.createGroupName,
              description: this.createGroupDescription,
            },
            {
              headers: {
                Authorization: tokenStore.getToken(),
              },
            }
          )
          .then((res) => {
            axios
              .post(
                process.env.VUE_APP_API_ADDR +
                  "/api/group/" +
                  this.selectedRootGroup.id +
                  "/subgroup",
                {
                  id: res.data.group.id,
                },
                {
                  headers: {
                    Authorization: tokenStore.getToken(),
                  },
                }
              )
              .then(() => {
                this.loadGroups();

                this.leaveCreateGroupDialog();
              });
          });
      } else if (this.createGroupType == "final") {
        axios
          .post(
            process.env.VUE_APP_API_ADDR + "/api/group",
            {
              name: this.createGroupName,
              description: this.createGroupDescription,
            },
            {
              headers: {
                Authorization: tokenStore.getToken(),
              },
            }
          )
          .then((res) => {
            axios
              .post(
                process.env.VUE_APP_API_ADDR +
                  "/api/group/" +
                  this.selectedSubGroup.id +
                  "/subgroup",
                {
                  id: res.data.group.id,
                },
                {
                  headers: {
                    Authorization: tokenStore.getToken(),
                  },
                }
              )
              .then(() => {
                this.loadGroups();

                this.leaveCreateGroupDialog();
              });
          });
      }
    },
    leaveEditGroupDialog() {
      this.createGroupName = "";

      this.createGroupDescription = "";

      this;
      this.selectedEditGroup = null;

      this.editGroupDialog = false;
    },
    leaveCreateGroupDialog() {
      this.createGroupName = "";

      this.createGroupDescription = "";

      this.createGroupType = "";

      this.createGroupDialog = false;
    },
    createGroup(type) {
      this.createGroupDialog = true;
      this.createGroupType = type;
    },
    selectRootGroup(group) {
      this.loadedSubGroups = [];
      this.loadedFinalGroups = [];
      this.selectedSubGroup = null;

      if (this.selectedRootGroup == group) {
        this.selectedRootGroup = null;
      } else {
        this.selectedRootGroup = group;
        this.subGroups.forEach((g) => {
          if (g.parentId == group.id) {
            this.loadedSubGroups.push(g);
          }
        });
      }
    },
    selectSubGroup(group) {
      this.loadedFinalGroups = [];
      if (this.selectedSubGroup == group) {
        this.selectedSubGroup = null;
      } else {
        this.selectedSubGroup = group;
        this.finalGroups.forEach((g) => {
          if (g.parentId == group.id) {
            this.loadedFinalGroups.push(g);
          }
        });
      }
    },
    loadGroups() {
      this.rootGroups = [];
      this.subGroups = [];
      this.finalGroups = [];
      this.rootGroupsLoading = true;
      axios
        .get(process.env.VUE_APP_API_ADDR + "/api/group/@me", {
          headers: {
            Authorization: tokenStore.getToken(),
          },
        })
        .then((res) => {
          res.data.groups.forEach((group) => {
            if (group.groupLevel == 0) {
              this.rootGroups.push(group);
            } else if (group.groupLevel == 1) {
              this.subGroups.push(group);
            } else if (group.groupLevel == 2) {
              this.finalGroups.push(group);
            }
          });
          if (this.selectedRootGroup != null) {
            let i = this.rootGroups.findIndex((group) => {
              return group.id == this.selectedRootGroup.id;
            });
            if (i >= 0) {
              let tmpSelectedSubGroup = this.selectedSubGroup;
              this.selectRootGroup(this.rootGroups[i]);
              this.selectedSubGroup = tmpSelectedSubGroup;
              if (this.selectedSubGroup != null) {
                let i = this.subGroups.findIndex((group) => {
                  return group.id == this.selectedSubGroup.id;
                });
                if (i >= 0) {
                  this.selectSubGroup(this.subGroups[i]);
                }
              }
            }
          }

          this.rootGroupsLoading = false;
        });
    },
  },
};
</script>
