<template>
  <v-main>
    <v-row class="ml-5 mr-5">
      <v-col cols="12" class="pa-0">
        <v-card>
          <v-card-title>
            <v-row align="center">
              <v-col cols="4"> Registered Devices</v-col>
              <v-col cols="3">
                <v-checkbox label="Group By Group" v-model="groupBy"
                  >test</v-checkbox
                >
              </v-col>
              <v-col cols="5">
                <v-text-field
                  class="pt-0"
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field
              ></v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            fixed-header
            height="63vh"
            :headers="headers"
            :items="devicesLoading || groupsLoading ? [] : devices"
            :items-per-page="-1"
            :loading="devicesLoading || groupsLoading"
            :search="search"
            :group-by="groupBy ? 'groupId' : null"
            ref="table"
          >
            <template v-slot:item="{ item }">
              <tr
                style="
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: normal;
                "
              >
                <td style="max-width: 300px !important">{{ item.name }}</td>
                <td style="max-width: 300px !important">{{ item.id }}</td>
                <td v-if="!groupBy" style="max-width: 300px !important">
                  {{ item.group }}
                </td>
                <td style="max-width: 300px !important">
                  {{ new Date(item.lastData.date).toLocaleString() }}
                </td>
                <td>
                  <export-data :item="item"></export-data>
                </td>
              </tr>
            </template>
            <template
              v-slot:[`group.header`]="{ group, headers, toggle, isOpen }"
            >
              <td :colspan="headers.length-1">
                <v-btn
                  @click="toggle"
                  small
                  icon
                  :ref="group"
                  :data-open="isOpen"
                >
                  <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
                  <v-icon v-else>mdi-chevron-down</v-icon>
                </v-btn>
                {{ groups[groups.findIndex((g)=>{
                  return g.id == group
                })].name }}
              </td>
                <td>
                   <export-group-data :group="groups[groups.findIndex((g)=>{
                  return g.id == group
                })]"></export-group-data>
                </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import axios from "axios";
import tokenStore from "../../store/TokenStore";

import ExportDataDialog from "../ExportDataDialog.vue";
import ExportGroupDataDialog from "../ExportGroupDataDialog.vue";

export default {
  components: {
    "export-data": ExportDataDialog,
    "export-group-data":ExportGroupDataDialog,
  },
  data: () => ({

    groupBy: true,
    headers: [
      {
        text: "Name",
        sortable: true,
        value: "name",
        align: "start",
        groupable: true,
      },
      {
        text: "Id",
        value: "id",
        sortable: true,
        align: "start",
        groupable: true,
      },

      {
        text: "Group",
        value: "groupId",
        sortable: true,

        groupable: true,
        align: "start",
      },
      {
        text: "Last Communication",
        value: "lastData.date",
        sortable: true,
        align: "start",
        groupable: true,
        filterable: false,
      },
      {
        text: "Data",
        value: "",
        width: "1px",
        sortable: false,
        align: "center",
        filterable: false,
      },
    ],
    devices: [],
    devicesLoading: false,
    groups: [],
    groupsLoading: false,
    search: "",
  }),
  computed: {},

  beforeMount() {
    setTimeout(() => {
      this.devicesLoading = true;
      this.loadGroups();
    }, 1);
  },

  methods: {

    checkData(item) {
      // item is a device
      console.log(item.id);
    },
    closeAll() {
      Object.keys(this.$refs).forEach((k) => {
        if (this.$refs[k] && this.$refs[k].$attrs["data-open"]) {
          this.$refs[k].$el.click();
        }
      });
    },
    loadGroups() {
      this.groups = [];
      axios
        .get(process.env.VUE_APP_API_ADDR + "/api/group/@me", {
          headers: {
            Authorization: tokenStore.getToken(),
          },
        })
        .then((res) => {
          res.data.groups.forEach((group) => {
            if (group.groupLevel == 2) {
              this.groups.push(group);
            }
          });

          this.loadDevices();
        });
    },
    loadDevices() {
      this.devices = [];
      this.devicesLoading = true;
      axios
        .get(process.env.VUE_APP_API_ADDR + "/api/device/@me", {
          headers: {
            Authorization: tokenStore.getToken(),
          },
        })
        .then((res) => {
          res.data.devices.forEach((device) => {
            if (device.registered) {
              let i = this.groups.findIndex((group) => {
                return group.id == device.groups[0];
              });
              if (i >= 0) {
                device.group = this.groups[i].name;
                device.groupId = this.groups[i].id;
                this.devices.push(device);
              } else {
                //device is lost in an anexisting group
                console.log("deviceLOST");
                console.log(device);
              }
            }
          });
          setTimeout(() => {
            let table = this.$refs.table;
            this.closeAll(table);
          }, 1);

          this.devicesLoading = false;
        });
    },
  },
};
</script>

