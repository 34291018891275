<template>
  <div>
    <v-snackbar v-model="snackbar" :color="snackbarColor">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <grid-layout
      :responsive="false"
      :layout.sync="layout"
      :col-num="width"
      :row-height="1"
      :is-draggable="true"
      :is-resizable="true"
      :is-mirrored="false"
      :margin="[0, 0]"
      :use-css-transforms="true"
      :verticalCompact="false"
      :preventCollision="true"
      style="z-index: 10"
      @layout-updated="saveWidgets"
    >
      <grid-item
        :x="50"
        :y="0"
        :w="254"
        :h="1"
        :i="'dashBoardMenu'"
        :static="true"
        style="z-index: 15"
      >
        <dashBoardMenu
          @dashboardMenu="selectFromMenu"
          @lockChange="lockChange"
          :dashboardName="dashboardName"
          :lock="lock"
        ></dashBoardMenu>
      </grid-item>
      <grid-item
        v-for="item in layout"
        :minW="100"
        :minH="100"
        :x="item.x"
        :y="item.y"
        :w="item.w"
        :h="item.h"
        :i="item.i"
        :key="item.i"
        :isResizable="item.type == 'map' ? false : true"
        :static="item.static"
        drag-allow-from=".dragAllow"
        style="z-index: 10; transition: none !important"
        @resize="resizeEvent(item)"
        @resized="resizedEvent(item)"

      >
        <v-hover v-slot="{ hover }">
          <v-card
            class="pb-6"
            :width="item.type == 'map' ? '' : '100%'"
            :height="item.type == 'map' ? '400px' : '100%'"
            :class="[dragging ? 'dragging' : '']"
            :elevation="
              item.static && item.transparent
                ? 0
                : !item.transparent
                ? 2
                : hover
                ? 1
                : 0
            "
            :style="
              item.static && item.transparent
                ? 'background-color:rgba(' +
                  hexToRgb(
                    $vuetify.theme.dark
                      ? $vuetify.theme.themes.dark.widgetBackground
                      : $vuetify.theme.themes.light.widgetBackground,
                    'r'
                  ) +
                  ',' +
                  hexToRgb(
                    $vuetify.theme.dark
                      ? $vuetify.theme.themes.dark.widgetBackground
                      : $vuetify.theme.themes.light.widgetBackground,
                    'g'
                  ) +
                  ',' +
                  hexToRgb(
                    $vuetify.theme.dark
                      ? $vuetify.theme.themes.dark.widgetBackground
                      : $vuetify.theme.themes.light.widgetBackground,
                    'b'
                  ) +
                  ', 0) !important;'
                : !item.transparent
                ? 'background-color:rgba(' +
                  hexToRgb(
                    $vuetify.theme.dark
                      ? $vuetify.theme.themes.dark.widgetBackground
                      : $vuetify.theme.themes.light.widgetBackground,
                    'r'
                  ) +
                  ',' +
                  hexToRgb(
                    $vuetify.theme.dark
                      ? $vuetify.theme.themes.dark.widgetBackground
                      : $vuetify.theme.themes.light.widgetBackground,
                    'g'
                  ) +
                  ',' +
                  hexToRgb(
                    $vuetify.theme.dark
                      ? $vuetify.theme.themes.dark.widgetBackground
                      : $vuetify.theme.themes.light.widgetBackground,
                    'b'
                  ) +
                  ', 1) !important;'
                : hover
                ? 'background-color:rgba(' +
                  hexToRgb(
                    $vuetify.theme.dark
                      ? $vuetify.theme.themes.dark.widgetBackground
                      : $vuetify.theme.themes.light.widgetBackground,
                    'r'
                  ) +
                  ',' +
                  hexToRgb(
                    $vuetify.theme.dark
                      ? $vuetify.theme.themes.dark.widgetBackground
                      : $vuetify.theme.themes.light.widgetBackground,
                    'g'
                  ) +
                  ',' +
                  hexToRgb(
                    $vuetify.theme.dark
                      ? $vuetify.theme.themes.dark.widgetBackground
                      : $vuetify.theme.themes.light.widgetBackground,
                    'b'
                  ) +
                  ', 0.25) !important;'
                : 'background-color:rgba(' +
                  hexToRgb(
                    $vuetify.theme.dark
                      ? $vuetify.theme.themes.dark.widgetBackground
                      : $vuetify.theme.themes.light.widgetBackground,
                    'r'
                  ) +
                  ',' +
                  hexToRgb(
                    $vuetify.theme.dark
                      ? $vuetify.theme.themes.dark.widgetBackground
                      : $vuetify.theme.themes.light.widgetBackground,
                    'g'
                  ) +
                  ',' +
                  hexToRgb(
                    $vuetify.theme.dark
                      ? $vuetify.theme.themes.dark.widgetBackground
                      : $vuetify.theme.themes.light.widgetBackground,
                    'b'
                  ) +
                  ', 0) !important;'
            "
          >
            <v-system-bar
              :style="
                item.static && item.transparent
                  ? 'opacity:0;'
                  : !item.transparent
                  ? 'opacity:1;'
                  : hover
                  ? 'opacity:0.25;'
                  : 'opacity:0;'
              "
              color="primary"
              class="dragAllow"
              :rounded="false"
              @mousedown="dragStart"
              @mouseup="dragStop"
            >
              <span
                :class="[item.icon, 'mr-1']"
                style="color: white; font-size: 16px"
              ></span>
              <span
                class="mt-1"
                style="height: 100%; overflow: hidden; color: white"
                >{{ item.name }}</span
              >
              <v-spacer></v-spacer>
              <v-icon
                color="white"
                @click="linkWidget(item.i)"
                :disabled="item.static"
                >mdi-link-plus</v-icon
              >
              <v-icon
                color="white"
                :disabled="item.static"
                @click="editWidget(item.i)"
                >mdi-cog</v-icon
              >
              <v-icon
                color="white"
                @click="removeWidget(item.i)"
                :disabled="item.static"
                >mdi-close</v-icon
              >
            </v-system-bar>

            <widget
              :i="item.i"
              :w="item.w"
              :h="item.h"
              :data="item.myData"
              :series="item.mySeries"
              :type="item.type"
              :isResizing="itemResizingId == item.i"
              @redirectDashboard="redirectDashboard"
              @snackbar="snackbarFct"
              @line2ChangeDate="line2ChangeDate"
              @line2ChangeDateArray="line2ChangeDateArray"
            ></widget>
          </v-card>
        </v-hover>
      </grid-item>
    </grid-layout>

    <br />
  </div>
</template>


<script>
import VueGridLayout from "vue-grid-layout";
import Widget from "./Widget";

import axios from "axios";
import tokenStore from "../../store/TokenStore";

import dashboardMenu from "./DashboardMenu";
export default {
  components: {
    dashBoardMenu: dashboardMenu,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    widget: Widget,
  },
  data: () => ({
    dragging: false,
    layout: [],
    firstUpdate: true,
    lock: false,
    snackbar: false,
    snackbarText: `Hello, I'm a snackbar`,
    snackbarColor: `success`,
    itemResizingId:"",
  }),
  props: {
    width: Number,
    items: Array,
    forceGridItemsUpdate: String,
    dashboardName: String,
    dashboardId: String,
  },
  watch: {
    forceGridItemsUpdate(value) {
      if (value != "") {
        /*let x = this.layout.findIndex((widget) => {
          return widget.i == value;
        });
        let item = this.layout[x];
        this.layout.splice(x, 1);
        setTimeout(() => {
          this.layout.push(item);
        }, 1);

        console.log("la");*/

        let i = this.layout.findIndex((e) => {
          return e.i == value;
        });

        let tmp2 = this.layout[i].mySeries;

        this.layout[i].mySeries = [];
        this.layout[i].mySeries = JSON.parse(JSON.stringify(tmp2));

        this.$emit("forceGridItemsUpdated");
      }
    },
    items(value) {
      this.layout = [...value];
      this.lockChange(this.lock);
    },
    lock(value) {
      console.log(this.lock);
      axios
        .post(
          process.env.VUE_APP_API_ADDR +
            "/api/dashboard/" +
            this.dashboardId +
            "/lock",
          { lock: value },
          {
            headers: {
              Authorization: tokenStore.getToken(),
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            //
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  methods: {
    snackbarFct(value){
      this.snackbarText=value.msg;
      this.snackbarColor=value.type;
      this.snackbar=true;
    },
    hexToRgb(hex, p) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      if (p == "r") {
        return parseInt(result[1], 16);
      } else if (p == "g") {
        return parseInt(result[2], 16);
      } else if (p == "b") {
        return parseInt(result[3], 16);
      }
    },
    resizeEvent(item) {
      window.getSelection().removeAllRanges();

      this.itemResizingId = item.i;
    },
    resizedEvent(){
      this.itemResizingId = "";
    },
    lockChange(value) {
      this.lock = value;
      this.items.forEach((item) => {
        item.static = value;
      });
    },
    dragStart() {
      this.dragging = true;
    },
    dragStop() {
      this.dragging = false;
    },
    selectFromMenu(value) {
      this.$emit("selectFromMenu", value);
    },
    test() {
      console.log("test");
    },
    redirectDashboard(dashboardId) {
      this.$emit("redirectDashboard", dashboardId);
    },
    line2ChangeDate(json){
      this.$emit("line2ChangeDate",json)
    },
    line2ChangeDateArray(json){
      this.$emit("line2ChangeDateArray",json)
      },
    saveWidgets() {
      if (!this.firstUpdate) {
        let toSave = JSON.parse(JSON.stringify([...this.items]));
        this.items.forEach((item,i) => {
          if (
            item.type != "text" &&
            item.type != "coloredIcon" &&
            item.type != "Alert" &&
            item.type != "map" &&
            item.type != "downlink" &&
            item.type != "linkDashboard"
          ) {
            toSave[i].mySeries.forEach((serie) => {
              serie.values = [];
            });
            toSave[i].myData.series.forEach((serie) => {
              serie.values = [];
            });
          }
        });
        axios
          .put(
            process.env.VUE_APP_API_ADDR + "/api/dashboard/" + this.dashboardId,
            { widgets: toSave },
            {
              headers: {
                Authorization: tokenStore.getToken(),
              },
            }
          )
          .then((res) => {
            if (res.data.success) {
              //
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      this.firstUpdate = false;
    },
    removeWidget: function (id) {
      this.$emit("removeWidget", id);
    },
    editWidget: function (id) {
      this.$emit("editWidget", id);
    },
    linkWidget: function (id) {
      this.$emit("linkWidget", id);
    },
  },
  beforeMount() {
    this.layout = [...this.items];

    axios
      .get(
        process.env.VUE_APP_API_ADDR +
          "/api/dashboard/" +
          this.dashboardId +
          "/lock",

        {
          headers: {
            Authorization: tokenStore.getToken(),
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          //
          this.lock = res.data.lock;
          this.items.forEach((item) => {
            item.static = res.data.lock;
          });
          console.log(this.lock);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>

<style >
@import "https://d1azc1qln24ryf.cloudfront.net/208069/SicameReference/style-cf.css?i3e4dm";

.dragAllow {
}

.dragging {
  user-select: none;
}

.vue-grid-item.vue-grid-placeholder {
  background: var(--v-primary-base) !important;
  border-radius: 5px;
}
</style>
