<template>
  <v-main v-if="selectedPreset == null">
    <v-row justify="center">
      <v-col cols="6">
        <div class="text-center text-h6 mb-2" v-text="'Personal presets'"></div>
        <v-row justify="center">
          <v-col cols="9" v-for="preset in sortedPresets" :key="preset.id">
            <v-card elevation="2" :ripple="false" @click="selectPreset(preset)">
              <v-app-bar class="pt-1" flat dense color="rgba(0, 0, 0, 0)">
                <v-toolbar-title class="title pl-0">
                  {{ preset.name }}
                </v-toolbar-title>
                <v-spacer></v-spacer>

                <confirm
                  type="preset"
                  :item="preset"
                  @removeConfirmed="removePreset(preset)"
                ></confirm>
              </v-app-bar>
              <v-card-subtitle class="mb-0 pt-0">
                <div>{{ preset.id }}</div>
              </v-card-subtitle>
              <v-card-text
                ><div>{{ preset.description }}</div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row></v-col
      >
      <v-col cols="6">
        <div class="text-center text-h6 mb-2" v-text="'Default presets'"></div>
        <v-row justify="center">
          <v-col
            cols="9"
            v-for="preset in sortedDefaultPresets"
            :key="preset.id"
          >
            <v-card elevation="2" :ripple="false" @click="selectPreset(preset)">
              <v-app-bar class="pt-1" flat dense color="rgba(0, 0, 0, 0)">
                <v-toolbar-title class="title pl-0">
                  {{ preset.name }}
                </v-toolbar-title>
                <v-spacer></v-spacer>

                <confirm
                  v-if="root"
                  type="preset"
                  :item="preset"
                  @removeConfirmed="removeDefaultPreset(preset)"
                ></confirm>
              </v-app-bar>
              <v-card-subtitle class="mb-0 pt-0">
                <div>{{ preset.id }}</div>
              </v-card-subtitle>
              <v-card-text
                ><div>{{ preset.description }}</div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-main>
  <v-main v-else>
    <showPreset
      :selectedDashboard="selectedPreset"
      @previous="previous"
    ></showPreset
  ></v-main>
</template>

<script>
import axios from "axios";
import tokenStore from "../../store/TokenStore";
import ConfirmRemove from "./../ConfirmRemove.vue";
import ShowPreset from "./ShowPreset.vue";

export default {
  components: {
    confirm: ConfirmRemove,
    showPreset: ShowPreset,
  },
  data: () => ({
    presets: [],
    defaultPresets: [],
    root: false,
    selectedPreset: null,
  }),
  computed: {
    sortedPresets: function () {
      if (this.presets) {
        let sortedGroups = this.presets;
        if (this.presets.length == 0) sortedGroups = [];
        sortedGroups = sortedGroups.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        return sortedGroups;
      } else {
        return [];
      }
    },
    sortedDefaultPresets: function () {
      if (this.defaultPresets) {
        let sortedGroups = this.defaultPresets;
        if (this.defaultPresets.length == 0) sortedGroups = [];
        sortedGroups = sortedGroups.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        return sortedGroups;
      } else {
        return [];
      }
    },
  },
  props: {
    reloadPreset: Boolean,
  },
  watch: {
    reloadPreset(value) {
      if (value) {
        this.selectedPreset = null;
        this.loadExistingPreset();
        this.loadDefaultPreset();
        this.$emit("presetReloaded");
        this.$emit("preset", "");
      }
    },
  },
  beforeMount() {
    this.loadExistingPreset();
    this.loadDefaultPreset();
    axios
      .get(
        process.env.VUE_APP_API_ADDR + "/api/auth/isRoot",

        {
          headers: {
            Authorization: tokenStore.getToken(),
          },
        }
      )
      .then((res) => {
        this.root = res.data.root;
      })
      .catch((error) => {
        console.log({ error });
      });
  },
  methods: {
    selectPreset(preset) {
      this.selectedPreset = preset;
      this.$emit("preset", this.selectedPreset.name);
    },
    previous() {
      this.selectedPreset = null;
      this.loadExistingPreset();
      this.loadDefaultPresets();
      this.$emit("preset", "");
    },
    loadExistingPreset() {
      this.$emit("preset", "");
      axios
        .get(process.env.VUE_APP_API_ADDR + "/api/preset/@me", {
          headers: {
            Authorization: tokenStore.getToken(),
          },
        })
        .then((res) => {
          this.presets = res.data.presets;
        })
        .catch(() => {
          //console.log(err.response.status)
        });
    },
    loadDefaultPreset() {
      axios
        .get(process.env.VUE_APP_API_ADDR + "/api/preset/default", {
          headers: {
            Authorization: tokenStore.getToken(),
          },
        })
        .then((res) => {
          this.defaultPresets = res.data.presets;
        })
        .catch(() => {
          //console.log(err.response.status)
        });
    },
    removePreset(preset) {
      axios
        .delete(
          process.env.VUE_APP_API_ADDR + "/api/preset/" + preset.id + "/@me",
          {
            headers: {
              Authorization: tokenStore.getToken(),
            },
          }
        )
        .then(() => {
          this.loadExistingPreset();
          this.loadDefaultPreset();
        })
        .catch(() => {
          //console.log(err.response.status)
        });
    },
    removeDefaultPreset(preset) {
      axios
        .delete(
          process.env.VUE_APP_API_ADDR + "/api/preset/default/" + preset.id,
          {
            headers: {
              Authorization: tokenStore.getToken(),
            },
          }
        )
        .then(() => {
          this.loadExistingPreset();
          this.loadDefaultPreset();
        })
        .catch(() => {
          //console.log(err.response.status)
        });
    },
  },
};
</script>
