import VueCookies from "vue-cookies";
import axios from "axios";

class TokenStore {
    constructor() {
        this.state = {
            token: null,
            username: null,
        }
    }

    removeToken() {
        this.token = null;
        VueCookies.set(
            "session_cookie_" + process.env.VUE_APP_COOKIENAME,
            null,
            1,
            null,
            null,
            null,
            "lax"
        );
    }

    setToken(token) {
        this.token = token;
        VueCookies.set(
            "session_cookie_" + process.env.VUE_APP_COOKIENAME,
            token,
            parseInt(process.env.VUE_APP_TOKEN_SESSION_SEC),
            null,
            null,
            null,
            "lax"
        );
    }

    setUsername(username) {
        this.username = username;
    }

    getUsername(){
        return this.username;
    }

    getToken() {

        this.token = VueCookies.get("session_cookie_" + process.env.VUE_APP_COOKIENAME);
        return this.token;
    }

    refreshToken() {
        console.log("refresh start")
        axios
            .get(
                process.env.VUE_APP_API_ADDR + "/api/auth/refresh",
                {
                    headers: { Authorization: this.token }
                }
            )
            .then(res => {
                if (res.data.success) {
                    this.removeToken();
                    this.setToken(res.data.token);

                }
                else {
                    this.removeToken();
                    window.location.reload();
                }
            })
            .catch((err) => {
                try {
                    if (err.response.statusText == "Unauthorized") {
                        console.log("killl");
                        this.removeToken();
                        window.location.reload();
                    }
                } catch {
                    //
                }
                console.log(err.response)
                console.log("refresh failed !")
            });
    }









}

export default new TokenStore();
