<template>
  <v-main v-if="selectedDashboard == null">
    <v-row class="ml-5 mr-5" justify="center" v-if="!isRedirecting">
      <v-col cols="9" class="pa-0">
        <v-card>
          <v-card-title
            style="border-bottom: 1px solid #e0e0e0"
            class="pt-0 pb-0"
          >
            <v-row align="center">
              <v-col cols="2">Dashboard</v-col>
              <v-col cols="2">
                <v-dialog v-model="dialog" max-width="500">
                  <template
                    v-if="!isRedirecting"
                    v-slot:activator="{ on, attrs }"
                  >
                    <v-btn color="primary" v-bind="attrs" v-on="on">
                      Create
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title
                      v-if="editingDashboard == null"
                      class="headline secondary"
                    >
                      Create Dashboard
                    </v-card-title>
                    <v-card-title v-else class="headline secondary">
                      Edit Dashboard
                    </v-card-title>
                    <v-card-text>
                      <v-row justify="center"
                        ><v-col cols="12" class="pt-0 pb-0">
                          <v-text-field
                            v-model="name"
                            label="Dashboard Name"
                            clearable
                          ></v-text-field></v-col
                        ><v-col cols="12" class="pt-0 pb-0">
                          <v-textarea
                            auto-grow
                            clearable
                            rows="1"
                            clear-icon="mdi-close-circle"
                            v-model="description"
                            label="Description"
                          ></v-textarea
                        ></v-col>
                        <v-col
                          v-if="editingDashboard == null"
                          cols="12"
                          class="pt-0 pb-0"
                        >
                          <v-radio-group v-model="checkbox">
                            <v-radio
                              v-for="item in [
                                { i: 1, label: 'No preset' },
                                { i: 2, label: 'Personal preset' },
                                { i: 3, label: 'Default preset' },
                              ]"
                              :key="item.i"
                              :label="item.label"
                              :value="item.i"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col
                          v-if="editingDashboard == null && checkbox == 2"
                          cols="12"
                          class="pt-0 pb-0"
                        >
                          <template v-if="presets.length > 0">
                            <v-select
                              v-model="choosenPreset"
                              :items="presets"
                              item-text="name"
                              item-value="id"
                              label="Preset"
                              clearable
                            ></v-select>
                          </template>
                          <template v-else> No preset registred</template>
                        </v-col>
                        <v-col
                          v-if="editingDashboard == null && checkbox == 3"
                          cols="12"
                          class="pt-0 pb-0"
                        >
                          <template v-if="defaultPresets.length > 0">
                            <v-select
                              v-model="choosenPreset"
                              :items="defaultPresets"
                              item-text="name"
                              item-value="id"
                              label="Default preset"
                              clearable
                            ></v-select>
                          </template>
                          <template v-else>
                            No default preset registred</template
                          >
                        </v-col>
                        <v-col cols="12" class="text-center">
                          <v-btn
                            v-if="editingDashboard != null"
                            color="primary"
                            @click="duplicateDashboard"
                          >
                            Duplicate
                          </v-btn></v-col
                        ></v-row
                      >
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-btn
                        color="primary"
                        text
                        @click="
                          () => {
                            dialog = false;
                            name = '';
                            description = '';
                            editingDashboard = null;
                            choosenPreset = null;
                            checkbox = 1;
                          }
                        "
                      >
                        Cancel
                      </v-btn>
                      <v-spacer></v-spacer>

                      <v-btn
                        v-if="editingDashboard == null"
                        color="primary"
                        text
                        @click="save"
                        :disabled="name.length < 3"
                      >
                        Save
                      </v-btn>
                      <v-btn
                        v-if="editingDashboard != null"
                        :disabled="name.length < 3"
                        color="primary"
                        text
                        @click="saveEditing"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="6">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field
              ></v-col>
            </v-row>
          </v-card-title>

          <v-card-text class="pl-0 pr-0" style="height: 74.1vh">
            <v-progress-linear
              indeterminate
              v-if="dashboardLoading"
            ></v-progress-linear>
            <v-virtual-scroll
              :items="
                sortedDashboards.filter((dash) => {
                  return (
                    dash.id.toLowerCase().includes(this.search.toLowerCase()) ||
                    dash.name.toLowerCase().includes(this.search.toLowerCase())
                  );
                })
              "
              :item-height="135"
            >
              <template v-slot="{ item }">
                <v-card
                  class="mt-3 mb-3 mr-3 ml-3"
                  outlined
                  :ripple="false"
                  @click="selectDashboard(item)"
                >
                  <v-app-bar class="pt-1" flat dense color="rgba(0, 0, 0, 0)">
                    <v-toolbar-title class="title pl-0">
                      {{ item.name }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      icon
                      @click.stop="chooseEditDashboard(item)"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                    <confirm
                      type="dashboard"
                      :item="item"
                      @removeConfirmed="removeDashboard(item)"
                    ></confirm>
                  </v-app-bar>
                  <v-card-subtitle class="mb-0 pt-0">
                    <div>{{ item.id }}</div>

                    <div>Active widgets : {{ item.widgets.length }}</div>
                  </v-card-subtitle>
                  <v-card-text
                    ><div>{{ item.description }}</div></v-card-text
                  >
                </v-card>
              </template>
            </v-virtual-scroll>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row></v-main
  >
  <v-main v-else>
    <router-view
      :selectedDashboard="selectedDashboard"
      :dashBoardList="dashboards"
      @previous="previous"
      @redirectDashboard="redirectDashboard"
    ></router-view
  ></v-main>
</template>


<script>
import axios from "axios";
import tokenStore from "../../store/TokenStore";
import ConfirmRemove from "../ConfirmRemove.vue";

export default {
  components: {
    confirm: ConfirmRemove,
  },
  data: () => ({
    dialog: false,
    name: "",
    description: "",
    dashboards: [],
    selectedDashboard: null,
    editingDashboard: null,
    dialog1: false,
    checkbox: 1,
    presets: [],
    defaultPresets: [],
    choosenPreset: null,
    isRedirecting: false,
    search: "",
    dashboardLoading: false,
  }),
  computed: {
    sortedDashboards: function () {
      if (this.dashboards) {
        let sortedGroups = this.dashboards;
        if (this.dashboards.length == 0) sortedGroups = [];
        sortedGroups = sortedGroups.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        return sortedGroups;
      } else {
        return [];
      }
    },
  },
  props: {
    reloadDashboard: Boolean,
  },
  watch: {
    reloadDashboard(value) {
      if (value) {
        this.selectedDashboard = null;
        this.loadExistingDashboard();
        this.loadExistingPresets();
        this.loadDefaultPresets();
        this.$emit("dashboardReloaded");
        this.$emit("dashboard", "");
      }
    },
    dashboards(v) {
      console.log(v);
    },
  },
  beforeMount() {
    setTimeout(() => {
      this.loadExistingDashboard();
      this.loadDefaultPresets();
      this.loadExistingPresets();
      this.isRedirecting = false;
    }, 1);
  },
  mounted() {
    this.$router.beforeEach((to, from, next) => {
      const IsItABackButton = window.popStateDetected;
      window.popStateDetected = false;
      if (IsItABackButton) {
        let pathArray = to.path.split("/");
        
        if (pathArray.length == 2 && pathArray[1] == "dashboard") {
          this.$router.push("/dashboard");
          this.previous();
        } else if (pathArray.length > 2 && pathArray[1] == "dashboard") {
          let i = this.dashboards.findIndex((dash) => {
            return dash.id == pathArray[2];
          });
          if (i >= 0) {
            this.redirectDashboard(pathArray[2]);
          } else {
            this.$router.push("/dashboard");
           this.previous();
          }
        }
      }
      if (IsItABackButton && from.meta.someLogica) {
        next(false);
        return "";
      }
      next();
    });
  },
  methods: {
    redirectDashboard(dashboardId) {
      this.isRedirecting = true;
      this.selectedDashboard = null;
      let index = this.dashboards.findIndex((dash) => {
        return dash.id == dashboardId;
      });
      setTimeout(() => {
        this.isRedirecting = false;
        this.selectDashboard(this.dashboards[index]);
      }, 1);
    },
    duplicateDashboard() {
      this.dialog = false;
      this.name += " copy";
      axios
        .post(
          process.env.VUE_APP_API_ADDR + "/api/dashboard",
          {
            name: this.name,
            description: this.description,
          },
          {
            headers: {
              Authorization: tokenStore.getToken(),
            },
          }
        )
        .then((res1) => {
          this.name = "";
          this.description = "";
          axios
            .get(
              process.env.VUE_APP_API_ADDR +
                "/api/dashboard/" +
                this.editingDashboard.id +
                "/widget",
              {
                headers: {
                  Authorization: tokenStore.getToken(),
                },
              }
            )
            .then((res2) => {
              axios
                .put(
                  process.env.VUE_APP_API_ADDR +
                    "/api/dashboard/" +
                    res1.data.dashboard.id +
                    "/widget",
                  {
                    widgets: res2.data.widgets,
                  },
                  {
                    headers: {
                      Authorization: tokenStore.getToken(),
                    },
                  }
                )
                .then(() => {
                  this.loadExistingDashboard();
                  this.loadDefaultPresets();
                  this.loadExistingPresets();
                })
                .catch(() => {
                  //console.log(err.response.status)
                });
            })
            .catch(() => {
              //console.log(err.response.status)
            });
        })
        .catch(() => {
          //console.log(err.response.status)
        });
    },
    previous() {
      this.selectedDashboard = null;
      this.loadExistingDashboard();
      this.loadExistingPresets();
      this.loadDefaultPresets();
      this.$emit("dashboard", "");
    },
    removeDashboard(dash) {
      axios
        .delete(process.env.VUE_APP_API_ADDR + "/api/dashboard/" + dash.id, {
          headers: {
            Authorization: tokenStore.getToken(),
          },
        })
        .then(() => {
          this.loadExistingDashboard();
          this.loadDefaultPresets();
          this.loadExistingPresets();
        })
        .catch(() => {
          //console.log(err.response.status)
        });
    },
    chooseEditDashboard(dash) {
      this.editingDashboard = dash;
      this.dialog = true;
      this.name = dash.name;
      this.description = dash.description;
    },
    save() {
      if (this.checkbox == 2 && this.choosenPreset != null) {
        axios
          .post(
            process.env.VUE_APP_API_ADDR +
              "/api/preset/" +
              this.choosenPreset +
              "/@me",
            { name: this.name, description: this.description },
            {
              headers: {
                Authorization: tokenStore.getToken(),
              },
            }
          )
          .then(() => {
            this.dialog = false;
            this.name = "";
            this.description = "";
            this.choosenPreset = "";
            this.checkbox = 1;
            this.loadExistingDashboard();
            this.loadExistingPresets();
            this.loadDefaultPresets();
          })
          .catch(() => {
            //console.log(err.response.status)
          });
      } else if (this.checkbox == 1) {
        axios
          .post(
            process.env.VUE_APP_API_ADDR + "/api/dashboard",
            {
              name: this.name,
              description: this.description,
            },
            {
              headers: {
                Authorization: tokenStore.getToken(),
              },
            }
          )
          .then(() => {
            this.dialog = false;
            this.name = "";
            this.description = "";
            this.choosenPreset = "";
            this.checkbox = 1;
            this.loadExistingDashboard();
            this.loadExistingPresets();
            this.loadDefaultPresets();
          })
          .catch(() => {
            //console.log(err.response.status)
          });
      } else if (this.checkbox == 3) {
        axios
          .post(
            process.env.VUE_APP_API_ADDR +
              "/api/preset/default/" +
              this.choosenPreset,
            { name: this.name, description: this.description },
            {
              headers: {
                Authorization: tokenStore.getToken(),
              },
            }
          )
          .then(() => {
            this.dialog = false;
            this.name = "";
            this.description = "";
            this.choosenPreset = "";
            this.checkbox = 1;
            this.loadExistingDashboard();
            this.loadExistingPresets();
            this.loadDefaultPresets();
          })
          .catch(() => {
            //console.log(err.response.status)
          });
      }
    },
    saveEditing() {
      axios
        .put(
          process.env.VUE_APP_API_ADDR +
            "/api/dashboard/" +
            this.editingDashboard.id,
          {
            name: this.name,
            description: this.description,
          },
          {
            headers: {
              Authorization: tokenStore.getToken(),
            },
          }
        )
        .then(() => {
          this.dialog = false;
          this.name = "";
          this.description = "";
          this.editingDashboard = null;
          this.loadExistingDashboard();
          this.loadExistingPresets();
          this.loadDefaultPresets();
        })
        .catch(() => {
          //console.log(err.response.status)
        });
    },
    loadExistingDashboard() {
      this.$emit("dashboard", "");
      this.dashboardLoading = true;
      axios
        .get(process.env.VUE_APP_API_ADDR + "/api/dashboard/@me", {
          headers: {
            Authorization: tokenStore.getToken(),
          },
        })
        .then((res) => {
          this.dashboards = res.data.dashboards;

          this.dashboardLoading = false;
          let pathArray = this.$router.currentRoute.path.split("/");
          if (pathArray.length > 2 && pathArray[2] != "") {
            let i = this.dashboards.findIndex((dash) => {
              return dash.id == pathArray[2];
            });
            if (i >= 0) {
              this.redirectDashboard(pathArray[2]);
            } else {
              this.$router.push("/dashboard");
               this.previous();
            }
          }
        })
        .catch(() => {
          //console.log(err.response.status)
        });
    },
    loadExistingPresets() {
      axios
        .get(process.env.VUE_APP_API_ADDR + "/api/preset/@me", {
          headers: {
            Authorization: tokenStore.getToken(),
          },
        })
        .then((res) => {
          this.presets = res.data.presets;
        })
        .catch(() => {
          //console.log(err.response.status)
        });
    },
    loadDefaultPresets() {
      axios
        .get(process.env.VUE_APP_API_ADDR + "/api/preset/default", {
          headers: {
            Authorization: tokenStore.getToken(),
          },
        })
        .then((res) => {
          this.defaultPresets = res.data.presets;
        })
        .catch(() => {
          //console.log(err.response.status)
        });
    },
    selectDashboard(dash) {
      this.selectedDashboard = dash;
      let pathArray = this.$router.currentRoute.path.split("/");
      if (pathArray.length > 2 && pathArray[2] == dash.id) {
        console.log("guiiiii");
      } else {
        this.$router.push("/dashboard/" + dash.id);
      }
      this.$emit("dashboard", this.selectedDashboard.name);
    },
  },
};
</script>

