
<template>
  <v-main>
    <v-dialog
      v-model="dialogDeviceOption"
      scrollable
      width="600px"
      @click:outside="leaveDeviceOptionDialog"
    >
      <v-card>
        <v-card-title class="headline secondary"> Device options </v-card-title>
        <v-card-text class="mt-5">
          <v-row>
            <v-col cols="12">
              <v-text-field
                hide-details
                dense
                label="Name"
                v-model="deviceName"
              >
                <v-btn icon slot="append" color="primary" @click="resetName">
                  <v-icon>mdi-cached</v-icon>
                </v-btn></v-text-field
              ></v-col
            >
            <v-col cols="6">
              <v-text-field
                hide-details
                v-model="deviceLat"
                label="Lat"
              ></v-text-field
            ></v-col>
            <v-col cols="6">
              <v-text-field
                hide-details
                v-model="deviceLong"
                label="Long"
              ></v-text-field
            ></v-col> </v-row></v-card-text
        ><v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="leaveDeviceOptionDialog">
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="saveDeviceOptionDialog">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row class="ml-5 mr-5">
      <v-col cols="6" class="pa-0 pr-2">
        <v-card>
          <v-card-title>
            Unregistred Devices
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search2"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            fixed-header
            height="67.3vh"
            :headers="headers2"
            :items="devicesLoading || groupsLoading ? [] : devices"
            :items-per-page="15"
            :loading="devicesLoading || groupsLoading"
            :search="search2"
          >
            <template v-slot:item="{ item }">
              <tr
                style="
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: normal;
                "
              >
                <td style="max-width: 300px !important">{{ item.name }}</td>
                <td style="max-width: 300px !important">{{ item.id }}</td>
                <td class="text-center">
                  <v-icon
                    class="mr-1"
                    small
                    color="primary"
                    @click="editItem(item)"
                    >mdi-pencil</v-icon
                  >
                  <check-data :item="item"></check-data>
                  <v-icon
                    color="primary"
                    class="ml-1"
                    :disabled="expanded.length == 0"
                    @click="addToGroup(item)"
                    >mdi-arrow-right-thick</v-icon
                  >
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>

      <v-col cols="6" class="pa-0 pl-2">
        <v-card>
          <v-card-title>
            Groups
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            fixed-header
            height="67.3vh"
            :headers="headers"
            :items="
              devicesLoading || groupsLoading
                ? []
                : groups.filter(function (group) {
                    return group.groupLevel == 2;
                  })
            "
            :items-per-page="15"
            :loading="devicesLoading || groupsLoading"
            :search="search"
            :single-expand="true"
            :expanded.sync="expanded"
            show-expand
            :disabled="true"
          >
            <template v-slot:item="{ item }">
              <tr
                style="
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: normal;
                "
              >
                <td
                  :style="
                    item == expanded[0]
                      ? 'background-color:#cccccc !important;border-left: 2px solid #006FB7;border-top: 2px solid #006FB7;border-bottom: 2px solid #006FB7;border-top-left-radius: 2px !important;border-bottom-left-radius: 2px !important;'
                      : ''
                  "
                >
                  <p class="font-weight-black mb-0">{{ item.name }}</p>

                  <p class="font-italic mb-0">
                    {{ item.grandParentObject.name }} ->
                    {{ item.parentObject.name }}
                  </p>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        class="ml-1 mb-1"
                        v-show="item.description != ''"
                        small
                        >mdi-information-outline</v-icon
                      >
                    </template>
                    <span>{{ item.description }}</span>
                  </v-tooltip>
                </td>
                <td
                  :style="
                    item == expanded[0]
                      ? 'background-color:#cccccc !important;border-top: 2px solid #006FB7;border-bottom: 2px solid #006FB7;'
                      : ''
                  "
                >
                  {{ item.id }}
                </td>
                <td
                  :style="
                    item == expanded[0]
                      ? 'background-color:#cccccc !important;border-top: 2px solid #006FB7;border-bottom: 2px solid #006FB7;'
                      : ''
                  "
                >
                  {{ item.devices.length }}
                </td>
                <td
                  :style="
                    item == expanded[0]
                      ? 'background-color:#cccccc !important;border-right: 2px solid #006FB7;border-top: 2px solid #006FB7;border-bottom: 2px solid #006FB7;border-top-right-radius: 2px !important;border-bottom-right-radius: 2px !important;'
                      : ''
                  "
                >
                  <v-icon v-if="item == expanded[0]" @click="expanded.pop()"
                    >mdi-menu-up</v-icon
                  >
                  <v-icon v-else @click="expand(item)">mdi-menu-down</v-icon>
                </td>
              </tr>
            </template>
            <template v-slot:expanded-item="{ item, headers }">
              <td :colspan="headers.length" class="pa-0">
                <v-card elevation="20">
                  <v-card-title>
                    Registered Device in {{ item.name }}
                    <v-spacer></v-spacer>
                    <v-text-field
                      v-model="search3"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-card-title>
                  <v-data-table
                    fixed-header
                    :headers="headers3"
                    :search="search3"
                    :items="selectedRegisteredDevices"
                    :items-per-page="5"
                    :loading="selectedRegisteredDevicesLoading"
                    :footer-props="{
                      'disable-items-per-page': true,
                    }"
                    elevation="5"
                  >
                    <template v-slot:item="{ item }">
                      <tr
                        style="
                          overflow: hidden;
                          text-overflow: ellipsis;
                          white-space: normal;
                        "
                      >
                        <td class="text-center">
                          <v-icon
                            color="primary"
                            class="mr-1"
                            :disabled="expanded.length == 0"
                            @click="removeFromGroup(item)"
                            >mdi-arrow-left-thick</v-icon
                          >
                          <check-data :item="item"></check-data>
                          <v-icon
                            class="ml-1"
                            small
                            color="primary"
                            @click="editItem(item)"
                            >mdi-pencil</v-icon
                          >
                        </td>
                        <td style="max-width: 300px !important">
                          {{ item.name }}
                        </td>
                        <td style="max-width: 300px !important">
                          {{ item.id }}
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-card>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import axios from "axios";
import tokenStore from "../store/TokenStore";

import CheckDataDialog from "./CheckDataDialog.vue";

export default {
  components: {
    "check-data": CheckDataDialog,
  },
  data: () => ({
    dialogDeviceOption: false,
    expanded: [],
    headers: [
      {
        text: "Name",
        sortable: true,
        value: "name",
        width: "20vh",
        align: "start",
      },
      {
        text: "Id",
        value: "id",
        width: "35vh",
        align: "start",
      },

      {
        text: "Devices Registered",
        value: "devices.length",
        sortable: true,
        width: "11vh",
        align: "start",
      },
      {
        text: "",
        value: "data-table-expand",

        sortable: false,
        align: "start",
      },
    ],
    headers2: [
      {
        text: "Name",
        sortable: true,
        value: "name",
        width: "300px",
        align: "start",
      },
      {
        text: "Id",
        value: "id",
        width: "300px",
        align: "start",
      },
      {
        text: "Actions",
        value: "action",
        sortable: false,
        align: "center",
      },
    ],
    headers3: [
      {
        text: "Actions",
        value: "action",
        sortable: false,
        align: "center",
      },
      {
        text: "Name",
        sortable: true,
        value: "name",
        width: "300px",
        align: "start",
      },
      {
        text: "Id",
        value: "id",
        width: "300px",
        align: "start",
      },
    ],
    search: "",
    search2: "",
    search3: "",
    groups: [],
    groupsLoading: false,
    devices: [],
    registeredDevices: [],
    devicesLoading: false,
    selectedRegisteredDevices: [],
    selectedRegisteredDevicesLoading: false,
    deviceName: null,
    deviceId: null,
    deviceLat: null,
    deviceLong: null,
    selectedItem: null,
  }),
  computed: {},
  beforeMount() {
    setTimeout(() => {
      this.loadGroups();
      this.loadDevices();
    }, 1);
  },
  methods: {
    addToGroup(item) {
      //item is a device
      axios
        .post(
          process.env.VUE_APP_API_ADDR +
            "/api/group/" +
            this.expanded[0].id +
            "/device",
          {
            id: item.id,
          },
          {
            headers: {
              Authorization: tokenStore.getToken(),
            },
          }
        )
        .then(() => {
          item.registered = true;
          item.groups.push(this.expanded[0].id);
          this.devices.splice(
            this.devices.findIndex((device) => {
              return device.id == item.id;
            }),
            1
          );
          this.registeredDevices.push(item);
          this.selectedRegisteredDevices.push(item);

          this.groups[
            this.groups.findIndex((group) => {
              return group.id == this.expanded[0].id;
            })
          ].devices.push(item.id);
        });
    },
    removeFromGroup(item) {
      //item is a device
      axios
        .delete(
          process.env.VUE_APP_API_ADDR +
            "/api/group/" +
            this.expanded[0].id +
            "/device",
          {
            data: {
              id: item.id,
            },
            headers: {
              Authorization: tokenStore.getToken(),
            },
          }
        )
        .then(() => {
          item.registered = false;

          item.groups.splice(
            item.groups.findIndex((id) => {
              return id == this.expanded[0].id;
            }),
            1
          );

          this.devices.push(item);

          this.registeredDevices.splice(
            this.registeredDevices.findIndex((device) => {
              return device.id == item.id;
            }),
            1
          );

          this.selectedRegisteredDevices.splice(
            this.selectedRegisteredDevices.findIndex((device) => {
              return device.id == item.id;
            }),
            1
          );

          let index = this.groups.findIndex((group) => {
            return group.id == this.expanded[0].id;
          });
          this.groups[index].devices.splice(
            this.groups[index].devices.findIndex((id) => {
              return id == this.expanded[0].id;
            }),
            1
          );
        });
    },
    expand(item) {
      //item is a group
      this.expanded = [item];
      this.selectedRegisteredDevices = [];
      this.selectedRegisteredDevicesLoading = true;
      this.registeredDevices.forEach((device) => {
        if (device.groups[0] == item.id) {
          this.selectedRegisteredDevices.push(device);
        }
      });
      this.selectedRegisteredDevicesLoading = false;
    },
    editItem(item) {
      //item is a group
      this.selectedItem = item;
      this.deviceName = item.name;
      this.deviceId = item.id;
      this.deviceLat = item.lat;
      this.deviceLong = item.long;
      this.dialogDeviceOption = true;
    },
    resetName() {
      this.deviceName = this.deviceId;
    },
    saveDeviceOptionDialog() {
      axios
        .post(
          process.env.VUE_APP_API_ADDR +
            "/api/device/" +
            this.deviceId +
            "/position",
          {
            lat: this.deviceLat,
            long: this.deviceLong,
          },
          {
            headers: {
              Authorization: tokenStore.getToken(),
            },
          }
        )
        .then(() => {
          axios
            .put(
              process.env.VUE_APP_API_ADDR +
                "/api/device/" +
                this.deviceId +
                "/name",
              {
                name: this.deviceName,
              },
              {
                headers: {
                  Authorization: tokenStore.getToken(),
                },
              }
            )
            .then(() => {
              let i = this.devices.findIndex((device) => {
                return device.id == this.deviceId;
              });
              if (i >= 0) {
                this.devices[i].name = this.deviceName;
                this.devices[i].lat = this.deviceLat;
                this.devices[i].long = this.deviceLong;
              } else {
                let a = this.registeredDevices.findIndex((device) => {
                  return device.id == this.deviceId;
                });

                let b = this.selectedRegisteredDevices.findIndex((device) => {
                  return device.id == this.deviceId;
                });

                this.registeredDevices[a].name = this.deviceName;
                this.registeredDevices[a].lat = this.deviceLat;
                this.registeredDevices[a].long = this.deviceLong;

                this.selectedRegisteredDevices[b].name = this.deviceName;
                this.selectedRegisteredDevices[b].lat = this.deviceLat;
                this.selectedRegisteredDevices[b].long = this.deviceLong;
              }

              this.leaveDeviceOptionDialog();
            });
        });
    },
    leaveDeviceOptionDialog() {
      this.deviceName = null;
      this.deviceLat = null;
      this.deviceLong = null;
      this.deviceId = null;
      this.dialogDeviceOption = false;
    },
    loadDevices() {
      this.devices = [];
      this.devicesLoading = true;
      axios
        .get(process.env.VUE_APP_API_ADDR + "/api/device/@me", {
          headers: {
            Authorization: tokenStore.getToken(),
          },
        })
        .then((res) => {
          res.data.devices.forEach((device) => {
            if (!device.registered) {
              this.devices.push(device);
            } else {
              this.registeredDevices.push(device);
            }
          });

          console.log("device2");
          this.devicesLoading = false;
        });
    },
    loadGroups() {
      this.groups = [];
      this.groupsLoading = true;
      axios
        .get(process.env.VUE_APP_API_ADDR + "/api/group/@me", {
          headers: {
            Authorization: tokenStore.getToken(),
          },
        })
        .then((res) => {
          res.data.groups.forEach((group) => {
            group.parentObject = res.data.groups.find((g) => {
              return g.id == group.parentId;
            });
            if (group.parentObject != undefined) {
              group.grandParentObject = res.data.groups.find((g) => {
                return g.id == group.parentObject.parentId;
              });
            }
            this.groups.push(group);
          });

          console.log("group2");
          this.groupsLoading = false;
        });
    },
  },
};
</script>
