<template>
  <v-main>
    <v-dialog v-model="dialog" scrollable @click:outside="$emit('leaveUploadDialog')">
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn class="mr-5" depressed color="primary" v-bind="attrs" v-on="on">
          <v-icon left>mdi-cog</v-icon>
          Image
        </v-btn>
      </template> -->

      <v-card height="90vh">
        <v-card-title class="headline secondary">
          Upload Image
        </v-card-title>

        <v-card-text>
          <v-flex
            xs12
            class="text-xs-center text-sm-center text-md-center text-lg-center pt-5 pl-5 pr-5"
          >
            <v-row align="center" justify="center"
              ><v-col cols="6" class="pb-0">
                <v-file-input
                  accept="image/*"
                  label="File input"
                  ref="myfile"
                  v-model="file"
                  @change="onFilePicked"
                  class="mb-5 pb-0"
                ></v-file-input></v-col></v-row
            ><v-row align="center" justify="center"
              ><v-col cols="6" class="pt-0">
                <v-slider
                  v-model="slider"
                  class="align-center mb-3 pt-0"
                  :max="1650"
                  :min="100"
                  hide-details
                ></v-slider></v-col
            ></v-row>

            <br />
            <img
            ref="image"
              class="mb-5"
              :width="slider + 'px'"
              
              :src="imageUrl"
              v-if="imageUrl"
            /> </v-flex
        ></v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="uploadCanceled"> Cancel </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="uploadImg"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import axios from "axios";
import tokenStore from "../../store/TokenStore";

export default {
  data: () => ({
    title: "Image Upload",
    dialog: false,
    file: {},
    imageName: "",
    imageUrl: "",
    imageFile: "",
    formData: new FormData(),
    slider: 875,
  }),
  props: {
    dashboard: Object,
    uploadImage: Boolean,
  },
  watch: {
    uploadImage(value) {
      if (value) {
        this.dialog = value;
      }
    },
  },
  methods: {
    uploadCanceled() {
      this.dialog = false;
      this.$emit("leaveUploadDialog");
      this.file = {};
      this.imageName = "";
      this.imageUrl = "";
      this.imageFile = "";
    },
    uploadImg() {
      axios
        .post(
          process.env.VUE_APP_API_ADDR + "/api/upload/" + this.dashboard.id,
          this.formData,
          {
            headers: {
              Authorization: tokenStore.getToken(),
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          axios
            .put(
              process.env.VUE_APP_API_ADDR +
                "/api/dashboard/" +
                this.dashboard.id +
                "/imageWidth",
              {
                width: this.slider,
                height: this.$refs.image.scrollHeight,
              },
              {
                headers: {
                  Authorization: tokenStore.getToken(),
                },
              }
            )
            .then(() => {
              this.dashboard.imgWidth = this.$refs.image.scrollWidth;
              this.dashboard.imgHeight = this.$refs.image.scrollHeight;
              this.dialog = false;
              this.$emit("leaveUploadDialog");
              this.file = {};
              this.imageName = "";
              this.imageUrl = "";
              this.imageFile = "";
              this.formData = new FormData();
              console.log(this.dashboard.imgWidth,this.dashboard.imgHeight);
              this.$emit("uploaded", res.data.imgId);
            });
        })
        .catch((error) => {
          console.log({ error });
        });
    },
    onFilePicked() {
      this.imageName = "";
      this.imageUrl = "";
      this.imageFile = "";
      this.formData = new FormData();
      if (this.files !== {}) {
        this.imageName = this.file.name;

        const fr = new FileReader();
        fr.readAsDataURL(this.file);
        fr.addEventListener("load", () => {
          this.imageUrl = fr.result;
          this.imageFile = this.file; // this is an image file that can be sent to server..
          this.formData.append("file", this.file);
        });
      } else {
        this.imageName = "";
        this.imageFile = "";
        this.imageUrl = "";
      }
    },
  },
};
</script>
