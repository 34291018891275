<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :disabled="
          type == 'group' &&
          (item.subGroups.length > 0 || item.devices.length > 0)
        "
        color="primary"
        icon
        small
        v-bind="attrs"
        v-on="on"
      >
        <v-icon small>mdi-delete</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="headline secondary"> Confirm ?</v-card-title>
      <v-card-text
        ><div class="mt-5">Do you really want to remove this ?</div>
        <br />
        <div>{{ type.charAt(0).toUpperCase() + type.slice(1) }}</div>
        <div v-if="item.name" class="">Name : {{ item.name }}</div>
        <div v-if="item.id" class="">Id : {{ item.id }}</div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn color="primary" text @click="dialog = false"> No </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="warning" text @click="confirmed"> Yes ! </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
  }),
  props: {
    item: Object,
    type: String,
  },
  methods: {
    confirmed() {
      this.$emit("removeConfirmed");
      this.dialog = false;
    },
  },
};
</script>


