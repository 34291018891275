<template>
  <v-main v-if="selectedAlert == null">
    <v-row justify="center">
      <v-dialog
        v-model="dialog2"
        max-width="500"
        @click:outside="
          () => {
            dialog2 = false;
            step = 0;
            name = '';

            description = '';
            devices = [];

            channels = [];
            selectedChannel = null;
            disabledChannel = true;
            selectedGroupChannel = null;
          }
        "
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" v-bind="attrs" v-on="on"> Create </v-btn>
        </template>
        <v-card>
          <v-card-title class="headline grey lighten-2">
            Create Alert
          </v-card-title>
          <template v-if="step == 0">
            <v-app-bar flat color="rgba(0, 0, 0, 0)">
              <v-toolbar-title class="title pl-0">
                Choose your alert type
              </v-toolbar-title>
            </v-app-bar>
            <v-card-text>
              <v-row justify="center"
                ><v-col cols="12" class="pt-0 pb-0">
                  <v-text-field
                    v-model="name"
                    label="Alert Name"
                    clearable
                  ></v-text-field
                ></v-col>
                <v-col cols="12" class="pt-0 pb-0">
                  <v-textarea
                    auto-grow
                    clearable
                    rows="1"
                    clear-icon="mdi-close-circle"
                    v-model="description"
                    label="Description"
                  ></v-textarea></v-col
              ></v-row>
              <v-row>
                <v-col>
                  <v-radio-group v-model="radioGroup">
                    <v-radio
                      v-for="(name, i) in [
                        'Alert with simple limit',
                        'Alert in % of value from other sensor',
                      ]"
                      :key="i"
                      :label="name"
                      :value="i"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row> </v-card-text
          ></template>

          <template v-if="step == 1 && radioGroup == 0">
            <v-app-bar flat color="rgba(0, 0, 0, 0)">
              <v-toolbar-title class="title pl-0">
                Configure your group alert
              </v-toolbar-title>
            </v-app-bar>
            <v-card-text>
              <v-row>
                <v-row class="pl-5 pr-5" align="center" justify="center">
                  <v-col cols="2"></v-col>
                  <v-col cols="8">
                    <v-autocomplete
                      v-model="selectedGroupChannel"
                      :items="sortedGroups"
                      item-text="name"
                      item-value="id"
                      :label="'Group'"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="2"></v-col
                ></v-row>
                <v-row class="pl-5 pr-5" align="center" justify="center">
                  <v-col cols="1"></v-col>
                  <v-col cols="1">
                    <v-progress-circular
                      v-if="selectedGroupChannel && disabledChannel"
                      class="text-center"
                      indeterminate
                      color="primary"
                    ></v-progress-circular
                  ></v-col>
                  <v-col cols="8">
                    <v-autocomplete
                      :disabled="disabledChannel"
                      v-model="selectedChannel"
                      :items="channels"
                      :item-text="(item) => item.id + ' : ' + item.name"
                      item-value="id"
                      :label="'Channel'"
                    ></v-autocomplete> </v-col
                  ><v-col cols="2"></v-col>
                  <v-col class="pr-5 pl-5" cols="4"><v-text-field v-model="sliderMin" label="min" type="number"></v-text-field></v-col>
                  <v-col class="pr-5 pl-5" cols="4"><v-text-field v-model="sliderStep" label="step" type="number"></v-text-field></v-col>
                  <v-col class="pr-5 pl-5" cols="4"><v-text-field v-model="sliderMax" label="max" type="number"></v-text-field></v-col>
                </v-row>
              </v-row>
              <v-alert v-if="alertRange[0] < alarmRange[0]" type="error">
                <div>
                  <span class="font-weight-black">Warning : </span>
                  the min treshold for warning should be higher than alarm.
                  Otherwise warning level will never be triggered.
                </div></v-alert
              >
              <v-alert v-if="alarmRange[1] < alertRange[1]" type="error">
                <div>
                  <span class="font-weight-black">Warning : </span>
                  the max treshold for warning should be lower than alarm.
                  Otherwise warning level will never be triggered.
                </div></v-alert
              >
            </v-card-text>
            <v-app-bar flat color="orange">
              <v-toolbar-title class="title pl-0">
                Select warning level
              </v-toolbar-title>
            </v-app-bar>
            <v-card-text>
              <v-row>
                <v-col cols="12" class="pt-5 pb-0 mb-0"
                  ><v-range-slider
                    v-model="alertRange"
                    :thumb-size="24"
                    thumb-label="always"
                    :max="sliderMax"
                    :min="sliderMin"
                    :step="sliderStep"
                    class="mt-5 mb-0"
                  ></v-range-slider
                ></v-col>
              </v-row>
            </v-card-text>

            <v-app-bar flat color="red">
              <v-toolbar-title class="title pl-0">
                Select alarm level
              </v-toolbar-title>
            </v-app-bar>
            <v-card-text>
              <v-row>
                <v-col cols="12" class="pt-5 pb-0 mt-0"
                  ><v-range-slider
                    v-model="alarmRange"
                    :thumb-size="24"
                    thumb-label="always"
                    :max="sliderMax"
                    :min="sliderMin"
                    :step="sliderStep"
                    class="mt-5 mb-0"
                  ></v-range-slider
                ></v-col>
              </v-row>
            </v-card-text>
          </template>
          <template v-if="step == 1 && radioGroup == 1">
            <v-app-bar flat color="rgba(0, 0, 0, 0)">
              <v-toolbar-title class="title pl-0">
                Configure your group alert
              </v-toolbar-title>
            </v-app-bar>
            <v-card-text>
              <v-row>
                <v-row align="center" justify="center">
                  <v-col cols="2"></v-col>
                  <v-col cols="8">
                    <v-autocomplete
                      v-model="selectedGroupChannel"
                      :items="sortedGroups"
                      item-text="name"
                      item-value="id"
                      :label="'Group'"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="2"></v-col
                ></v-row>
                <v-row align="center" justify="center">
                  <v-col cols="1"></v-col>
                  <v-col cols="1">
                    <v-progress-circular
                      v-if="selectedGroupChannel && disabledChannel"
                      class="text-center"
                      indeterminate
                      color="primary"
                    ></v-progress-circular
                  ></v-col>
                  <v-col cols="8">
                    <v-autocomplete
                      :disabled="disabledChannel"
                      v-model="selectedChannel"
                      :items="channels"
                      :item-text="(item) => item.id + ' : ' + item.name"
                      item-value="id"
                      :label="'Channel'"
                    ></v-autocomplete> </v-col
                  ><v-col cols="2"></v-col>
                </v-row> </v-row
            ></v-card-text>
            <v-app-bar flat color="orange">
              <v-toolbar-title class="title pl-0">
                Select warning level
              </v-toolbar-title>
            </v-app-bar>
            <v-card-text>
              <v-row>
                <v-col cols="6">
                  <v-select
                    :items="['>', '<']"
                    label="Operator"
                    dense
                    hide-details
                    outlined
                    v-model="operator"
                  ></v-select></v-col
                ><v-col cols="6">
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    label="Percentage"
                    v-model="percentageAlert"
                    min="10"
                    max="99"
                    type="number"
                  />
                </v-col>
              </v-row>
              <div>
                <span class="font-weight-black">Warning</span> level will be
                triggered when sensor reaches a value
                <span class="font-weight-black"
                  >{{ operator }} at {{ percentageAlert }} %
                </span>
                of the median of all other sensors selected in the group
              </div>
              <v-alert
                v-if="
                  operator == '<' &&
                  parseInt(percentageAlert) < parseInt(percentageAlarm)
                "
                type="error"
              >
                <div>
                  <span class="font-weight-black">Warning : </span> with
                  <span class="font-weight-black">{{ operator }} </span>
                  operator, percentage value from alarm level must be
                  {{ operator }} at percentage value from alert level !
                </div></v-alert
              >
              <v-alert
                v-if="
                  operator == '>' &&
                  parseInt(percentageAlert) > parseInt(percentageAlarm)
                "
                type="error"
              >
                <div>
                  <span class="font-weight-black">Warning : </span> with
                  <span class="font-weight-black">{{ operator }} </span>
                  operator, percentage value from alarm level must be
                  {{ operator }} at percentage value from alert level !
                </div></v-alert
              >
            </v-card-text>

            <v-app-bar flat color="red">
              <v-toolbar-title class="title pl-0">
                Select alarm level
              </v-toolbar-title>
            </v-app-bar>
            <v-card-text>
              <v-row>
                <v-col cols="6">
                  <v-select
                    :items="['>', '<']"
                    label="Operator"
                    dense
                    hide-details
                    outlined
                    v-model="operator"
                  ></v-select></v-col
                ><v-col cols="6">
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    label="Percentage"
                    v-model="percentageAlarm"
                    min="10"
                    max="99"
                    type="number"
                  />
                </v-col>
              </v-row>
              <div>
                <span class="font-weight-black">Alarm</span> level will be
                triggered when sensor reaches a value
                <span class="font-weight-black"
                  >{{ operator }} at {{ percentageAlarm }} %
                </span>
                of the median of all other sensors selected in the group
              </div>
            </v-card-text>
          </template>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              color="primary"
              text
              @click="
                () => {
                  dialog2 = false;
                  step = 0;
                  name = '';
                  description = '';

                  description = '';
                  devices = [];

                  channels = [];
                  selectedChannel = null;
                  disabledChannel = true;
                  selectedGroupChannel = null;
                }
              "
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              text
              v-if="step > 0"
              @click="
                () => {
                  step -= 1;

                  devices = [];

                  channels = [];
                  selectedChannel = null;
                  disabledChannel = true;
                  selectedGroupChannel = null;
                }
              "
            >
              Previous
            </v-btn>
            <v-spacer></v-spacer>

            <v-btn color="primary" text @click="next"> Next </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="dialog"
        max-width="500"
        @click:outside="
          () => {
            dialog = false;
            name = '';
            description = '';
            minAlarm = 0;
            maxAlarm = 20;
            minAlert = 0;
            maxAlert = 20;
          }
        "
      >
        <!-- <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" v-bind="attrs" v-on="on"> Create </v-btn>
        </template> -->
        <v-card>
          <v-card-title class="headline grey lighten-2">
            Create Alert
          </v-card-title>

          <v-card-text>
            <v-row justify="center"
              ><v-col cols="12" class="pt-0 pb-0">
                <v-text-field
                  v-model="name"
                  label="Alert Name"
                  clearable
                ></v-text-field
              ></v-col>
              <v-col cols="12" class="pt-0 pb-0">
                <v-textarea
                  auto-grow
                  clearable
                  rows="1"
                  clear-icon="mdi-close-circle"
                  v-model="description"
                  label="Description"
                ></v-textarea
              ></v-col>
              <v-col cols="12" class="pt-0 pb-0">
                <v-select
                  v-model="sensorType"
                  :items="['Temperature']"
                  label="Type of sensor"
                ></v-select></v-col
              ><v-col cols="12" class="headline pt-0 pb-0">Warning level</v-col>
              <v-col cols="6" class="pt-0 pb-0">
                <v-text-field
                  v-model="minAlert"
                  type="number"
                  label="Min"
                  :step="0.1"
                ></v-text-field> </v-col
              ><v-col cols="6" class="pt-0 pb-0">
                <v-text-field
                  v-model="maxAlert"
                  type="number"
                  label="Max"
                  :step="0.1"
                ></v-text-field> </v-col
              ><v-col cols="12" class="headline pt-0 pb-0">Alarm level</v-col
              ><v-col cols="6" class="pt-0 pb-0">
                <v-text-field
                  v-model="minAlarm"
                  type="number"
                  label="Min"
                  :step="0.1"
                ></v-text-field> </v-col
              ><v-col cols="6" class="pt-0 pb-0">
                <v-text-field
                  v-model="maxAlarm"
                  type="number"
                  label="Max"
                  :step="0.1"
                ></v-text-field> </v-col
            ></v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              color="primary"
              text
              @click="
                () => {
                  dialog = false;
                  name = '';
                  description = '';
                  minAlarm = 0;
                  maxAlarm = 20;
                  minAlert = 0;
                  maxAlert = 20;
                }
              "
            >
              Cancel
            </v-btn>
            <v-spacer></v-spacer>

            <v-btn color="primary" text @click="saveAlert"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="editDialogChannel"
        max-width="500"
        @click:outside="
          () => {
            editDialogChannel = false;
            editingAlert = null;
            description = '';
            percentageAlarm = 110;
            percentageAlert = 105;
            alertRange = [0, 20];
            alarmRange = [0, 20];
            sliderMax = 150;
            sliderMin = -50;
            sliderStep = 1;
          }
        "
      >
        <v-card v-if="editingAlert != null">
          <v-card-title class="headline grey lighten-2">
            Edit Alert
          </v-card-title>
          <template v-if="editingAlert.type == 'percentageAlert'">
            <v-card-text>
              <v-row justify="center">
                <v-col cols="12" class="pt-0 pb-0">
                  <v-textarea
                    auto-grow
                    clearable
                    rows="1"
                    clear-icon="mdi-close-circle"
                    v-model="description"
                    label="Description"
                  ></v-textarea></v-col
              ></v-row>
            </v-card-text>
            <v-app-bar flat color="orange">
              <v-toolbar-title class="title pl-0">
                Select warning level
              </v-toolbar-title>
            </v-app-bar>
            <v-card-text>
              <v-row>
                <v-col cols="6">
                  <v-select
                    :items="['>', '<']"
                    label="Operator"
                    dense
                    hide-details
                    outlined
                    v-model="operator"
                  ></v-select></v-col
                ><v-col cols="6">
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    label="Percentage"
                    v-model="percentageAlert"
                    min="10"
                    max="99"
                    type="number"
                  />
                </v-col>
              </v-row>
              <div>
                <span class="font-weight-black">Warning</span> level will be
                triggered when sensor reaches a value
                <span class="font-weight-black"
                  >{{ operator }} at {{ percentageAlert }} %
                </span>
                of the median of all other sensors selected in the group
              </div>
              <v-alert
                v-if="
                  operator == '<' &&
                  parseInt(percentageAlert) < parseInt(percentageAlarm)
                "
                type="error"
              >
                <div>
                  <span class="font-weight-black">Warning : </span> with
                  <span class="font-weight-black">{{ operator }} </span>
                  operator, percentage value from alarm level must be
                  {{ operator }} at percentage value from alert level !
                </div></v-alert
              >
              <v-alert
                v-if="
                  operator == '>' &&
                  parseInt(percentageAlert) > parseInt(percentageAlarm)
                "
                type="error"
              >
                <div>
                  <span class="font-weight-black">Warning : </span> with
                  <span class="font-weight-black">{{ operator }} </span>
                  operator, percentage value from alarm level must be
                  {{ operator }} at percentage value from alert level !
                </div></v-alert
              >
            </v-card-text>

            <v-app-bar flat color="red">
              <v-toolbar-title class="title pl-0">
                Select alarm level
              </v-toolbar-title>
            </v-app-bar>
            <v-card-text>
              <v-row>
                <v-col cols="6">
                  <v-select
                    :items="['>', '<']"
                    label="Operator"
                    dense
                    hide-details
                    outlined
                    v-model="operator"
                  ></v-select></v-col
                ><v-col cols="6">
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    label="Percentage"
                    v-model="percentageAlarm"
                    min="10"
                    max="99"
                    type="number"
                  />
                </v-col>
              </v-row>
              <div>
                <span class="font-weight-black">Alarm</span> level will be
                triggered when sensor reaches a value
                <span class="font-weight-black"
                  >{{ operator }} at {{ percentageAlarm }} %
                </span>
                of the median of all other sensors selected in the group
              </div>
            </v-card-text>
          </template>
          <template v-else>
            <v-card-text>
              <v-row justify="center">
                <v-col cols="12" class="pt-0 pb-0">
                  <v-textarea
                    auto-grow
                    clearable
                    rows="1"
                    clear-icon="mdi-close-circle"
                    v-model="description"
                    label="Description"
                  ></v-textarea></v-col
              >
                                <v-col class="pr-5 pl-5" cols="4"><v-text-field v-model="sliderMin" label="min" type="number"></v-text-field></v-col>
                  <v-col class="pr-5 pl-5" cols="4"><v-text-field v-model="sliderStep" label="step" type="number"></v-text-field></v-col>
                  <v-col class="pr-5 pl-5" cols="4"><v-text-field v-model="sliderMax" label="max" type="number"></v-text-field></v-col>
                  </v-row>

              <v-alert v-if="alertRange[0] < alarmRange[0]" type="error">
                <div>
                  <span class="font-weight-black">Warning : </span>
                  the min treshold for warning should be higher than alarm.
                  Otherwise warning level will never be triggered.
                </div></v-alert
              >
              <v-alert v-if="alarmRange[1] < alertRange[1]" type="error">
                <div>
                  <span class="font-weight-black">Warning : </span>
                  the max treshold for warning should be lower than alarm.
                  Otherwise warning level will never be triggered.
                </div></v-alert
              >
            </v-card-text>
            <v-app-bar flat color="orange">
              <v-toolbar-title class="title pl-0">
                Select warning level
              </v-toolbar-title>
            </v-app-bar>
            <v-card-text>
              <v-row>
                <v-col cols="12" class="pt-5 pb-0 mb-0"
                  ><v-range-slider
                    v-model="alertRange"
                    :thumb-size="24"
                    thumb-label="always"
                    :max="sliderMax"
                    :min="sliderMin"
                    :step="sliderStep"
                    class="mt-5 mb-0"
                  ></v-range-slider
                ></v-col>
              </v-row>
            </v-card-text>

            <v-app-bar flat color="red">
              <v-toolbar-title class="title pl-0">
                Select alarm level
              </v-toolbar-title>
            </v-app-bar>
            <v-card-text>
              <v-row>
                <v-col cols="12" class="pt-5 pb-0 mt-0"
                  ><v-range-slider
                    v-model="alarmRange"
                    :thumb-size="24"
                    thumb-label="always"
                    :max="sliderMax"
                    :min="sliderMin"
                    :step="sliderStep"
                    class="mt-5 mb-0"
                  ></v-range-slider
                ></v-col>
              </v-row>
            </v-card-text>
          </template>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              color="primary"
              text
              @click="
                () => {
                  editDialogChannel = false;
                  editingAlert = null;
                  description = '';
                  percentageAlarm = 110;
                  percentageAlert = 105;

                  alertRange = [0, 20];
                  alarmRange = [0, 20];
            sliderMax = 150;
            sliderMin = -50;
            sliderStep = 1;
                }
              "
            >
              Cancel
            </v-btn>
            <v-spacer></v-spacer>

            <v-btn
              v-if="editingAlert.type == 'percentageAlert'"
              color="primary"
              text
              @click="saveEditingAlertChannel"
            >
              Next
            </v-btn>
            <v-btn v-else color="primary" text @click="saveEditingAlertLimits">
              Next
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="editDialog"
        max-width="500"
        @click:outside="
          () => {
            editDialog = false;
            name = '';
            description = '';
            minAlarm = 0;
            maxAlarm = 20;
            minAlert = 0;
            maxAlert = 20;
            changingAlertParams = false;
            editingAlert = null;
          }
        "
      >
        <v-card>
          <v-card-title class="headline grey lighten-2">
            {{ changingAlertParams ? "Change alert treshold" : "Edit Alert" }}
          </v-card-title>

          <v-card-text v-if="editingAlert != null">
            <template v-if="!changingAlertParams">
              <v-row justify="center"
                ><v-col cols="12" class="pt-0 pb-0">
                  <v-text-field
                    v-model="editingAlert.name"
                    label="Alert Name"
                    clearable
                  ></v-text-field
                ></v-col>
                <v-col cols="12" class="pt-0 pb-0">
                  <v-textarea
                    auto-grow
                    clearable
                    rows="1"
                    clear-icon="mdi-close-circle"
                    v-model="editingAlert.description"
                    label="Description"
                  ></v-textarea
                ></v-col>
                <v-col cols="12" class="pt-0 pb-0 text-center">
                  <v-btn
                    class="mt-5 mb-5"
                    color="primary"
                    @click="changingAlertParams = true"
                  >
                    Change tresholds </v-btn
                  ><br
                /></v-col>
              </v-row>
            </template>
            <template
              v-if="
                changingAlertParams &&
                editingAlert.alertParams.type == 'Temperature'
              "
            >
              <v-row justify="center"
                ><v-col cols="12" class="headline pt-0 pb-0"
                  >Warning level</v-col
                >
                <v-col cols="6" class="pt-0 pb-0">
                  <v-text-field
                    v-model="editingAlert.alertParams.minAlert"
                    type="number"
                    label="Min"
                    :step="0.1"
                  ></v-text-field> </v-col
                ><v-col cols="6" class="pt-0 pb-0">
                  <v-text-field
                    v-model="editingAlert.alertParams.maxAlert"
                    type="number"
                    label="Max"
                    :step="0.1"
                  ></v-text-field> </v-col
                ><v-col cols="12" class="headline pt-0 pb-0">Alarm level</v-col
                ><v-col cols="6" class="pt-0 pb-0">
                  <v-text-field
                    v-model="editingAlert.alertParams.minAlarm"
                    type="number"
                    label="Min"
                    :step="0.1"
                  ></v-text-field> </v-col
                ><v-col cols="6" class="pt-0 pb-0">
                  <v-text-field
                    v-model="editingAlert.alertParams.maxAlarm"
                    type="number"
                    label="Max"
                    :step="0.1"
                  ></v-text-field>
                </v-col>
              </v-row>
            </template>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              v-if="!changingAlertParams"
              color="primary"
              text
              @click="
                () => {
                  editDialog = false;
                  name = '';
                  description = '';
                  minAlarm = 0;
                  maxAlarm = 20;
                  minAlert = 0;
                  maxAlert = 20;
                  changingAlertParams = false;
                  editingAlert = null;
                }
              "
            >
              Cancel
            </v-btn>
            <v-btn
              v-if="changingAlertParams"
              color="primary"
              text
              @click="changingAlertParams = false"
            >
              Back
            </v-btn>
            <v-spacer></v-spacer>

            <v-btn
              v-if="!changingAlertParams"
              color="primary"
              text
              @click="saveEditingAlert"
            >
              Save
            </v-btn>
            <v-btn
              v-if="changingAlertParams"
              color="primary"
              text
              @click="saveEditingAlertLimits"
            >
              Save new tresholds</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog> </v-row
    ><br />
    <v-row justify="center">
      <v-col cols="7" v-for="alert in sortedAlerts" :key="alert.id">
        <v-card elevation="2" :ripple="false" @click="selectAlert(alert)">
          <v-app-bar class="pt-1" flat dense color="rgba(0, 0, 0, 0)">
            <v-icon v-if="alert.active" class="mr-3" color="green"
              >mdi-check</v-icon
            >

            <v-icon v-else class="mr-3" color="red">mdi-close</v-icon>
            <v-toolbar-title class="title pl-0">
              {{ alert.name }}

              <v-chip
                v-if="alert.newAlertCount > 0"
                class="mb-1 ml-3"
                small
                color="primary"
              >
                {{ alert.newAlertCount }}
                {{ alert.newAlertCount == 1 ? "New" : "News" }}</v-chip
              >
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="primary" icon @click.stop="chooseEditAlert(alert)">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
            <confirm
              type="alert"
              :item="alert"
              @removeConfirmed="removeAlert(alert)"
            ></confirm>
          </v-app-bar>
          <v-card-subtitle class="mb-0 pt-0">
            <div>{{ alert.id }}</div>
          </v-card-subtitle>
          <v-card-text
            ><div class="mb-3">{{ alert.description }}</div>
            <template
              v-if="
                alert.alertParams != undefined &&
                alert.alertParams.type == 'Temperature'
              "
              ><div class="text-subtitle-2">
                Warning level :
                <pre>{{
                  `\tmin:${alert.alertParams.minAlert}\tmax:${alert.alertParams.maxAlert}`
                }}</pre>
              </div>
              <div class="text-subtitle-2">
                Alarm level :
                <pre>{{
                  `\tmin:${alert.alertParams.minAlarm}\tmax:${alert.alertParams.maxAlarm}`
                }}</pre>
              </div></template
            >
            <template
              v-if="
                alert.alertParams != undefined &&
                alert.type == 'percentageAlert'
              "
              ><div class="text-subtitle-2">
                Warning level :
                <pre>{{
                  `\t value ${alert.alertParams.operator} ${alert.alertParams.percentageAlert} % of group's average`
                }}</pre>
              </div>
              <div class="text-subtitle-2">
                Alarm level :
                <pre>{{
                  `\t value ${alert.alertParams.operator} ${alert.alertParams.percentageAlarm} % of group's average`
                }}</pre>
              </div></template
            >
          </v-card-text>
        </v-card>
      </v-col>
    </v-row></v-main
  >
  <v-main v-else>
    <showAlert
      :selectedAlert="selectedAlert"
      @reloadAlert="reloadSelectedAlert"
    ></showAlert
  ></v-main>
</template>


<script>
import channelList from "../../channelList.json";
import axios from "axios";
import tokenStore from "../../store/TokenStore";

import ConfirmRemove from "./../ConfirmRemove.vue";
import ShowAlert from "./ShowAlert.vue";

export default {
  components: {
    confirm: ConfirmRemove,
    showAlert: ShowAlert,
  },
  data: () => ({
    alerts: [],
    dialog: false,
    dialog2: false,
    editDialog: false,

    editDialogChannel: false,
    name: "",
    description: "",
    minAlert: 0,
    minAlarm: 0,
    alertRange: [0, 20],
    maxAlert: 20,
    maxAlarm: 20,
    alarmRange: [0, 20],
    sensorType: "Temperature",
    selectedAlert: null,
    interval: null,
    editingAlert: null,
    changingAlertParams: false,
    radioGroup: 1,
    step: 0,
    percentageAlert: 105,
    percentageAlarm: 110,
    operator: ">",
    selectedGroupChannel: null,
    disabledChannel: true,
    selectedChannel: null,
    groups: [],
    devices: [],
    channels: [],
    sliderMin: -50,
    sliderMax:150,
    sliderStep:1
  }),
  computed: {
    sortedGroups: function () {
      if (this.groups) {
        let sortedGroups = this.groups;
        if (this.groups.length == 0) sortedGroups = [];
        sortedGroups = sortedGroups.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        return sortedGroups;
      } else {
        return [];
      }
    },
    sortedAlerts: function () {
      if (this.alerts) {
        let sortedGroups = this.alerts;
        if (this.alerts.length == 0) sortedGroups = [];
        sortedGroups = sortedGroups.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        return sortedGroups;
      } else {
        return [];
      }
    },
  },
  props: {
    reloadAlert: Boolean,
  },
  watch: {
    reloadAlert(value) {
      if (value) {
        this.selectedAlert = null;
        this.$emit("alertReloaded");
        this.$emit("alert", "");
      }
    },
    selectedGroupChannel(id) {
      if (id == null) {
        this.devices = [];
      } else {
        this.devices = [];

        this.channels = [];
        this.selectedChannel = null;
        this.disabledChannel = true;
        axios
          .get(
            process.env.VUE_APP_API_ADDR + "/api/group/" + id + "/allDevice",
            {
              headers: {
                Authorization: tokenStore.getToken(),
              },
            }
          )
          .then((res1) => {
            res1.data.devices.forEach((dev) => {
              this.devices.push(dev);
              axios
                .get(
                  process.env.VUE_APP_API_ADDR +
                    "/api/device/" +
                    dev +
                    "/channel",
                  {
                    headers: {
                      Authorization: tokenStore.getToken(),
                    },
                  }
                )
                .then((res2) => {
                  res2.data.channels.forEach((channel) => {
                    let index = this.channels.findIndex((c) => {
                      return c.id == channel;
                    });
                    if (index < 0) {
                      if (
                        channel != "" &&
                        channelList[channel].type == "number" &&
                        channelList[channel] != undefined
                      ) {
                        this.channels.push(channelList[channel]);
                      }
                    }
                  });
                  this.disabledChannel = false;
                });
            });
          });
      }
    },
  },

  beforeMount() {
    this.$emit("alert", "");
    this.loadExistingAlert();

    this.loadGroups();

    /*
    axios
      .get(process.env.VUE_APP_API_ADDR + "/api/device/@me", {
        headers: {
          Authorization: tokenStore.getToken(),
        },
      })
      .then(() => {
        console.log("laaaa");
      });*/
    /*this.interval = setInterval(() => {
      axios
        .get(process.env.VUE_APP_API_ADDR + "/api/device/@me", {
          headers: {
            Authorization: tokenStore.getToken(),
          },
        })
        .then(() => {})
        .catch((err) => {
          console.log(err.response);
        });
    }, 5000);*/
  },
  beforeDestroy() {
    clearInterval(this.interval);
    window.stop();
  },
  methods: {
    saveChannelAlertNormal() {
      let fct = "";
      let alertParams = {};
      if (this.sensorType == "Temperature") {
        fct = `
        if(data.value < ${this.alarmRange[0]}){
          return 'alarm'
        }
        if(data.value < ${this.alertRange[0]}){
          return 'alert'
        }
        if(data.value > ${this.alarmRange[1]}){
          return 'alarm'
        }
        if(data.value > ${this.alertRange[1]}){
          return 'alert'
        }
        `;

        alertParams = {
          type: this.sensorType,
          channel: this.selectedChannel,
          group: this.selectedGroup,
          minAlarm: this.alarmRange[0],
          minAlert: this.alertRange[0],
          maxAlarm: this.alarmRange[1],
          maxAlert: this.alertRange[1],
        };
      }
      console.log("la");
      axios
        .post(
          process.env.VUE_APP_API_ADDR + "/api/channelAlert",
          {
            name: this.name,
            description: this.description,
            function: fct,
            testJson: {
              value: 10,
              updateTime: "2021-10-27T08:53:42.779000Z",
            },
            alertParams: alertParams,
          },
          {
            headers: {
              Authorization: tokenStore.getToken(),
            },
          }
        )
        .then((res) => {
          if (res.data.success == true) {
            // connect all device from group to this alert
            axios
              .post(
                process.env.VUE_APP_API_ADDR +
                  "/api/alert/" +
                  res.data.alert.id +
                  "/devices",
                {
                  devicesId: this.devices,
                },
                {
                  headers: {
                    Authorization: tokenStore.getToken(),
                  },
                }
              )
              .then(() => {
                this.dialog2 = false;
                this.step = 0;
                this.name = "";

                this.description = "";
                this.devices = [];

                this.channels = [];
                this.selectedChannel = null;
                this.disabledChannel = true;
                this.selectedGroupChannel = null;

                //
                this.loadExistingAlert();
              })
              .catch(() => {
                //console.log(err.response.status)
              });
          }
        })
        .catch((err) => {
          console.log(err.response.data);
          //console.log(err.response.status)
        });
    },
    saveChannelAlertPercentage() {
      let alertParams = {};
      /*let fct = `
        if(data.value ${this.operator} ${this.percentageAlarm}){
          return 'alarm'
        }
        else if(data.value ${this.operator} ${this.percentageAlert}){
          return 'alert'
        }
        `;
*/
      alertParams = {
        channel: this.selectedChannel,
        group: this.selectedGroup,
        operator: this.operator,
        percentageAlert: this.percentageAlert,
        percentageAlarm: this.percentageAlarm,
      };

      axios
        .post(
          process.env.VUE_APP_API_ADDR + "/api/channelAlert/advanced",
          {
            name: this.name,
            description: this.description,
            type: "percentageAlert",
            alertParams: alertParams,
          },
          {
            headers: {
              Authorization: tokenStore.getToken(),
            },
          }
        )
        .then((res) => {
          if (res.data.success == true) {
            // connect all device from group to this alert
            axios
              .post(
                process.env.VUE_APP_API_ADDR +
                  "/api/alert/" +
                  res.data.alert.id +
                  "/devices/advanced",
                {
                  devicesId: this.devices,
                },
                {
                  headers: {
                    Authorization: tokenStore.getToken(),
                  },
                }
              )
              .then((res5) => {
                console.log(res5);
                this.dialog2 = false;
                this.step = 0;
                this.name = "";

                this.description = "";
                this.devices = [];

                this.channels = [];
                this.selectedChannel = null;
                this.disabledChannel = true;
                this.selectedGroupChannel = null;

                //
                this.loadExistingAlert();
              })
              .catch(() => {
                //console.log(err.response.status)
              });
          }
        });

      /*axios
        .post(
          process.env.VUE_APP_API_ADDR + "/api/channelAlert",
          {
            name: this.name,
            description: this.description,
            function: fct,
            testJson: {
              value: 10,
              updateTime: "2021-10-27T08:53:42.779000Z",
            },
            alertParams: alertParams,
          },
          {
            headers: {
              Authorization: tokenStore.getToken(),
            },
          }
        )
        .then((res) => {
          if (res.data.success == true) {
            // connect all device from group to this alert
            axios
              .post(
                process.env.VUE_APP_API_ADDR +
                  "/api/alert/" +
                  res.data.alert.id +
                  "/devices",
                {
                  devicesId: this.devices,
                },
                {
                  headers: {
                    Authorization: tokenStore.getToken(),
                  },
                }
              )
              .then(() => {
                this.dialog2 = false;
                this.step = 0;
                this.name = "";

                this.description = "";
                this.devices = [];

                this.channels = [];
                this.selectedChannel = null;
                this.disabledChannel = true;
                this.selectedGroupChannel = null;

                //
                this.loadExistingAlert();
              })
              .catch(() => {
                //console.log(err.response.status)
              });
          }
        })
        .catch((err) => {
          console.log(err.response);
          //console.log(err.response.status)
        });*/
    },
    loadGroups() {
      this.groups = [];
      axios
        .get(process.env.VUE_APP_API_ADDR + "/api/group/@me", {
          headers: {
            Authorization: tokenStore.getToken(),
          },
        })
        .then((res) => {
          res.data.groups.forEach((group) => {
            if (group.subGroups.length == 0) {
              this.groups.push(group);
            }
          });
          this.groups.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
        });
    },
    next() {
      if (this.step == 1) {
        if (this.selectedChannel != null) {
          if (this.radioGroup == 1) {
            if (
              !(
                this.operator == "<" &&
                parseInt(this.percentageAlert) < parseInt(this.percentageAlarm)
              ) &&
              !(
                this.operator == ">" &&
                parseInt(this.percentageAlert) > parseInt(this.percentageAlarm)
              )
            ) {
              this.saveChannelAlertPercentage();
            }
          } else if (this.radioGroup == 0) {
            this.saveChannelAlertNormal();
          }
        }
      } else if (this.step == 0 && this.name != "") {
        this.step++;
      }
    },
    chooseEditAlert(alert) {
      this.editingAlert = JSON.parse(JSON.stringify(alert));
      if (alert.type == "percentageAlert") {
        this.operator = this.editingAlert.alertParams.operator;
        this.percentageAlert = this.editingAlert.alertParams.percentageAlert;
        this.percentageAlarm = this.editingAlert.alertParams.percentageAlarm;
      } else {
        this.alertRange = [
          this.editingAlert.alertParams.minAlert,
          this.editingAlert.alertParams.maxAlert,
        ];
        this.alarmRange = [
          this.editingAlert.alertParams.minAlarm,
          this.editingAlert.alertParams.maxAlarm,
        ];
      }
      // this.editDialog = true;
      this.editDialogChannel = true;
      this.description = this.editingAlert.description;
    },
    reloadSelectedAlert() {
      let alertId = this.selectedAlert.id;
      axios
        .get(process.env.VUE_APP_API_ADDR + "/api/alert/@me", {
          headers: {
            Authorization: tokenStore.getToken(),
          },
        })
        .then((res) => {
          this.alerts = [];
          res.data.alerts.forEach((alert) => {
            alert.newAlertCount = 0;
            alert.devicesId.forEach((id) => {
              axios
                .get(process.env.VUE_APP_API_ADDR + "/api/device/" + id, {
                  headers: {
                    Authorization: tokenStore.getToken(),
                  },
                })
                .then((res) => {
                  res.data.device.alerts.forEach((a) => {
                    if (a.new && a.name == alert.name + "_" + alert.id)
                      alert.newAlertCount++;
                  });
                })
                .catch(() => {
                  //console.log(err.response.status);
                });
            });
            this.alerts.push(alert);
          });
          this.selectedAlert =
            this.alerts[
              this.alerts.findIndex((alert) => {
                return alert.id == alertId;
              })
            ];
        })
        .catch(() => {
          //console.log(err.response.status)
        });
    },
    loadExistingAlert() {
      axios
        .get(process.env.VUE_APP_API_ADDR + "/api/alert/@me", {
          headers: {
            Authorization: tokenStore.getToken(),
          },
        })
        .then((res) => {
          this.alerts = [];
          for (let i = 0; i < res.data.alerts.length; i++) {
            let alert = JSON.parse(JSON.stringify(res.data.alerts[i]));
            alert.newAlertCount = 0;
            alert.devicesId.forEach((id) => {
              axios
                .get(process.env.VUE_APP_API_ADDR + "/api/device/" + id, {
                  headers: {
                    Authorization: tokenStore.getToken(),
                  },
                })
                .then(() => {
                  //
                });
            });
            this.alerts.push(alert);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    saveAlert() {
      if (this.name != "") {
        let fct = "";
        let alertParams = {};
        if (this.sensorType == "Temperature") {
          fct = `
        if(data.temperature.value.value < ${this.minAlarm}){
          return 'alarm'
        }
        if(data.temperature.value.value < ${this.minAlert}){
          return 'alert'
        }
        if(data.temperature.value.value > ${this.maxAlarm}){
          return 'alarm'
        }
        if(data.temperature.value.value > ${this.maxAlert}){
          return 'alert'
        }
        `;

          alertParams = {
            type: this.sensorType,
            minAlarm: this.minAlarm,
            minAlert: this.minAlert,
            maxAlarm: this.maxAlarm,
            maxAlert: this.maxAlert,
          };
        }
        axios
          .post(
            process.env.VUE_APP_API_ADDR + "/api/alert",
            {
              name: this.name,
              description: this.description,
              function: fct,
              testJson: {
                temperature: {
                  value: 5,
                },
              },
              alertParams: alertParams,
            },
            {
              headers: {
                Authorization: tokenStore.getToken(),
              },
            }
          )
          .then(() => {
            this.dialog = false;
            this.name = "";
            this.description = "";
            this.minAlarm = 0;
            this.maxAlarm = 20;
            this.minAlert = 0;
            this.maxAlert = 20;

            this.loadExistingAlert();
          })
          .catch(() => {
            //console.log(err.response.status)
          });
      }
    },
    saveEditingAlert() {
      axios
        .put(
          process.env.VUE_APP_API_ADDR + "/api/alert/" + this.editingAlert.id,
          {
            name: this.editingAlert.name,
            description: this.editingAlert.description,
          },
          {
            headers: {
              Authorization: tokenStore.getToken(),
            },
          }
        )
        .then(() => {
          this.editDialog = false;
          this.editingAlert = null;
          this.changingAlertParams = false;
          this.loadExistingAlert();
        })
        .catch(() => {
          //console.log(err.response.status)
        });
    },

    saveEditingAlertLimits() {
      let fct = "";
      let alertParams = {};

      fct = `
        if(data.value < ${this.alarmRange[0]}){
          return 'alarm'
        }
        if(data.value < ${this.alertRange[0]}){
          return 'alert'
        }
        if(data.value > ${this.alarmRange[1]}){
          return 'alarm'
        }
        if(data.value > ${this.alertRange[1]}){
          return 'alert'
        }
        `;

      alertParams = {
        type: this.editingAlert.alertParams.type,
        channel: this.editingAlert.alertParams.channel,

        minAlarm: this.alarmRange[0],
        minAlert: this.alertRange[0],
        maxAlarm: this.alarmRange[1],
        maxAlert: this.alertRange[1],
      };
      axios
        .put(
          process.env.VUE_APP_API_ADDR + "/api/alert/" + this.editingAlert.id,
          {
            function: fct,
            testJson: {
              value: 10,
              updateTime: "2021-10-27T08:53:42.779000Z",
            },
            alertParams: JSON.parse(JSON.stringify(alertParams)),
          },
          {
            headers: {
              Authorization: tokenStore.getToken(),
            },
          }
        )
        .then(() => {
          let tmp = [];
          this.editingAlert.devicesId.forEach((id) => {
            tmp.push(id);
          });
          axios
            .delete(
              process.env.VUE_APP_API_ADDR +
                "/api/alert/" +
                this.editingAlert.id +
                "/devices",

              {
                data: {
                  devicesId: tmp,
                },
                headers: {
                  Authorization: tokenStore.getToken(),
                },
              }
            )
            .then(() => {
              axios
                .post(
                  process.env.VUE_APP_API_ADDR +
                    "/api/alert/" +
                    this.editingAlert.id +
                    "/devices",
                  {
                    devicesId: tmp,
                  },
                  {
                    headers: {
                      Authorization: tokenStore.getToken(),
                    },
                  }
                )
                .then(() => {
                  this.editDialogChannel = false;
                  this.editingAlert = null;
                  this.changingAlertParams = false;
                  this.description = "";
                  this.alertRange = [0, 20];
                  this.alarmRange = [0, 20];
            this.sliderMax = 150;
            this.sliderMin = -50;
            this.sliderStep = 1;
                  this.loadExistingAlert();
                })
                .catch(() => {
                  //console.log(err.response.status)
                });
            })
            .catch(() => {
              //console.log(err.response.status)
            });
        })
        .catch(() => {
          //console.log(err.response.status)
        });
    },

    saveEditingAlertChannel() {
      axios
        .put(
          process.env.VUE_APP_API_ADDR +
            "/api/channelAlert/" +
            this.editingAlert.id,
          {
            name: this.editingAlert.name,
            description: this.description,
            type: this.editingAlert.type,
            alertParams: {
              channel: this.editingAlert.alertParams.channel,
              group: this.editingAlert.alertParams.group,
              operator: this.operator,
              percentageAlert: this.percentageAlert,
              percentageAlarm: this.percentageAlarm,
            },
          },
          {
            headers: {
              Authorization: tokenStore.getToken(),
            },
          }
        )
        .then(() => {
          this.editDialogChannel = false;
          this.editingAlert = null;
          this.description = "";
          this.percentageAlarm = 110;
          this.percentageAlert = 105;
          this.loadExistingAlert();
        })
        .catch(() => {
          //console.log(err.response.status)
        });
    },

    removeAlert(alert) {
      axios
        .delete(
          process.env.VUE_APP_API_ADDR + "/api/alert/" + alert.id,

          {
            headers: {
              Authorization: tokenStore.getToken(),
            },
          }
        )
        .then(() => {
          this.loadExistingAlert();
        })
        .catch(() => {
          //console.log(err.response.status)
        });
    },
    selectAlert(alert) {
      this.selectedAlert = alert;
      this.$emit("alert", this.selectedAlert.name);
    },
    previous() {
      this.selectedAlert = null;
      this.loadExistingAlert();
      this.$emit("alert", "");
    },
  },
};
</script>

