<template>
  <div>
       <grid
      :width="1920"
      style="z-index: 10"
      :items="items"  
      :dashboardId="selectedDashboard.id"
      :dashboardName="selectedDashboard.name"
    >
    </grid>
    <img
      ref="img"
      :src="imageUrl"
      :style="{
        position: 'absolute',
        top: '30px',
        left: '50%',
        width: imageWidth + 'px',
        transform: 'translate(-50%, 0)',
        'z-index': 1,
      }"
    />
  </div>
</template>


<script>
import Grid from "./Grid.vue";

export default {
  components: {
       Grid,
  },
  data: () => ({
          items: [],
      imageUrl: "",
    imageWidth: 100,
  }),
  props: {
    selectedDashboard: Object,
  },
  watch: {
   
    
  },
  methods: {
    
       loadImg() {
      if (this.selectedDashboard.imgId) {
        this.imageWidth = this.selectedDashboard.imgWidth;
        this.imageUrl =
          process.env.VUE_APP_API_ADDR +
          "/api/load/" +
          this.selectedDashboard.imgId;
      }
    },

  },
  beforeMount() {
       this.loadImg();
  },
    mounted() {


      if (this.selectedDashboard.widgets == null) {
        this.items = [];
      } else {
        this.items = this.selectedDashboard.widgets;
      }

  },
};
</script>