<template>
  <v-main>
    <v-dialog v-model="dialog" max-width="70%" :persistent="exportStarted">
      <v-card>
        <v-card-title class="headline secondary">
          {{ !exportStarted ? "Time selection" : "Exporting"  }}
        </v-card-title>

        <v-card-text>
          <template v-if="!exportStarted">
            <v-row justify="center">
              <v-col cols="4">
                <v-menu
                  ref="firstMenu"
                  v-model="firstMenu"
                  :close-on-content-click="false"
                  :return-value.sync="firstDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="firstDate"
                      label="First date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="firstDate" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="firstMenu = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.firstMenu.save(firstDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="4">
                <v-menu
                  ref="secondMenu"
                  v-model="secondMenu"
                  :close-on-content-click="false"
                  :return-value.sync="secondDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="secondDate"
                      label="Second date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="secondDate" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="secondMenu = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.secondMenu.save(secondDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row></template
          ><template v-else>
            <div class="mt-5 text-center">
              <v-progress-circular
                :rotate="90"
                :size="100"
                :width="10"
                :value="loadingValue"
                color="primary"
              >
                {{ loadingValue }} %
              </v-progress-circular>
            </div>
          </template>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions v-if="!exportStarted">
          <v-btn
            color="primary"
            text
            @click="
              () => {
                dialog = false;
              }
            "
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn color="primary" text @click="loadData"> Export </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row justify="center"
      ><v-col cols="10">
        <v-card class="pl-5 pr-5">
          <v-row justify="center"
            ><v-col cols="4">
              <v-row
                ><v-col cols="10">
                  <div class="text-h6 mb-2" v-text="'Groups'"></div></v-col
              ></v-row>

              <v-card style="height: 600px; overflow-y: scroll" class="pa-4">
                <template v-for="group in groups">
                  <v-card
                    :class="[
                      'mb-3',
                      selectedGroup != null && selectedGroup.id == group.id
                        ? 'selected'
                        : 'unselected',
                    ]"
                    elevation="2"
                    :key="group.id"
                    :ripple="false"
                    @click="selectGroup(group)"
                  >
                    <v-app-bar flat color="rgba(0, 0, 0, 0)">
                      <v-toolbar-title class="title pl-0">
                        {{ group.name }}
                      </v-toolbar-title>
                    </v-app-bar>
                    <v-card-subtitle class="mb-0 mt-0"
                      ><div>{{ group.id }}</div>
                    </v-card-subtitle>
                    <v-card-text>{{ group.description }} </v-card-text>
                  </v-card>
                </template>
              </v-card></v-col
            ><v-col cols="4">
              <v-row
                ><v-col cols="10"
                  ><div class="text-h6 mb-2" v-text="'Devices'"></div></v-col
                ><v-col cols="2" class="text-right">
                  <v-btn
                    v-if="selectedGroup != null"
                    color="primary"
                    icon
                    @click.stop="connectAllDevice()"
                  >
                    <v-icon>mdi-arrow-collapse-right</v-icon>
                  </v-btn></v-col
                ></v-row
              >

              <v-card style="height: 600px; overflow-y: scroll" class="pa-4">
                <v-alert
                  v-if="
                    !deviceLoading &&
                    devices.length == 0 &&
                    this.selectedGroup != null
                  "
                  border="left"
                  type="info"
                  >No device registered in this group or all devices already
                  selected</v-alert
                >
                <v-alert
                  v-if="this.selectedGroup == null"
                  border="left"
                  type="info"
                  >Select a group</v-alert
                >
                <template v-for="device in devices">
                  <v-card
                    class="mb-3"
                    elevation="2"
                    width="100%"
                    :key="device.id"
                  >
                    <v-app-bar flat color="rgba(0, 0, 0, 0)">
                      <v-card-title class="title pl-0 mt-5">{{
                        device.name
                      }}</v-card-title>

                      <v-spacer></v-spacer>
                      <v-btn
                        v-if="
                          connectedDevices.findIndex((dev) => {
                            return dev.id == device.id;
                          }) < 0
                        "
                        color="primary"
                        icon
                        @click.stop="connectDevice(device)"
                      >
                        <v-icon>mdi-arrow-right</v-icon>
                      </v-btn>
                    </v-app-bar>
                    <v-card-subtitle class="pb-0"
                      >CheckMeId : {{ device.checkMeId }}</v-card-subtitle
                    >
                    <v-card-subtitle class="pt-0 mb-0"
                      >Registered : {{ device.registered }}
                      <v-expansion-panels flat>
                        <v-expansion-panel>
                          <v-expansion-panel-header>
                            Last data :
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <vue-json-pretty :deep="3" :data="device.lastData">
                            </vue-json-pretty>
                          </v-expansion-panel-content>
                        </v-expansion-panel> </v-expansion-panels
                    ></v-card-subtitle>
                  </v-card>
                </template> </v-card></v-col
            ><v-col cols="4">
              <v-row
                ><v-col cols="10"
                  ><div
                    class="text-h6 mb-2"
                    v-text="'Selected Devices'"
                  ></div></v-col
                ><v-col cols="2" class="text-right">
                  <v-btn
                    v-if="selectedGroup != null"
                    color="primary"
                    icon
                    @click.stop="unconnectAllDevice()"
                  >
                    <v-icon>mdi-arrow-collapse-left</v-icon>
                  </v-btn></v-col
                ></v-row
              >
              <v-card style="height: 600px; overflow-y: scroll" class="pa-4">
                <template v-for="device in connectedDevices">
                  <v-card
                    class="mb-3"
                    elevation="2"
                    width="100%"
                    :key="device.id"
                  >
                    <v-app-bar flat color="rgba(0, 0, 0, 0)">
                      <v-card-title class="title pl-0 mt-5">{{
                        device.name
                      }}</v-card-title>

                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        icon
                        @click.stop="unconnectDevice(device)"
                      >
                        <v-icon>mdi-arrow-left</v-icon>
                      </v-btn>
                    </v-app-bar>
                    <v-card-subtitle class="pb-0"
                      >CheckMeId : {{ device.checkMeId }}</v-card-subtitle
                    >
                    <v-card-subtitle class="pt-0 mb-0"
                      >Registered : {{ device.registered }}
                      <v-expansion-panels flat>
                        <v-expansion-panel>
                          <v-expansion-panel-header>
                            Last data :
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <vue-json-pretty :deep="3" :data="device.lastData">
                            </vue-json-pretty>
                          </v-expansion-panel-content>
                        </v-expansion-panel> </v-expansion-panels
                    ></v-card-subtitle>
                  </v-card>
                </template> </v-card></v-col
          ></v-row>
          <v-row justify="center">
            <div>
              <v-btn
                class="mt-5 mb-5"
                color="primary"
                @click="
                  dialog = true
                  /*download(
                    'hello.csv',
                    'Prenom, Nom, Email, Age, Ville\r\nRobert, Lepingre, bobby@exemple.com, 41, Paris\r\nJeanne, Ducoux, jeanne@exemple.com, 32, Marseille\r\nPierre, Lenfant, pierre@exemple.com, 23, Rennes'
                  )*/
                "
                >Export data</v-btn
              >
            </div></v-row
          >
        </v-card>
      </v-col></v-row
    >
  </v-main>
</template>

<script>
import axios from "axios";

import tokenStore from "../../store/TokenStore";
import VueJsonPretty from "vue-json-pretty";

export default {
  components: {
    VueJsonPretty,
  },
  data: () => ({
    devices: [],
    groups: [],
    connectedDevices: [],
    data: [],
    selectedGroup: null,
    deviceLoading: false,
    dialog: false,
    firstDate: null,
    firstMenu: false,
    secondDate: null,
    secondMenu: false,
    dataReady: false,
    loadingValue: 0,
    exportStarted: false,
  }),

  beforeMount() {
    this.loadGroups();
    this.dialog = false;
  },

  beforeDestroy() {},
  methods: {
    loadData() {
      if (this.connectedDevices.length > 0) {
        this.exportStarted = true;
        this.dataReady = false;
        if (this.firstDate != null && this.secondDate != null) {
          let timestamp1 = new Date(this.firstDate).getTime();
          let timestamp2 = new Date(this.secondDate).getTime();
          if (timestamp1 > timestamp2) {
            let tmp = timestamp1;
            timestamp1 = timestamp2;
            timestamp2 = tmp;
          }
          let i = 0;
          this.connectedDevices.forEach((device) => {
            axios
              .get(
                process.env.VUE_APP_API_ADDR +
                  "/api/device/" +
                  device.id +
                  "/data/query?minDate=" +
                  timestamp1 +
                  "&maxDate=" +
                  timestamp2,
                {
                  headers: {
                    Authorization: tokenStore.getToken(),
                  },
                }
              )
              .then((res) => {
                axios
                  .get(
                    process.env.VUE_APP_API_ADDR + "/api/device/" + device.id,
                    {
                      headers: {
                        Authorization: tokenStore.getToken(),
                      },
                    }
                  )
                  .then((res1) => {
                    res.data.data.forEach((data) => {
                      try {
                        let json = {
                          data: JSON.parse(data.data),
                          time: data.time,
                          deviceId: data.deviceId,
                          deviceName: res1.data.device.name,
                        };
                        this.data.push(json);
                      } catch {
                        //
                      }
                    });
                    i++;
                    this.loadingValue = Math.round(
                      (i * 100) / this.connectedDevices.length
                    );
                    if (i == this.connectedDevices.length) {
                      this.csvExport(this.data);
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          });
        }
      }
    },
    csvExport(dataArray) {
      let keys = this.getAllJsonKeys(dataArray[0]);
      let jsonPaths = [];
      let str = "unixTime; localTime; deviceId; deviceName; ";
      keys.forEach((arrayKey) => {
        if (arrayKey[0] == "data" && arrayKey[arrayKey.length - 1] == "value") {
          let tmp = "json";
          for (let i = 0; i < arrayKey.length; i++) {
            tmp += "." + arrayKey[i];
            if (i > 0) {
              str += arrayKey[i].charAt(0).toUpperCase() + arrayKey[i].slice(1);
            } else {
              str += arrayKey[i];
            }
          }
          jsonPaths.push(tmp);
          str += "; ";
        }
      });
      str += "\r\n";

      dataArray.forEach((json) => {
        str +=
          json.time +
          "; " +
          new Date(json.time).toLocaleString() +
          "; " +
          json.deviceId +
          "; " +
          json.deviceName +
          "; ";

        jsonPaths.forEach((jsonPath) => {
          try {
            str += eval(jsonPath) + "; ";
          } catch {
            str += "; ";
          }
        });

        str += "\n\r";
      });
      this.download("csvExport.csv", str);
      this.dialog = false;
      this.data = [];
      this.dataReady = false;
      this.firstDate = null;
      this.secondDate = null;
      this.loadingValue = 0;
      this.exportStarted = false;
    },
    getAllJsonKeys(json) {
      let keys = [];
      for (let k in json) {
        if (typeof json[k] === "object" && json[k] !== null) {
          let tmp = this.getAllJsonKeys(json[k]);
          tmp.forEach((array) => {
            array.unshift(k);
            keys.push(array);
          });
        } else {
          keys.push([k]);
        }
      }
      return keys;
    },
    unconnectDevice(device) {
      let index = this.connectedDevices.findIndex((dev) => {
        return dev == device;
      });
      if (this.selectedGroup.id == device.groups[0]) {
        this.devices.push(this.connectedDevices.splice(index, 1)[0]);
      } else {
        this.connectedDevices.splice(index, 1)[0];
      }
    },
    unconnectAllDevice() {
      while (this.connectedDevices.length > 0) {
        this.unconnectDevice(this.connectedDevices[0]);
      }
    },
    connectDevice(device) {
      let index = this.devices.findIndex((dev) => {
        return dev == device;
      });

      this.connectedDevices.push(this.devices.splice(index, 1)[0]);
    },
    connectAllDevice() {
      while (this.devices.length > 0) {
        this.connectDevice(this.devices[0]);
      }
    },
    selectGroup(group) {
      this.devices = [];
      this.selectedGroup = group;
      this.deviceLoading = true;
      axios
        .get(
          process.env.VUE_APP_API_ADDR +
            "/api/group/" +
            group.id +
            "/allDevice",
          {
            headers: {
              Authorization: tokenStore.getToken(),
            },
          }
        )
        .then((res) => {
          let count = 0;
          res.data.devices.forEach((dev) => {
            axios
              .get(process.env.VUE_APP_API_ADDR + "/api/device/" + dev, {
                headers: {
                  Authorization: tokenStore.getToken(),
                },
              })
              .then((res2) => {
                count++;
                if (
                  this.connectedDevices.findIndex((dev) => {
                    return dev.id == res2.data.device.id;
                  }) < 0
                ) {
                  res2.data.device.lastData.data = JSON.parse(
                    res2.data.device.lastData.data
                  );
                  this.devices.push(res2.data.device);
                }
                if (count == res.data.devices.length) {
                  this.deviceLoading = false;
                }
              });
          });
        });
    },
    loadGroups() {
      this.groups = [];
      axios
        .get(process.env.VUE_APP_API_ADDR + "/api/group/@me", {
          headers: {
            Authorization: tokenStore.getToken(),
          },
        })
        .then((res) => {
          res.data.groups.forEach((group) => {
            if (group.subGroups.length == 0) {
              this.groups.push(group);
            }
          });
        });
    },
    download(filename, text) {
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/csv;charset=utf-8," + encodeURIComponent(text)
      );
      element.setAttribute("download", filename);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },
  },
};
</script>

<style scoped>
.selected {
  border: 3px solid #006fb7 !important;
  background-color: rgb(0, 111, 183, 0.1) !important;
  cursor: pointer !important;
}
.unselected {
  border: 3px solid rgb(255, 255, 255) !important;
  cursor: pointer !important;
}
</style>