
<template>
  <div>
    <v-dialog
      v-model="linkDialogChannel"
      scrollable
      :width="
        customLineChannel ||
        customLinkDashboardChannel ||
        customLinkAlertChannel ||
        customLinkColoredIconChannel
          ? '1500px'
          : '1000px'
      "
      @click:outside="
        unSaveWidget();
        leaveLinkDialogChannel();
      "
    >
      <v-card v-if="linkDialogChannel">
        <template v-if="choosenWidget.type == 'downlink'">
          <v-card-title class="headline secondary">
            Link data to widget
          </v-card-title>
          <v-app-bar flat color="rgba(0, 0, 0, 0)">
            <v-toolbar-title class="title pl-0">
              {{
                choosenWidget.myData.inputs[currentStep].name +
                ` (${currentStep + 1}/${choosenWidget.myData.inputs.length})`
              }}
            </v-toolbar-title>
          </v-app-bar>
          <v-card-text>
            <v-radio-group
              v-model="choosenWidget.myData.inputs[currentStep].type"
              row
              hide-details
            >
              <v-radio
                class="pl-5"
                v-for="item in [
                  { name: 'MQTT', i: 0 },
                  { name: 'POST', i: 1 },
                ]"
                :label="item.name"
                :value="item.i"
                :key="item.i"
              ></v-radio>
            </v-radio-group>
            <template v-if="choosenWidget.myData.inputs[currentStep].type == 0">
              <v-row no-gutters>
                <v-col cols="9" class="pr-2">
                  <v-text-field
                    v-model="choosenWidget.myData.inputs[currentStep].url"
                    label="Broker Url"
                  ></v-text-field
                ></v-col>

                <v-col cols="3" class="pl-2">
                  <v-text-field
                    v-model="choosenWidget.myData.inputs[currentStep].port"
                    label="Broker Port"
                  ></v-text-field
                ></v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="choosenWidget.myData.inputs[currentStep].topic"
                    label="Broker Topic"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="pr-2">
                  <v-text-field
                    v-model="choosenWidget.myData.inputs[currentStep].username"
                    label="Username"
                  ></v-text-field>
                </v-col>

                <v-col cols="6" class="pl-2">
                  <v-text-field
                    v-model="choosenWidget.myData.inputs[currentStep].password"
                    label="Password"
                  ></v-text-field>
                </v-col>
                <v-col cols="2" class="pl-2">
                  <v-checkbox
                    v-model="
                      choosenWidget.myData.inputs[currentStep].downlinkAck
                    "
                    :label="`Ack ?`"
                  ></v-checkbox>
                </v-col>
                <v-col cols="10" class="pl-2">
                  <v-text-field
                    v-if="choosenWidget.myData.inputs[currentStep].downlinkAck"
                    v-model="choosenWidget.myData.inputs[currentStep].ackTopic"
                    label="Ack Topic"
                  ></v-text-field>
                </v-col>
              </v-row>
              Body :
              <v-jsoneditor
                v-model="choosenWidget.myData.inputs[currentStep].body"
                :options="{ mode: 'code' }"
                height="400px"
              ></v-jsoneditor>
            </template>
            <template v-if="choosenWidget.myData.inputs[currentStep].type == 1">
              <v-text-field
                v-model="choosenWidget.myData.inputs[currentStep].url"
                label="Post Url"
              ></v-text-field>
              <v-text-field
                v-model="
                  choosenWidget.myData.inputs[currentStep].authentification
                "
                label="Authorization"
              ></v-text-field>
              Body :
              <v-jsoneditor
                v-model="choosenWidget.myData.inputs[currentStep].body"
                :options="{ mode: 'code' }"
                height="400px"
              ></v-jsoneditor>
            </template>
            <!--  <v-radio-group
              v-model="choosenWidget.myData.inputs[currentStep].type"
              row
              hide-details
            >
              <v-radio
                class="pl-5"
                v-for="item in [
                  { name: 'MQTT', i: 0 },
                  { name: 'POST', i: 1 },
                ]"
                :label="item.name"
                :value="item.i"
                :key="item.i"
              ></v-radio>
            </v-radio-group>
            <template v-if="choosenWidget.myData.inputs[currentStep].type == 0">
              <v-row no-gutters>
                <v-col cols="9" class="pr-2">
                  <v-select :items="['Samba']" label="Object"></v-select>
                </v-col>

                <v-col cols="3" class="pl-2">
                  <v-select :items="['Samba']" label="Object"></v-select>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="choosenWidget.myData.inputs[currentStep].topic"
                    label="Broker Topic"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="pr-2">
                  <v-text-field
                    v-model="choosenWidget.myData.inputs[currentStep].username"
                    label="Username"
                  ></v-text-field>
                </v-col>

                <v-col cols="6" class="pl-2">
                  <v-text-field
                    v-model="choosenWidget.myData.inputs[currentStep].password"
                    label="Password"
                  ></v-text-field>
                </v-col>
              </v-row>
              Body :
              <v-jsoneditor
                v-model="choosenWidget.myData.inputs[currentStep].body"
                :options="{ mode: 'code' }"
                height="400px"
              ></v-jsoneditor>
            </template>
            <template v-if="choosenWidget.myData.inputs[currentStep].type == 1">
              <v-text-field
                v-model="choosenWidget.myData.inputs[currentStep].url"
                label="Post Url"
              ></v-text-field>
              <v-text-field
                v-model="
                  choosenWidget.myData.inputs[currentStep].authentification
                "
                label="Authorization"
              ></v-text-field>
              Body :
              <v-jsoneditor
                v-model="choosenWidget.myData.inputs[currentStep].body"
                :options="{ mode: 'code' }"
                height="400px"
              ></v-jsoneditor> 
            </template>-->
          </v-card-text>
          <v-divider></v-divider>

          <v-card-actions>
            <v-btn
              color="primary"
              text
              @click="
                unSaveWidget();
                leaveLinkDialogChannel();
              "
            >
              Cancel
            </v-btn>
            <v-btn
              v-if="currentStep != 0"
              color="primary"
              text
              @click="previousLinkChannel"
            >
              Previous
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
              v-if="currentStep != choosenWidget.myData.inputs.length - 1"
              @click="nextLinkChannel"
              color="primary"
              text
            >
              Next
            </v-btn>
            <v-btn
              v-if="currentStep == choosenWidget.myData.inputs.length - 1"
              @click="nextLinkChannel"
              color="primary"
              text
            >
              Save
            </v-btn>
          </v-card-actions>
        </template>
        <template v-if="choosenWidget.type == 'coloredIcon'">
          <v-card-title class="headline secondary">
            Link data to widget
          </v-card-title>
          <v-app-bar flat color="rgba(0, 0, 0, 0)">
            <v-toolbar-title class="title pl-0">
              {{
                choosenWidget.myData.inputs[currentStep].name +
                ` (${currentStep + 1}/${choosenWidget.myData.inputs.length})`
              }}
            </v-toolbar-title>
          </v-app-bar>
          <v-card-text>
            <v-row align="center" justify="center">
              <v-col :cols="customLinkColoredIconChannel ? 6 : 12">
                <v-row align="center" justify="center">
                  <v-col :cols="customLinkColoredIconChannel ? 3 : 4"></v-col>

                  <v-col :cols="customLinkColoredIconChannel ? 6 : 4">
                    <v-autocomplete
                      v-model="selectedGroupChannel"
                      :items="groups"
                      item-text="name"
                      item-value="id"
                      :label="'Group'"
                    ></v-autocomplete>
                  </v-col>
                  <v-col :cols="customLinkColoredIconChannel ? 3 : 4"></v-col
                ></v-row>
                <v-row align="center" justify="center">
                  <v-col :cols="customLinkColoredIconChannel ? 2 : 3"></v-col>
                  <v-col cols="1">
                    <v-progress-circular
                      v-if="
                        selectedGroupChannel && disabledSelectedDeviceChannel
                      "
                      class="text-center"
                      indeterminate
                      color="primary"
                    ></v-progress-circular
                  ></v-col>
                  <v-col :cols="customLinkColoredIconChannel ? 6 : 4">
                    <v-autocomplete
                      v-model="selectedDeviceChannel"
                      :items="devices"
                      item-text="name"
                      item-value="id"
                      :label="'Device'"
                      :disabled="disabledSelectedDeviceChannel"
                    ></v-autocomplete> </v-col
                  ><v-col :cols="customLinkColoredIconChannel ? 3 : 4"></v-col
                ></v-row>
                <v-row align="center" justify="center">
                  <v-col :cols="customLinkColoredIconChannel ? 2 : 3"></v-col>
                  <v-col cols="1">
                    <v-progress-circular
                      v-if="selectedDeviceChannel && disabledChannel"
                      class="text-center"
                      indeterminate
                      color="primary"
                    ></v-progress-circular
                  ></v-col>
                  <v-col :cols="customLinkColoredIconChannel ? 6 : 4">
                    <v-autocomplete
                      :disabled="disabledChannel"
                      v-model="selectedChannel"
                      :items="channels"
                      :item-text="(item) => item.id + ' : ' + item.name"
                      item-value="id"
                      :label="'Channel'"
                    ></v-autocomplete> </v-col
                  ><v-col :cols="customLinkColoredIconChannel ? 3 : 4"></v-col>
                </v-row>
<template                  v-if="Array.isArray(choosenWidget.myData.inputs[
                    currentStep
                  ].condition)">
                <template

                  v-for="(condition, i) in choosenWidget.myData.inputs[
                    currentStep
                  ].condition"
                >
                  <v-row :key="i" align="center">
                    <v-col :cols="customLinkColoredIconChannel ? 3 : 4"></v-col>
                    <v-col
                      :cols="customLinkColoredIconChannel ? 2 : 2"
                      class="text-center"
                    >
                      <v-select
                        v-model="
                          choosenWidget.myData.inputs[currentStep].condition[i]
                        "
                        :items="[
                          { item: 'is True', value: 'is True' },
                          { item: 'is False', value: 'is False' },
                          { item: '<', value: '<' },
                          { item: '<=', value: '<=' },
                          { item: '>', value: '>' },
                          { item: '>=', value: '>=' },
                          { item: '=', value: '=' },
                          { item: '!=', value: '!=' },
                          // { item: 'Inside', value: 'inside' },
                        ]"
                        item-text="item"
                        item-value="value"
                        label="Condition"
                      ></v-select>
                    </v-col>

                    <v-col
                      :cols="customLinkColoredIconChannel ? 2 : 1"
                      class="text-center"
                    >
                      <v-text-field
                        class="pt-5"
                        outlined
                        dense
                        label="Value"
                        v-model="
                          choosenWidget.myData.inputs[currentStep]
                            .conditionValue[i]
                        "
                        :disabled="
                          choosenWidget.myData.inputs[currentStep].condition ==
                            'is True' ||
                          choosenWidget.myData.inputs[currentStep].condition ==
                            'is False'
                        "
                        type="number"
                      />
                    </v-col>

                    <v-col
                      :cols="customLinkColoredIconChannel ? 2 : 1"
                      class="text-center"
                    >
                      <v-menu transition="slide-y-transition">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            style="border-style: solid; border-color: black"
                            elevation="2"
                            fab
                            height="25"
                            width="25"
                            :color="
                              choosenWidget.myData.inputs[currentStep]
                                .colorTrue[i]
                            "
                            v-on="on"
                            v-bind="attrs"
                          >
                          </v-btn>
                        </template>
                        <v-card @click.stop.native>
                          <v-color-picker
                            v-model="
                              choosenWidget.myData.inputs[currentStep]
                                .colorTrue[i]
                            "
                            :mode="'hexa'"
                            @input="updateModelWidget"
                            show-swatches
                            swatches-max-height="230px"
                          ></v-color-picker
                        ></v-card>
                      </v-menu>
                      <v-btn
                        v-if="i > 0"
                        icon
                        color="primary"
                        @click="
                          coloredIconWidgetRemoveCondition(
                            choosenWidget.myData.inputs[currentStep],
                            i
                          )
                        "
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-col>

                    <v-col :cols="customLinkColoredIconChannel ? 3 : 4"></v-col>
                  </v-row>
                </template> </template>
                
                <v-row>
                  <v-col :cols="customLinkColoredIconChannel ? 3 : 4"></v-col>
                  <v-col
                    :cols="customLinkColoredIconChannel ? 6 : 4"
                    class="text-center"
                  >
                    <v-btn                  v-if="Array.isArray(choosenWidget.myData.inputs[
                    currentStep
                  ].condition)"
                      color="primary"
                      @click="
                        coloredIconWidgetAddCondition(
                          choosenWidget.myData.inputs[currentStep]
                        )
                      "
                      >+ ADD CONDITION</v-btn
                    ><template v-else> <v-alert type="error">WIDGET MODEL HAS BEEN UPDATED, PLEASE RE CREATE THE WIDGET</v-alert></template>
                  </v-col>
                  <v-col :cols="customLinkColoredIconChannel ? 3 : 4"></v-col>
                  <v-col :cols="customLinkColoredIconChannel ? 3 : 4"></v-col>
                  <v-col
                    :cols="customLinkColoredIconChannel ? 6 : 4"
                    class="text-center"
                  >
                    FALSE
                    <v-menu transition="slide-y-transition">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          style="border-style: solid; border-color: black"
                          elevation="2"
                          fab
                          height="25"
                          width="25"
                          :color="
                            choosenWidget.myData.inputs[currentStep].colorFalse
                          "
                          v-on="on"
                          v-bind="attrs"
                        >
                        </v-btn>
                      </template>
                      <v-card @click.stop.native>
                        <v-color-picker
                          v-model="
                            choosenWidget.myData.inputs[currentStep].colorFalse
                          "
                          :mode="'hexa'"
                          @input="updateModelWidget"
                          show-swatches
                          swatches-max-height="230px"
                        ></v-color-picker
                      ></v-card>
                    </v-menu>
                  </v-col>
                  <v-col :cols="customLinkColoredIconChannel ? 3 : 4"></v-col>
                  <v-col :cols="customLinkColoredIconChannel ? 3 : 4"> </v-col>
                  <v-col :cols="customLinkColoredIconChannel ? 6 : 4">
                    <v-checkbox
                      v-model="
                        choosenWidget.myData.inputs[currentStep]
                          .linkToADashboard
                      "
                      :label="`Link a dashboard ?`"
                    ></v-checkbox> </v-col
                  ><v-col :cols="customLinkColoredIconChannel ? 3 : 4"></v-col>

                  <v-col
                    v-if="
                      choosenWidget.myData.inputs[currentStep].linkToADashboard
                    "
                    :cols="customLinkColoredIconChannel ? 3 : 4"
                  ></v-col>
                  <v-col
                    v-if="
                      choosenWidget.myData.inputs[currentStep].linkToADashboard
                    "
                    :cols="customLinkColoredIconChannel ? 6 : 4"
                    class="text-center"
                  >
                    <v-autocomplete
                      v-model="selectedDashboardLink"
                      :items="dashBoardList"
                      item-text="name"
                      :label="'Dashboard'"
                      return-object
                      @change="() => {}"
                    ></v-autocomplete> </v-col
                  ><v-col
                    v-if="
                      choosenWidget.myData.inputs[currentStep].linkToADashboard
                    "
                    :cols="customLinkColoredIconChannel ? 3 : 4"
                  ></v-col>

                  <v-col :cols="customLinkColoredIconChannel ? 3 : 4"></v-col>

                  <v-col
                    :cols="customLinkColoredIconChannel ? 6 : 4"
                    class="text-center"
                    ><v-btn
                      color="primary"
                      @click="customLinkColoredIconChannelFct"
                    >
                      Custom
                    </v-btn></v-col
                  >

                  <v-col :cols="customLinkColoredIconChannel ? 3 : 4"></v-col>
                </v-row>
                <v-row
                  v-if="customLinkColoredIconChannel"
                  align="center"
                  justify="center"
                >
                  <v-col cols="3"></v-col>
                  <v-col cols="3">
                    <v-checkbox
                      v-model="
                        choosenWidget.myData.inputs[currentStep].showUpdateTime
                      "
                      :label="`Show updateTime`"
                    ></v-checkbox
                  ></v-col>
                  <v-col cols="6"></v-col>
                </v-row>
                <v-row
                  v-if="
                    customLinkColoredIconChannel &&
                    choosenWidget.myData.inputs[currentStep].showUpdateTime
                  "
                  align="center"
                  justify="center"
                >
                  <v-col cols="3"></v-col>
                  <v-col cols="3" class="text-center">
                    Font Color
                    <v-menu transition="slide-y-transition">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          style="border-style: solid; border-color: black"
                          elevation="2"
                          fab
                          height="25"
                          width="25"
                          :color="
                            choosenWidget.myData.inputs[currentStep].fontColor
                          "
                          v-on="on"
                          v-bind="attrs"
                        >
                        </v-btn>
                      </template>
                      <v-card @click.stop.native>
                        <v-color-picker
                          v-model="
                            choosenWidget.myData.inputs[currentStep].fontColor
                          "
                          :mode="'hexa'"
                          @input="updateModelWidget"
                          show-swatches
                          swatches-max-height="230px"
                        ></v-color-picker
                      ></v-card>
                    </v-menu>
                  </v-col>
                  <v-col :cols="3" class="text-center">
                    <v-select
                      v-model="
                        choosenWidget.myData.inputs[currentStep].textPosition
                      "
                      :items="[
                        { item: 'Top', value: 'top' },
                        // { item: 'Inside', value: 'inside' },
                      ]"
                      item-text="item"
                      item-value="value"
                      label="TextPosition"
                    ></v-select>
                  </v-col>

                  <v-col cols="3"></v-col>
                </v-row>
                <v-row
                  v-if="customLinkColoredIconChannel"
                  align="center"
                  justify="center"
                >
                  <v-col cols="3"></v-col>
                  <v-col cols="6">
                    <v-select
                      :items="widgetIconList"
                      label="Icon"
                      dense
                      hide-details
                      outlined
                      v-model="choosenWidget.myData.inputs[currentStep].icon"
                      clearable
                    >
                      <template slot="selection" slot-scope="data">
                        <span :class="[data.item, 'mr-3']"></span>
                        {{ data.item }}
                      </template>
                      <template slot="item" slot-scope="data">
                        <span :class="[data.item, 'mr-3']"></span>
                        {{ data.item }}
                      </template></v-select
                    >
                  </v-col>
                  <v-col cols="3"></v-col>
                  <v-col cols="3"></v-col>
                  <v-col :cols="4" class="text-center">
                    <v-checkbox
                      v-model="transparentWidget"
                      :label="`Transparent`"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      class="pt-5"
                      outlined
                      dense
                      label="Size"
                      v-model="choosenWidget.myData.inputs[currentStep].size"
                      min="50"
                      max="400"
                      type="number"
                  /></v-col>
                  <v-col cols="3"></v-col>
                </v-row>
              </v-col>
              <v-col v-if="customLinkColoredIconChannel" cols="6">
                <v-hover v-slot="{ hover }">
                  <v-card
                    class="pb-6"
                    height="400px"
                    :elevation="transparentWidget && !hover ? 0 : 2"
                    :style="
                      transparentWidget && !hover
                        ? 'background-color:rgba(' +
                          hexToRgb(
                            $vuetify.theme.dark
                              ? $vuetify.theme.themes.dark.widgetBackground
                              : $vuetify.theme.themes.light.widgetBackground,
                            'r'
                          ) +
                          ',' +
                          hexToRgb(
                            $vuetify.theme.dark
                              ? $vuetify.theme.themes.dark.widgetBackground
                              : $vuetify.theme.themes.light.widgetBackground,
                            'g'
                          ) +
                          ',' +
                          hexToRgb(
                            $vuetify.theme.dark
                              ? $vuetify.theme.themes.dark.widgetBackground
                              : $vuetify.theme.themes.light.widgetBackground,
                            'b'
                          ) +
                          ', 0) !important;'
                        : 'background-color:rgba(' +
                          hexToRgb(
                            $vuetify.theme.dark
                              ? $vuetify.theme.themes.dark.widgetBackground
                              : $vuetify.theme.themes.light.widgetBackground,
                            'r'
                          ) +
                          ',' +
                          hexToRgb(
                            $vuetify.theme.dark
                              ? $vuetify.theme.themes.dark.widgetBackground
                              : $vuetify.theme.themes.light.widgetBackground,
                            'g'
                          ) +
                          ',' +
                          hexToRgb(
                            $vuetify.theme.dark
                              ? $vuetify.theme.themes.dark.widgetBackground
                              : $vuetify.theme.themes.light.widgetBackground,
                            'b'
                          ) +
                          ', 1) !important;'
                    "
                  >
                    <v-system-bar
                      :style="
                        transparentWidget && !hover ? 'opacity:0' : 'opacity:1'
                      "
                      color="primary"
                      :rounded="false"
                    >
                      <span
                        :class="['', 'mr-1']"
                        style="color: white; font-size: 16px"
                      ></span>
                      <span
                        class="mt-1"
                        style="height: 100%; overflow: hidden; color: white"
                        >Example</span
                      >
                      <v-spacer></v-spacer>
                      <v-icon color="white" @click="&quot;&quot;;"
                        >mdi-cog</v-icon
                      >
                      <v-icon color="white" @click="&quot;&quot;;"
                        >mdi-close</v-icon
                      >
                    </v-system-bar>

                    <widget
                      :key="forceUpdate"
                      :data="choosenWidget.myData"
                      :series="choosenWidget.mySeries"
                      :type="choosenWidget.type"
                    ></widget>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>

          <v-card-actions>
            <v-btn
              color="primary"
              text
              @click="
                unSaveWidget();
                leaveLinkDialogChannel();
              "
            >
              Cancel
            </v-btn>
            <v-btn
              v-if="currentStep != 0"
              color="primary"
              text
              @click="previousLinkChannel"
            >
              Previous
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
              v-if="currentStep != choosenWidget.myData.inputs.length - 1"
              @click="nextLinkChannel"
              color="primary"
              text
            >
              Next
            </v-btn>
            <v-btn
              v-if="currentStep == choosenWidget.myData.inputs.length - 1"
              @click="nextLinkChannel"
              color="primary"
              text
            >
              Save
            </v-btn>
          </v-card-actions>
        </template>

        <template v-if="choosenWidget.type == 'linkDashboard'">
          <v-card-title class="headline secondary">
            Link data to widget
          </v-card-title>
          <v-app-bar flat color="rgba(0, 0, 0, 0)">
            <v-toolbar-title class="title pl-0">
              {{
                "Link a dashboard" +
                ` (${currentStep + 1}/${choosenWidget.myData.inputs.length})`
              }}
            </v-toolbar-title>
          </v-app-bar>
          <v-card-text>
            <v-row align="center" justify="center">
              <v-col :cols="customLinkDashboardChannel ? 6 : 12">
                <v-row align="center" justify="center">
                  <v-col :cols="customLinkDashboardChannel ? 3 : 4"></v-col
                  ><v-col :cols="customLinkDashboardChannel ? 6 : 4">
                    <v-autocomplete
                      v-model="selectedDashboardLink"
                      :items="dashBoardList"
                      item-text="name"
                      :label="'Dashboard'"
                      return-object
                      @change="
                        choosenWidget.myData.inputs[currentStep].name =
                          choosenWidget.myData.inputs[currentStep]
                            .dashBoardName == null
                            ? selectedDashboardLink.name
                            : choosenWidget.myData.inputs[currentStep].name
                      "
                    ></v-autocomplete>
                  </v-col>

                  <v-col :cols="customLinkDashboardChannel ? 3 : 4"></v-col>

                  <v-col :cols="customLinkDashboardChannel ? 3 : 4"></v-col>
                  <v-col :cols="customLinkDashboardChannel ? 6 : 4">
                    <v-text-field
                      outlined
                      dense
                      hide-details
                      label="Name"
                      v-model="choosenWidget.myData.inputs[currentStep].name"
                      :disabled="selectedDashboardLink == null"
                    />
                  </v-col>
                  <v-col :cols="customLinkDashboardChannel ? 3 : 4"></v-col>

                  <v-col :cols="customLinkDashboardChannel ? 3 : 4"></v-col>

                  <v-col
                    :cols="customLinkDashboardChannel ? 6 : 4"
                    class="text-center"
                    ><v-btn
                      color="primary"
                      @click="customLinkDashboardChannelFct"
                    >
                      Custom
                    </v-btn></v-col
                  >

                  <v-col :cols="customLinkDashboardChannel ? 3 : 4"></v-col
                ></v-row>
                <v-row
                  v-if="customLinkDashboardChannel"
                  align="center"
                  justify="center"
                >
                  <v-col :cols="3"></v-col>

                  <v-col :cols="3" class="text-center">
                    <v-select
                      v-model="choosenWidget.myData.inputs[currentStep].style"
                      :items="[
                        { item: 'Normal', value: 'default' },
                        { item: 'Rounded', value: 'rounded' },
                        { item: 'Circle', value: 'fab' },
                      ]"
                      item-text="item"
                      item-value="value"
                      label="Button Style"
                    ></v-select>
                  </v-col>

                  <v-col cols="3" class="text-center">
                    Font Color
                    <v-menu transition="slide-y-transition">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          style="border-style: solid; border-color: black"
                          elevation="2"
                          fab
                          height="25"
                          width="25"
                          :color="
                            choosenWidget.myData.inputs[currentStep].fontColor
                          "
                          v-on="on"
                          v-bind="attrs"
                        >
                        </v-btn>
                      </template>
                      <v-card @click.stop.native>
                        <v-color-picker
                          v-model="
                            choosenWidget.myData.inputs[currentStep].fontColor
                          "
                          :mode="'hexa'"
                          @input="updateModelWidget"
                          show-swatches
                          swatches-max-height="230px"
                        ></v-color-picker
                      ></v-card>
                    </v-menu>
                  </v-col>
                  <v-col :cols="3"></v-col>
                  <v-col :cols="3"></v-col>
                  <v-col :cols="3" class="text-center">
                    <v-checkbox
                      v-model="transparentWidget"
                      :label="`Transparent`"
                    ></v-checkbox>
                  </v-col>
                  <v-col :cols="3" class="text-center">
                    Button Color
                    <v-menu transition="slide-y-transition">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          style="border-style: solid; border-color: black"
                          elevation="2"
                          fab
                          height="25"
                          width="25"
                          :color="
                            choosenWidget.myData.inputs[currentStep].color
                          "
                          v-on="on"
                          v-bind="attrs"
                        >
                        </v-btn>
                      </template>
                      <v-card @click.stop.native>
                        <v-color-picker
                          v-model="
                            choosenWidget.myData.inputs[currentStep].color
                          "
                          :mode="'hexa'"
                          show-swatches
                          swatches-max-height="230px"
                          @input="
                            () => {
                              forceUpdate++;
                            }
                          "
                        ></v-color-picker
                      ></v-card>
                    </v-menu>
                  </v-col>
                  <v-col :cols="3"></v-col>
                  <v-col :cols="3"></v-col>
                  <v-col :cols="3">
                    <v-text-field
                      outlined
                      dense
                      hide-details
                      label="Width"
                      v-model="choosenWidget.myData.inputs[currentStep].width"
                      min="10"
                      max="200"
                      type="number"
                      @input="
                        () => {
                          forceUpdate++;
                        }
                      "
                    />
                  </v-col>
                  <v-col :cols="3">
                    <v-text-field
                      outlined
                      dense
                      hide-details
                      label="Height"
                      v-model="choosenWidget.myData.inputs[currentStep].height"
                      min="10"
                      max="200"
                      type="number"
                      @input="
                        () => {
                          forceUpdate++;
                        }
                      "
                    />
                  </v-col>
                  <v-col :cols="3"></v-col>
                </v-row>
              </v-col>
              <v-col v-if="customLinkDashboardChannel" cols="6">
                <v-hover v-slot="{ hover }">
                  <v-card
                    class="pb-6"
                    height="400px"
                    :elevation="transparentWidget && !hover ? 0 : 2"
                    :style="
                      transparentWidget && !hover
                        ? 'background-color:rgba(' +
                          hexToRgb(
                            $vuetify.theme.dark
                              ? $vuetify.theme.themes.dark.widgetBackground
                              : $vuetify.theme.themes.light.widgetBackground,
                            'r'
                          ) +
                          ',' +
                          hexToRgb(
                            $vuetify.theme.dark
                              ? $vuetify.theme.themes.dark.widgetBackground
                              : $vuetify.theme.themes.light.widgetBackground,
                            'g'
                          ) +
                          ',' +
                          hexToRgb(
                            $vuetify.theme.dark
                              ? $vuetify.theme.themes.dark.widgetBackground
                              : $vuetify.theme.themes.light.widgetBackground,
                            'b'
                          ) +
                          ', 0) !important;'
                        : 'background-color:rgba(' +
                          hexToRgb(
                            $vuetify.theme.dark
                              ? $vuetify.theme.themes.dark.widgetBackground
                              : $vuetify.theme.themes.light.widgetBackground,
                            'r'
                          ) +
                          ',' +
                          hexToRgb(
                            $vuetify.theme.dark
                              ? $vuetify.theme.themes.dark.widgetBackground
                              : $vuetify.theme.themes.light.widgetBackground,
                            'g'
                          ) +
                          ',' +
                          hexToRgb(
                            $vuetify.theme.dark
                              ? $vuetify.theme.themes.dark.widgetBackground
                              : $vuetify.theme.themes.light.widgetBackground,
                            'b'
                          ) +
                          ', 1) !important;'
                    "
                  >
                    <v-system-bar
                      :style="
                        transparentWidget && !hover ? 'opacity:0' : 'opacity:1'
                      "
                      color="primary"
                      :rounded="false"
                    >
                      <span
                        :class="['', 'mr-1']"
                        style="color: white; font-size: 16px"
                      ></span>
                      <span
                        class="mt-1"
                        style="height: 100%; overflow: hidden; color: white"
                        >Example</span
                      >
                      <v-spacer></v-spacer>
                      <v-icon color="white" @click="&quot;&quot;;"
                        >mdi-cog</v-icon
                      >
                      <v-icon color="white" @click="&quot;&quot;;"
                        >mdi-close</v-icon
                      >
                    </v-system-bar>

                    <widget
                      :key="forceUpdate"
                      :data="choosenWidget.myData"
                      :series="choosenWidget.mySeries"
                      :type="choosenWidget.type"
                    ></widget>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>

          <v-card-actions>
            <v-btn
              color="primary"
              text
              @click="
                unSaveWidget();
                leaveLinkDialogChannel();
              "
            >
              Cancel
            </v-btn>
            <v-btn
              v-if="currentStep != 0"
              color="primary"
              text
              @click="previousLinkChannel"
            >
              Previous
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
              v-if="currentStep != choosenWidget.myData.inputs.length - 1"
              @click="nextLinkChannel"
              color="primary"
              text
            >
              Next
            </v-btn>
            <v-btn
              v-if="currentStep == choosenWidget.myData.inputs.length - 1"
              @click="nextLinkChannel"
              color="primary"
              text
            >
              Save
            </v-btn>
          </v-card-actions>
        </template>

        <template v-if="choosenWidget.type == 'map'">
          <v-card-title class="headline secondary">
            Link data to widget
          </v-card-title>
          <v-app-bar flat color="rgba(0, 0, 0, 0)">
            <v-toolbar-title class="title pl-0">
              {{
                choosenWidget.myData.inputs[currentStep].name +
                ` (${currentStep + 1}/${choosenWidget.myData.inputs.length})`
              }}
            </v-toolbar-title>
          </v-app-bar>
          <v-card-text>
            <v-row align="center" justify="center">
              <v-col cols="3"></v-col>
              <v-col cols="1"></v-col>
              <v-col cols="4">
                <v-autocomplete
                  v-model="selectedGroup"
                  :items="groups"
                  item-text="name"
                  :label="'Group'"
                  return-object
                ></v-autocomplete>
              </v-col>
              <v-col cols="4"></v-col
            ></v-row>
          </v-card-text>
          <v-divider></v-divider>

          <v-card-actions>
            <v-btn
              color="primary"
              text
              @click="
                unSaveWidget();
                leaveLinkDialogChannel();
              "
            >
              Cancel
            </v-btn>
            <v-btn
              v-if="currentStep != 0"
              color="primary"
              text
              @click="previousLinkChannel"
            >
              Previous
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
              v-if="currentStep != choosenWidget.myData.inputs.length - 1"
              @click="nextLinkChannel"
              color="primary"
              text
            >
              Next
            </v-btn>
            <v-btn
              v-if="currentStep == choosenWidget.myData.inputs.length - 1"
              @click="nextLinkChannel"
              color="primary"
              text
            >
              Save
            </v-btn>
          </v-card-actions>
        </template>
        <template v-if="choosenWidget.type == 'Alert'">
          <v-card-title class="headline secondary">
            Link data to widget
          </v-card-title>
          <v-app-bar flat color="rgba(0, 0, 0, 0)">
            <v-toolbar-title class="title pl-0">
              {{
                choosenWidget.myData.inputs[currentStep].name +
                ` (${currentStep + 1}/${choosenWidget.myData.inputs.length})`
              }}
            </v-toolbar-title>
          </v-app-bar>
          <v-card-text>
            <v-row align="center" justify="center">
              <v-col :cols="customLinkAlertChannel ? 6 : 12">
                <v-row align="center" justify="center">
                  <v-col :cols="customLinkAlertChannel ? 3 : 4"></v-col>

                  <v-col :cols="customLinkAlertChannel ? 6 : 4">
                    <v-autocomplete
                      v-model="selectedGroup"
                      :items="groups"
                      item-text="name"
                      :label="'Group'"
                      return-object
                    ></v-autocomplete>
                  </v-col>
                  <v-col :cols="customLinkAlertChannel ? 3 : 4"></v-col
                ></v-row>
                <v-row align="center" justify="center">
                  <v-col :cols="customLinkAlertChannel ? 3 : 4"> </v-col>
                  <v-col :cols="customLinkAlertChannel ? 6 : 4">
                    <v-autocomplete
                      v-model="selectedAlert"
                      :items="alerts"
                      item-text="name"
                      :label="'Alert'"
                      return-object
                    ></v-autocomplete> </v-col
                  ><v-col :cols="customLinkAlertChannel ? 3 : 4"></v-col
                ></v-row>
                <v-row align="center" justify="center">
                  <v-col :cols="customLinkAlertChannel ? 3 : 4"> </v-col>
                  <v-col :cols="customLinkAlertChannel ? 6 : 4">
                    <v-checkbox
                      v-model="linkToADashboard"
                      :label="`Link a dashboard ?`"
                    ></v-checkbox> </v-col
                  ><v-col :cols="customLinkAlertChannel ? 3 : 4"></v-col
                ></v-row>

                <v-row v-if="linkToADashboard" align="center" justify="center">
                  <v-col :cols="customLinkAlertChannel ? 3 : 4"></v-col>

                  <v-col
                    :cols="customLinkAlertChannel ? 6 : 4"
                    class="text-center"
                  >
                    <v-autocomplete
                      v-model="selectedDashboardLink"
                      :items="dashBoardList"
                      item-text="name"
                      :label="'Dashboard'"
                      return-object
                      @change="() => {}"
                    ></v-autocomplete> </v-col
                  ><v-col :cols="customLinkAlertChannel ? 3 : 4"></v-col
                ></v-row>
                <v-row align="center" justify="center">
                  <v-col :cols="customLinkAlertChannel ? 3 : 4"></v-col>

                  <v-col
                    :cols="customLinkAlertChannel ? 6 : 4"
                    class="text-center"
                  >
                    <v-btn color="primary" @click="customLinkAlertChannelFct">
                      Custom
                    </v-btn> </v-col
                  ><v-col :cols="customLinkAlertChannel ? 3 : 4"></v-col
                ></v-row>

                <v-row
                  v-if="customLinkAlertChannel"
                  align="center"
                  justify="center"
                >
                  <v-col :cols="3"></v-col>
                  <v-col cols="3" class="text-center">
                    Font Color
                    <v-menu transition="slide-y-transition">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          style="border-style: solid; border-color: black"
                          elevation="2"
                          fab
                          height="25"
                          width="25"
                          :color="
                            choosenWidget.myData.inputs[currentStep].fontColor
                          "
                          v-on="on"
                          v-bind="attrs"
                        >
                        </v-btn>
                      </template>
                      <v-card @click.stop.native>
                        <v-color-picker
                          v-model="
                            choosenWidget.myData.inputs[currentStep].fontColor
                          "
                          :mode="'hexa'"
                          @input="updateModelWidget"
                          show-swatches
                          swatches-max-height="230px"
                        ></v-color-picker
                      ></v-card>
                    </v-menu>
                  </v-col>

                  <v-col :cols="3" class="text-center">
                    <v-select
                      v-model="
                        choosenWidget.myData.inputs[currentStep].textPosition
                      "
                      :items="[
                        { item: 'Top', value: 'top' },
                        { item: 'Left', value: 'left' },
                        { item: 'Right', value: 'right' },

                        { item: 'Bottom', value: 'bottom' },

                        { item: 'Inside', value: 'inside' },
                        { item: 'Hidden', value: 'hidden' },
                      ]"
                      item-text="item"
                      item-value="value"
                      label="TextPosition"
                    ></v-select>
                  </v-col>

                  <v-col :cols="3"></v-col>

                  <v-col :cols="3"></v-col>

                  <v-col :cols="3" class="text-center">
                    <v-checkbox
                      v-model="transparentWidget"
                      :label="`Transparent`"
                    ></v-checkbox>
                  </v-col>
                  <v-col :cols="3">
                    <v-text-field
                      outlined
                      dense
                      hide-details
                      label="Icon size"
                      v-model="choosenWidget.myData.inputs[currentStep].size"
                      min="10"
                      max="300"
                      type="number"
                      @input="
                        () => {
                          forceUpdate++;
                        }
                      "
                    />
                  </v-col>

                  <v-col :cols="3"></v-col>
                </v-row>
              </v-col>
              <v-col v-if="customLinkAlertChannel" cols="6">
                <v-hover v-slot="{ hover }">
                  <v-card
                    class="pb-6"
                    height="400px"
                    :elevation="transparentWidget && !hover ? 0 : 2"
                    :style="
                      transparentWidget && !hover
                        ? 'background-color:rgba(' +
                          hexToRgb(
                            $vuetify.theme.dark
                              ? $vuetify.theme.themes.dark.widgetBackground
                              : $vuetify.theme.themes.light.widgetBackground,
                            'r'
                          ) +
                          ',' +
                          hexToRgb(
                            $vuetify.theme.dark
                              ? $vuetify.theme.themes.dark.widgetBackground
                              : $vuetify.theme.themes.light.widgetBackground,
                            'g'
                          ) +
                          ',' +
                          hexToRgb(
                            $vuetify.theme.dark
                              ? $vuetify.theme.themes.dark.widgetBackground
                              : $vuetify.theme.themes.light.widgetBackground,
                            'b'
                          ) +
                          ', 0) !important;'
                        : 'background-color:rgba(' +
                          hexToRgb(
                            $vuetify.theme.dark
                              ? $vuetify.theme.themes.dark.widgetBackground
                              : $vuetify.theme.themes.light.widgetBackground,
                            'r'
                          ) +
                          ',' +
                          hexToRgb(
                            $vuetify.theme.dark
                              ? $vuetify.theme.themes.dark.widgetBackground
                              : $vuetify.theme.themes.light.widgetBackground,
                            'g'
                          ) +
                          ',' +
                          hexToRgb(
                            $vuetify.theme.dark
                              ? $vuetify.theme.themes.dark.widgetBackground
                              : $vuetify.theme.themes.light.widgetBackground,
                            'b'
                          ) +
                          ', 1) !important;'
                    "
                  >
                    <v-system-bar
                      :style="
                        transparentWidget && !hover ? 'opacity:0' : 'opacity:1'
                      "
                      color="primary"
                      :rounded="false"
                    >
                      <span
                        :class="['', 'mr-1']"
                        style="color: white; font-size: 16px"
                      ></span>
                      <span
                        class="mt-1"
                        style="height: 100%; overflow: hidden; color: white"
                        >Example</span
                      >
                      <v-spacer></v-spacer>
                      <v-icon color="white" @click="&quot;&quot;;"
                        >mdi-cog</v-icon
                      >
                      <v-icon color="white" @click="&quot;&quot;;"
                        >mdi-close</v-icon
                      >
                    </v-system-bar>

                    <widget
                      :key="forceUpdate"
                      :data="choosenWidget.myData"
                      :series="choosenWidget.mySeries"
                      :type="choosenWidget.type"
                    ></widget>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>

          <v-card-actions>
            <v-btn
              color="primary"
              text
              @click="
                unSaveWidget();
                leaveLinkDialogChannel();
              "
            >
              Cancel
            </v-btn>
            <v-btn
              v-if="currentStep != 0"
              color="primary"
              text
              @click="previousLinkChannel"
            >
              Previous
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
              v-if="currentStep != choosenWidget.myData.inputs.length - 1"
              @click="nextLinkChannel"
              color="primary"
              text
            >
              Next
            </v-btn>
            <v-btn
              v-if="currentStep == choosenWidget.myData.inputs.length - 1"
              @click="nextLinkChannel"
              color="primary"
              text
            >
              Save
            </v-btn>
          </v-card-actions>
        </template>
        <template v-if="choosenWidget.type == 'text'">
          <v-card-title class="headline secondary">
            Link data to widget
          </v-card-title>
          <v-app-bar flat color="rgba(0, 0, 0, 0)">
            <v-toolbar-title class="title pl-0">
              {{
                choosenWidget.myData.inputs[currentStep].name +
                ` (${currentStep + 1}/${choosenWidget.myData.inputs.length})`
              }}
            </v-toolbar-title>
          </v-app-bar>
          <v-card-text>
            <v-row align="center" justify="center">
              <v-col cols="4"></v-col>
              <v-col cols="4">
                <v-text-field
                  outlined
                  dense
                  hide-details
                  label="Name"
                  v-model="choosenWidget.myData.inputs[currentStep].name"
                />
              </v-col>
              <v-col cols="4"></v-col>
              <v-col cols="3"></v-col>
              <v-col cols="1"></v-col>
              <v-col cols="4">
                <v-autocomplete
                  v-model="selectedGroupChannel"
                  :items="groups"
                  item-text="name"
                  item-value="id"
                  :label="'Group'"
                ></v-autocomplete>
              </v-col>
              <v-col cols="4"></v-col
            ></v-row>
            <v-row align="center" justify="center">
              <v-col cols="3"></v-col>
              <v-col cols="1">
                <v-progress-circular
                  v-if="selectedGroupChannel && disabledSelectedDeviceChannel"
                  class="text-center"
                  indeterminate
                  color="primary"
                ></v-progress-circular
              ></v-col>
              <v-col cols="4">
                <v-autocomplete
                  v-model="selectedDeviceChannel"
                  :items="devices"
                  item-text="name"
                  item-value="id"
                  :label="'Device'"
                  :disabled="disabledSelectedDeviceChannel"
                ></v-autocomplete> </v-col
              ><v-col cols="4"></v-col
            ></v-row>
            <v-row align="center" justify="center">
              <v-col cols="3"></v-col>
              <v-col cols="1">
                <v-progress-circular
                  v-if="selectedDeviceChannel && disabledChannel"
                  class="text-center"
                  indeterminate
                  color="primary"
                ></v-progress-circular
              ></v-col>
              <v-col cols="4">
                <v-autocomplete
                  :disabled="disabledChannel"
                  v-model="selectedChannel"
                  :items="channels"
                  :item-text="(item) => item.id + ' : ' + item.name"
                  item-value="id"
                  :label="'Channel'"
                ></v-autocomplete> </v-col
              ><v-col cols="4"></v-col><v-col cols="4"></v-col>
              <v-col cols="4">
                <v-checkbox
                  v-model="checkboxShowDateData"
                  :label="`Show updateTime for this data`"
                ></v-checkbox
              ></v-col>
              <v-col cols="4"></v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>

          <v-card-actions>
            <v-btn
              color="primary"
              text
              @click="
                unSaveWidget();
                leaveLinkDialogChannel();
              "
            >
              Cancel
            </v-btn>
            <v-btn
              v-if="currentStep != 0"
              color="primary"
              text
              @click="previousLinkChannel"
            >
              Previous
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
              v-if="currentStep != choosenWidget.myData.inputs.length - 1"
              @click="nextLinkChannel"
              color="primary"
              text
            >
              Next
            </v-btn>
            <v-btn
              v-if="currentStep == choosenWidget.myData.inputs.length - 1"
              @click="nextLinkChannel"
              color="primary"
              text
            >
              Save
            </v-btn>
          </v-card-actions>
        </template>
        <template
          v-if="
            choosenWidget.type == 'line' ||
            choosenWidget.type == 'area' ||
            choosenWidget.type == 'line2'
          "
        >
          <v-card-title class="headline secondary">
            Link data to widget
          </v-card-title>
          <v-app-bar flat color="rgba(0, 0, 0, 0)">
            <v-toolbar-title class="title pl-0">
              {{
                choosenWidget.mySeries[currentStep].text +
                ` (${currentStep + 1}/${choosenWidget.mySeries.length})`
              }}
            </v-toolbar-title>
          </v-app-bar>
          <v-card-text>
            <v-row align="center" justify="center">
              <v-col :cols="customLineChannel ? 6 : 12">
                <v-row align="center" justify="center">
                  <v-col cols="1"></v-col>
                  <v-col cols="4">
                    <v-autocomplete
                      v-model="selectedGroupChannel"
                      :items="groups"
                      item-text="name"
                      item-value="id"
                      :label="'Group'"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="2"></v-col
                  ><v-col cols="4">
                    <v-text-field
                      outlined
                      dense
                      hide-details
                      label="Legend name"
                      v-model="choosenWidget.myData.series[currentStep].text"
                      
                    /> </v-col
                  ><v-col cols="1"></v-col
                ></v-row>
                <v-row align="center" justify="center">
                  <v-col cols="1">
                    <v-progress-circular
                      v-if="
                        selectedGroupChannel && disabledSelectedDeviceChannel
                      "
                      class="text-center"
                      indeterminate
                      color="primary"
                    ></v-progress-circular
                  ></v-col>
                  <v-col cols="4">
                    <v-autocomplete
                      v-model="selectedDeviceChannel"
                      :items="devices"
                      item-text="name"
                      item-value="id"
                      :label="'Device'"
                      :disabled="disabledSelectedDeviceChannel"
                    ></v-autocomplete> </v-col
                  ><v-col cols="2"></v-col
                  ><v-col cols="4">
                    <v-select
                      v-if="choosenWidget.type != 'line2'"
                      v-model="choosenWidget.range"
                      :disabled="currentStep > 0"
                      :items="[
                        { item: 'Half hour', value: 'half-hour' },
                        { item: 'Hour', value: 'hour' },
                        { item: 'Day', value: 'day' },
                        { item: 'Week', value: 'week' },
                        { item: 'Month', value: 'month' },
                        { item: 'Year', value: 'year' },
                      ]"
                      item-text="item"
                      item-value="value"
                      label="Range"
                    ></v-select> </v-col
                  ><v-col cols="1"></v-col
                ></v-row>
                <v-row align="center" justify="center">
                  <v-col cols="1">
                    <v-progress-circular
                      v-if="selectedDeviceChannel && disabledChannel"
                      class="text-center"
                      indeterminate
                      color="primary"
                    ></v-progress-circular
                  ></v-col>
                  <v-col cols="4">
                    <v-autocomplete
                      :disabled="disabledChannel"
                      v-model="selectedChannel"
                      :items="channels"
                      :item-text="(item) => item.id + ' : ' + item.name"
                      item-value="id"
                      :label="'Channel'"
                    ></v-autocomplete> </v-col
                  ><v-col cols="2"></v-col
                  ><v-col cols="4" class="text-center">
                    <v-btn color="primary" @click="customLineChannelFct">
                      Custom
                    </v-btn></v-col
                  ><v-col cols="1"></v-col
                ></v-row>
                <v-row v-if="customLineChannel" align="center" justify="center">
                  <v-col cols="2"></v-col>
                  <v-col cols="3">
                    <v-select
                      :items="[
                        { name: 'segmented', text: 'Segmented' },
                        { name: 'spline', text: 'Spline' },
                        { name: 'stepped', text: 'Stepped' },
                      ]"
                      item-text="text"
                      item-value="name"
                      label="Line aspect"
                      dense
                      hide-details
                      outlined
                      v-model="choosenWidget.mySeries[currentStep].aspect"
                      @change="
                        () => {
                          forceUpdate++;
                        }
                      "
                    ></v-select
                  ></v-col>
                  <v-col cols="1"> </v-col
                  ><v-col cols="2">
                    <v-text-field
                      outlined
                      dense
                      hide-details
                      label="Size"
                      v-model="
                        choosenWidget.mySeries[currentStep]['line-width']
                      "
                      min="1"
                      max="20"
                      type="number"
                      @input="
                        () => {
                          forceUpdate++;
                        }
                      "
                    /> </v-col
                  ><v-col cols="3" class="text-center">
                    Line
                    <v-menu transition="slide-y-transition">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          style="border-style: solid; border-color: black"
                          elevation="2"
                          fab
                          height="25"
                          width="25"
                          :color="
                            choosenWidget.mySeries[currentStep]['line-color']
                          "
                          v-on="on"
                          v-bind="attrs"
                        >
                        </v-btn>
                      </template>
                      <v-card @click.stop.native>
                        <v-color-picker
                          v-model="
                            choosenWidget.mySeries[currentStep]['line-color']
                          "
                          :mode="'hexa'"
                          show-swatches
                          swatches-max-height="230px"
                          @input="
                            () => {
                              forceUpdate++;
                            }
                          "
                        ></v-color-picker
                      ></v-card>
                    </v-menu>
                  </v-col>
                  <v-col cols="1"></v-col>
                </v-row>
                <v-row v-if="customLineChannel" align="center" justify="center">
                  <v-col cols="2"> </v-col>
                  <v-col cols="3">
                    <v-select
                      :items="[
                        { name: 'solid', text: 'Solid' },
                        { name: 'dashed', text: 'Dashed' },
                        { name: 'dashdot', text: 'Dashdot' },
                        { name: 'dotted', text: 'Dotted' },
                      ]"
                      item-text="text"
                      item-value="name"
                      label="Line style"
                      dense
                      hide-details
                      outlined
                      v-model="
                        choosenWidget.mySeries[currentStep]['line-style']
                      "
                      @change="
                        () => {
                          forceUpdate++;
                        }
                      "
                    ></v-select></v-col
                  ><v-col cols="1"> </v-col
                  ><v-col cols="2">
                    <v-text-field
                      outlined
                      dense
                      hide-details
                      label="Marker size"
                      v-model="choosenWidget.mySeries[currentStep].marker.size"
                      min="1"
                      max="20"
                      type="number"
                      @input="
                        () => {
                          forceUpdate++;
                        }
                      "
                    />
                  </v-col>
                  <v-col cols="3" class="text-center">
                    Marker
                    <v-menu transition="slide-y-transition">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          style="border-style: solid; border-color: black"
                          elevation="2"
                          fab
                          height="25"
                          width="25"
                          :color="
                            choosenWidget.mySeries[currentStep].marker[
                              'background-color'
                            ]
                          "
                          v-on="on"
                          v-bind="attrs"
                        >
                        </v-btn>
                      </template>
                      <v-card @click.stop.native>
                        <v-color-picker
                          v-model="
                            choosenWidget.mySeries[currentStep].marker[
                              'background-color'
                            ]
                          "
                          :mode="'hexa'"
                          show-swatches
                          swatches-max-height="230px"
                          @input="
                            () => {
                              forceUpdate++;
                            }
                          "
                        ></v-color-picker
                      ></v-card>
                    </v-menu>
                  </v-col>
                  <v-col cols="1"></v-col>
                  <v-col cols="6" class="text-center">
                    Transparency
                    <v-slider
                      dense
                      max="1"
                      min="0"
                      step="0.01"
                      v-model="choosenWidget.mySeries[currentStep].alpha"
                      @input="
                        () => {
                          forceUpdate++;
                        }
                      "
                    ></v-slider>
                  </v-col>
                </v-row>
              </v-col>
              <v-col v-if="customLineChannel" cols="6">
                <v-card class="pb-6" height="400px">
                  <v-system-bar color="primary" :rounded="false">
                    <span
                      :class="['', 'mr-1']"
                      style="color: white; font-size: 16px"
                    ></span>
                    <span
                      class="mt-1"
                      style="height: 100%; overflow: hidden; color: white"
                      >Example</span
                    >
                    <v-spacer></v-spacer>
                    <v-icon color="white" @click="&quot;&quot;;"
                      >mdi-cog</v-icon
                    >
                    <v-icon color="white" @click="&quot;&quot;;"
                      >mdi-close</v-icon
                    >
                  </v-system-bar>

                  <widget
                    :key="forceUpdate"
                    :data="choosenWidget.myData"
                    :series="choosenWidget.mySeries"
                    :type="choosenWidget.type"
                  ></widget>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>

          <v-card-actions>
            <v-btn
              color="primary"
              text
              @click="
                unSaveWidget();
                leaveLinkDialogChannel();
              "
            >
              Cancel
            </v-btn>
            <v-btn
              v-if="currentStep != 0"
              color="primary"
              text
              @click="previousLinkChannel"
            >
              Previous
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
              v-if="currentStep != choosenWidget.myData.series.length - 1"
              @click="nextLinkChannel"
              color="primary"
              text
            >
              Next
            </v-btn>
            <v-btn
              v-if="currentStep == choosenWidget.myData.series.length - 1"
              @click="nextLinkChannel"
              color="primary"
              text
            >
              Save
            </v-btn>
          </v-card-actions>
        </template>
        <template
          v-if="
            choosenWidget.type == 'reflectogram' 
          "
        >
          <v-card-title class="headline secondary">
            Link data to widget
          </v-card-title>
          <v-app-bar flat color="rgba(0, 0, 0, 0)">
            <v-toolbar-title class="title pl-0">
              {{
                choosenWidget.mySeries[currentStep].text +
                ` (${currentStep + 1}/${choosenWidget.mySeries.length})`
              }}
            </v-toolbar-title>
          </v-app-bar>
          <v-card-text>
            <v-row align="center" justify="center">
              <v-col :cols="customLineChannel ? 6 : 12">
                <v-row align="center" justify="center">
                  <v-col cols="1"></v-col>
                  <v-col cols="4">
                    <v-autocomplete
                      v-model="selectedGroupChannel"
                      :items="groups"
                      item-text="name"
                      item-value="id"
                      :label="'Group'"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="2"></v-col
                  ><v-col cols="4">
                    <v-text-field
                      outlined
                      dense
                      hide-details
                      label="Legend name"
                      v-model="choosenWidget.myData.series[currentStep].text"
                      
                    /> </v-col
                  ><v-col cols="1"></v-col
                ></v-row>
                <v-row align="center" justify="center">
                  <v-col cols="1">
                    <v-progress-circular
                      v-if="
                        selectedGroupChannel && disabledSelectedDeviceChannel
                      "
                      class="text-center"
                      indeterminate
                      color="primary"
                    ></v-progress-circular
                  ></v-col>
                  <v-col cols="4">
                    <v-autocomplete
                      v-model="selectedDeviceChannel"
                      :items="devices"
                      item-text="name"
                      item-value="id"
                      :label="'Device'"
                      :disabled="disabledSelectedDeviceChannel"
                    ></v-autocomplete> </v-col
                  ><v-col cols="2"></v-col
                  ><v-col cols="4">
                     </v-col
                  ><v-col cols="1"></v-col
                ></v-row>
                <v-row align="center" justify="center">
                  <v-col cols="1">
                    <v-progress-circular
                      v-if="selectedDeviceChannel && disabledChannel"
                      class="text-center"
                      indeterminate
                      color="primary"
                    ></v-progress-circular
                  ></v-col>
                  <v-col cols="4">
                    <v-autocomplete
                      :disabled="disabledChannel"
                      v-model="selectedChannel"
                      :items="channels"
                      :item-text="(item) => item.id + ' : ' + item.name"
                      item-value="id"
                      :label="'Channel'"
                    ></v-autocomplete> </v-col
                  ><v-col cols="2"></v-col
                  ><v-col cols="4" class="text-center">
                    <v-btn color="primary" @click="customLineChannelFct">
                      Custom
                    </v-btn></v-col
                  ><v-col cols="1"></v-col
                ></v-row>
                <v-row v-if="customLineChannel" align="center" justify="center">
                  <v-col cols="2"></v-col>
                  <v-col cols="3">
                    <v-select
                      :items="[
                        { name: 'segmented', text: 'Segmented' },
                        { name: 'spline', text: 'Spline' },
                        { name: 'stepped', text: 'Stepped' },
                      ]"
                      item-text="text"
                      item-value="name"
                      label="Line aspect"
                      dense
                      hide-details
                      outlined
                      v-model="choosenWidget.mySeries[currentStep].aspect"
                      @change="
                        () => {
                          forceUpdate++;
                        }
                      "
                    ></v-select
                  ></v-col>
                  <v-col cols="1"> </v-col
                  ><v-col cols="2">
                    <v-text-field
                      outlined
                      dense
                      hide-details
                      label="Size"
                      v-model="
                        choosenWidget.mySeries[currentStep]['line-width']
                      "
                      min="1"
                      max="20"
                      type="number"
                      @input="
                        () => {
                          forceUpdate++;
                        }
                      "
                    /> </v-col
                  ><v-col cols="3" class="text-center">
                    Line
                    <v-menu transition="slide-y-transition">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          style="border-style: solid; border-color: black"
                          elevation="2"
                          fab
                          height="25"
                          width="25"
                          :color="
                            choosenWidget.mySeries[currentStep]['line-color']
                          "
                          v-on="on"
                          v-bind="attrs"
                        >
                        </v-btn>
                      </template>
                      <v-card @click.stop.native>
                        <v-color-picker
                          v-model="
                            choosenWidget.mySeries[currentStep]['line-color']
                          "
                          :mode="'hexa'"
                          show-swatches
                          swatches-max-height="230px"
                          @input="
                            () => {
                              forceUpdate++;
                            }
                          "
                        ></v-color-picker
                      ></v-card>
                    </v-menu>
                  </v-col>
                  <v-col cols="1"></v-col>
                </v-row>
                <v-row v-if="customLineChannel" align="center" justify="center">
                  <v-col cols="2"> </v-col>
                  <v-col cols="3">
                    <v-select
                      :items="[
                        { name: 'solid', text: 'Solid' },
                        { name: 'dashed', text: 'Dashed' },
                        { name: 'dashdot', text: 'Dashdot' },
                        { name: 'dotted', text: 'Dotted' },
                      ]"
                      item-text="text"
                      item-value="name"
                      label="Line style"
                      dense
                      hide-details
                      outlined
                      v-model="
                        choosenWidget.mySeries[currentStep]['line-style']
                      "
                      @change="
                        () => {
                          forceUpdate++;
                        }
                      "
                    ></v-select></v-col
                  ><v-col cols="1"> </v-col
                  ><v-col cols="2">
                    <v-text-field
                      outlined
                      dense
                      hide-details
                      label="Marker size"
                      v-model="choosenWidget.mySeries[currentStep].marker.size"
                      min="1"
                      max="20"
                      type="number"
                      @input="
                        () => {
                          forceUpdate++;
                        }
                      "
                    />
                  </v-col>
                  <v-col cols="3" class="text-center">
                    Marker
                    <v-menu transition="slide-y-transition">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          style="border-style: solid; border-color: black"
                          elevation="2"
                          fab
                          height="25"
                          width="25"
                          :color="
                            choosenWidget.mySeries[currentStep].marker[
                              'background-color'
                            ]
                          "
                          v-on="on"
                          v-bind="attrs"
                        >
                        </v-btn>
                      </template>
                      <v-card @click.stop.native>
                        <v-color-picker
                          v-model="
                            choosenWidget.mySeries[currentStep].marker[
                              'background-color'
                            ]
                          "
                          :mode="'hexa'"
                          show-swatches
                          swatches-max-height="230px"
                          @input="
                            () => {
                              forceUpdate++;
                            }
                          "
                        ></v-color-picker
                      ></v-card>
                    </v-menu>
                  </v-col>
                  <v-col cols="1"></v-col>
                  <v-col cols="6" class="text-center">
                    Transparency
                    <v-slider
                      dense
                      max="1"
                      min="0"
                      step="0.01"
                      v-model="choosenWidget.mySeries[currentStep].alpha"
                      @input="
                        () => {
                          forceUpdate++;
                        }
                      "
                    ></v-slider>
                  </v-col>
                </v-row>
              </v-col>
              <v-col v-if="customLineChannel" cols="6">
                <v-card class="pb-6" height="400px">
                  <v-system-bar color="primary" :rounded="false">
                    <span
                      :class="['', 'mr-1']"
                      style="color: white; font-size: 16px"
                    ></span>
                    <span
                      class="mt-1"
                      style="height: 100%; overflow: hidden; color: white"
                      >Example</span
                    >
                    <v-spacer></v-spacer>
                    <v-icon color="white" @click="&quot;&quot;;"
                      >mdi-cog</v-icon
                    >
                    <v-icon color="white" @click="&quot;&quot;;"
                      >mdi-close</v-icon
                    >
                  </v-system-bar>

                  <widget
                    :key="forceUpdate"
                    :data="choosenWidget.myData"
                    :series="choosenWidget.mySeries"
                    :type="choosenWidget.type"
                  ></widget>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>

          <v-card-actions>
            <v-btn
              color="primary"
              text
              @click="
                unSaveWidget();
                leaveLinkDialogChannel();
              "
            >
              Cancel
            </v-btn>
            <v-btn
              v-if="currentStep != 0"
              color="primary"
              text
              @click="previousLinkChannel"
            >
              Previous
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
              v-if="currentStep != choosenWidget.myData.series.length - 1"
              @click="nextLinkChannel"
              color="primary"
              text
            >
              Next
            </v-btn>
            <v-btn
              v-if="currentStep == choosenWidget.myData.series.length - 1"
              @click="nextLinkChannel"
              color="primary"
              text
            >
              Save
            </v-btn>
          </v-card-actions>
        </template>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="presetDialog"
      scrollable
      width="500"
      @click:outside="
        () => {
          presetDialog = false;
          presetName = '';
          presetDescription = '';
          saveAsDefaultPreset = false;
        }
      "
    >
      <v-card>
        <v-card-title class="headline secondary">
          {{
            saveAsDefaultPreset ? "Save as default preset" : "Save as preset"
          }}
        </v-card-title>

        <v-card-text>
          <v-row justify="center"
            ><v-col cols="12" class="pt-0 pb-0">
              <v-text-field
                v-model="presetName"
                label="Preset Name"
                clearable
              ></v-text-field></v-col
            ><v-col cols="12" class="pt-0 pb-0">
              <v-textarea
                auto-grow
                clearable
                rows="1"
                clear-icon="mdi-close-circle"
                v-model="presetDescription"
                label="Description"
              ></v-textarea></v-col></v-row
        ></v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click="
              () => {
                presetDialog = false;
                presetName = '';
                presetDescription = '';
                saveAsDefaultPreset = false;
              }
            "
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn color="primary" text @click="savePreset"> Save </v-btn>
        </v-card-actions>
      </v-card></v-dialog
    >

    <v-dialog
      v-model="editWidgetDialog"
      scrollable
      width="1000px"
      @click:outside="
        unSaveWidget();
        leaveEditDialog();
      "
    >
      <v-card>
        <v-card-title class="headline secondary"> Edit widget </v-card-title>

        <v-card-text>
          <template v-if="choosenWidget != null">
            <v-row class="mt-5">
              <v-col cols="4"></v-col>
              <v-col cols="4">
                <v-text-field
                  class="hide-details"
                  outlined
                  dense
                  label="Widget name"
                  v-model="choosenWidget.name"
                ></v-text-field>
              </v-col>
              <v-col cols="4"></v-col>

              <v-col cols="4"></v-col>

              <v-col cols="4">
                <v-select
                  :items="widgetIconList"
                  label="Icon"
                  dense
                  hide-details
                  outlined
                  v-model="choosenWidget.icon"
                  clearable
                >
                  <template slot="selection" slot-scope="data">
                    <span :class="[data.item, 'mr-3']"></span>
                    {{ data.item }}
                  </template>
                  <template slot="item" slot-scope="data">
                    <span :class="[data.item, 'mr-3']"></span>
                    {{ data.item }}
                  </template></v-select
                >
              </v-col>

              <v-col cols="4"></v-col>
              <v-col cols="4"></v-col>

              <v-col cols="4">
                <v-checkbox
                  v-model="choosenWidget.transparent"
                  :label="`Transparent`"
                ></v-checkbox>
              </v-col>

              <v-col cols="4"></v-col>
            </v-row>
          </template>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click="
              unSaveWidget();
              leaveEditDialog();
            "
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="leaveEditDialog()"> Edit </v-btn>
        </v-card-actions>
      </v-card></v-dialog
    >

    <v-dialog v-model="dialog" scrollable width="80%">
      <v-card>
        <v-card-title class="headline secondary"> Add widget </v-card-title>

        <v-card-text>
          <v-row class="mt-5">
            <v-col cols="6">
              <v-text-field
                class="hide-details"
                outlined
                dense
                label="Widget name"
                v-model="widgetName"
              ></v-text-field>
              <v-select
                :items="widgetIconList"
                label="Icon"
                dense
                hide-details
                outlined
                v-model="widgetIcon"
                clearable
              >
                <template slot="selection" slot-scope="data">
                  <span :class="[data.item, 'mr-3']"></span>
                  {{ data.item }}
                </template>
                <template slot="item" slot-scope="data">
                  <span :class="[data.item, 'mr-3']"></span>
                  {{ data.item }}
                </template></v-select
              >
              <v-row no-gutters>
                <v-col>
                  <v-radio-group v-model="radioGroup">
                    <v-row no-gutters>
                      <v-col
                        class="mb-2 pl-5"
                        cols="6"
                        v-for="item in [
                          { name: 'Simple data', i: 0 },
                          { name: 'Status', i: 6 },
                          { name: 'Line chart (Auto Range)', i: 1 },
                          { name: 'Alert', i: 7 },
                          { name: 'Line chart (Specific Range)', i: 12 },
                          //{ name: 'Reflectogram', i: 13 },
                          { name: 'Map', i: 8 },
                          { name: 'Area chart', i: 2 },
                          { name: 'DownLink Communication', i: 11 },
                          { name: 'Link to dashboard', i: 9 },
                          //{ name: 'Image', i: 10 },

                          //{name:'Bar chart',i:3},
                          //{name:'Pie',i:4},
                          //{name:'Gauge',i:5},
                        ]"
                        :key="item.i"
                      >
                        <v-radio :label="item.name" :value="item.i"></v-radio>
                      </v-col>
                    </v-row>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="text-center pl-5 pr-5 mt-1 pt-0">
                  <template v-if="radioGroup == 0">
                    <v-card
                      style="height: 150px; overflow-y: scroll"
                      elevation="1"
                    >
                      <v-card-text>
                        <v-row no-gutters>
                          <v-col
                            cols="4"
                            v-for="(input, i) in widgetModels[radioGroup].myData
                              .inputs"
                            :key="i"
                          >
                            <v-row no-gutters align="center" justify="center">
                              <v-col cols="9" class="pt-2 pb-2">
                                <v-text-field
                                  hide-details
                                  outlined
                                  dense
                                  :label="'Input ' + (i + 1)"
                                  v-model="input.name"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="2">
                                <v-btn
                                  icon
                                  color="primary"
                                  class="pb-1"
                                  :disabled="
                                    widgetModels[radioGroup].myData.inputs
                                      .length == 1
                                  "
                                  @click="removeTextInput(input)"
                                >
                                  <v-icon>mdi-delete</v-icon>
                                </v-btn>
                              </v-col>
                              <v-col cols="1"></v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                    <!-- <v-expansion-panels accordion>
                      <v-expansion-panel
                        v-for="(input, i) in widgetModels[radioGroup].myData
                          .inputs"
                        :key="i"
                      >
                        <v-expansion-panel-header>{{
                          input.name
                        }}</v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-text-field
                            hide-details
                            outlined
                            dense
                            :label="'Input ' + (i + 1)"
                            v-model="input.name"
                          ></v-text-field>
                          <v-row align="center" justify="center">
                            <v-col cols="4">
                              <v-checkbox
                                v-model="input.center"
                                :label="`Center`"
                              ></v-checkbox></v-col
                            ><v-col cols="4">
                              <v-text-field
                                class="pt-5"
                                outlined
                                dense
                                :rules="rules"
                                label="Size"
                                v-model="input.size"
                                min="8"
                                max="72"
                                type="number"
                            /></v-col>
                            <v-col cols="4">
                              <v-btn
                                icon
                                color="primary"
                                class="pb-1"
                                @click="removeTextInput(input)"
                              >
                                <v-icon>mdi-delete</v-icon>
                              </v-btn></v-col
                            >
                          </v-row>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels> -->
                    <v-btn
                      class="mt-5"
                      depressed
                      color="primary"
                      @click="addTextInput"
                      ><v-icon left> mdi-plus </v-icon>
                      Input
                    </v-btn>
                  </template>
                  <template
                    v-if="
                      radioGroup == 1 || radioGroup == 2 || radioGroup == 12
                    "
                  >
                    <v-card
                      style="height: 150px; overflow-y: scroll"
                      elevation="1"
                    >
                      <v-card-text>
                        <v-row no-gutters>
                          <v-col
                            cols="4"
                            v-for="(input, i) in widgetModels[radioGroup]
                              .mySeries"
                            :key="i"
                          >
                            <v-row no-gutters align="center" justify="center">
                              <v-col cols="7" class="pt-2 pb-2">
                                <v-text-field
                                  hide-details
                                  outlined
                                  dense
                                  :label="'Input ' + (i + 1)"
                                  v-model="input.text"
                                  @change="updateModelWidget"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="2">
                                <v-menu transition="slide-y-transition">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      style="
                                        border-style: solid;
                                        border-color: black;
                                      "
                                      elevation="2"
                                      fab
                                      height="25"
                                      width="25"
                                      :color="input['line-color']"
                                      v-on="on"
                                      v-bind="attrs"
                                    >
                                    </v-btn>
                                  </template>
                                  <v-card @click.stop.native>
                                    <v-color-picker
                                      v-model="input['line-color']"
                                      :mode="'hexa'"
                                      @input="
                                        () => {
                                          input.marker['background-color'] =
                                            input['line-color'];
                                          if (radioGroup == 2)
                                            input['background-color'] =
                                              input['line-color'];
                                          updateModelWidget();
                                        }
                                      "
                                      show-swatches
                                      swatches-max-height="230px"
                                    ></v-color-picker
                                  ></v-card>
                                </v-menu>
                              </v-col>
                              <v-col cols="2">
                                <v-btn
                                  icon
                                  color="primary"
                                  class="pb-1"
                                  @click="removeInput(input)"
                                  :disabled="
                                    widgetModels[radioGroup].mySeries.length ==
                                    1
                                  "
                                >
                                  <v-icon>mdi-delete</v-icon>
                                </v-btn>
                              </v-col>
                              <v-col cols="1"></v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>

                    <!-- <v-expansion-panels accordion>
                      <v-expansion-panel
                        v-for="(input, i) in widgetModels[radioGroup].mySeries"
                        :key="i"
                      >
                        <v-expansion-panel-header>{{
                          input.text
                        }}</v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-text-field
                            hide-details
                            outlined
                            dense
                            :label="'Input ' + (i + 1)"
                            v-model="input.text"
                          ></v-text-field>
                          <v-row class="mt-3" align="center" justify="center">
                            <v-col cols="6">
                              <v-select
                                :items="[
                                  { name: 'segmented', text: 'Segmented' },
                                  { name: 'spline', text: 'Spline' },
                                  { name: 'stepped', text: 'Stepped' },
                                ]"
                                item-text="text"
                                item-value="name"
                                label="Line aspect"
                                dense
                                hide-details
                                outlined
                                v-model="input.aspect"
                                @change="updateModelWidget"
                              ></v-select>
                            </v-col>
                            <v-col cols="4">
                              <v-text-field
                                outlined
                                dense
                                hide-details
                                label="Size"
                                v-model="input['line-width']"
                                min="1"
                                max="20"
                                type="number"
                                @input="updateModelWidget"
                              />
                            </v-col>
                            <v-col cols="2">
                              <v-menu transition="slide-y-transition">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    style="
                                      border-style: solid;
                                      border-color: black;
                                    "
                                    elevation="2"
                                    fab
                                    height="25"
                                    width="25"
                                    :color="input['line-color']"
                                    v-on="on"
                                    v-bind="attrs"
                                  >
                                  </v-btn>
                                </template>
                                <v-card @click.stop.native>
                                  <v-color-picker
                                    canvas-height="300"
                                    v-model="input['line-color']"
                                    :mode="'hexa'"
                                    @input="updateModelWidget"
                                  ></v-color-picker
                                ></v-card>
                              </v-menu>
                            </v-col>

                            <v-col cols="6">
                              <v-select
                                :items="[
                                  { name: 'solid', text: 'Solid' },
                                  { name: 'dashed', text: 'Dashed' },
                                  { name: 'dashdot', text: 'Dashdot' },
                                  { name: 'dotted', text: 'Dotted' },
                                ]"
                                item-text="text"
                                item-value="name"
                                label="Line style"
                                dense
                                hide-details
                                outlined
                                v-model="input['line-style']"
                                @change="updateModelWidget"
                              ></v-select>
                            </v-col>
                            <v-col cols="4">
                              <v-text-field
                                outlined
                                dense
                                hide-details
                                label="Marker size"
                                v-model="input.marker.size"
                                min="1"
                                max="20"
                                type="number"
                                @input="updateModelWidget"
                              />
                            </v-col>
                            <v-col cols="2">
                              <v-menu transition="slide-y-transition">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    style="
                                      border-style: solid;
                                      border-color: black;
                                    "
                                    elevation="2"
                                    fab
                                    height="25"
                                    width="25"
                                    :color="input.marker['background-color']"
                                    v-on="on"
                                    v-bind="attrs"
                                  >
                                  </v-btn>
                                </template>
                                <v-card @click.stop.native>
                                  <v-color-picker
                                    canvas-height="300"
                                    v-model="input.marker['background-color']"
                                    :mode="'hexa'"
                                    @input="updateModelWidget"
                                  ></v-color-picker
                                ></v-card>
                              </v-menu>
                            </v-col>
                            <v-col v-if="radioGroup == 2" cols="10"
                              ><v-slider
                                ref="slider"
                                v-model="input['alpha-area']"
                                class="align-self-stretch"
                                min="0"
                                max="1"
                                step="0.01"
                                @input="updateModelWidget"
                                hide-details
                                label="Opacity"
                              ></v-slider>
                            </v-col>
                            <v-col cols="2" v-if="radioGroup == 2">
                              <v-menu transition="slide-y-transition">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    style="
                                      border-style: solid;
                                      border-color: black;
                                    "
                                    elevation="2"
                                    fab
                                    height="25"
                                    width="25"
                                    :color="input['background-color']"
                                    v-on="on"
                                    v-bind="attrs"
                                  >
                                  </v-btn>
                                </template>
                                <v-card @click.stop.native>
                                  <v-color-picker
                                    canvas-height="300"
                                    v-model="input['background-color']"
                                    :mode="'hexa'"
                                    @input="updateModelWidget"
                                  ></v-color-picker
                                ></v-card>
                              </v-menu>
                            </v-col>
                            <v-col cols="2">
                              <v-btn
                                icon
                                color="primary"
                                class="pb-1"
                                @click="removeInput(input)"
                              >
                                <v-icon>mdi-delete</v-icon>
                              </v-btn></v-col
                            >
                          </v-row>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels> -->
                    <v-btn
                      class="mt-5"
                      depressed
                      color="primary"
                      @click="addInput"
                      ><v-icon left> mdi-plus </v-icon>
                      Input
                    </v-btn>
                  </template>
                  <template v-if="radioGroup == 3">
                    <v-expansion-panels accordion>
                      <v-expansion-panel
                        v-for="(input, i) in widgetModels[radioGroup].mySeries"
                        :key="i"
                      >
                        <v-expansion-panel-header>{{
                          input.text
                        }}</v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-text-field
                            hide-details
                            outlined
                            dense
                            :label="'Input ' + (i + 1)"
                            v-model="input.text"
                          ></v-text-field>
                          <v-row class="mt-3" align="center" justify="center">
                            <v-col cols="10"
                              ><v-slider
                                ref="slider1"
                                v-model="input.alpha"
                                class="align-self-stretch"
                                min="0"
                                max="1"
                                step="0.01"
                                @input="updateModelWidget"
                                hide-details
                                label="Opacity"
                              ></v-slider>
                            </v-col>
                            <v-col cols="2">
                              <v-menu transition="slide-y-transition">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    style="
                                      border-style: solid;
                                      border-color: black;
                                    "
                                    elevation="2"
                                    fab
                                    height="25"
                                    width="25"
                                    :color="input['background-color']"
                                    v-on="on"
                                    v-bind="attrs"
                                  >
                                  </v-btn>
                                </template>
                                <v-card @click.stop.native>
                                  <v-color-picker
                                    v-model="input['background-color']"
                                    :mode="'hexa'"
                                    @input="updateModelWidget"
                                    show-swatches
                                    swatches-max-height="230px"
                                  ></v-color-picker
                                ></v-card>
                              </v-menu>
                            </v-col>
                            <v-col cols="8">
                              <v-select
                                :items="[
                                  { name: 'bar', text: 'Bar' },
                                  { name: 'cone', text: 'Cone' },
                                ]"
                                item-text="text"
                                item-value="name"
                                label="Aspect"
                                dense
                                hide-details
                                outlined
                                v-model="input.aspect"
                                @change="updateModelWidget"
                              ></v-select>
                            </v-col>

                            <v-col cols="4">
                              <v-checkbox
                                v-model="input.line"
                                :label="`Line`"
                                @change="
                                  () => {
                                    if (!input.line) {
                                      input['border-color'] = '';
                                    } else {
                                      input['border-color'] = '#000000';
                                    }
                                    updateModelWidget();
                                  }
                                "
                                @load="
                                  () => {
                                    if (!input.line) {
                                      input['border-color'] = '';
                                    } else {
                                      input['border-color'] = '#000000';
                                    }
                                    updateModelWidget();
                                  }
                                "
                              ></v-checkbox
                            ></v-col>
                            <template v-if="input.line">
                              <v-col cols="6">
                                <v-select
                                  :items="[
                                    { name: 'solid', text: 'Solid' },
                                    { name: 'dashed', text: 'Dashed' },
                                    { name: 'dashdot', text: 'Dashdot' },
                                    { name: 'dotted', text: 'Dotted' },
                                  ]"
                                  item-text="text"
                                  item-value="name"
                                  label="Line style"
                                  dense
                                  hide-details
                                  outlined
                                  v-model="input['line-style']"
                                  @change="updateModelWidget"
                                ></v-select
                              ></v-col>
                              <v-col cols="4">
                                <v-text-field
                                  outlined
                                  dense
                                  hide-details
                                  label="Border width"
                                  v-model="input['border-width']"
                                  min="1"
                                  max="10"
                                  type="number"
                                  @input="updateModelWidget"
                                />
                              </v-col>
                              <v-col cols="2">
                                <v-menu transition="slide-y-transition">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      style="
                                        border-style: solid;
                                        border-color: black;
                                      "
                                      elevation="2"
                                      fab
                                      height="25"
                                      width="25"
                                      :color="input['border-color']"
                                      v-on="on"
                                      v-bind="attrs"
                                    >
                                    </v-btn>
                                  </template>
                                  <v-card @click.stop.native>
                                    <v-color-picker
                                      v-model="input['border-color']"
                                      :mode="'hexa'"
                                      @input="updateModelWidget"
                                      show-swatches
                                      swatches-max-height="230px"
                                    ></v-color-picker
                                  ></v-card>
                                </v-menu>
                              </v-col>

                              <v-col cols="12" v-if="input.aspect == 'bar'"
                                ><v-slider
                                  ref="slider2"
                                  v-model="input['border-radius']"
                                  class="align-self-stretch"
                                  min="0"
                                  max="25"
                                  step="1"
                                  @input="updateModelWidget"
                                  hide-details
                                  label="Border radius"
                                ></v-slider>
                              </v-col>

                              <v-col cols="12"
                                ><v-slider
                                  ref="slider3"
                                  v-model="input['border-alpha']"
                                  class="align-self-stretch"
                                  min="0"
                                  max="1"
                                  step="0.01"
                                  @input="updateModelWidget"
                                  hide-details
                                  label="Alpha Border"
                                ></v-slider>
                              </v-col>
                            </template>
                            <v-col cols="2">
                              <v-btn
                                icon
                                color="primary"
                                class="pb-1"
                                @click="removeInput(input)"
                              >
                                <v-icon>mdi-delete</v-icon>
                              </v-btn></v-col
                            >
                          </v-row>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                    <v-btn
                      class="mt-5"
                      depressed
                      color="primary"
                      @click="addInput"
                      ><v-icon left> mdi-plus </v-icon>
                      Input
                    </v-btn>
                  </template>
                  <template v-if="radioGroup == 4">
                    <v-row class="ma-0 pa-0" align="center" justify="center">
                      <v-col cols="12" class="ma-0 pa-0"
                        ><v-slider
                          ref="slider"
                          v-model="pieSlice"
                          class="align-self-stretch"
                          min="0"
                          max="100"
                          step="1"
                          @input="updateModelWidget"
                          hide-details
                          label="Splice"
                        ></v-slider> </v-col
                      ><v-col cols="8" class="ma-0 pa-0">
                        <v-checkbox
                          v-model="widgetModels[radioGroup].myData.plot.box"
                          :label="`Box out`"
                          @change="
                            () => {
                              if (widgetModels[radioGroup].myData.plot.box) {
                                widgetModels[radioGroup].myData.plot[
                                  'value-box'
                                ].placement = 'out';
                              } else {
                                widgetModels[radioGroup].myData.plot[
                                  'value-box'
                                ].placement = 'in';
                              }
                              updateModelWidget();
                            }
                          "
                          @load="
                            () => {
                              if (widgetModels[radioGroup].myData.plot.box) {
                                widgetModels[radioGroup].myData.plot[
                                  'value-box'
                                ].placement = 'out';
                              } else {
                                widgetModels[radioGroup].myData.plot[
                                  'value-box'
                                ].placement = 'in';
                              }
                              updateModelWidget();
                            }
                          "
                        ></v-checkbox
                      ></v-col>
                      <v-col cols="4" class="ma-0">
                        <v-text-field
                          outlined
                          dense
                          hide-details
                          label="Font size"
                          v-model="
                            widgetModels[radioGroup].myData.plot['value-box'][
                              'font-size'
                            ]
                          "
                          min="1"
                          max="50"
                          type="number"
                          @input="updateModelWidget"
                        />
                      </v-col>
                      <v-col cols="12" class="ma-0 pa-0">
                        <v-checkbox
                          v-model="widgetModels[radioGroup].myData.plot.line"
                          :label="`Line`"
                          @change="
                            () => {
                              if (!widgetModels[radioGroup].myData.plot.line) {
                                widgetModels[radioGroup].myData.plot[
                                  'border-color'
                                ] = '';
                              } else {
                                widgetModels[radioGroup].myData.plot[
                                  'border-color'
                                ] = '#000000';
                              }
                              updateModelWidget();
                            }
                          "
                          @load="
                            () => {
                              if (!widgetModels[radioGroup].myData.plot.line) {
                                widgetModels[radioGroup].myData.plot[
                                  'border-color'
                                ] = '';
                              } else {
                                widgetModels[radioGroup].myData.plot[
                                  'border-color'
                                ] = '#000000';
                              }
                              updateModelWidget();
                            }
                          "
                        ></v-checkbox
                      ></v-col>

                      <template
                        v-if="widgetModels[radioGroup].myData.plot.line"
                      >
                        <v-col cols="6" class="ma-0">
                          <v-select
                            :items="[
                              { name: 'solid', text: 'Solid' },
                              { name: 'dashed', text: 'Dashed' },
                              { name: 'dashdot', text: 'Dashdot' },
                              { name: 'dotted', text: 'Dotted' },
                            ]"
                            item-text="text"
                            item-value="name"
                            label="Line style"
                            dense
                            hide-details
                            outlined
                            v-model="
                              widgetModels[radioGroup].myData.plot['line-style']
                            "
                            @change="updateModelWidget"
                          ></v-select
                        ></v-col>
                        <v-col cols="4" class="ma-0">
                          <v-text-field
                            outlined
                            dense
                            hide-details
                            label="Border width"
                            v-model="
                              widgetModels[radioGroup].myData.plot[
                                'border-width'
                              ]
                            "
                            min="1"
                            max="10"
                            type="number"
                            @input="updateModelWidget"
                          />
                        </v-col>
                        <v-col cols="2" class="ma-0 pa-0">
                          <v-menu transition="slide-y-transition">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                style="border-style: solid; border-color: black"
                                elevation="2"
                                fab
                                height="25"
                                width="25"
                                :color="
                                  widgetModels[radioGroup].myData.plot[
                                    'border-color'
                                  ]
                                "
                                v-on="on"
                                v-bind="attrs"
                              >
                              </v-btn>
                            </template>
                            <v-card @click.stop.native>
                              <v-color-picker
                                v-model="
                                  widgetModels[radioGroup].myData.plot[
                                    'border-color'
                                  ]
                                "
                                :mode="'hexa'"
                                @input="updateModelWidget"
                                show-swatches
                                swatches-max-height="230px"
                              ></v-color-picker
                            ></v-card>
                          </v-menu>
                        </v-col>
                      </template>
                      <template
                        v-for="(input, i) in widgetModels[radioGroup].mySeries"
                      >
                        <v-col cols="12" :key="i" class="ma-0 pa-0"
                          ><v-row
                            align="center"
                            justify="center"
                            class="ma-0 pa-0"
                          >
                            <v-col cols="4" class="ma-0 pa-0">
                              <v-checkbox
                                v-model="input.detached"
                                :label="`Detached`"
                                @change="updateModelWidget"
                              ></v-checkbox
                            ></v-col>
                            <v-col cols="2" class="ma-0 pa-0">
                              <v-menu transition="slide-y-transition">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    style="
                                      border-style: solid;
                                      border-color: black;
                                    "
                                    elevation="2"
                                    fab
                                    height="25"
                                    width="25"
                                    :color="input['background-color']"
                                    v-on="on"
                                    v-bind="attrs"
                                  >
                                  </v-btn>
                                </template>
                                <v-card @click.stop.native>
                                  <v-color-picker
                                    v-model="input['background-color']"
                                    :mode="'hexa'"
                                    @input="updateModelWidget"
                                    show-swatches
                                    swatches-max-height="230px"
                                  ></v-color-picker
                                ></v-card>
                              </v-menu>
                            </v-col>
                            <v-col cols="2" class="ma-0 pa-0">
                              <v-btn
                                icon
                                color="primary"
                                class="pb-1"
                                @click="removeInput(input)"
                              >
                                <v-icon>mdi-delete</v-icon>
                              </v-btn></v-col
                            ></v-row
                          >
                        </v-col>
                      </template>
                      <v-col cols="12" class="ma-0">
                        <v-btn
                          icon
                          color="primary"
                          class="pb-1"
                          @click="addInput()"
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn></v-col
                      >
                    </v-row>
                  </template>
                  <template v-if="radioGroup == 6"> </template>
                  <template v-if="radioGroup == 7"></template
                  ><template v-if="radioGroup == 8"></template>
                  <template v-if="radioGroup == 9"></template>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="6">
              <v-card
                class="pb-6"
                height="400px"
                :style="
                  'background-color:' +
                  ($vuetify.theme.dark
                    ? $vuetify.theme.themes.dark.widgetBackground
                    : $vuetify.theme.themes.light.widgetBackground) +
                  ' !important;'
                "
              >
                <v-system-bar color="primary" :rounded="false">
                  <span
                    :class="[widgetIcon, 'mr-1']"
                    style="color: white; font-size: 16px"
                  ></span>
                  <span
                    class="mt-1"
                    style="height: 100%; overflow: hidden; color: white"
                    >{{ widgetName }}</span
                  >
                  <v-spacer></v-spacer>
                  <v-icon color="white" @click="&quot;&quot;;">mdi-cog</v-icon>
                  <v-icon color="white" @click="&quot;&quot;;"
                    >mdi-close</v-icon
                  >
                </v-system-bar>
                <widget
                  :key="forceUpdate"
                  :data="widgetModels[radioGroup].myData"
                  :series="widgetModels[radioGroup].mySeries"
                  :type="widgetModels[radioGroup].type"
                ></widget>
              </v-card> </v-col
          ></v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click="
              dialog = false;
              resetWidgetModels;
            "
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="widget != null"
            color="primary"
            text
            @click="saveEditedWidget()"
          >
            Edit
          </v-btn>
          <v-btn v-else color="primary" text @click="createWidget()">
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <uploadImg
      :dashboard="selectedDashboard"
      @uploaded="changeImg"
      :uploadImage="uploadImage"
      @leaveUploadDialog="uploadImage = false"
    ></uploadImg>
    <v-dialog
      v-model="linkDialog"
      scrollable
      width="1000px"
      @click:outside="leaveLinkDialog"
    >
      <v-card v-if="linkDialog">
        <v-card-title class="headline secondary">
          Link data to widget
        </v-card-title>
        <v-card-text class="mt-5">
          <template v-if="currentStep == 0">
            <v-row>
              <v-col cols="4" style="height: 550px; overflow-y: scroll">
                <template v-for="group in sortedGroups">
                  <v-card
                    :class="[
                      'mb-3',
                      selectedGroup != null && selectedGroup.id == group.id
                        ? 'selected'
                        : 'unselected',
                    ]"
                    elevation="2"
                    :key="group.id"
                    :ripple="false"
                    @click="selectGroup(group)"
                  >
                    <v-app-bar flat color="rgba(0, 0, 0, 0)">
                      <v-toolbar-title class="title pl-0">
                        {{ group.name }}
                      </v-toolbar-title>
                    </v-app-bar>
                    <v-card-subtitle class="mb-0 mt-0"
                      ><div>{{ group.id }}</div>
                      <div>Subgroups : {{ group.subGroups.length }}</div>
                    </v-card-subtitle>
                    <v-card-text>{{ group.description }} </v-card-text>
                  </v-card>
                </template>
              </v-col>
              <v-col cols="8" style="height: 550px; overflow-y: scroll">
                <template v-if="!deviceLoading">
                  <template v-for="device in sortedDevices">
                    <v-card
                      :class="[
                        'mb-3',
                        selectedDevices.findIndex((dev) => {
                          return dev.id == device.id;
                        }) >= 0
                          ? 'selected'
                          : 'unselected',
                      ]"
                      elevation="2"
                      :key="device.id"
                      :ripple="false"
                      @click="selectDevice(device)"
                    >
                      <v-app-bar flat color="rgba(0, 0, 0, 0)">
                        <v-toolbar-title class="title pl-0">
                          {{ device.name }}
                        </v-toolbar-title>
                      </v-app-bar>
                      <v-card-subtitle class="mb-0 mt-0"
                        ><div>{{ device.id }}</div>
                      </v-card-subtitle>
                    </v-card>
                  </template></template
                >
                <template v-else>
                  <template v-if="selectedGroup != null">
                    <div class="text-center">
                      <v-progress-circular
                        class="text-center"
                        indeterminate
                        color="primary"
                      ></v-progress-circular></div></template
                ></template>
              </v-col>
            </v-row>
          </template>
          <template
            v-if="currentStep != 0 && choosenWidget.type == 'coloredIcon'"
          >
            <template v-if="selectedDevices.length == 1">
              <template v-for="(input, i) in choosenWidget.myData.inputs">
                <div :key="i" v-if="currentStep == i + 1">
                  <v-row align="center" justify="center">
                    <v-col cols="4">
                      <v-select
                        :key="i"
                        v-model="input.linkedPath"
                        :items="displayJsonPaths"
                        item-value="path"
                        item-text="display"
                        :label="input.name"
                      ></v-select> </v-col
                    ><v-col offset="2" cols="4">
                      <template>
                        <div
                          class="text-center primary--text font-weight-bold"
                          style="font-size: 20px"
                        >
                          Expert
                        </div>
                        <v-row justify="center" align="center">
                          <div
                            :class="[
                              'mb-1',
                              !switch1 ? 'primary--text font-weight-bold' : '',
                            ]"
                          >
                            OFF
                          </div>
                          <v-switch
                            v-model="switch1"
                            inset
                            class="pa-0 ml-4"
                          ></v-switch>
                          <div
                            :class="[
                              'mb-1',
                              switch1 ? 'primary--text font-weight-bold' : '',
                            ]"
                          >
                            ON
                          </div>
                        </v-row></template
                      >
                      <template v-if="switch1">
                        Data :
                        <vue-json-pretty
                          :deep="3"
                          :data="selectedDevices[0].lastData.data"
                        >
                        </vue-json-pretty></template></v-col
                  ></v-row>
                </div>
              </template>
            </template>
            <template v-else> CHOISIR SEULEMENT 1 DEVICE</template>
          </template>
          <template v-if="currentStep != 0 && choosenWidget.type == 'text'">
            <template v-if="selectedDevices.length == 1">
              <template v-for="(input, i) in choosenWidget.myData.inputs">
                <div :key="i" v-if="currentStep == i + 1">
                  <v-row align="center" justify="center">
                    <v-col cols="4">
                      <v-select
                        :key="i"
                        v-model="input.linkedPath"
                        :items="displayJsonPaths"
                        item-value="path"
                        item-text="display"
                        :label="input.name"
                      ></v-select> </v-col
                    ><v-col offset="2" cols="4">
                      <template>
                        <div
                          class="text-center primary--text font-weight-bold"
                          style="font-size: 20px"
                        >
                          Expert
                        </div>
                        <v-row justify="center" align="center">
                          <div
                            :class="[
                              'mb-1',
                              !switch1 ? 'primary--text font-weight-bold' : '',
                            ]"
                          >
                            OFF
                          </div>
                          <v-switch
                            v-model="switch1"
                            inset
                            class="pa-0 ml-4"
                          ></v-switch>
                          <div
                            :class="[
                              'mb-1',
                              switch1 ? 'primary--text font-weight-bold' : '',
                            ]"
                          >
                            ON
                          </div>
                        </v-row></template
                      >
                      <template v-if="switch1">
                        Data :
                        <vue-json-pretty
                          :deep="3"
                          :data="selectedDevices[0].lastData.data"
                        >
                        </vue-json-pretty></template></v-col
                  ></v-row>
                  <v-row align="center" justify="center"
                    ><v-col cols="4">
                      <v-radio-group v-model="linkRadioGroup">
                        <v-radio
                          v-for="(item, n) in linkArrayChoices"
                          :key="n"
                          :label="item"
                          :value="n"
                        ></v-radio> </v-radio-group></v-col
                    ><v-col offset="2" cols="4">
                      <v-select
                        v-if="linkRadioGroup != 0"
                        :key="i"
                        v-model="linkDateRange"
                        :items="[
                          { item: 'Day', value: 'day' },
                          { item: 'Week', value: 'week' },
                          { item: 'Month', value: 'month' },
                          { item: 'Year', value: 'year' },
                        ]"
                        item-text="item"
                        item-value="value"
                        label="Range"
                      ></v-select> </v-col
                  ></v-row>
                </div>
              </template>
            </template>
            <template v-else>
              <template v-for="(input, i) in choosenWidget.myData.inputs">
                <div :key="i" v-if="currentStep == i + 1">
                  <v-row align="center" justify="center">
                    <v-col cols="4">
                      <v-select
                        :key="i"
                        v-model="input.linkedPath"
                        :items="displayJsonPaths"
                        item-value="path"
                        item-text="display"
                        :label="input.name"
                      ></v-select></v-col
                    ><v-col offset="2" cols="4">
                      <template>
                        <div
                          class="text-center primary--text font-weight-bold"
                          style="font-size: 20px"
                        >
                          Expert
                        </div>
                        <v-row justify="center" align="center">
                          <div
                            :class="[
                              'mb-1',
                              !switch1 ? 'primary--text font-weight-bold' : '',
                            ]"
                          >
                            OFF
                          </div>
                          <v-switch
                            v-model="switch1"
                            inset
                            class="pa-0 ml-4"
                          ></v-switch>
                          <div
                            :class="[
                              'mb-1',
                              switch1 ? 'primary--text font-weight-bold' : '',
                            ]"
                          >
                            ON
                          </div>
                        </v-row></template
                      >
                      <template v-if="switch1">
                        Data :
                        <vue-json-pretty
                          :deep="3"
                          :data="selectedDevices[0].lastData.data"
                        >
                        </vue-json-pretty></template></v-col
                  ></v-row>
                  <v-row align="center" justify="center"
                    ><v-col cols="4">
                      <v-radio-group v-model="linkRadioGroup">
                        <v-radio
                          v-for="(item, n) in linkArrayChoices"
                          :key="n"
                          :label="item"
                          :value="n"
                        ></v-radio> </v-radio-group></v-col
                    ><v-col offset="2" cols="4"> </v-col
                  ></v-row>
                </div>
              </template>
            </template>
          </template>
          <template v-if="currentStep != 0 && choosenWidget.type == 'line'">
            <template v-if="selectedDevices.length == 1">
              <div class="text-h5">
                Step {{ currentStep }} /
                {{ choosenWidget.mySeries.length + 1 }}
              </div>
              <template v-if="currentStep == 1">
                <div>
                  <v-row align="center" justify="center">
                    <v-col cols="4">
                      <v-select
                        v-model="choosenWidget.linkedXPath"
                        :items="displayJsonPaths"
                        item-value="path"
                        item-text="display"
                        :label="'Abscisse'"
                      ></v-select> </v-col
                    ><v-col offset="2" cols="4">
                      <template>
                        <div
                          class="text-center primary--text font-weight-bold"
                          style="font-size: 20px"
                        >
                          Expert
                        </div>
                        <v-row justify="center" align="center">
                          <div
                            :class="[
                              'mb-1',
                              !switch1 ? 'primary--text font-weight-bold' : '',
                            ]"
                          >
                            OFF
                          </div>
                          <v-switch
                            v-model="switch1"
                            inset
                            class="pa-0 ml-4"
                          ></v-switch>
                          <div
                            :class="[
                              'mb-1',
                              switch1 ? 'primary--text font-weight-bold' : '',
                            ]"
                          >
                            ON
                          </div>
                        </v-row></template
                      >
                      <template v-if="switch1">
                        Data :
                        <vue-json-pretty
                          :deep="3"
                          :data="selectedDevices[0].lastData.data"
                        >
                        </vue-json-pretty></template></v-col
                  ></v-row>
                  <v-row align="center" justify="center"
                    ><v-col cols="4">
                      <v-radio-group v-model="linkRadioGroup">
                        <v-radio
                          v-for="(item, n) in linkArrayChoices"
                          :key="n"
                          :label="item"
                          :value="n"
                          :disabled="item == 'Other'"
                        ></v-radio> </v-radio-group></v-col
                    ><v-col offset="2" cols="4">
                      <v-select
                        v-if="linkRadioGroup == 0"
                        v-model="linkDateRange"
                        :items="[
                          { item: 'Day', value: 'day' },
                          { item: 'Week', value: 'week' },
                          { item: 'Month', value: 'month' },
                          { item: 'Year', value: 'year' },
                        ]"
                        item-text="item"
                        item-value="value"
                        label="Range"
                      ></v-select> </v-col
                  ></v-row>
                </div>
              </template>
              <template v-else>
                <template v-for="(serie, i) in choosenWidget.mySeries">
                  <div :key="i" v-if="currentStep == i + 2">
                    <v-row align="center" justify="center">
                      <v-col cols="4">
                        <v-select
                          :key="i"
                          v-model="serie.linkedPath"
                          :items="displayJsonPaths"
                          item-value="path"
                          item-text="display"
                          label="Select sensor output"
                        ></v-select> </v-col
                      ><v-col offset="2" cols="4">
                        <template>
                          <div
                            class="text-center primary--text font-weight-bold"
                            style="font-size: 20px"
                          >
                            Expert
                          </div>
                          <v-row justify="center" align="center">
                            <div
                              :class="[
                                'mb-1',
                                !switch1
                                  ? 'primary--text font-weight-bold'
                                  : '',
                              ]"
                            >
                              OFF
                            </div>
                            <v-switch
                              v-model="switch1"
                              inset
                              class="pa-0 ml-4"
                            ></v-switch>
                            <div
                              :class="[
                                'mb-1',
                                switch1 ? 'primary--text font-weight-bold' : '',
                              ]"
                            >
                              ON
                            </div>
                          </v-row></template
                        >
                        <template v-if="switch1">
                          Data :
                          <vue-json-pretty
                            :deep="3"
                            :data="selectedDevices[0].lastData.data"
                          >
                          </vue-json-pretty></template></v-col
                    ></v-row>
                    <v-row align="center" justify="center">
                      <v-col cols="4">
                        <v-text-field
                          outlined
                          dense
                          hide-details
                          label="Legend name"
                          v-model="
                            choosenWidget.myData.series[currentStep - 2].text
                          "
                      /></v-col>
                    </v-row>
                  </div> </template
              ></template>
            </template>
            <template
              v-if="
                choosenWidget.myData.series.length == selectedDevices.length &&
                selectedDevices.length > 1
              "
            >
              <div class="text-h5">
                Step {{ currentStep }} / {{ selectedDevices.length + 1 }}
                {{
                  currentStep > 1
                    ? "-- ( " + selectedDevices[currentStep - 2].name + " )"
                    : "-- ( Select updateTime )"
                }}
              </div>
              <template v-if="currentStep == 1">
                <div>
                  <v-row align="center" justify="center">
                    <v-col cols="4">
                      <v-select
                        v-model="choosenWidget.linkedXPath"
                        :items="displayJsonPaths"
                        item-value="path"
                        item-text="display"
                        :label="'Abscisse'"
                      ></v-select> </v-col
                    ><v-col offset="2" cols="4">
                      <template>
                        <div
                          class="text-center primary--text font-weight-bold"
                          style="font-size: 20px"
                        >
                          Expert
                        </div>
                        <v-row justify="center" align="center">
                          <div
                            :class="[
                              'mb-1',
                              !switch1 ? 'primary--text font-weight-bold' : '',
                            ]"
                          >
                            OFF
                          </div>
                          <v-switch
                            v-model="switch1"
                            inset
                            class="pa-0 ml-4"
                          ></v-switch>
                          <div
                            :class="[
                              'mb-1',
                              switch1 ? 'primary--text font-weight-bold' : '',
                            ]"
                          >
                            ON
                          </div>
                        </v-row></template
                      >
                      <template v-if="switch1">
                        Data :
                        <vue-json-pretty
                          :deep="3"
                          :data="selectedDevices[0].lastData.data"
                        >
                        </vue-json-pretty></template></v-col
                  ></v-row>
                  <v-row align="center" justify="center"
                    ><v-col cols="4">
                      <v-radio-group v-model="linkRadioGroup">
                        <v-radio
                          v-for="(item, n) in linkArrayChoices"
                          :key="n"
                          :label="item"
                          :value="n"
                          :disabled="item == 'Other'"
                        ></v-radio> </v-radio-group></v-col
                    ><v-col offset="2" cols="4">
                      <v-select
                        v-if="linkRadioGroup == 0"
                        v-model="linkDateRange"
                        :items="[
                          { item: 'Day', value: 'day' },
                          { item: 'Week', value: 'week' },
                          { item: 'Month', value: 'month' },
                          { item: 'Year', value: 'year' },
                        ]"
                        item-text="item"
                        item-value="value"
                        label="Range"
                      ></v-select> </v-col
                  ></v-row>
                </div>
              </template>

              <template v-else>
                <template v-for="(serie, i) in choosenWidget.mySeries">
                  <div :key="i" v-if="currentStep == i + 2">
                    <v-row align="center" justify="center">
                      <v-col cols="4">
                        <v-select
                          :key="i"
                          v-model="serie.linkedPath"
                          :items="displayJsonPaths"
                          item-value="path"
                          item-text="display"
                          label="Select sensor output"
                        ></v-select> </v-col
                      ><v-col offset="2" cols="4">
                        <template>
                          <div
                            class="text-center primary--text font-weight-bold"
                            style="font-size: 20px"
                          >
                            Expert
                          </div>
                          <v-row justify="center" align="center">
                            <div
                              :class="[
                                'mb-1',
                                !switch1
                                  ? 'primary--text font-weight-bold'
                                  : '',
                              ]"
                            >
                              OFF
                            </div>
                            <v-switch
                              v-model="switch1"
                              inset
                              class="pa-0 ml-4"
                            ></v-switch>
                            <div
                              :class="[
                                'mb-1',
                                switch1 ? 'primary--text font-weight-bold' : '',
                              ]"
                            >
                              ON
                            </div>
                          </v-row></template
                        >
                        <template v-if="switch1">
                          Data :
                          <vue-json-pretty
                            :deep="3"
                            :data="selectedDevices[0].lastData.data"
                          >
                          </vue-json-pretty></template></v-col
                    ></v-row>
                    <v-row justify="center" align="center"
                      ><v-col cols="4">
                        <v-text-field
                          outlined
                          dense
                          hide-details
                          label="Legend name"
                          v-model="
                            choosenWidget.myData.series[currentStep - 2].text
                          " /></v-col
                    ></v-row>
                  </div> </template
              ></template>
            </template>
            <template
              v-if="
                selectedDevices.length != 1 &&
                choosenWidget.myData.series.length != selectedDevices.length
              "
              >TROP DE DEVICE CHOISIS OU PAS ASSER</template
            >
          </template>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="leaveLinkDialog"> Cancel </v-btn>
          <v-btn
            v-if="currentStep != 0"
            color="primary"
            text
            @click="
              () => {
                currentStep -= 1;
              }
            "
          >
            Previous
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
            v-if="
              currentStep != 0 &&
              choosenWidget.type == 'text' &&
              currentStep == choosenWidget.myData.inputs.length
            "
            color="primary"
            text
            @click="next"
          >
            Save
          </v-btn>
          <v-btn
            v-if="
              currentStep != 0 &&
              choosenWidget.type == 'text' &&
              currentStep != choosenWidget.myData.inputs.length
            "
            color="primary"
            text
            @click="next"
          >
            Next
          </v-btn>
          <v-btn
            v-if="
              currentStep != 0 &&
              choosenWidget.type == 'coloredIcon' &&
              currentStep == choosenWidget.myData.inputs.length
            "
            color="primary"
            text
            @click="next"
          >
            Save
          </v-btn>
          <v-btn
            v-if="
              currentStep != 0 &&
              choosenWidget.type == 'line' &&
              currentStep != choosenWidget.mySeries.length + 1
            "
            color="primary"
            text
            @click="next"
          >
            Next
          </v-btn>
          <v-btn
            v-if="
              currentStep != 0 &&
              choosenWidget.type == 'line' &&
              currentStep == choosenWidget.mySeries.length + 1
            "
            color="primary"
            text
            @click="next"
          >
            Save
          </v-btn>
          <v-btn v-if="currentStep == 0" color="primary" text @click="next">
            Next
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <grid
      :width="1920"
      style="z-index: 10"
      :items="items"
      @removeWidget="removeWidget"
      @editWidget="editWidget"
      @linkWidget="linkWidget"
      :forceGridItemsUpdate="forceGridItemsUpdate"
      @forceGridItemsUpdated="forceGridItemsUpdate = ''"
      :dashboardId="selectedDashboard.id"
      :dashboardName="selectedDashboard.name"
      @selectFromMenu="selectFromMenu"
      @redirectDashboard="redirectDashboard"
      @line2ChangeDate="line2ChangeDate"
      @line2ChangeDateArray="line2ChangeDateArray"
      ref="gridRef"
    >
    </grid>

    <img
      v-if="imageUrl"
      ref="img"
      :src="imageUrl"
      :style="{
        position: 'absolute',
        top: '30px',
        left: '50%',

        transform: 'translate(-50%, 0)',
        'z-index': 1,

        width: imgWidth + 'px',
        height: imageHeight + 'px',
      }"
    />
  </div>
</template>

<script>
import channelList from "../../channelList.json";

import Grid from "./Grid";
import { v4 as uuidv4 } from "uuid";
import Widget from "./Widget";
import UploadImg from "./UploadImg";

import axios from "axios";
import tokenStore from "../../store/TokenStore";

import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
import VJsoneditor from "v-jsoneditor";

export default {
  components: {
    widget: Widget,
    uploadImg: UploadImg,
    Grid,
    VueJsonPretty,
    VJsoneditor,
  },
  props: {
    selectedDashboard: Object,
    dashBoardList: Array,
  },
  data: () => ({
    K: 0,
    N: 0,
    S: 0,
    gridWidth: 0,
    dialog: false,
    radioGroup: 0,
    widgetName: "",
    widgetIcon: "",
    widgetIconList: [
      "icon-monitor-levels",
      "icon-translate",
      "icon-dedent-all",
      "icon-sidebar",
      "icon-arrow-circle-o-down",
      "icon-arrow-circle-o-up",
      "icon-edit",
      "icon-pencil-square-o",
      "icon-chevron-left",
      "icon-chevron-right",
      "icon-plus-circle",
      "icon-minus-circle",
      "icon-times-circle",
      "icon-check-circle",
      "icon-question-circle",
      "icon-info-circle",
      "icon-arrow-left1",
      "icon-arrow-right1",
      "icon-arrow-up1",
      "icon-arrow-down1",
      "icon-mail-forward",
      "icon-share1",
      "icon-chevron-up",
      "icon-chevron-down",
      "icon-arrow-circle-left",
      "icon-arrow-circle-right",
      "icon-arrow-circle-up",
      "icon-arrow-circle-down",
      "icon-angle-double-left",
      "icon-angle-double-right",
      "icon-angle-double-up",
      "icon-angle-double-down",
      "icon-angle-left",
      "icon-angle-right",
      "icon-angle-up",
      "icon-angle-down",
      "icon-circle-o",
      "icon-circle",
      "icon-ellipsis-h",
      "icon-ellipsis-v",
      "icon-dot-circle-o",
      "icon-file-code-o",
      "icon-home",
      "icon-home2",
      "icon-home3",
      "icon-office",
      "icon-newspaper",
      "icon-pencil",
      "icon-pencil2",
      "icon-quill",
      "icon-pen",
      "icon-blog",
      "icon-eyedropper",
      "icon-droplet",
      "icon-paint-format",
      "icon-image",
      "icon-images",
      "icon-camera",
      "icon-headphones",
      "icon-music",
      "icon-play",
      "icon-film",
      "icon-video-camera",
      "icon-dice",
      "icon-pacman",
      "icon-spades",
      "icon-clubs",
      "icon-diamonds",
      "icon-bullhorn",
      "icon-connection",
      "icon-podcast",
      "icon-feed",
      "icon-mic",
      "icon-book",
      "icon-books",
      "icon-library",
      "icon-file-text",
      "icon-profile",
      "icon-file-empty",
      "icon-files-empty",
      "icon-file-text2",
      "icon-file-picture",
      "icon-file-music",
      "icon-file-play",
      "icon-file-video",
      "icon-file-zip",
      "icon-copy",
      "icon-paste",
      "icon-stack",
      "icon-folder",
      "icon-folder-open",
      "icon-folder-plus",
      "icon-folder-minus",
      "icon-folder-download",
      "icon-folder-upload",
      "icon-price-tag",
      "icon-price-tags",
      "icon-barcode",
      "icon-qrcode",
      "icon-ticket",
      "icon-cart",
      "icon-coin-dollar",
      "icon-coin-euro",
      "icon-coin-pound",
      "icon-coin-yen",
      "icon-credit-card",
      "icon-calculator",
      "icon-lifebuoy",
      "icon-phone",
      "icon-phone-hang-up",
      "icon-address-book",
      "icon-envelop",
      "icon-pushpin",
      "icon-location",
      "icon-location2",
      "icon-compass",
      "icon-compass2",
      "icon-map",
      "icon-map2",
      "icon-history",
      "icon-clock",
      "icon-clock2",
      "icon-alarm",
      "icon-bell",
      "icon-stopwatch",
      "icon-calendar",
      "icon-printer",
      "icon-keyboard",
      "icon-display",
      "icon-laptop",
      "icon-mobile",
      "icon-mobile2",
      "icon-tablet",
      "icon-tv",
      "icon-drawer",
      "icon-drawer2",
      "icon-box-add",
      "icon-box-remove",
      "icon-download",
      "icon-upload",
      "icon-floppy-disk",
      "icon-drive",
      "icon-database",
      "icon-undo",
      "icon-redo",
      "icon-undo2",
      "icon-redo2",
      "icon-forward",
      "icon-reply",
      "icon-bubble",
      "icon-bubbles",
      "icon-bubbles2",
      "icon-bubble2",
      "icon-bubbles3",
      "icon-bubbles4",
      "icon-user",
      "icon-users",
      "icon-user-plus",
      "icon-user-minus",
      "icon-user-check",
      "icon-user-tie",
      "icon-quotes-left",
      "icon-quotes-right",
      "icon-hour-glass",
      "icon-spinner",
      "icon-spinner2",
      "icon-spinner3",
      "icon-spinner4",
      "icon-spinner5",
      "icon-spinner6",
      "icon-spinner7",
      "icon-spinner8",
      "icon-spinner9",
      "icon-spinner10",
      "icon-spinner11",
      "icon-binoculars",
      "icon-search",
      "icon-zoom-in",
      "icon-zoom-out",
      "icon-enlarge",
      "icon-shrink",
      "icon-enlarge2",
      "icon-shrink2",
      "icon-key",
      "icon-key2",
      "icon-lock",
      "icon-unlocked",
      "icon-wrench",
      "icon-equalizer",
      "icon-equalizer2",
      "icon-cog",
      "icon-cogs",
      "icon-hammer",
      "icon-magic-wand",
      "icon-aid-kit",
      "icon-bug",
      "icon-pie-chart",
      "icon-stats-dots",
      "icon-stats-bars",
      "icon-stats-bars2",
      "icon-trophy",
      "icon-gift",
      "icon-glass",
      "icon-glass2",
      "icon-mug",
      "icon-spoon-knife",
      "icon-leaf",
      "icon-rocket",
      "icon-meter",
      "icon-meter2",
      "icon-hammer2",
      "icon-fire",
      "icon-lab",
      "icon-magnet",
      "icon-bin",
      "icon-bin2",
      "icon-briefcase",
      "icon-airplane",
      "icon-truck",
      "icon-road",
      "icon-accessibility",
      "icon-target",
      "icon-shield",
      "icon-power",
      "icon-switch",
      "icon-power-cord",
      "icon-clipboard",
      "icon-list-numbered",
      "icon-list",
      "icon-list2",
      "icon-tree",
      "icon-menu",
      "icon-menu2",
      "icon-menu3",
      "icon-menu4",
      "icon-cloud",
      "icon-cloud-download",
      "icon-cloud-upload",
      "icon-cloud-check",
      "icon-download2",
      "icon-upload2",
      "icon-download3",
      "icon-upload3",
      "icon-sphere",
      "icon-earth",
      "icon-link",
      "icon-flag",
      "icon-attachment",
      "icon-eye",
      "icon-eye-plus",
      "icon-eye-minus",
      "icon-eye-blocked",
      "icon-bookmark",
      "icon-bookmarks",
      "icon-sun",
      "icon-contrast",
      "icon-brightness-contrast",
      "icon-star-empty",
      "icon-star-half",
      "icon-star-full",
      "icon-heart",
      "icon-heart-broken",
      "icon-man",
      "icon-woman",
      "icon-man-woman",
      "icon-happy",
      "icon-happy2",
      "icon-smile",
      "icon-smile2",
      "icon-tongue",
      "icon-tongue2",
      "icon-sad",
      "icon-sad2",
      "icon-wink",
      "icon-wink2",
      "icon-grin",
      "icon-grin2",
      "icon-cool",
      "icon-cool2",
      "icon-angry",
      "icon-angry2",
      "icon-evil",
      "icon-evil2",
      "icon-shocked",
      "icon-shocked2",
      "icon-baffled",
      "icon-baffled2",
      "icon-confused",
      "icon-confused2",
      "icon-neutral",
      "icon-neutral2",
      "icon-hipster",
      "icon-hipster2",
      "icon-wondering",
      "icon-wondering2",
      "icon-sleepy",
      "icon-sleepy2",
      "icon-frustrated",
      "icon-frustrated2",
      "icon-crying",
      "icon-crying2",
      "icon-point-up",
      "icon-point-right",
      "icon-point-down",
      "icon-point-left",
      "icon-warning",
      "icon-notification",
      "icon-question",
      "icon-plus",
      "icon-minus",
      "icon-info",
      "icon-cancel-circle",
      "icon-blocked",
      "icon-cross",
      "icon-checkmark",
      "icon-checkmark2",
      "icon-spell-check",
      "icon-enter",
      "icon-exit",
      "icon-play2",
      "icon-pause",
      "icon-stop",
      "icon-previous",
      "icon-next",
      "icon-backward",
      "icon-forward2",
      "icon-play3",
      "icon-pause2",
      "icon-stop2",
      "icon-backward2",
      "icon-forward3",
      "icon-first",
      "icon-last",
      "icon-previous2",
      "icon-next2",
      "icon-eject",
      "icon-volume-high",
      "icon-volume-medium",
      "icon-volume-low",
      "icon-volume-mute",
      "icon-volume-mute2",
      "icon-volume-increase",
      "icon-volume-decrease",
      "icon-loop",
      "icon-loop2",
      "icon-infinite",
      "icon-shuffle",
      "icon-arrow-up-left",
      "icon-arrow-up",
      "icon-arrow-up-right",
      "icon-arrow-right",
      "icon-arrow-down-right",
      "icon-arrow-down",
      "icon-arrow-down-left",
      "icon-arrow-left",
      "icon-arrow-up-left2",
      "icon-arrow-up2",
      "icon-arrow-up-right2",
      "icon-arrow-right2",
      "icon-arrow-down-right2",
      "icon-arrow-down2",
      "icon-arrow-down-left2",
      "icon-arrow-left2",
      "icon-circle-up",
      "icon-circle-right",
      "icon-circle-down",
      "icon-circle-left",
      "icon-tab",
      "icon-move-up",
      "icon-move-down",
      "icon-sort-alpha-asc",
      "icon-sort-alpha-desc",
      "icon-sort-numeric-asc",
      "icon-sort-numberic-desc",
      "icon-sort-amount-asc",
      "icon-sort-amount-desc",
      "icon-command",
      "icon-shift",
      "icon-ctrl",
      "icon-opt",
      "icon-checkbox-checked",
      "icon-checkbox-unchecked",
      "icon-radio-checked",
      "icon-radio-checked2",
      "icon-radio-unchecked",
      "icon-crop",
      "icon-make-group",
      "icon-ungroup",
      "icon-scissors",
      "icon-filter",
      "icon-font",
      "icon-ligature",
      "icon-ligature2",
      "icon-text-height",
      "icon-text-width",
      "icon-font-size",
      "icon-bold",
      "icon-underline",
      "icon-italic",
      "icon-strikethrough",
      "icon-omega",
      "icon-sigma",
      "icon-page-break",
      "icon-superscript",
      "icon-subscript",
      "icon-superscript2",
      "icon-subscript2",
      "icon-text-color",
      "icon-pagebreak",
      "icon-clear-formatting",
      "icon-table",
      "icon-table2",
      "icon-insert-template",
      "icon-pilcrow",
      "icon-ltr",
      "icon-rtl",
      "icon-section",
      "icon-paragraph-left",
      "icon-paragraph-center",
      "icon-paragraph-right",
      "icon-paragraph-justify",
      "icon-indent-increase",
      "icon-indent-decrease",
      "icon-share",
      "icon-new-tab",
      "icon-embed",
      "icon-embed2",
      "icon-terminal",
      "icon-share2",
      "icon-mail",
      "icon-mail2",
      "icon-mail3",
      "icon-mail4",
      "icon-amazon",
      "icon-google",
      "icon-google2",
      "icon-google3",
      "icon-google-plus",
      "icon-google-plus2",
      "icon-google-plus3",
      "icon-hangouts",
      "icon-google-drive",
      "icon-facebook",
      "icon-facebook2",
      "icon-instagram",
      "icon-whatsapp",
      "icon-spotify",
      "icon-telegram",
      "icon-twitter",
      "icon-vine",
      "icon-vk",
      "icon-renren",
      "icon-sina-weibo",
      "icon-rss",
      "icon-rss2",
      "icon-youtube",
      "icon-youtube2",
      "icon-twitch",
      "icon-vimeo",
      "icon-vimeo2",
      "icon-lanyrd",
      "icon-flickr",
      "icon-flickr2",
      "icon-flickr3",
      "icon-flickr4",
      "icon-dribbble",
      "icon-behance",
      "icon-behance2",
      "icon-deviantart",
      "icon-500px",
      "icon-steam",
      "icon-steam2",
      "icon-dropbox",
      "icon-onedrive",
      "icon-github",
      "icon-npm",
      "icon-basecamp",
      "icon-trello",
      "icon-wordpress",
      "icon-joomla",
      "icon-ello",
      "icon-blogger",
      "icon-blogger2",
      "icon-tumblr",
      "icon-tumblr2",
      "icon-yahoo",
      "icon-yahoo2",
      "icon-tux",
      "icon-appleinc",
      "icon-finder",
      "icon-android",
      "icon-windows",
      "icon-windows8",
      "icon-soundcloud",
      "icon-soundcloud2",
      "icon-skype",
      "icon-reddit",
      "icon-hackernews",
      "icon-wikipedia",
      "icon-linkedin",
      "icon-linkedin2",
      "icon-lastfm",
      "icon-lastfm2",
      "icon-delicious",
      "icon-stumbleupon",
      "icon-stumbleupon2",
      "icon-stackoverflow",
      "icon-pinterest",
      "icon-pinterest2",
      "icon-xing",
      "icon-xing2",
      "icon-flattr",
      "icon-foursquare",
      "icon-yelp",
      "icon-paypal",
      "icon-chrome",
      "icon-firefox",
      "icon-IE",
      "icon-edge",
      "icon-safari",
      "icon-opera",
      "icon-file-pdf",
      "icon-file-openoffice",
      "icon-file-word",
      "icon-file-excel",
      "icon-libreoffice",
      "icon-html-five",
      "icon-html-five2",
      "icon-css3",
      "icon-git",
      "icon-codepen",
      "icon-svg",
      "icon-IcoMoon",
    ],
    colorsList: [
      "#000000",
      "#FF0000",
      "#580AFF",
      "#0AFF99",
      "#FF8700",
      "#0AEFFF",
      "#A1FF0A",
      "#BE0AFF",
      "#FFD300",
      "#147DF5",
      "#DEFF0A",
    ],

    widget: null,
    rules: [(v) => v >= 8 || "Minimum is 8", (v) => v <= 72 || "Maximum is 72"],
    rules2: [
      (v) => v >= 8 || "Minimum is 100",
      (v) => v <= 72 || "Maximum is 400",
    ],
    widgetModels: [],
    items: [],
    pieSlice: 0,
    forceUpdate: 0,
    imageUrl: "",
    imageWidth: 100,
    imageHeight: 100,
    linkDialog: false,
    groups: [],
    selectedGroup: null,
    selectedDevices: [],
    devices: [],
    currentStep: 0,
    choosenWidget: null,
    linkRadioGroup: 0,
    linkDateRange: "day",
    requestIntervals: [],
    jsonPaths: [],
    displayJsonPaths: [],
    linkArrayChoices: [],
    tmpLinkSave: [],
    forceGridItemsUpdate: "",
    uploadImage: false,
    switch1: false,
    presetDialog: false,
    presetName: "",
    presetDescription: "",
    saveAsDefaultPreset: false,
    deviceLoading: "false",
    linkDialogChannel: false,
    selectedGroupChannel: null,
    selectedDeviceChannel: null,
    disabledSelectedDeviceChannel: true,
    channels: [],
    selectedChannel: null,
    disabledChannel: true,
    customLineChannel: false,
    customLinkDashboardChannel: false,
    alerts: [],
    selectedAlert: null,
    checkboxShowDateData: false,
    selectedDashboardLink: null,
    transparentWidget: false,
    customLinkAlertChannel: false,
    linkToADashboard: false,
    customLinkColoredIconChannel: false,
    tmpSaveWidget: null,
    editWidgetDialog: false,
    cancelRequest: null,
  }),
  beforeMount() {
    this.resetWidgetModels();
    this.loadImg();
    this.loadGroups();
    this.loadAlerts();
  },
  computed: {
    imgWidth() {
      return (this.gridWidth * this.imageWidth) / (this.K - this.N - this.S);
    },
    sortedGroups: function () {
      if (this.groups) {
        let sortedGroups = this.groups;
        if (this.groups.length == 0) sortedGroups = [];
        sortedGroups = sortedGroups.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        return sortedGroups;
      } else {
        return [];
      }
    },
    sortedDevices: function () {
      if (this.devices) {
        let sortedDevices = this.devices;
        if (this.devices.length == 0) sortedDevices = [];
        sortedDevices = sortedDevices.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        return sortedDevices;
      } else {
        return [];
      }
    },
  },

  beforeDestroy() {
    this.requestIntervals.forEach((json) => {
      json.intervals.forEach((interval) => {
        clearInterval(interval.interval);
      });
    });
    window.stop();
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.resetWidgetModels();
      }
    },
    selectedDeviceChannel(id) {
      if (this.linkDialogChannel) {
        if (id == null) {
          this.channels = [];
        } else {
          this.channels = [];
          this.selectedChannel = null;
          this.disabledChannel = true;
          axios
            .get(
              process.env.VUE_APP_API_ADDR + "/api/device/" + id + "/channel",
              {
                headers: {
                  Authorization: tokenStore.getToken(),
                },
              }
            )
            .then((res) => {
              console.log("laaaaa");
              res.data.channels.forEach((channel) => {
                if (
                  this.choosenWidget.type == "line" ||
                  this.choosenWidget.type == "line2" ||
                  this.choosenWidget.type == "area"
                ) {
                  if (
                    channel != "" &&
                    channelList[channel] != undefined &&
                    channelList[channel] != null &&
                    channelList[channel].type == "number"
                  ) {
                    this.channels.push(channelList[channel]);
                  } else if (
                    channelList[channel] == undefined ||
                    channelList[channel] == null
                  ) {
                    console.log("error loading channel : " + channel);
                  }
                } else if(this.choosenWidget.type == "reflectogram"){
                  if (
                    channel != "" &&
                    channelList[channel] != undefined &&
                    channelList[channel] != null &&
                    channelList[channel].id == "281"
                  ) {
                    this.channels.push(channelList[channel]);
                  }
                  }
                  else {
                  if (
                    channel != "" &&
                    channelList[channel] != undefined &&
                    channelList[channel] != null
                  ) {
                    this.channels.push(channelList[channel]);
                  } else if (
                    channelList[channel] == undefined ||
                    channelList[channel] == null
                  ) {
                    console.log("error loading channel : " + channel);
                  }
                }
              });
              if (
                this.choosenWidget.myData.inputs[this.currentStep].channel !=
                null
              ) {
                if (
                  this.channels.findIndex((channel) => {
                    return (
                      channel.id ==
                      this.choosenWidget.myData.inputs[this.currentStep].channel
                    );
                  }) >= 0
                ) {
                  this.selectedChannel =
                    this.choosenWidget.myData.inputs[this.currentStep].channel;
                }
              }
              this.disabledChannel = false;
            });
        }
      }
    },
    selectedGroupChannel(id) {
      if (this.linkDialogChannel) {
        if (id == null) {
          this.devices = [];
        } else {
          this.devices = [];
          let tmp = [];
          this.channels = [];
          this.selectedChannel = null;
          this.disabledChannel = true;
          this.selectedDeviceChannel = null;
          this.disabledSelectedDeviceChannel = true;
          axios
            .get(
              process.env.VUE_APP_API_ADDR + "/api/group/" + id + "/allDevice",
              {
                headers: {
                  Authorization: tokenStore.getToken(),
                },
              }
            )
            .then((res1) => {
              res1.data.devices.forEach((dev) => {
                axios
                  .get(process.env.VUE_APP_API_ADDR + "/api/device/" + dev, {
                    headers: {
                      Authorization: tokenStore.getToken(),
                    },
                  })
                  .then((res) => {
                    res.data.device.lastData.data = JSON.parse(
                      res.data.device.lastData.data
                    );
                    tmp.push(res.data.device);
                    if (tmp.length == res1.data.devices.length) {
                      this.devices = tmp.sort((a, b) => {
                        if (a.name < b.name) {
                          return -1;
                        }
                        if (a.name > b.name) {
                          return 1;
                        }
                        return 0;
                      });
                      if (
                        this.choosenWidget.myData.inputs[this.currentStep]
                          .deviceId != null
                      ) {
                        if (
                          this.devices.findIndex((device) => {
                            return (
                              device.id ==
                              this.choosenWidget.myData.inputs[this.currentStep]
                                .deviceId
                            );
                          }) >= 0
                        ) {
                          this.selectedDeviceChannel =
                            this.choosenWidget.myData.inputs[
                              this.currentStep
                            ].deviceId;
                        }
                      }
                      this.disabledSelectedDeviceChannel = false;
                    }
                  });
              });
            });
        }
      }
    },
    items(value) {
      value.forEach((widget) => {
        if (widget.requestInterval) {
          let i = this.requestIntervals.findIndex((json) => {
            return json.widgetId == widget.i;
          });

          if (i < 0) {
            this.requestIntervals.push({
              widgetId: widget.i,
              intervals: [],
            });
            widget.requestInterval.forEach((jsonInterval) => {
              let fct = new Function("res", "widget", jsonInterval.fonction);
              if (
                widget.type == "line" ||
                widget.type == "area" ||
                widget.type == "line2" ||
                widget.type == "reflectogram"
              ) {
                axios
                  .get(eval(jsonInterval.request), {
                    cancelToken: this.cancelRequest.token,
                    headers: {
                      Authorization: tokenStore.getToken(),
                    },
                  })
                  .then((res) => {
                    fct(res, widget);
                    this.forceGridItemsUpdate = widget.i;
                    console.log("update");
                  })
                  .catch(() => {
                    //console.log(err.response.status)
                  });
                let interval = setInterval(() => {
                  axios
                    .get(eval(jsonInterval.request), {
                      cancelToken: this.cancelRequest.token,
                      headers: {
                        Authorization: tokenStore.getToken(),
                      },
                    })
                    .then((res) => {
                      fct(res, widget);
                      this.forceGridItemsUpdate = widget.i;
                    })
                    .catch(() => {
                      //console.log(err.response.status)
                    });
                }, 60000);
                this.requestIntervals[
                  this.requestIntervals.length - 1
                ].intervals.push({
                  name: jsonInterval.name,
                  interval: interval,
                });
              } else if (widget.type == "text") {
                axios
                  .get(jsonInterval.request, {
                    cancelToken: this.cancelRequest.token,
                    headers: {
                      Authorization: tokenStore.getToken(),
                    },
                  })
                  .then((res) => {
                    fct(res, widget);
                    console.log("update");
                  })
                  .catch(() => {
                    //console.log(err.response.status)
                  });
                let interval = setInterval(() => {
                  axios
                    .get(jsonInterval.request, {
                      cancelToken: this.cancelRequest.token,
                      headers: {
                        Authorization: tokenStore.getToken(),
                      },
                    })
                    .then((res) => {
                      fct(res, widget);
                      console.log("update");
                    })
                    .catch(() => {
                      //console.log(err.response.status)
                    });
                }, 10000);
                this.requestIntervals[
                  this.requestIntervals.length - 1
                ].intervals.push({
                  name: jsonInterval.name,
                  interval: interval,
                });
              } else if (widget.type == "coloredIcon") {
                axios
                  .get(jsonInterval.request, {
                    cancelToken: this.cancelRequest.token,
                    headers: {
                      Authorization: tokenStore.getToken(),
                    },
                  })
                  .then((res) => {
                    fct(res, widget);
                    console.log("update");
                  })
                  .catch(() => {
                    //console.log(err.response.status)
                  });
                let interval = setInterval(() => {
                  axios
                    .get(jsonInterval.request, {
                      cancelToken: this.cancelRequest.token,
                      headers: {
                        Authorization: tokenStore.getToken(),
                      },
                    })
                    .then((res) => {
                      fct(res, widget);
                      console.log("update");
                    })
                    .catch(() => {
                      //console.log(err.response.status)
                    });
                }, 5000);
                this.requestIntervals[
                  this.requestIntervals.length - 1
                ].intervals.push({
                  name: jsonInterval.name,
                  interval: interval,
                });
              } else if (widget.type == "map") {
                axios
                  .get(jsonInterval.request, {
                    cancelToken: this.cancelRequest.token,
                    headers: {
                      Authorization: tokenStore.getToken(),
                    },
                  })
                  .then((res) => {
                    fct(res, widget);
                    console.log("update");
                  })
                  .catch(() => {
                    //console.log(err.response.status)
                  });
                let interval = setInterval(() => {
                  axios
                    .get(jsonInterval.request, {
                      cancelToken: this.cancelRequest.token,
                      headers: {
                        Authorization: tokenStore.getToken(),
                      },
                    })
                    .then((res) => {
                      fct(res, widget);
                      console.log("update");
                    })
                    .catch(() => {
                      //console.log(err.response.status)
                    });
                }, 5000);
                this.requestIntervals[
                  this.requestIntervals.length - 1
                ].intervals.push({
                  name: jsonInterval.name,
                  interval: interval,
                });
              } else if (widget.type == "Alert") {
                axios
                  .get(jsonInterval.request, {
                    cancelToken: this.cancelRequest.token,
                    headers: {
                      Authorization: tokenStore.getToken(),
                    },
                  })
                  .then((res) => {
                    fct(res, widget);
                    console.log("update");
                  })
                  .catch(() => {
                    //console.log(err.response.status)
                  });
                let interval = setInterval(() => {
                  axios
                    .get(jsonInterval.request, {
                      cancelToken: this.cancelRequest.token,
                      headers: {
                        Authorization: tokenStore.getToken(),
                      },
                    })
                    .then((res) => {
                      fct(res, widget);
                      console.log("update");
                    })
                    .catch(() => {
                      //console.log(err.response.status)
                    });
                }, 5000);
                this.requestIntervals[
                  this.requestIntervals.length - 1
                ].intervals.push({
                  name: jsonInterval.name,
                  interval: interval,
                });
              } else if (widget.type == "linkDashboard") {
                this.requestIntervals[
                  this.requestIntervals.length - 1
                ].intervals.push({
                  name: jsonInterval.name,
                  interval: null,
                });
              }
            });
          } else {
            this.requestIntervals[i].intervals.forEach((interval) => {
              clearInterval(interval.interval);
            });
            this.requestIntervals[i].intervals = [];
            widget.requestInterval.forEach((jsonInterval) => {
              let fct = new Function("res", "widget", jsonInterval.fonction);
              if (
                widget.type == "line" ||
                widget.type == "area" ||
                widget.type == "line2" ||
                widget.type == "reflectogram"
              ) {
                axios
                  .get(eval(jsonInterval.request), {
                    cancelToken: this.cancelRequest.token,
                    headers: {
                      Authorization: tokenStore.getToken(),
                    },
                  })
                  .then((res) => {
                    fct(res, widget);
                    this.forceGridItemsUpdate = widget.i;
                    console.log("update");
                  })
                  .catch(() => {
                    //console.log(err.response.status)
                  });
                let interval = setInterval(() => {
                  axios
                    .get(eval(jsonInterval.request), {
                      cancelToken: this.cancelRequest.token,
                      headers: {
                        Authorization: tokenStore.getToken(),
                      },
                    })
                    .then((res) => {
                      fct(res, widget);
                      this.forceGridItemsUpdate = widget.i;
                    })
                    .catch(() => {
                      //console.log(err.response.status)
                    });
                }, 60000);
                this.requestIntervals[i].intervals.push({
                  name: jsonInterval.name,
                  interval: interval,
                });
              } else {
                axios
                  .get(jsonInterval.request, {
                    cancelToken: this.cancelRequest.token,
                    headers: {
                      Authorization: tokenStore.getToken(),
                    },
                  })
                  .then((res) => {
                    fct(res, widget);
                    console.log("update");
                  })
                  .catch(() => {
                    //console.log(err.response.status)
                  });
                let interval = setInterval(() => {
                  axios
                    .get(jsonInterval.request, {
                      cancelToken: this.cancelRequest.token,
                      headers: {
                        Authorization: tokenStore.getToken(),
                      },
                    })
                    .then((res) => {
                      fct(res, widget);
                      console.log("update");
                    })
                    .catch(() => {
                      //console.log(err.response.status)
                    });
                }, 10000);
                this.requestIntervals[i].intervals.push({
                  name: jsonInterval.name,
                  interval: interval,
                });
              }
            });
          }
        }
      });
    },
  },
  methods: {
    savePreset() {
      if (this.saveAsDefaultPreset) {
        axios
          .put(
            process.env.VUE_APP_API_ADDR + "/api/preset/default",
            {
              id: this.selectedDashboard.id,
              name: this.presetName,
              description: this.presetDescription,
            },
            {
              headers: {
                Authorization: tokenStore.getToken(),
              },
            }
          )
          .then((res) => {
            console.log(res.data);
            this.presetDialog = false;
            this.presetName = "";
            this.presetDescription = "";
            this.saveAsDefaultPreset = false;
          })
          .catch(() => {
            //console.log(err.response.status)
          });
      } else {
        axios
          .put(
            process.env.VUE_APP_API_ADDR + "/api/preset/@me",
            {
              id: this.selectedDashboard.id,
              name: this.presetName,
              description: this.presetDescription,
            },
            {
              headers: {
                Authorization: tokenStore.getToken(),
              },
            }
          )
          .then((res) => {
            console.log(res.data);
            this.presetDialog = false;
            this.presetName = "";
            this.presetDescription = "";
            this.saveAsDefaultPreset = false;
          })
          .catch(() => {
            //console.log(err.response.status)
          });
      }
    },
    selectFromMenu(value) {
      switch (value) {
        case "Configure":
          this.uploadImage = true;
          break;
        case "Create widget":
          this.dialog = true;
          break;
        case "Parent Dashboard":
          this.dialog = true;
          break;
        case "Save as preset":
          this.saveAsDefaultPreset = false;
          this.presetDialog = true;
          break;
        case "Save as default preset":
          this.saveAsDefaultPreset = true;
          this.presetDialog = true;
          break;
      }
    },
    test(value) {
      console.log(value);
    },
    nextLinkChannel() {
      if (this.choosenWidget.type == "Alert") {
        if (this.selectedGroup != null && this.selectedAlert != null) {
          this.saveChannelAlertWidget(this.choosenWidget, 0);
        }
      } else if (this.choosenWidget.type == "map") {
        if (this.selectedGroup != null) {
          this.saveChannelMapWidget(this.choosenWidget, 0);
        }
      } else if (this.choosenWidget.type == "downlink") {
        this.saveChannelDownlinkWidget(this.choosenWidget, 0);
      } else if (this.choosenWidget.type == "linkDashboard") {
        if (this.selectedDashboardLink != null) {
          this.saveChannelLinkDashboardWidget(
            this.choosenWidget,
            this.currentStep
          );
        }
      } else if (this.selectedChannel != null) {
        if (this.choosenWidget.type == "text") {
          this.saveChannelTextWidget(this.choosenWidget, this.currentStep);
        } else if (
          this.choosenWidget.type == "line" ||
          this.choosenWidget.type == "area"
        ) {
          this.saveChannelLineWidget(this.choosenWidget, this.currentStep);
          if(channelList[this.selectedChannel] != undefined && !this.choosenWidget.myData.series[this.currentStep].text.includes(channelList[this.selectedChannel].unité)){
            this.choosenWidget.myData.series[this.currentStep].text += " ( "+channelList[this.selectedChannel].unité+" )"
          }
        } else if (this.choosenWidget.type == "coloredIcon") {
          this.saveChannelColoredIconWidget(this.choosenWidget, 0);
        } else if (this.choosenWidget.type == "line2") {
          this.saveChannelLine2Widget(this.choosenWidget, this.currentStep);
      }else if (this.choosenWidget.type == "reflectogram") {
          this.saveChannelReflectogramWidget(this.choosenWidget, this.currentStep);
      }
      }
    },
    unSaveWidget() {
      let a = this.items.findIndex((widget) => {
        return widget.i == this.tmpSaveWidget.i;
      });

      this.items.splice(a, 1, JSON.parse(JSON.stringify(this.tmpSaveWidget)));
      this.tmpSaveWidget = null;
    },
    previousLinkChannel() {
      this.tmpLinkSave.pop();
      this.currentStep--;
      if (this.choosenWidget.myData.inputs[this.currentStep].groupId != null) {
        if (
          this.groups.findIndex((group) => {
            return (
              group.id ==
              this.choosenWidget.myData.inputs[this.currentStep].groupId
            );
          }) >= 0
        ) {
          this.selectedGroupChannel =
            this.choosenWidget.myData.inputs[this.currentStep].groupId;
        }
      }
    },
    saveChannelDownlinkWidget(widget) {
      this.tmpLinkSave.push({
        name: "linkDashboard",
        request: "",
        fonction: "",
      });
      if (this.choosenWidget.myData.inputs.length != this.tmpLinkSave.length) {
        this.currentStep++;
      } else {
        let x = this.items.findIndex((w) => {
          return w == widget;
        });

        this.items[x].requestInterval = this.tmpLinkSave;
        let item = this.items[x];
        this.items.splice(x, 1);
        this.items.push(item);
        this.leaveLinkDialogChannel();
      }
    },
    saveChannelLinkDashboardWidget(widget, i) {
      this.choosenWidget.myData.inputs[i].dashBoardName =
        this.selectedDashboardLink.name;
      this.choosenWidget.myData.inputs[i].dashBoardId =
        this.selectedDashboardLink.id;
      this.choosenWidget.mySeries[i].dashBoardName =
        this.selectedDashboardLink.name;
      this.choosenWidget.mySeries[i].dashBoardId =
        this.selectedDashboardLink.id;
      this.choosenWidget.transparent = this.transparentWidget;

      this.tmpLinkSave.push({
        name: "linkDashboard",
        request: "",
        fonction: "",
      });
      if (this.choosenWidget.myData.inputs.length != this.tmpLinkSave.length) {
        this.currentStep++;
        this.selectedDashboardLink = null;
      } else {
        let x = this.items.findIndex((w) => {
          return w == widget;
        });

        this.items[x].requestInterval = this.tmpLinkSave;
        let item = this.items[x];
        this.items.splice(x, 1);
        this.items.push(item);
        this.leaveLinkDialogChannel();
      }
    },
    saveChannelMapWidget(widget) {
      let request = (
        process.env.VUE_APP_API_ADDR +
        "/api/group/" +
        this.selectedGroup.id +
        "/allDevicePosition"
      ).toString();

      let fctBody = `
      let tmp = [];
      let devices = res.data.devices;
      devices.forEach((device)=>{
        tmp.push([device.lat,device.long]);
        console.log(tmp);
      })
      
      widget.mySeries = tmp;
              `;

      axios
        .get(request, {
          headers: {
            Authorization: tokenStore.getToken(),
          },
        })
        .then((res) => {
          console.log(res.data);
          try {
            let fct = new Function("res", "widget", fctBody);
            fct(res, widget);
          } catch (err) {
            console.log(err);
          }
          this.tmpLinkSave.push({
            name: "map",
            request: request,
            fonction: fctBody,
          });
          if (
            this.choosenWidget.myData.inputs.length != this.tmpLinkSave.length
          ) {
            this.currentStep++;
            this.selectedGroup = null;
          } else {
            let x = this.items.findIndex((w) => {
              return w == widget;
            });
            this.items[x].requestInterval = this.tmpLinkSave;
            let item = this.items[x];
            this.items.splice(x, 1);
            this.items.push(item);
            this.leaveLinkDialogChannel();
          }
        })
        .catch(() => {
          //console.log(err.response.status)
        });
    },
    saveChannelAlertWidget(widget, i) {
      let request = (
        process.env.VUE_APP_API_ADDR +
        "/api/alert/" +
        this.selectedAlert.id
      ).toString();

      let fctBody = `
      widget.transparent = ${this.transparentWidget};
      widget.mySeries[${i}].groupName = "${this.selectedGroup.name}";
      widget.mySeries[${i}].alertName = "${this.selectedAlert.name}";
      widget.mySeries[${i}].dashboardName = "${
        this.linkToADashboard ? this.selectedDashboardLink.name : null
      }";
      widget.mySeries[${i}].dashboardId = "${
        this.linkToADashboard ? this.selectedDashboardLink.id : null
      }";
      widget.mySeries[${i}].alertId = "${this.selectedAlert.id}";

      if(res.data.alerts.length>0){
        let alerts = res.data.alerts;
          console.log(alerts);
            let lastAlert = alerts[0];
            widget.mySeries[${i}].updateTime = new Date(lastAlert.date).toLocaleString();
            widget.mySeries[${i}].state = lastAlert.level;

      }
      else{
         widget.mySeries[${i}].updateTime = new Date(Date.now()).toLocaleString();
      }
              `;

      axios
        .get(request, {
          headers: {
            Authorization: tokenStore.getToken(),
          },
        })
        .then((res) => {
          try {
            let fct = new Function("res", "widget", fctBody);
            fct(res, widget);
          } catch (err) {
            console.log(err);
          }
          this.tmpLinkSave.push({
            name: "Alert",
            request: request,
            fonction: fctBody,
          });
          if (
            this.choosenWidget.myData.inputs.length != this.tmpLinkSave.length
          ) {
            this.currentStep++;
            this.selectedGroup = null;
            this.selectedAlert = null;
          } else {
            let x = this.items.findIndex((w) => {
              return w == widget;
            });
            this.items[x].requestInterval = this.tmpLinkSave;
            let item = this.items[x];
            this.items.splice(x, 1);
            this.items.push(item);
            this.leaveLinkDialogChannel();
          }
        })
        .catch(() => {
          //console.log(err.response.status)
        });
    },

    saveChannelColoredIconWidget(widget, i) {
      let request = (
        process.env.VUE_APP_API_ADDR +
        "/api/device/" +
        this.selectedDeviceChannel +
        "/data/last/" +
        this.selectedChannel
      ).toString();
      let fctBody = `

      widget.transparent = ${this.transparentWidget};
      widget.myData.inputs[${i}].dashBoardName = "${
        widget.myData.inputs[i].linkToADashboard
          ? this.selectedDashboardLink.name
          : null
      }";
      widget.myData.inputs[${i}].dashBoardId = "${
        widget.myData.inputs[i].linkToADashboard
          ? this.selectedDashboardLink.id
          : null
      }";
            if (res.data.data.length > 0) {
              let json = JSON.parse(res.data.data[0].data);
              console.log(json);
              json.updateTime = (new Date(json.updateTime)).toLocaleString();
              widget.mySeries[${i}].value = json;
            }
              `;

      axios
        .get(request, {
          headers: {
            Authorization: tokenStore.getToken(),
          },
        })
        .then((res) => {
          try {
            let fct = new Function("res", "widget", fctBody);
            fct(res, widget);
          } catch (err) {
            console.log(err);
          }
          this.tmpLinkSave.push({
            name: "coloredIcon",
            request: request,
            fonction: fctBody,
          });
          this.choosenWidget.myData.inputs[i].groupId =
            this.selectedGroupChannel;
          this.choosenWidget.myData.inputs[i].deviceId =
            this.selectedDeviceChannel;
          this.choosenWidget.myData.inputs[i].channel = this.selectedChannel;
          if (
            this.choosenWidget.myData.inputs.length != this.tmpLinkSave.length
          ) {
            this.currentStep++;
            this.devices = [];
            this.channels = [];
            this.checkboxShowDateData = false;
            this.selectedChannel = null;
            this.disabledChannel = true;
            this.selectedDeviceChannel = null;
            this.disabledSelectedDeviceChannel = true;
            this.selectedGroupChannel = null;
            setTimeout(() => {
              this.selectedGroupChannel =
                this.choosenWidget.myData.inputs[this.currentStep].groupId;
            }, 1);
          } else {
            let x = this.items.findIndex((w) => {
              return w == widget;
            });
            this.items[x].requestInterval = this.tmpLinkSave;
            let item = this.items[x];
            this.items.splice(x, 1);
            this.items.push(item);
            this.leaveLinkDialogChannel();
          }
        })
        .catch(() => {
          //console.log(err.response.status)
        });
    },

    saveChannelTextWidget(widget, i) {
      let request = (
        process.env.VUE_APP_API_ADDR +
        "/api/device/" +
        this.selectedDeviceChannel +
        "/data/last/" +
        this.selectedChannel
      ).toString();

      let fctBody = `
            if (res.data.data.length > 0) {
              let json = JSON.parse(res.data.data[0].data);
              if(${this.checkboxShowDateData}){
                json.updateTime = (new Date(json.updateTime)).toLocaleString();
                widget.mySeries[${i}].value = json;
              }else{
                widget.mySeries[${i}].value = json.value;
              }
              
            }
              `;

      axios
        .get(request, {
          headers: {
            Authorization: tokenStore.getToken(),
          },
        })
        .then((res) => {
          try {
            let fct = new Function("res", "widget", fctBody);
            fct(res, widget);
          } catch (err) {
            console.log(err);
          }
          this.tmpLinkSave.push({
            name: "text",
            request: request,
            fonction: fctBody,
          });
          this.choosenWidget.myData.inputs[i].groupId =
            this.selectedGroupChannel;
          this.choosenWidget.myData.inputs[i].deviceId =
            this.selectedDeviceChannel;
          this.choosenWidget.myData.inputs[i].channel = this.selectedChannel;
          if (
            this.choosenWidget.myData.inputs.length != this.tmpLinkSave.length
          ) {
            this.currentStep++;
            this.devices = [];
            this.channels = [];
            this.checkboxShowDateData = false;
            this.selectedChannel = null;
            this.disabledChannel = true;
            this.selectedDeviceChannel = null;
            this.disabledSelectedDeviceChannel = true;
            this.selectedGroupChannel = null;
            setTimeout(() => {
              this.selectedGroupChannel =
                this.choosenWidget.myData.inputs[this.currentStep].groupId;
            }, 1);
          } else {
            let x = this.items.findIndex((w) => {
              return w == widget;
            });
            this.items[x].requestInterval = this.tmpLinkSave;
            let item = this.items[x];
            this.items.splice(x, 1);
            this.items.push(item);
            this.leaveLinkDialogChannel();
          }
        })
        .catch(() => {
          //console.log(err.response.status)
        });
    },
    line2ChangeDate(json) {
      let x = this.items.findIndex((w) => {
        return w.i == json.i;
      });
      let date = new Date(json.date);
      let date2 = new Date(json.date);
      date2.setDate(date2.getDate() + 1);
      this.items[x].myData.inputs.forEach((jsonInput, i) => {
        let request = `"${process.env.VUE_APP_API_ADDR}/api/device/${
          jsonInput.deviceId
        }/data/query/${
          jsonInput.channel
        }?maxDate=${date2.getTime()}&minDate=${date.getTime()}"`;
        this.items[x].requestInterval[i].request = request;
      });
      this.items[x].myData.date = date.toISOString().substr(0, 10);
      this.items[x].myData.dateType = "day";
      let item = this.items[x];
      this.items.splice(x, 1);
      this.items.push(item);
    },
    line2ChangeDateArray(json) {
      let x = this.items.findIndex((w) => {
        return w.i == json.i;
      });
      let date = new Date(json.dateArray[0]);
      let date2 = new Date(json.dateArray[1]);
      date2.setDate(date2.getDate() + 1);
      this.items[x].myData.inputs.forEach((jsonInput, i) => {
        let request = `"${process.env.VUE_APP_API_ADDR}/api/device/${
          jsonInput.deviceId
        }/data/query/${
          jsonInput.channel
        }?maxDate=${date2.getTime()}&minDate=${date.getTime()}"`;
        this.items[x].requestInterval[i].request = request;
      });
      this.items[x].myData.date = [
        date.toISOString().substr(0, 10),
        date2.toISOString().substr(0, 10),
      ];
      this.items[x].myData.dateType = "range";
      let item = this.items[x];
      this.items.splice(x, 1);
      this.items.push(item);
    },
    saveChannelLine2Widget(widget, i) {
      let date = new Date(widget.myData.date);
      let date2 = new Date(widget.myData.date);
      date2.setDate(date2.getDate() + 1);

      let request = `"${process.env.VUE_APP_API_ADDR}/api/device/${
        this.selectedDeviceChannel
      }/data/query/${
        this.selectedChannel
      }?maxDate=${date2.getTime()}&minDate=${date.getTime()}"`;
      let fctBody = `
          try {
            let array = [];
            res.data.data.forEach((data) => {
              try {
                let json = JSON.parse(data.data);
                array.push([
                  new Date(json.updateTime).getTime(),
                  json.value
                ])
              } catch (err) {
                //
                console.log(err);
              }
            });
            array.sort(function(a,b){
            return (a[0] < b[0]) ? -1 : (a[0] > b[0]);
          });
          console.log(array)
            widget.myData.series[${i}].values = array;
          } catch (err) {
            console.log(err);
            //;
          }
              `;

      axios
        .get(eval(request), {
          headers: {
            Authorization: tokenStore.getToken(),
          },
        })
        .then((res) => {
          let tmp = [...this.choosenWidget.mySeries[this.currentStep].values];

          try {
            let fct = new Function("res", "widget", fctBody);
            fct(res, widget);
          } catch (err) {
            console.log(err);
          }

          this.choosenWidget.myData.inputs[i].groupId =
            this.selectedGroupChannel;
          this.choosenWidget.myData.inputs[i].deviceId =
            this.selectedDeviceChannel;
          this.choosenWidget.myData.inputs[i].channel = this.selectedChannel;

          this.tmpLinkSave.push({
            name: widget.type,
            request: request,
            fonction: fctBody,
          });
          if (
            this.choosenWidget.myData.series.length == this.tmpLinkSave.length
          ) {
            console.log("link finished");
            let x = this.items.findIndex((w) => {
              return w == widget;
            });
            this.items[x].requestInterval = this.tmpLinkSave;
            let item = this.items[x];
            this.items.splice(x, 1);
            this.items.push(item);
            this.leaveLinkDialogChannel();
          } else {
            console.log("suivant");
            this.choosenWidget.mySeries[this.currentStep].values = tmp;
            this.currentStep++;
            this.devices = [];
            this.channels = [];
            this.selectedChannel = null;
            this.disabledChannel = true;
            this.selectedDeviceChannel = null;
            this.disabledSelectedDeviceChannel = true;
            this.selectedGroupChannel = null;
            setTimeout(() => {
              this.selectedGroupChannel =
                this.choosenWidget.myData.inputs[this.currentStep].groupId;
            }, 1);
          }
        })
        .catch(() => {
          //console.log(err.response.status)
        });
    },
    saveChannelReflectogramWidget(widget, i) {

      let request = `"${process.env.VUE_APP_API_ADDR}/api/device/${
        this.selectedDeviceChannel
      }/data/last/${
        this.selectedChannel
      }"`;

      let fctBody = `
          try {
            let array = [];
            JSON.parse(res.data.data[0].data).value.forEach((data,i) => {
              try {
                
                array.push([
                  (i+1)*2,
                  data
                ])
              } catch (err) {
                //
                console.log(err);
              }

            });
            console.log(array)
            widget.myData.series[${i}].values = array;
          } catch (err) {
            console.log(err);
            //;
          }
              `;

      axios
        .get(eval(request), {
          headers: {
            Authorization: tokenStore.getToken(),
          },
        })
        .then((res) => {
          let tmp = [...this.choosenWidget.mySeries[this.currentStep].values];

          try {
            let fct = new Function("res", "widget", fctBody);
            fct(res, widget);
          } catch (err) {
            console.log(err);
          }

          this.choosenWidget.myData.inputs[i].groupId =
            this.selectedGroupChannel;
          this.choosenWidget.myData.inputs[i].deviceId =
            this.selectedDeviceChannel;
          this.choosenWidget.myData.inputs[i].channel = this.selectedChannel;

          this.tmpLinkSave.push({
            name: widget.type,
            request: request,
            fonction: fctBody,
          });
          if (
            this.choosenWidget.myData.series.length == this.tmpLinkSave.length
          ) {
            console.log("link finished");
            let x = this.items.findIndex((w) => {
              return w == widget;
            });
            this.items[x].requestInterval = this.tmpLinkSave;
            let item = this.items[x];
            this.items.splice(x, 1);
            this.items.push(item);
            this.leaveLinkDialogChannel();
          } else {
            console.log("suivant");
            this.choosenWidget.mySeries[this.currentStep].values = tmp;
            this.currentStep++;
            this.devices = [];
            this.channels = [];
            this.selectedChannel = null;
            this.disabledChannel = true;
            this.selectedDeviceChannel = null;
            this.disabledSelectedDeviceChannel = true;
            this.selectedGroupChannel = null;
            setTimeout(() => {
              this.selectedGroupChannel =
                this.choosenWidget.myData.inputs[this.currentStep].groupId;
            }, 1);
          }
        })
        .catch(() => {
          //console.log(err.response.status)
        });
    },
    saveChannelLineWidget(widget, i) {
      let end = 0;
      switch (widget.range) {
        case "half-hour":
          end = 1000 * 60 * 30;
          break;
        case "hour":
          end = 1000 * 60 * 60;
          break;
        case "day":
          end = 1000 * 60 * 60 * 24;
          break;
        case "week":
          end = 1000 * 60 * 60 * 24 * 7;
          break;
        case "month":
          end = 1000 * 60 * 60 * 24 * 31;
          break;
        case "year":
          end = 1000 * 60 * 60 * 24 * 351;
          break;
      }
      let request = `"${process.env.VUE_APP_API_ADDR}/api/device/${this.selectedDeviceChannel}/data/query/${this.selectedChannel}?maxDate="+Date.now()+"&minDate="+(Date.now()-${end})+""`;

      let fctBody = `
          try {
            let array = [];
            res.data.data.forEach((data) => {
              try {
                let json = JSON.parse(data.data);
                array.push([
                  new Date(json.updateTime).getTime(),
                  json.value
                ])
              } catch (err) {
                //
                console.log(err);
              }
            });
            array.sort(function(a,b){
            return (a[0] < b[0]) ? -1 : (a[0] > b[0]);
          });
          console.log(array)
            widget.myData.series[${i}].values = array;
          } catch (err) {
            console.log(err);
            //;
          }
              `;

      axios
        .get(eval(request), {
          headers: {
            Authorization: tokenStore.getToken(),
          },
        })
        .then((res) => {
          let tmp = [...this.choosenWidget.mySeries[this.currentStep].values];

          try {
            let fct = new Function("res", "widget", fctBody);
            fct(res, widget);
          } catch (err) {
            console.log(err);
          }

          this.choosenWidget.myData.inputs[i].groupId =
            this.selectedGroupChannel;
          this.choosenWidget.myData.inputs[i].deviceId =
            this.selectedDeviceChannel;
          this.choosenWidget.myData.inputs[i].channel = this.selectedChannel;

          this.tmpLinkSave.push({
            name: widget.type,
            request: request,
            fonction: fctBody,
          });
          if (
            this.choosenWidget.myData.series.length == this.tmpLinkSave.length
          ) {
            console.log("link finished");
            let x = this.items.findIndex((w) => {
              return w == widget;
            });
            this.items[x].requestInterval = this.tmpLinkSave;
            let item = this.items[x];
            this.items.splice(x, 1);
            this.items.push(item);
            this.leaveLinkDialogChannel();
          } else {
            console.log("suivant");
            this.choosenWidget.mySeries[this.currentStep].values = tmp;
            this.currentStep++;
            this.devices = [];
            this.channels = [];
            this.selectedChannel = null;
            this.disabledChannel = true;
            this.selectedDeviceChannel = null;
            this.disabledSelectedDeviceChannel = true;
            this.selectedGroupChannel = null;
            setTimeout(() => {
              this.selectedGroupChannel =
                this.choosenWidget.myData.inputs[this.currentStep].groupId;
            }, 1);
          }
        })
        .catch(() => {
          //console.log(err.response.status)
        });
    },
    next() {
      if (this.currentStep == 0) {
        if (this.selectedDevices.length > 0) {
          this.currentStep++;
          let json = this.selectedDevices[0].lastData.data;
          this.jsonPaths = this.getAllJsonKeys(json);
          this.jsonPaths.forEach((array) => {
            let str = "json";
            for (let i = 0; i < array.length; i++) {
              str += "." + array[i];
            }
            this.displayJsonPaths.push({
              path: array,
              display:
                array.slice(0, array.length).toString() + " -> " + eval(str),
            });
          });
          this.choosenWidget.group = this.selectedGroup;
          this.choosenWidget.devices = this.selectedDevices;

          if (this.selectedDevices.length == 1) {
            if (this.choosenWidget.type == "text") {
              this.linkArrayChoices = [
                "Last value",
                "Max value in period",
                "Min value in period",
                "Average value in period",
              ];
            } else if (this.choosenWidget.type == "line") {
              this.linkArrayChoices = ["Date", "Other"];
            }
          } else {
            if (this.choosenWidget.type == "text") {
              this.linkArrayChoices = [
                "Last value for all choosen sensors",
                "Max value between sensors",
                "Min value between sensors",
                "Average value for sensors",
              ];
            } else if (this.choosenWidget.type == "line") {
              this.linkArrayChoices = ["Date", "Other"];
            }
          }
        }
      } else {
        if (this.choosenWidget.type == "text") {
          if (this.selectedDevices.length == 1) {
            if (this.choosenWidget.myData.inputs.length == this.currentStep) {
              if (
                this.choosenWidget.myData.inputs[this.currentStep - 1]
                  .linkedPath.length > 0
              ) {
                this.saveLinkTextSingleData(
                  this.choosenWidget,
                  this.currentStep - 1
                );
              }
            } else {
              if (
                this.choosenWidget.myData.inputs[this.currentStep - 1]
                  .linkedPath.length > 0
              ) {
                this.saveLinkTextSingleData(
                  this.choosenWidget,
                  this.currentStep - 1
                );
                this.currentStep++;
              }
            }
          } else {
            if (this.choosenWidget.myData.inputs.length == this.currentStep) {
              if (
                this.choosenWidget.myData.inputs[this.currentStep - 1]
                  .linkedPath.length > 0
              ) {
                this.saveLinkTextMultipleData(
                  this.choosenWidget,
                  this.currentStep - 1
                );
              }
            } else {
              if (
                this.choosenWidget.myData.inputs[this.currentStep - 1]
                  .linkedPath.length > 0
              ) {
                this.saveLinkTextMultipleData(
                  this.choosenWidget,
                  this.currentStep - 1
                );
                this.currentStep++;
              }
            }
          }
        } else if (this.choosenWidget.type == "line") {
          if (this.selectedDevices.length == 1) {
            if (this.currentStep == 1) {
              if (this.choosenWidget.linkedXPath.length > 0) {
                console.log("x bind");
                this.currentStep++;
              }
            } else {
              if (this.choosenWidget.mySeries.length == this.currentStep - 1) {
                if (
                  this.choosenWidget.mySeries[this.currentStep - 2].linkedPath
                    .length > 0
                ) {
                  this.saveLinkChartSimpleData(
                    this.choosenWidget,
                    this.currentStep - 2
                  );
                }
              } else {
                if (
                  this.choosenWidget.mySeries[this.currentStep - 2].linkedPath
                    .length > 0
                ) {
                  console.log("next");
                  this.currentStep++;
                }
              }
            }
          } else {
            if (this.currentStep == 1) {
              if (this.choosenWidget.linkedXPath.length > 0) {
                console.log("x bind");
                this.currentStep++;
              }
            } else {
              if (this.choosenWidget.mySeries.length == this.currentStep - 1) {
                if (
                  this.choosenWidget.mySeries[this.currentStep - 2].linkedPath
                    .length > 0
                ) {
                  console.log("save");
                  this.saveLinkChartMultiData(
                    this.choosenWidget,
                    this.currentStep - 2
                  );
                }
              } else {
                if (
                  this.choosenWidget.mySeries[this.currentStep - 2].linkedPath
                    .length > 0
                ) {
                  let jsonData =
                    this.selectedDevices[this.currentStep - 1].lastData.data;
                  this.jsonPaths = this.getAllJsonKeys(jsonData);
                  this.jsonPaths.forEach((array) => {
                    let str = "jsonData";
                    for (let i = 0; i < array.length; i++) {
                      str += "." + array[i];
                    }
                    this.displayJsonPaths.push({
                      path: array,
                      display:
                        array.slice(0, array.length).toString() +
                        " -> " +
                        eval(str),
                    });
                  });
                  console.log("next");
                  this.currentStep++;
                }
              }
            }
          }
        } else if (this.choosenWidget.type == "coloredIcon") {
          this.saveColoredIconData(this.choosenWidget);
        }
      }
    },
    saveColoredIconData(widget) {
      let request = `"${process.env.VUE_APP_API_ADDR}/api/device/${this.selectedDevices[0].id}"`;

      let fctBody = `
        
              res.data.device.lastData.data = JSON.parse(
                res.data.device.lastData.data
              );

              let str = "res.data.device.lastData.data";
              for (
                let y = 0;
                y < widget.myData.inputs[0].linkedPath.length;
                y++
              ) {
                str += "." + widget.myData.inputs[0].linkedPath[y];
              }
              widget.mySeries[0].value = eval(str);

        
      `;

      axios
        .get(eval(request), {
          headers: {
            Authorization: tokenStore.getToken(),
          },
        })
        .then((res) => {
          try {
            let fct = new Function("res", "widget", fctBody);
            fct(res, widget);
          } catch (err) {
            console.log(err);
          }
          this.tmpLinkSave.push({
            name: "coloredIcon",
            request: request,
            fonction: fctBody,
          });
          if (this.tmpLinkSave.length == this.selectedDevices.length) {
            let x = this.items.findIndex((w) => {
              return w == widget;
            });
            this.items[x].requestInterval = this.tmpLinkSave;
            let item = this.items[x];
            this.items.splice(x, 1);
            this.items.push(item);
            this.leaveLinkDialog();
          }
        })
        .catch(() => {
          //console.log(err.response.status)
        });
    },
    saveLinkChartMultiData(widget) {
      for (let a = 0; a < this.selectedDevices.length; a++) {
        let end = 0;
        switch (this.linkDateRange) {
          case "day":
            end = 1000 * 60 * 60 * 24;
            break;
          case "week":
            end = 1000 * 60 * 60 * 24 * 7;
            break;
          case "month":
            end = 1000 * 60 * 60 * 24 * 31;
            break;
          case "year":
            end = 1000 * 60 * 60 * 24 * 351;
            break;
        }
        let request = `"${process.env.VUE_APP_API_ADDR}/api/device/${this.selectedDevices[a].id}/data/query?maxDate="+Date.now()+"&minDate="+(Date.now()-${end})+""`;

        let fctBody = `
          console.log(${a});
        console.log(res.data.data);
        let i = res.data.data.findIndex((data)=>{
          return data.data == "Registered !";
        });
        if(i>=0){
        res.data.data.splice(i,1);
        }

          let str = "JSON.parse(data.data)";
          for (let x = 0; x < widget.linkedXPath.length; x++) {
            str += "." + widget.linkedXPath[x];
          }

          let ordonateStr = '';

            widget.myData.series[${a}].values=[];
            let tmp = "JSON.parse(data.data)";
            for (let x = 0; x < widget.myData.series[${a}].linkedPath.length; x++) {
              tmp += "." + widget.myData.series[${a}].linkedPath[x];
            }
            ordonateStr = tmp;
          

          let min=1000000000000000;
          
          try {

          let tmpData = [];
          res.data.data.forEach((data) => {
            try{
              let tmpX = (new Date(eval(str)).getTime());
              tmpData.push({"tmpX" : tmpX, "data":data.data});
            }
            catch (err) {
              //
            }
          });
          tmpData.sort(function(a,b){
            return (a.tmpX < b.tmpX) ? -1 : (a.tmpX > b.tmpX);
          });
          tmpData.forEach((data) => {

              
                let tmpX = data.tmpX;
                
                let tmpY = eval(ordonateStr);
                widget.myData.series[${a}].values.push([
                  tmpX, tmpY
                ]);
                if(min > tmpY){
                  min = tmpY
                }
              
              
            
           });

          } catch (err) {console.log(err);
              //
            }

          if(widget.myData['scale-y'].minValue == 0 || widget.myData['scale-y'].minValue > min){
            widget.myData['scale-y'].minValue = min-1;
          }

          


      `;

        axios
          .get(eval(request), {
            headers: {
              Authorization: tokenStore.getToken(),
            },
          })
          .then((res) => {
            try {
              let fct = new Function("res", "widget", fctBody);
              fct(res, widget);
            } catch (err) {
              console.log(err);
            }
            this.tmpLinkSave.push({
              name: a,
              request: request,
              fonction: fctBody,
            });
            if (this.tmpLinkSave.length == this.selectedDevices.length) {
              let x = this.items.findIndex((w) => {
                return w == widget;
              });
              this.items[x].requestInterval = this.tmpLinkSave;
              let item = this.items[x];
              this.items.splice(x, 1);
              this.items.push(item);
              this.leaveLinkDialog();
            }
          })
          .catch(() => {
            //console.log(err.response.status)
          });
      }
    },
    saveLinkChartSimpleData(widget, i) {
      let end = 0;
      switch (this.linkDateRange) {
        case "day":
          end = 1000 * 60 * 60 * 24;
          break;
        case "week":
          end = 1000 * 60 * 60 * 24 * 7;
          break;
        case "month":
          end = 1000 * 60 * 60 * 24 * 31;
          break;
        case "year":
          end = 1000 * 60 * 60 * 24 * 351;
          break;
      }
      let request = `"${process.env.VUE_APP_API_ADDR}/api/device/${this.selectedDevices[0].id}/data/query?maxDate="+Date.now()+"&minDate="+(Date.now()-${end})+""`;

      let fctBody =
        `
        console.log(res.data.data);
        let i = res.data.data.findIndex((data)=>{
          return data.data == "Registered !";
        });
        if(i>=0){
        res.data.data.splice(i,1);
        }

          let str = "JSON.parse(data.data)";
          for (let x = 0; x < widget.linkedXPath.length; x++) {
            str += "." + widget.linkedXPath[x];
          }

          let ordanateStr = [];
          for (let y = 0; y <= ` +
        i +
        `; y++) {
            widget.myData.series[y].values=[];
            let tmp = "JSON.parse(data.data)";
            for (let x = 0; x < widget.myData.series[y].linkedPath.length; x++) {
              tmp += "." + widget.myData.series[y].linkedPath[x];
            }
            ordanateStr.push(tmp);
          }

          let min=1000000000000000;
        

          try {

          let tmpData = [];
          res.data.data.forEach((data) => {
            try{
              let tmpX = (new Date(eval(str)).getTime());
              tmpData.push({"tmpX" : tmpX, "data":data.data});
            }
            catch (err) {
              //
            }
          });
          tmpData.sort(function(a,b){
            return (a.tmpX < b.tmpX) ? -1 : (a.tmpX > b.tmpX);
          });
          tmpData.forEach((data) => {

              for(let u = 0; u<ordanateStr.length;u++){
                let tmpX = data.tmpX;
                
                let tmpY = eval(ordanateStr[u]);
                widget.myData.series[u].values.push([
                  tmpX, tmpY
                ]);
                if(min > tmpY){
                  min = tmpY
                }
              }
              
            
           });

          } catch (err) {console.log(err);
              //
            }
          
          widget.myData['scale-y'].minValue = min-1;


      `;

      axios
        .get(eval(request), {
          headers: {
            Authorization: tokenStore.getToken(),
          },
        })
        .then((res) => {
          try {
            let fct = new Function("res", "widget", fctBody);
            fct(res, widget);
          } catch (err) {
            console.log(err);
          }

          let x = this.items.findIndex((w) => {
            return w == widget;
          });
          this.items[x].requestInterval = [
            {
              name: "",
              request: request,
              fonction: fctBody,
            },
          ];
          let item = this.items[x];
          this.items.splice(x, 1);
          this.items.push(item);
          this.leaveLinkDialog();
        })
        .catch(() => {
          //console.log(err.response.status)
        });
    },
    saveLinkTextMultipleData(widget, i) {
      if (this.linkRadioGroup == 0) {
        let ids = "[";
        this.selectedDevices.forEach((json) => {
          ids += json.id + ",";
        });
        ids = ids.slice(0, -1);
        ids += "]";

        let request = (
          process.env.VUE_APP_API_ADDR +
          "/api/device/multiple/data?idArray=" +
          ids
        ).toString();

        let fctBody =
          `
            let result = "";
            let str = "JSON.parse(json.data[0].data)";
            for (
              let y = 0;
              y < widget.myData.inputs[` +
          i +
          `].linkedPath.length;
              y++
            ) {
              str += "." + widget.myData.inputs[` +
          i +
          `].linkedPath[y];
            }

            let array = res.data.result;
            array.sort((a,b)=>{
            return (a.deviceName > b.deviceName)
            });

            array.forEach((json) => {
              result += "\\r\\n" + json.deviceName + " : " + eval(str);
            });
            widget.mySeries[` +
          i +
          `].value = result;
        `;
        axios
          .get(request, {
            headers: {
              Authorization: tokenStore.getToken(),
            },
          })
          .then((res) => {
            let fct = new Function("res", "widget", fctBody);
            fct(res, widget);
            this.tmpLinkSave.push({
              name: widget.myData.inputs[i].name,
              request: request,
              fonction: fctBody,
            });
            if (widget.myData.inputs.length - 1 == i) {
              let x = this.items.findIndex((w) => {
                return w == widget;
              });
              this.items[x].requestInterval = this.tmpLinkSave;
              let item = this.items[x];
              this.items.splice(x, 1);
              this.items.push(item);
              this.leaveLinkDialog();
            }
          })
          .catch(() => {
            //console.log(err.response.status)
          });
      }
    },
    saveLinkTextSingleData(widget, i) {
      if (this.linkRadioGroup == 0) {
        let request = (
          process.env.VUE_APP_API_ADDR +
          "/api/device/" +
          this.selectedDevices[0].id
        ).toString();

        let fctBody =
          `
              res.data.device.lastData.data = JSON.parse(
                res.data.device.lastData.data
              );

              let str = "res.data.device.lastData.data";
              for (
                let y = 0;
                y < widget.myData.inputs[` +
          i +
          `].linkedPath.length;
                y++
              ) {
                str += "." + widget.myData.inputs[` +
          i +
          `].linkedPath[y];
              }
              widget.mySeries[` +
          i +
          `].value = eval(str);
               
          
              `;
        axios
          .get(request, {
            headers: {
              Authorization: tokenStore.getToken(),
            },
          })
          .then((res) => {
            let fct = new Function("res", "widget", fctBody);
            fct(res, widget);
            this.tmpLinkSave.push({
              name: widget.myData.inputs[i].name,
              request: request,
              fonction: fctBody,
            });
            if (widget.myData.inputs.length - 1 == i) {
              let x = this.items.findIndex((w) => {
                return w == widget;
              });
              this.items[x].requestInterval = this.tmpLinkSave;
              let item = this.items[x];
              this.items.splice(x, 1);
              this.items.push(item);
              this.leaveLinkDialog();
            }
          })
          .catch(() => {
            //console.log(err.response.status)
          });
      } else if (this.linkRadioGroup == 1 || this.linkRadioGroup == 2) {
        let start = Date.now();
        let end = 0;
        switch (this.linkDateRange) {
          case "day":
            end = start - 1000 * 60 * 60 * 24;
            break;
          case "week":
            end = start - 1000 * 60 * 60 * 24 * 7;
            break;
          case "month":
            end = start - 1000 * 60 * 60 * 24 * 31;
            break;
          case "year":
            end = start - 1000 * 60 * 60 * 24 * 351;
            break;
        }

        let request = (
          process.env.VUE_APP_API_ADDR +
          "/api/device/" +
          this.selectedDevices[0].id +
          "/data/query?maxDate=" +
          start +
          "&minDate=" +
          end
        ).toString();
        let fctBody = "";
        if (this.linkRadioGroup == 1) {
          fctBody =
            `
                      let tmpArray = [];
                      let str = "JSON.parse(data.data)";
                      for (
                        let y = 0;
                        y < widget.myData.inputs[` +
            i +
            `].linkedPath.length;
                        y++
                      ) {
                        str += "." + widget.myData.inputs[` +
            i +
            `].linkedPath[y];
                      }
                      res.data.data.forEach((data) => {
                        try{
                        tmpArray.push(eval(str));
                        }
                        catch{
                          //
                        }
                      });
                      if (!isNaN(tmpArray[0])) {
                        widget.mySeries[` +
            i +
            `].value = Math.max(...tmpArray);
          
                      } else {
                        //
                      }
          `;
        } else if (this.linkRadioGroup == 2) {
          fctBody =
            `
                      let tmpArray = [];
                      let str = "JSON.parse(data.data)";
                      for (
                        let y = 0;
                        y < widget.myData.inputs[` +
            i +
            `].linkedPath.length;
                        y++
                      ) {
                        str += "." + widget.myData.inputs[` +
            i +
            `].linkedPath[y];
                      }
                      res.data.data.forEach((data) => {
                        try{
                        tmpArray.push(eval(str));
                        }
                        catch{
                          //
                        }
                      });
                      if (!isNaN(tmpArray[0])) {
                        widget.mySeries[` +
            i +
            `].value = Math.min(...tmpArray);
         
                      } else {
                        //
                      }
          `;
        }

        axios
          .get(request, {
            headers: {
              Authorization: tokenStore.getToken(),
            },
          })
          .then((res) => {
            let fct = new Function("res", "widget", fctBody);
            fct(res, widget);
            this.tmpLinkSave.push({
              name: widget.myData.inputs[i].name,
              request: request,
              fonction: fctBody,
            });
            if (widget.myData.inputs.length - 1 == i) {
              let x = this.items.findIndex((w) => {
                return w == widget;
              });
              this.items[x].requestInterval = this.tmpLinkSave;
              let item = this.items[x];
              this.items.splice(x, 1);
              this.items.push(item);
              this.leaveLinkDialog();
            }
          })
          .catch(() => {
            //console.log(err.response.status)
          });
      } else if (this.linkRadioGroup == 3) {
        let start = Date.now();
        let end = 0;
        switch (this.linkDateRange) {
          case "day":
            end = start - 1000 * 60 * 60 * 24;
            break;
          case "week":
            end = start - 1000 * 60 * 60 * 24 * 7;
            break;
          case "month":
            end = start - 1000 * 60 * 60 * 24 * 31;
            break;
          case "year":
            end = start - 1000 * 60 * 60 * 24 * 351;
            break;
        }

        let request = (
          process.env.VUE_APP_API_ADDR +
          "/api/device/" +
          this.selectedDevices[0].id +
          "/data/query?maxDate=" +
          start +
          "&minDate=" +
          end
        ).toString();
        let fctBody =
          `            
            let tmpArray = [];
            let str = "JSON.parse(data.data)";
            for (
              let y = 0;
              y < widget.myData.inputs[` +
          i +
          `].linkedPath.length;
              y++
            ) {
              str += "." + widget.myData.inputs[` +
          i +
          `].linkedPath[y];
            }
            res.data.data.forEach((data) => {
              try {
                tmpArray.push(eval(str));
              } catch {
                //
              }
            });
            if (!isNaN(tmpArray[0])) {
              
              let sum = 0;

              tmpArray.forEach((value)=>{
                sum+=value;
              })
              widget.mySeries[` +
          i +
          `].value = Math.trunc(sum / tmpArray.length*1000)/1000;
            
            } else {
              //
             
            }
          `;

        axios
          .get(request, {
            headers: {
              Authorization: tokenStore.getToken(),
            },
          })
          .then((res) => {
            let fct = new Function("res", "widget", fctBody);
            fct(res, widget);
            this.tmpLinkSave.push({
              name: widget.myData.inputs[i].name,
              request: request,
              fonction: fctBody,
            });
            if (widget.myData.inputs.length - 1 == i) {
              let x = this.items.findIndex((w) => {
                return w == widget;
              });
              this.items[x].requestInterval = this.tmpLinkSave;
              let item = this.items[x];
              this.items.splice(x, 1);
              this.items.push(item);
              this.leaveLinkDialog();
            }
          })
          .catch(() => {
            //console.log(err.response.status)
          });
      }
    },

    getAllJsonKeys(json) {
      this.jsonPaths = [];
      this.displayJsonPaths = [];
      let keys = [];
      for (let k in json) {
        if (
          Array.isArray(json[k]) == false &&
          typeof json[k] === "object" &&
          json[k] !== null
        ) {
          let tmp = this.getAllJsonKeys(json[k]);
          tmp.forEach((array) => {
            array.unshift(k);
            keys.push(array);
          });
        } else {
          if (Array.isArray(json[k]) == false) {
            keys.push([k]);
          }
        }
      }
      return keys;
    },
    leaveLinkDialog() {
      this.linkDialog = false;
      setTimeout(() => {
        this.choosenWidget = null;
        this.selectedDevices = [];
        this.currentStep = 0;
        this.tmpLinkSave = [];
        this.displayJsonPaths = [];
        //this.$router.go(0);
      }, 500);
      this.selectedGroup = null;
      this.devices = [];
      this.jsonPaths = [];
      this.displayJsonPaths = [];
      this.linkRadioGroup = 0;
      this.linkDates = [];
    },
    leaveLinkDialogChannel() {
      this.linkDialogChannel = false;
      setTimeout(() => {
        this.choosenWidget = null;
        this.currentStep = 0;
        this.tmpLinkSave = [];
        //this.$router.go(0);
      }, 500);
      this.selectedGroupChannel = null;
      this.devices = [];
      this.checkboxShowDateData = false;
      this.selectedDeviceChannel = null;
      this.selectedDashboardLink = null;
      this.channels = [];
      this.selectedChannel = null;
      this.selectedAlert = null;
      this.selectedGroup = null;
      this.disabledChannel = true;
      this.disabledSelectedDeviceChannel = true;
      this.linkDates = [];
      this.linkDateRange = "day";
      this.customLineChannel = false;
      this.customLinkDashboardChannel = false;
      this.customLinkAlertChannel = false;
      this.transparentWidget = false;
      this.linkToADashboard = false;
      this.customLinkColoredIconChannel = false;
    },
    leaveEditDialog() {
      this.editWidgetDialog = false;
      this.choosenWidget = null;
    },
    hexToRgb(hex, p) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      if (p == "r") {
        return parseInt(result[1], 16);
      } else if (p == "g") {
        return parseInt(result[2], 16);
      } else if (p == "b") {
        return parseInt(result[3], 16);
      }
    },
    selectGroup(group) {
      this.selectedGroup = group;
      this.devices = [];
      this.selectedDevices = [];
      this.deviceLoading = true;
      axios
        .get(
          process.env.VUE_APP_API_ADDR +
            "/api/group/" +
            group.id +
            "/allDevice",
          {
            headers: {
              Authorization: tokenStore.getToken(),
            },
          }
        )
        .then((res) => {
          res.data.devices.forEach((dev) => {
            axios
              .get(process.env.VUE_APP_API_ADDR + "/api/device/" + dev, {
                headers: {
                  Authorization: tokenStore.getToken(),
                },
              })
              .then((res) => {
                res.data.device.lastData.data = JSON.parse(
                  res.data.device.lastData.data
                );
                this.devices.push(res.data.device);
              });
          });
          this.deviceLoading = false;
        });
    },
    selectDevice(device) {
      let i = this.selectedDevices.findIndex((dev) => {
        return dev.id == device.id;
      });
      if (i >= 0) {
        this.selectedDevices.splice(i, 1);
      } else {
        this.selectedDevices.push(device);
      }
    },
    loadAlerts() {
      axios
        .get(process.env.VUE_APP_API_ADDR + "/api/alert/@me", {
          headers: {
            Authorization: tokenStore.getToken(),
          },
        })
        .then((res) => {
          this.alerts = res.data.alerts;
          this.alerts.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    loadGroups() {
      this.groups = [];
      axios
        .get(process.env.VUE_APP_API_ADDR + "/api/group/@me", {
          headers: {
            Authorization: tokenStore.getToken(),
          },
        })
        .then((res) => {
          res.data.groups.forEach((group) => {
            if (group.subGroups.length == 0 && group.devices.length > 0) {
              this.groups.push(group);
            }
          });
          this.groups.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
        });
    },
    customLineChannelFct() {
      this.customLineChannel = !this.customLineChannel;
      this.forceUpdate++;
    },
    customLinkDashboardChannelFct() {
      this.customLinkDashboardChannel = !this.customLinkDashboardChannel;
      this.forceUpdate++;
    },
    customLinkAlertChannelFct() {
      this.customLinkAlertChannel = !this.customLinkAlertChannel;
      this.forceUpdate++;
    },
    customLinkColoredIconChannelFct() {
      this.customLinkColoredIconChannel = !this.customLinkColoredIconChannel;
      this.forceUpdate++;
    },
    changeImg(imgId) {
      this.selectedDashboard.imgId = imgId;
      this.loadImg();
    },
    loadImg() {
      if (this.selectedDashboard.imgId) {
        this.imageWidth = this.selectedDashboard.imgWidth;
        this.imageHeight = this.selectedDashboard.imgHeight;
        this.imageUrl =
          process.env.VUE_APP_API_ADDR +
          "/api/load/" +
          this.selectedDashboard.imgId;
      }
    },
    loadWidgets() {
      axios
        .get(
          process.env.VUE_APP_API_ADDR +
            "/api/dashboard/" +
            this.selectedDashboard.id,
          {
            headers: {
              Authorization: tokenStore.getToken(),
            },
          }
        )
        .then((res) => {
          if (res.data.dashboard.widgets == null) {
            this.items = [];
          } else {
            this.items = res.data.dashboard.widgets;
          }
        });
    },
    updateModelWidget() {
      this.widgetModels[this.radioGroup].mySeries.forEach((e, i) => {
        this.widgetModels[this.radioGroup].mySeries.splice(i, 1, e);
      });
      if (this.radioGroup == 1 || this.radioGroup == 12) {
        // this.widgetModels[this.radioGroup].myData.legend.layout =
        //   this.widgetModels[this.radioGroup].mySeries.length + "x1";
        this.forceUpdate += 1;
      } else if (this.radioGroup == 2) {
        // this.widgetModels[this.radioGroup].myData.legend.layout =
        //   this.widgetModels[this.radioGroup].mySeries.length + "x1";
        this.forceUpdate += 1;
      } else if (this.radioGroup == 4) {
        this.widgetModels[4].myData.plot.slice = this.pieSlice + "%";
        this.forceUpdate += 1;
      }
    },
testUpdate(){
  console.log("laaaaa")
},
    createWidget() {
      let maxY = 0;
      this.items.forEach((item) => {
        if (item.y + item.h > maxY) {
          maxY = item.y + item.h + 1;
        }
      });

      let widget = {
        x: 1920 / 2,
        y: maxY,
        w: 200,
        h: 200,
        name: "" + this.widgetName,
        icon: "" + this.widgetIcon,
        linkedXPath: [],
        i: uuidv4(),
        group: null,
        devices: [],
        type: this.widgetModels[this.radioGroup].type,
        myData: this.widgetModels[this.radioGroup].myData,
        mySeries: this.widgetModels[this.radioGroup].mySeries,
      };
      switch (this.radioGroup) {
        case 1:
          widget.w = 550;
          widget.h = 300;
          widget.range = "day";
          break;
        case 2:
          widget.w = 550;
          widget.h = 300;
          widget.range = "day";
          break;
        case 3:
          widget.w = 550;
          widget.h = 300;
          break;
        case 4:
          break;
        case 8:
          widget.w = 700;
          widget.h = 400;
          break;
        case 12:
          widget.w = 550;
          widget.h = 300;
          break;
        case 13:
          widget.w = 550;
          widget.h = 300;
          break;
      }
      widget.x -= widget.w / 2;
      this.addWidget(widget);
      this.dialog = false;
    },
    addWidget(widget) {
      this.items.push(widget);
      setTimeout(() => {
        if (widget.type != "downlink") {
          this.linkWidget(widget.i);
        }
      }, 1);
    },
    removeWidget(id) {
      this.items.splice(
        this.items.findIndex((e) => {
          return e.i == id;
        }),
        1
      );

      let i = this.requestIntervals.findIndex((json) => {
        return json.widgetId == id;
      });
      if (i != -1) {
        this.requestIntervals[i].intervals.forEach((savedInterval) => {
          clearInterval(savedInterval.interval);
        });
        this.requestIntervals.splice(i, 1);
      }
    },
    editWidget(id) {
      console.log("editingWidget");
      let widget =
        this.items[
          this.items.findIndex((e) => {
            return e.i == id;
          })
        ];

      this.choosenWidget = widget;

      this.tmpSaveWidget = JSON.parse(JSON.stringify(widget));

      this.editWidgetDialog = true;
    },
    linkWidget(id) {
      let widget =
        this.items[
          this.items.findIndex((e) => {
            return e.i == id;
          })
        ];
      this.choosenWidget = widget;
      //this.linkDialog = true;
      this.tmpSaveWidget = JSON.parse(JSON.stringify(widget));
      /* if (this.choosenWidget.group) {
        this.selectGroup(this.choosenWidget.group);
        this.choosenWidget.devices.forEach((dev) => {
          this.selectDevice(dev);
        });
      }*/
      if (
        this.choosenWidget.type == "line" ||
        this.choosenWidget.type == "area" ||
        this.choosenWidget.type == "line2"
      ) {
        for (let i = 0; i < this.choosenWidget.mySeries.length; i++) {
          if (this.choosenWidget.mySeries[i].values.length == 0) {
            this.choosenWidget.mySeries[i].values = [
              [1452412800000, Math.floor(Math.random() * 10)],
              [1452585600000, Math.floor(Math.random() * 10)],
              [1452844800000, Math.floor(Math.random() * 10)],
              [1453276800000, Math.floor(Math.random() * 10)],
              [1454054400000, Math.floor(Math.random() * 10)],
            ];
          }
        }
      }

      this.linkDialogChannel = true;
      if (
        this.choosenWidget.type == "text" ||
        this.choosenWidget.type == "line" ||
        this.choosenWidget.type == "area" ||
        this.choosenWidget.type == "line2"
      ) {
        if (
          this.choosenWidget.myData.inputs[this.currentStep].groupId != null
        ) {
          if (
            this.groups.findIndex((group) => {
              return (
                group.id ==
                this.choosenWidget.myData.inputs[this.currentStep].groupId
              );
            }) >= 0
          ) {
            this.selectedGroupChannel =
              this.choosenWidget.myData.inputs[this.currentStep].groupId;
          }
        }
      } else if (this.choosenWidget.type == "linkDashboard") {
        if (
          this.choosenWidget.myData.inputs[this.currentStep].dashBoardId != null
        ) {
          let index = this.dashBoardList.findIndex((dash) => {
            return (
              dash.id ==
              this.choosenWidget.myData.inputs[this.currentStep].dashBoardId
            );
          });

          if (index >= 0) {
            this.selectedDashboardLink = this.dashBoardList[index];
          }
        }
      } else if (this.choosenWidget.type == "coloredIcon") {
        if (
          this.choosenWidget.myData.inputs[this.currentStep].groupId != null
        ) {
          if (
            this.groups.findIndex((group) => {
              return (
                group.id ==
                this.choosenWidget.myData.inputs[this.currentStep].groupId
              );
            }) >= 0
          ) {
            this.selectedGroupChannel =
              this.choosenWidget.myData.inputs[this.currentStep].groupId;
          }
        }

        if (
          this.choosenWidget.myData.inputs[this.currentStep].dashBoardId != null
        ) {
          let index = this.dashBoardList.findIndex((dash) => {
            return (
              dash.id ==
              this.choosenWidget.myData.inputs[this.currentStep].dashBoardId
            );
          });
          if (index >= 0) {
            this.selectedDashboardLink = this.dashBoardList[index];
          }
        }
      }
    },
    redirectDashboard(dashboardId) {
      this.$emit("redirectDashboard", dashboardId);
    },
    saveEditedWidget() {
      let jsonWidget = {
        x: this.widget.x,
        y: this.widget.y,
        w: this.widget.w,
        h: this.widget.h,
        name: this.widgetName,
        icon: this.widgetIcon,
        i: this.widget.i,
        linkedXPath: this.widget.linkedXPath,
        requestInterval: this.widget.requestInterval,
        type: this.widgetModels[this.radioGroup].type,
        myData: this.widgetModels[this.radioGroup].myData,
        mySeries: this.widgetModels[this.radioGroup].mySeries,
      };

      if (
        jsonWidget.requestInterval != undefined &&
        jsonWidget.myData.inputs.length == jsonWidget.requestInterval.length
      ) {
        jsonWidget.requestInterval.forEach((jsonInterval, i) => {
          jsonInterval.name = jsonWidget.myData.inputs[i].name;
        });
      } else {
        jsonWidget.requestInterval = null;
        jsonWidget.myData.inputs.forEach((input, i) => {
          input.linkedPath = [];
          jsonWidget.mySeries[i].value = "unlinked";
        });
      }

      this.items.splice(
        this.items.findIndex((e) => {
          return e.i == this.widget.i;
        }),
        1,
        jsonWidget
      );

      this.dialog = false;

      let i = this.requestIntervals.findIndex((json) => {
        return json.widgetId == this.widget.i;
      });
      if (i != -1) {
        this.requestIntervals[i].intervals.forEach((savedInterval) => {
          clearInterval(savedInterval.interval);
        });
        this.requestIntervals.splice(i, 1);
      }
    },
    coloredIconWidgetAddCondition(input) {
      input.colorTrue.push(this.$vuetify.theme.themes.dark.success);
      input.condition.push("is True");
      input.conditionValue.push("0");
    },
    coloredIconWidgetRemoveCondition(input, i) {
      input.condition.splice(i, 1);
      input.colorTrue.splice(i, 1);
      input.conditionValue.splice(i, 1);
    },
    addTextInput() {
      this.widgetModels[0].myData.inputs.push({
        name: "Data " + (this.widgetModels[0].myData.inputs.length + 1),
        center: false,
        size: 18,

        groupId: null,
        deviceId: null,
        channel: null,
      });
      this.widgetModels[0].mySeries.push({
        value: "unlinked",
      });
    },
    removeTextInput(input) {
      let i = this.widgetModels[0].myData.inputs.findIndex((e) => {
        return e == input;
      });
      this.widgetModels[0].myData.inputs.splice(i, 1);

      this.widgetModels[0].mySeries.splice(i, 1);
    },
    addInput() {
      var color = "#";
      var letters = "0123456789ABCDEF";
      switch (this.radioGroup) {
        case 1:
          if (this.widgetModels[this.radioGroup].myData.inputs.length < 11) {
            color =
              this.colorsList[
                this.widgetModels[this.radioGroup].myData.inputs.length
              ];
          } else {
            for (let i = 0; i < 6; i++) {
              color += letters[Math.floor(Math.random() * 16)];
            }
          }

          this.widgetModels[this.radioGroup].myData.inputs.push({
            groupId: null,
            deviceId: null,
            channel: null,
          });
          this.widgetModels[this.radioGroup].mySeries.push({
            text:
              "Data " +
              (this.widgetModels[this.radioGroup].mySeries.length + 1),
            linkedPath: [],
            aspect: "spline",
            alpha: 1,
            "line-color": color,
            "line-style": "solid",
            "line-width": 2,
            marker: {
              borderAlpha: 0,
              "background-color": color,
              size: 2,
            },
            values: [
              [1452412800000, Math.floor(Math.random() * 10)],
              [1452585600000, Math.floor(Math.random() * 10)],
              [1452844800000, Math.floor(Math.random() * 10)],
              [1453276800000, Math.floor(Math.random() * 10)],
              [1454054400000, Math.floor(Math.random() * 10)],
            ],
          });
          break;
        case 2:
          if (this.widgetModels[this.radioGroup].myData.inputs.length < 11) {
            color =
              this.colorsList[
                this.widgetModels[this.radioGroup].myData.inputs.length
              ];
          } else {
            for (let i = 0; i < 6; i++) {
              color += letters[Math.floor(Math.random() * 16)];
            }
          }

          this.widgetModels[this.radioGroup].myData.inputs.push({
            groupId: null,
            deviceId: null,
            channel: null,
          });
          this.widgetModels[this.radioGroup].mySeries.push({
            text:
              "Data " +
              (this.widgetModels[this.radioGroup].mySeries.length + 1),
            aspect: "spline",
            alpha: 1,
            "line-color": color,
            "line-style": "solid",
            "line-width": 2,

            "background-color": color /* Single color or gradient (2 colors) */,
            "alpha-area": 0.5 /* Shaded region transparency */,
            marker: {
              borderAlpha: 0,
              "background-color": color,
              size: 2,
            },
            values: [
              [1452412800000, Math.floor(Math.random() * 10)],
              [1452585600000, Math.floor(Math.random() * 10)],
              [1452844800000, Math.floor(Math.random() * 10)],
              [1453276800000, Math.floor(Math.random() * 10)],
              [1454054400000, Math.floor(Math.random() * 10)],
            ],
          });
          break;
        case 3:
          this.widgetModels[this.radioGroup].mySeries.push({
            text: "New bar group",
            aspect: "bar" /*"bar"|| "cone" */,
            "background-color": "#000000" /* Bar fill color (gradient) */,
            alpha: 0.8 /* Transparency (more transparent) */,
            line: false,
            "border-width": 3 /* Border width */,
            "border-color": "" /* Border color */,
            "line-style": "solid" /* Border style */,
            "border-radius": "5",
            "border-alpha": "1",
            values: [
              Math.floor(Math.random() * 10),
              Math.floor(Math.random() * 10),
              Math.floor(Math.random() * 10),
              Math.floor(Math.random() * 10),
              Math.floor(Math.random() * 10),
            ],
          });
          break;
        case 4:
          this.widgetModels[this.radioGroup].mySeries.push({
            values: [Math.floor(Math.random() * 10) + 1],
            "background-color": "#FF0000",
            detached: false,
          });
          break;
        case 12:
          if (this.widgetModels[this.radioGroup].myData.inputs.length < 11) {
            color =
              this.colorsList[
                this.widgetModels[this.radioGroup].myData.inputs.length
              ];
          } else {
            for (let i = 0; i < 6; i++) {
              color += letters[Math.floor(Math.random() * 16)];
            }
          }

          this.widgetModels[this.radioGroup].myData.inputs.push({
            groupId: null,
            deviceId: null,
            channel: null,
          });
          this.widgetModels[this.radioGroup].mySeries.push({
            text:
              "Data " +
              (this.widgetModels[this.radioGroup].mySeries.length + 1),
            linkedPath: [],
            aspect: "spline",
            alpha: 1,
            "line-color": color,
            "line-style": "solid",
            "line-width": 2,
            marker: {
              borderAlpha: 0,
              "background-color": color,
              size: 2,
            },
            values: [
              [1452412800000, Math.floor(Math.random() * 10)],
              [1452585600000, Math.floor(Math.random() * 10)],
              [1452844800000, Math.floor(Math.random() * 10)],
              [1453276800000, Math.floor(Math.random() * 10)],
              [1454054400000, Math.floor(Math.random() * 10)],
            ],
          });
          break;
      }
      this.updateModelWidget();
    },
    removeInput(input) {
      let i = this.widgetModels[this.radioGroup].mySeries.findIndex((e) => {
        return e == input;
      });
      this.widgetModels[this.radioGroup].mySeries.splice(i, 1);

      this.widgetModels[this.radioGroup].myData.inputs.splice(i, 1);
      this.updateModelWidget();
    },
    resetWidgetModels() {
      this.radioGroup = 0;
      this.widgetName = "";
      this.widgetIcon = "";
      this.widget = null;
      this.widgetModels = [
        {
          x: 1401,
          y: 0,
          w: 400,
          h: 400,
          i: uuidv4(),
          type: "text",
          name: "Widget name",
          static: false,
          myData: {
            type: "text",
            inputs: [
              {
                name: "Data 1",
                center: false,
                size: 18,
                linkedPath: [],
                groupId: null,
                deviceId: null,
                channel: null,
              },
              {
                name: "Data 2",
                center: false,
                size: 18,
                linkedPath: [],
                groupId: null,
                deviceId: null,
                channel: null,
              },
            ],
          },
          mySeries: [
            {
              value: "unlinked",
            },
            {
              value: "unlinked",
            },
          ],
        },
        {
          x: 0,
          y: 0,
          w: 400,
          h: 200,
          name: "Widget name",
          static: false,
          i: uuidv4(),
          type: "line",
          linkedXPath: [],
          range: "day",
          myData: {
            type: "line",
            inputs: [
              {
                groupId: null,
                deviceId: null,
                channel: null,
              },
              {
                groupId: null,
                deviceId: null,
                channel: null,
              },
            ],
            /* noData: {
              text: "No data found",
              backgroundColor: "#efefef",
            },*/

            legend: {
              x: "7%",
              minimize: true,
              "max-items": 5,
              overflow: "scroll",
              draggable: true, //automatically adds header
              "drag-handler": "icon",
              header: {
                height: 20,
                "border-left": "1px solid black",
                "border-right": "1px solid black",
                "border-top": "1px solid black",
                "border-bottom": "1px solid black",
              },
              scroll: {
                bar: {
                  "border-left": "1px solid black",
                  "border-right": "1px solid black",
                  "border-top": "1px solid black",
                  "border-bottom": "1px solid black",
                },
                handle: {
                  "border-left": "2px solid black",
                  "border-right": "2px solid black",
                  "border-top": "2px solid black",
                  "border-bottom": "2px solid black",
                  "border-radius": "15px",
                },
              },
            },
            gui: {
              behaviors: [
                {
                  id: "ViewSource",
                  enabled: "none",
                },
                {
                  id: "ViewDataTable",
                  enabled: "none",
                },
                {
                  id: "Reload",
                  enabled: "none",
                },
              ],
            },
            "scale-x": {
              zooming: true,
              transform: {
                type: "date",
                all: "%d/%m/%y<br>%H:%i",
              },
            },
            "scale-y": {
              zooming: true,
              //minValue: 0,
            },

            "crosshair-x": {
              exact: true,
              "scale-label": {
                transform: {
                  type: "date",
                  all: "%d/%m/%y<br>%H:%i",
                },
              },
            },
            plotarea: {
              margin: "dynamic",
              adjustLayout: true,
            },
            plot: {
              tooltip: {
                visible: false,
              },
              hintTs: true, // tells the library you have timestamps as keys and activates a small optimization technique
              exact: false,
              smartSampling: true,
            },
          },
          mySeries: [
            {
              text: "Data 1",
              linkedPath: [],

              aspect: "spline",
              alpha: 1,
              /* "segmented" | "stepped" | "jumped" */
              "line-color": "#000000" /* hexadecimal or RGB value */,
              "line-style": "solid" /* "solid" | "dotted" */,
              "line-width": 2 /* in pixels */,
              marker: {
                borderAlpha: 0,
                "background-color": "#000000" /* hexadecimal or RGB value */,
                size: 2 /* in pixels */,
              },
              values: [
                [1452412800000, Math.floor(Math.random() * 10)],
                [1452585600000, Math.floor(Math.random() * 10)],
                [1452844800000, Math.floor(Math.random() * 10)],
                [1453276800000, Math.floor(Math.random() * 10)],
                [1454054400000, Math.floor(Math.random() * 10)],
              ],
            },
            {
              text: "Data 2",
              linkedPath: [],
              aspect: "spline",
              alpha: 1,
              /* "segmented"| "spline" | "stepped" | "jumped" */
              "line-color": "#FF0000" /* hexadecimal or RGB value */,
              "line-style": "solid" /* "solid" | "dotted" */,
              "line-width": 2 /* in pixels */,
              marker: {
                borderAlpha: 0,
                "background-color": "#FF0000" /* hexadecimal or RGB value */,
                size: 2 /* in pixels */,
              },
              values: [
                [1452412800000, Math.floor(Math.random() * 10)],
                [1452585600000, Math.floor(Math.random() * 10)],
                [1452844800000, Math.floor(Math.random() * 10)],
                [1453276800000, Math.floor(Math.random() * 10)],
                [1454054400000, Math.floor(Math.random() * 10)],
              ],
            },
          ],
        },
        {
          x: 0,
          y: 0,
          w: 400,
          h: 200,

          name: "Widget name",
          i: uuidv4(),
          type: "area",

          static: false,

          range: "day",
          linkedXPath: [],
          myData: {
            type: "area",

            inputs: [
              {
                groupId: null,
                deviceId: null,
                channel: null,
              },
              {
                groupId: null,
                deviceId: null,
                channel: null,
              },
            ],
            legend: {
              x: "7%",
              minimize: true,
              "max-items": 5,
              overflow: "scroll",
              draggable: true, //automatically adds header
              "drag-handler": "icon",
              header: {
                height: 20,
                "border-left": "1px solid black",
                "border-right": "1px solid black",
                "border-top": "1px solid black",
                "border-bottom": "1px solid black",
              },
              scroll: {
                bar: {
                  "border-left": "1px solid black",
                  "border-right": "1px solid black",
                  "border-top": "1px solid black",
                  "border-bottom": "1px solid black",
                },
                handle: {
                  "border-left": "2px solid black",
                  "border-right": "2px solid black",
                  "border-top": "2px solid black",
                  "border-bottom": "2px solid black",
                  "border-radius": "15px",
                },
              },
            },

            "scale-x": {
              zooming: true,
              transform: {
                type: "date",
                all: "%d/%m/%y %H:%i",
              },
            },
            "scale-y": {
              zooming: true,
              //minValue: 0,
            },
            plotarea: {
              margin: "dynamic",
              adjustLayout: true,
            },
            "crosshair-x": {
              ok: true,
            },
            plot: {
              maxNodes: 10000,
              maxTrackers: 10000,
            },
          },
          mySeries: [
            {
              text: "Data 1",
              aspect: "spline",
              alpha: 1,
              /* "segmented" | "stepped" | "jumped" */
              "line-color": "#000000" /* hexadecimal or RGB value */,
              "line-style": "solid" /* "solid" | "dotted" */,
              "line-width": 2 /* in pixels */,
              "background-color":
                "#000000" /* Single color or gradient (2 colors) */,
              "alpha-area": 0.5 /* Shaded region transparency */,
              marker: {
                borderAlpha: 0,
                "background-color": "#000000" /* hexadecimal or RGB value */,
                size: 2 /* in pixels */,
              },
              values: [
                [1452412800000, Math.floor(Math.random() * 10)],
                [1452585600000, Math.floor(Math.random() * 10)],
                [1452844800000, Math.floor(Math.random() * 10)],
                [1453276800000, Math.floor(Math.random() * 10)],
                [1454054400000, Math.floor(Math.random() * 10)],
              ],
            },
            {
              text: "Data 2",
              aspect: "spline",
              alpha: 1,
              /* "segmented" | "stepped" | "jumped" */
              "line-color": "#FF0000" /* hexadecimal or RGB value */,
              "line-style": "solid" /* "solid" | "dotted" */,
              "line-width": 2 /* in pixels */,
              "background-color":
                "#FF0000" /* Single color or gradient (2 colors) */,
              "alpha-area": 0.5 /* Shaded region transparency */,
              marker: {
                borderAlpha: 0,
                "background-color": "#FF0000" /* hexadecimal or RGB value */,
                size: 2 /* in pixels */,
              },
              values: [
                [1452412800000, Math.floor(Math.random() * 10)],
                [1452585600000, Math.floor(Math.random() * 10)],
                [1452844800000, Math.floor(Math.random() * 10)],
                [1453276800000, Math.floor(Math.random() * 10)],
                [1454054400000, Math.floor(Math.random() * 10)],
              ],
            },
          ],
        },
        {
          x: 0,
          y: 0,
          w: 400,
          h: 200,
          name: "Widget name",
          static: false,
          i: uuidv4(),
          type: "bar",
          myData: {
            type: "bar",
            plotarea: {
              marginTop: "10%",
              marginLeft: "8%",
              marginRight: "8%",
              marginBottom: "20%",
            },
          },
          mySeries: [
            {
              text: "Bar group 1",
              aspect: "bar" /*"bar"|| "cone" */,
              "background-color": "#B71818" /* Bar fill color (gradient) */,
              alpha: 1 /* Transparency (more transparent) */,
              line: false,
              "border-width": 3 /* Border width */,
              "border-color": "" /* Border color */,
              "line-style": "solid" /* Border style */,
              "border-radius": "5",
              "border-alpha": "1",
              values: [1, 2, 4, 5, 6],
            },
            {
              text: "Bar group 2",
              aspect: "bar" /*"bar"|| "cone" */,
              "background-color": "#6666FF" /* Bar fill color (gradient) */,
              alpha: 0.3 /* Transparency (more transparent) */,
              line: false,
              "border-width": 3 /* Border width */,
              "border-color": "" /* Border color */,
              "line-style": "solid" /* Border style */,
              "border-radius": "5",
              "border-alpha": "1",
              values: [3, 2, 8, 4, 2],
            },
          ],
        },
        {
          x: 401,
          y: 0,
          w: 400,
          h: 200,
          name: "Widget name",
          static: false,

          i: uuidv4(),
          type: "pie",
          myData: {
            type: "pie",
            plot: {
              pieTransform: "", //'fold=20', 'bite=20' | 'callout=20' | 'flow=20' | 'fold=20' | 'droplet' | 'flat'
              slice: "0%",
              line: false,
              box: true,
              "border-width": 3,
              "border-color": "",
              "line-style": "solid",
              "value-box": {
                "font-size": 10,
                "font-weight": "normal",
                placement: "out",
              },
            },
          },
          mySeries: [
            { values: [1], "background-color": "#cc0000", detached: false },
            { values: [2], "background-color": "#FF8100", detached: false },
            { values: [3], "background-color": "#FFD600", detached: false },
            { values: [4], "background-color": "#43FF00", detached: false },
            { values: [5], "background-color": "#00FFA9", detached: false },
            { values: [6], "background-color": "#00EAFF", detached: false },
          ],
        },

        {
          x: 401,
          y: 0,
          w: 400,
          h: 200,
          name: "Widget name",
          i: uuidv4(),
          type: "gauge",
          myData: {
            type: "gauge",
            plot: {
              size: "100%",
              valueBox: {
                placement: "center",
                text: "%v", //default
                fontSize: 10,
                rules: [
                  {
                    rule: "%v >= 700",
                    text: "%v<br>EXCELLENT",
                  },
                  {
                    rule: "%v < 700 && %v > 640",
                    text: "%v<br>Good",
                  },
                  {
                    rule: "%v < 640 && %v > 580",
                    text: "%v<br>Fair",
                  },
                  {
                    rule: "%v <  580",
                    text: "%v<br>Bad",
                  },
                ],
              },
            },
            tooltip: {
              borderRadius: 5,
            },
            scaleR: {
              aperture: 180,
              minValue: 300,
              maxValue: 850,
              step: 50,
              center: {
                visible: false,
              },
              tick: {
                visible: false,
              },
              item: {
                offsetR: 0,
                rules: [
                  {
                    rule: "%i == 9",
                    offsetX: 15,
                  },
                ],
              },
              labels: [
                "300",
                "",
                "",
                "",
                "",
                "",
                "580",
                "640",
                "700",
                "750",
                "",
                "850",
              ],
              ring: {
                size: 50,
                rules: [
                  {
                    rule: "%v <= 580",
                    backgroundColor: "#E53935",
                  },
                  {
                    rule: "%v > 580 && %v < 640",
                    backgroundColor: "#EF5350",
                  },
                  {
                    rule: "%v >= 640 && %v < 700",
                    backgroundColor: "#FFA726",
                  },
                  {
                    rule: "%v >= 700",
                    backgroundColor: "#29B6F6",
                  },
                ],
              },
            },
          },
          mySeries: [
            {
              values: [755], // starting value
              backgroundColor: "black",
              indicator: [10, 10, 10, 10, 0.75],
              animation: {
                effect: 2,
                method: 1,
                sequence: 4,
                speed: 900,
              },
            },
          ],
        },
        {
          x: 1401,
          y: 0,
          w: 400,
          h: 400,
          i: uuidv4(),
          type: "coloredIcon",
          name: "Widget name",
          static: false,
          transparent: false,
          myData: {
            type: "coloredIcon",
            inputs: [
              {
                icon: "icon-circle",
                fontColor: "#000000FF",
                colorTrue: [this.$vuetify.theme.themes.dark.success],
                colorFalse: this.$vuetify.theme.themes.dark.error,
                size: 250,
                name: "Status Widget",
                linkedPath: [],
                fontSize: 16,
                showUpdateTime: false,
                textPosition: "top",
                condition: ["is True"],
                conditionValue: [0],
                linkToADashboard: false,
                dashBoardName: null,
                dashBoardId: null,
                groupId: null,
                deviceId: null,
                channel: null,
              },
            ],
          },
          mySeries: [
            {
              value: {
                value: true,
                updateTime: new Date(Date.now()).toLocaleString(),
              },
            },
          ],
        },
        {
          x: 1401,
          y: 0,
          w: 400,
          h: 400,
          i: uuidv4(),
          type: "Alert",
          name: "Widget name",
          static: false,
          myData: {
            type: "Alert",

            inputs: [
              {
                icon: "icon-circle",
                size: 250,
                name: "Alert",
                linkedPath: [],
                fontSize: 16,
                fontColor: "#000000FF",
                textPosition: "right",
                transparent: false,
                groupId: null,
                deviceId: null,
                channel: null,
              },
            ],
          },
          mySeries: [
            {
              groupName: "groupName",
              alertName: "alertName",
              alertId: "alertId",
              dashboardName: null,
              dashboardId: null,
              active: true,
              state: "none",
              updateTime: new Date(Date.now()).toLocaleString(),
            },
          ],
        },
        {
          x: 1401,
          y: 0,
          w: 400,
          h: 400,
          i: uuidv4(),
          type: "map",
          name: "Widget name",
          myData: {
            type: "map",
            inputs: [
              {
                name: "Map",
                groupId: null,
                deviceId: null,
                channel: null,
              },
            ],
          },
          mySeries: [[0, 0]],
        },
        {
          x: 1401,
          y: 0,
          w: 400,
          h: 400,
          i: uuidv4(),
          type: "linkDashboard",
          name: "Widget name",
          static: false,
          transparent: false,
          myData: {
            type: "linkDashboard",
            inputs: [
              {
                name: "choosenName",
                style: "default",
                color: "#006fb7",
                fontColor: "#FFFFFFFF",
                width: "",
                height: "",
                dashBoardId: null,
                dashBoardName: null,
              },
            ],
          },
          mySeries: [
            {
              dashBoardName: "dashBoardName",
              dashBoardId: "dashBoardId",
            },
          ],
        },
        {
          x: 1401,
          y: 0,
          w: 400,
          h: 400,
          i: uuidv4(),
          type: "image",
          name: "Widget name",
          static: false,
          transparent: false,
          myData: {
            type: "image",
            inputs: [
              {
                imageUrl:
                  "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/640px-Image_created_with_a_mobile_phone.png",
              },
            ],
          },
          mySeries: [{}],
        },
        {
          x: 1401,
          y: 0,
          w: 400,
          h: 400,
          i: uuidv4(),
          type: "downlink",
          name: "Widget name",
          static: false,
          transparent: false,
          myData: {
            type: "downlink",
            inputs: [
              {
                name: "Downlink",
                type: 0, //0 : mqtt // 1 : POST
                url: "",
                port: "",
                topic: "",
                username: "",
                password: "",
                body: {},
                authentification: "",
                downlinkAck: false,
              },
            ],
          },
          mySeries: [{}],
        },
        {
          x: 0,
          y: 0,
          w: 400,
          h: 200,
          name: "Widget name",
          static: false,
          i: uuidv4(),
          type: "line2",
          linkedXPath: [],
          myData: {
            type: "line",
            dateType: "day",
            date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
              .toISOString()
              .substr(0, 10),
            inputs: [
              {
                groupId: null,
                deviceId: null,
                channel: null,
              },
              {
                groupId: null,
                deviceId: null,
                channel: null,
              },
            ],
            /* noData: {
              text: "No data found",
              backgroundColor: "#efefef",
            },*/

            legend: {
              x: "7%",
              minimize: true,
              "max-items": 5,
              overflow: "scroll",
              draggable: true, //automatically adds header
              "drag-handler": "icon",
              header: {
                height: 20,
                "border-left": "1px solid black",
                "border-right": "1px solid black",
                "border-top": "1px solid black",
                "border-bottom": "1px solid black",
              },
              scroll: {
                bar: {
                  "border-left": "1px solid black",
                  "border-right": "1px solid black",
                  "border-top": "1px solid black",
                  "border-bottom": "1px solid black",
                },
                handle: {
                  "border-left": "2px solid black",
                  "border-right": "2px solid black",
                  "border-top": "2px solid black",
                  "border-bottom": "2px solid black",
                  "border-radius": "15px",
                },
              },
            },
            gui: {
              behaviors: [
                {
                  id: "ViewSource",
                  enabled: "none",
                },
                {
                  id: "ViewDataTable",
                  enabled: "none",
                },
                {
                  id: "Reload",
                  enabled: "none",
                },
              ],
            },
            "scale-x": {
              zooming: true,
              transform: {
                type: "date",
                all: "%d/%m/%y<br>%H:%i",
              },
            },
            "scale-y": {
              zooming: true,
              //minValue: 0,
            },

            "crosshair-x": {
              exact: true,
              "scale-label": {
                transform: {
                  type: "date",
                  all: "%d/%m/%y<br>%H:%i",
                },
              },
            },
            plotarea: {
              margin: "dynamic",
              adjustLayout: true,
            },
            plot: {
              tooltip: {
                visible: false,
              },
              hintTs: true, // tells the library you have timestamps as keys and activates a small optimization technique
              exact: false,
              smartSampling: true,
            },
          },
          mySeries: [
            {
              text: "Data 1",
              linkedPath: [],

              aspect: "spline",
              alpha: 1,
              /* "segmented" | "stepped" | "jumped" */
              "line-color": "#000000" /* hexadecimal or RGB value */,
              "line-style": "solid" /* "solid" | "dotted" */,
              "line-width": 2 /* in pixels */,
              marker: {
                borderAlpha: 0,
                "background-color": "#000000" /* hexadecimal or RGB value */,
                size: 2 /* in pixels */,
              },
              values: [
                [1452412800000, Math.floor(Math.random() * 10)],
                [1452585600000, Math.floor(Math.random() * 10)],
                [1452844800000, Math.floor(Math.random() * 10)],
                [1453276800000, Math.floor(Math.random() * 10)],
                [1454054400000, Math.floor(Math.random() * 10)],
              ],
            },
            {
              text: "Data 2",
              linkedPath: [],
              aspect: "spline",
              alpha: 1,
              /* "segmented"| "spline" | "stepped" | "jumped" */
              "line-color": "#FF0000" /* hexadecimal or RGB value */,
              "line-style": "solid" /* "solid" | "dotted" */,
              "line-width": 2 /* in pixels */,
              marker: {
                borderAlpha: 0,
                "background-color": "#FF0000" /* hexadecimal or RGB value */,
                size: 2 /* in pixels */,
              },
              values: [
                [1452412800000, Math.floor(Math.random() * 10)],
                [1452585600000, Math.floor(Math.random() * 10)],
                [1452844800000, Math.floor(Math.random() * 10)],
                [1453276800000, Math.floor(Math.random() * 10)],
                [1454054400000, Math.floor(Math.random() * 10)],
              ],
            },
          ],
        },
        {
          x: 0,
          y: 0,
          w: 400,
          h: 200,
          name: "Widget name",
          static: false,
          i: uuidv4(),
          type: "reflectogram",
          linkedXPath: [],
          myData: {
            type: "line",
            inputs: [
              {
                groupId: null,
                deviceId: null,
                channel: null,
                p1: 1,
                p2: 1,
                p3: 1,
              }
            ],
            /* noData: {
              text: "No data found",
              backgroundColor: "#efefef",
            },*/

            legend: {
              x: "7%",
              minimize: true,
              "max-items": 5,
              overflow: "scroll",
              draggable: true, //automatically adds header
              "drag-handler": "icon",
              header: {
                height: 20,
                "border-left": "1px solid black",
                "border-right": "1px solid black",
                "border-top": "1px solid black",
                "border-bottom": "1px solid black",
              },
              scroll: {
                bar: {
                  "border-left": "1px solid black",
                  "border-right": "1px solid black",
                  "border-top": "1px solid black",
                  "border-bottom": "1px solid black",
                },
                handle: {
                  "border-left": "2px solid black",
                  "border-right": "2px solid black",
                  "border-top": "2px solid black",
                  "border-bottom": "2px solid black",
                  "border-radius": "15px",
                },
              },
            },
            gui: {
              behaviors: [
                {
                  id: "ViewSource",
                  enabled: "none",
                },
                {
                  id: "ViewDataTable",
                  enabled: "none",
                },
                {
                  id: "Reload",
                  enabled: "none",
                },
              ],
            },
            "scale-x": {
              zooming: true,

            },
            "scale-y": {
              zooming: true,
              //minValue: 0,
            },

            "crosshair-x": {
              exact: true,

            },
            plotarea: {
              margin: "dynamic",
              adjustLayout: true,
            },
            plot: {
              tooltip: {
                visible: false,
              },
              hintTs: true, // tells the library you have timestamps as keys and activates a small optimization technique
              exact: false,
              smartSampling: true,
            },
          },
          mySeries: [
            {
              text: "Data 1",
              linkedPath: [],

              aspect: "spline",
              alpha: 1,
              /* "segmented" | "stepped" | "jumped" */
              "line-color": "#000000" /* hexadecimal or RGB value */,
              "line-style": "solid" /* "solid" | "dotted" */,
              "line-width": 2 /* in pixels */,
              marker: {
                borderAlpha: 0,
                "background-color": "#000000" /* hexadecimal or RGB value */,
                size: 2 /* in pixels */,
              },
              values: [

              ],
            }
          ],
        }
      ];
    },

    handleResize() {
      this.gridWidth = this.$refs.gridRef.$el.scrollWidth;
      this.K =
        window.screen.availWidth - (window.outerWidth - window.innerWidth);
      this.N =
        this.$parent.$parent.$parent.$parent.$refs.navigation.$el.scrollWidth;
      this.S = window.innerWidth - document.body.offsetWidth;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  mounted() {
    this.handleResize();
    this.cancelRequest = axios.CancelToken.source();
    setTimeout(() => {
      //this.loadWidgets();

      if (this.selectedDashboard.widgets == null) {
        this.items = [];
      } else {
        this.items = this.selectedDashboard.widgets;
      }
    }, 200);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped>
@import "https://cdn.icomoon.io/208069/SicameReference/style.css?hwbvnm";

.selected {
  border: 3px solid #006fb7 !important;
  background-color: rgb(0, 111, 183, 0.1) !important;
  cursor: pointer !important;
}
.unselected {
  border: 3px solid rgb(255, 255, 255) !important;
  cursor: pointer !important;
}
</style>