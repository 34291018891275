<template>
  <v-dialog v-model="dialog">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        icon
        small
        v-bind="attrs"
        v-on="on"
        @click="loadData"
      >
        <v-icon small>mdi-arrow-collapse-down</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="headline secondary"
        >Data : {{ item.name }}</v-card-title
      >
      <v-card-text>
        <v-row align="center">
          <v-col cols="2">
            <v-checkbox label="Group By Channel" v-model="groupBy"
              >test</v-checkbox
            >
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="9">
            <v-card class="mt-2 mb-2 mr-3"
              ><v-row align="center" justify="center" no-gutters>
                <v-col cols="2" class="pl-5">
                  <v-radio-group mandatory v-model="radio">
                    <v-radio label="Last N Data" value="1"></v-radio>
                    <v-radio label="Specific Day" value="2"></v-radio>
                    <!-- <v-radio label="Use Range" value="3"></v-radio> -->
                  </v-radio-group>
                </v-col>
                <v-spacer></v-spacer>
                <v-col :cols="radio == 1 ? 2 : 4">
                  <template v-if="radio == 1">
                    <v-text-field
                      v-model="numberValue"
                      hide-details
                      label="Limit"
                      type="number"
                      max="50000"
                      min="1"
                      outlined
                      dense
                      @blur="checkNumberInput"
                    />
                  </template>
                  <template v-if="radio == 2">
                    <v-row>
                      <v-col>
                        <v-menu
                          v-model="menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="picker"
                              prepend-icon="mdi-calendar"
                              label="Date"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="picker"
                            @input="menu = false"
                          ></v-date-picker> </v-menu
                      ></v-col>
                      <v-col>
                        <v-menu
                          ref="menu"
                          v-model="menu2"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="time"
                              label="Beginning"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="menu2"
                            v-model="time"
                            use-seconds
                            full-width
                            format="24hr"
                          ></v-time-picker> </v-menu></v-col></v-row
                  ></template>
                </v-col>
                <v-col cols="2" class="pl-5">
                  <v-text-field
                    v-model="requestChannel"
                    hide-details
                    outlined
                    dense
                    label="Channel"
                  ></v-text-field>
                </v-col>
                <v-col cols="2" class="pl-5">
                  <v-btn color="primary" text @click="refreshData">
                    Update
                    <v-icon class="ml-1 mb-1">mdi-cached</v-icon>
                  </v-btn></v-col
                >
                <v-col cols="2" class="text-center">
                  <v-btn
                    v-if="!exporting"
                    color="primary"
                    text
                    @click="exportData"
                  >
                    Export
                    <v-icon class="ml-1 mb-1">mdi-arrow-collapse-down</v-icon>
                  </v-btn>
                  <v-progress-circular
                    v-else
                    :value="100 - exportingCoeff * exportingCooldown"
                    color="primary"
                  ></v-progress-circular>
                </v-col> </v-row
            ></v-card>
          </v-col>
        </v-row>

        <v-card elevation="0" outlined>
          <v-data-table
            fixed-header
            height="60vh"
            :headers="headers"
            :items="groupByArray"
            :items-per-page="itemPerPage"
            :footer-props="{ 'items-per-page-options': [100, 1000, 5000] }"
            :loading="dataLoading"
            dense
            :search="search"
            :group-by="groupBy ? ['channel', 'channelName'] : null"
            @update:options="updatePerPage"
            ref="table"
          >
            <template v-slot:item="{ item }">
              <tr
                style="
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: normal;
                "
              >
                <td v-if="!groupBy">
                  {{ item.channel }}
                </td>
                <td v-if="!groupBy">
                  {{ item.channelName }}
                </td>
                <td>
                  {{ new Date(item.time).toLocaleString() }}
                </td>
                <template v-if="item.channelName == 'default'">
                  <td>
                    {{ item.data }}
                  </td>
                  <td></td
                ></template>

                <template v-else>
                  <td>{{ item.data.value }}</td>
                  <td>
                    {{ new Date(item.data.updateTime).toLocaleString() }}
                  </td></template
                >
              </tr>
            </template>
            <template
              v-slot:[`group.header`]="{ group, headers, toggle, isOpen }"
            >
              <td :colspan="headers.length">
                <v-btn
                  @click="toggle"
                  small
                  icon
                  :ref="group"
                  :data-open="isOpen"
                >
                  <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
                  <v-icon v-else>mdi-chevron-down</v-icon>
                </v-btn>
                {{ group }} : {{ channelList[group].name }}
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false">
          Exit
        </v-btn></v-card-actions
      >
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import tokenStore from "../store/TokenStore";

import channelList from "../channelList.json";

export default {
  data: () => ({
    dialog: false,
    headers: [
      {
        text: "Channel Id",
        sortable: true,
        value: "channel",
        align: "start",
        groupable: true,
      },
      {
        text: "Channel Name",
        sortable: true,
        value: "channelName",
        align: "start",
        groupable: true,
      },

      {
        text: "Last Communication Recevied From Server",
        value: "time",
        sortable: true,
        align: "start",
        filterable: false,
      },

      {
        text: "Valeur",
        value: "data.value",
        sortable: true,
        align: "start",
        filterable: false,
      },

      {
        text: "Object Timestamp",
        value: "data.updateTime",
        sortable: true,
        align: "start",
        filterable: false,
      },
    ],
    itemPerPage: 100,
    data: [],
    dataLoading: false,
    search: "",
    groupBy: false,
    channelList: channelList,
    radio: 1,
    numberValue: 100,
    requestChannel: "",
    picker: new Date().toISOString().split("T")[0],
    menu: false,
    time: "00:00:00",
    menu2: false,
    exporting: false,
    exportingLoadingInterval: null,
    exportingCooldown: 0,
    exportingCoeff: 0,
  }),
  props: {
    item: Object,
  },
  computed: {
    timestamp: {
      get() {
        return new Date(this.picker + "T" + this.time + ".000Z").getTime();
      },
    },

    groupByArray: {
      get() {
        if (this.groupBy) {
          let arr = [...this.data].slice(0, this.itemPerPage);
          return arr;
        } else {
          return this.data;
        }
      },
    },
  },

  methods: {
    exportData() {
      this.exporting = true;
      this.exportingCooldown = 5;
      this.exportingCoeff = 100 / this.exportingCooldown;
      this.exportingLoadingInterval = setInterval(() => {
        this.exportingCooldown -= 1;
        if (this.exportingCooldown <= 0) {
          this.exporting = false;
          clearInterval(this.exportingLoadingInterval);
          this.exportingLoadingInterval = null;
        }
      }, 1000);
      let str = "channel; channelName; value; time; ";
      str += "\r\n";

      this.data.forEach((json) => {
        str +=
          json.channel +
          "; " +
          json.channelName +
          "; " +
          (typeof json.data.value === "object" &&
          !Array.isArray(json.data.value) &&
          json.data.value !== null
            ? JSON.stringify(json.data.value)
            : json.data.value) +
          "; " +
          json.data.updateTime +
          "; ";

        str += "\n\r";
      });

      str += "\n\r";
      this.downloadCSV(
        "csvExport_" +
          this.item.name +
          "_" +
          (new Date().toLocaleDateString() +
            "_" +
            new Date().toLocaleTimeString().replaceAll(":", "")) +
          ".csv",
        str
      );
    },
    downloadCSV(filename, text) {
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/csv;charset=utf-8," + encodeURIComponent(text)
      );
      element.setAttribute("download", filename);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },
    updatePerPage(value) {
      this.itemPerPage = value.itemsPerPage;
    },
    checkNumberInput() {
      if (isNaN(this.numberValue) || this.numberValue == "") {
        this.numberValue = 100;
      } else {
        if (!Number.isInteger(this.numberValue)) {
          this.numberValue = parseInt(this.numberValue);
        }
        if (this.numberValue > 10000) {
          this.numberValue = 10000;
        }
        if (this.numberValue < 1) {
          this.numberValue = 1;
        }
      }
    },
    refreshData() {
      this.loadData();
    },
    loadData() {
      if (this.radio == 1) {
        //limit = N = numberValue
        this.data = [];
        this.dataLoading = true;
        axios
          .get(
            process.env.VUE_APP_API_ADDR +
              "/api/device/" +
              this.item.id +
              "/data?limit=" +
              this.numberValue +
              "&channel=" +
              this.requestChannel,
            {
              headers: {
                Authorization: tokenStore.getToken(),
              },
            }
          )
          .then((res) => {
            res.data.data.forEach((d) => {
              try {
                d.data = JSON.parse(d.data);
                d.channelName = channelList[d.channel].name;
                this.data.push(d);
              } catch {
                //
              }
            });

            this.dataLoading = false;
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (this.radio == 2) {
        //day
        this.data = [];
        this.dataLoading = true;
        let date1 = new Date(this.timestamp);
        let date2 = new Date(this.picker + "T23:59:59.999Z");
        axios
          .get(
            process.env.VUE_APP_API_ADDR +
              "/api/device/" +
              this.item.id +
              "/data/query?minDate=" +
              (date1.getTime() + date1.getTimezoneOffset() * 60000) +
              "&maxDate=" +
              (date2.getTime() + date2.getTimezoneOffset() * 60000) +
              "&channel=" +
              this.requestChannel,
            {
              headers: {
                Authorization: tokenStore.getToken(),
              },
            }
          )
          .then((res) => {
            console.log(res.data.data.length);
            res.data.data.forEach((d) => {
              try {
                d.data = JSON.parse(d.data);
                d.channelName = channelList[d.channel].name;
                this.data.push(d);
              } catch {
                //
              }
            });

            this.dataLoading = false;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
  mounted() {},
};
</script>


