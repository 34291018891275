var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.selectedDashboard == null)?_c('v-main',[(!_vm.isRedirecting)?_c('v-row',{staticClass:"ml-5 mr-5",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"9"}},[_c('v-card',[_c('v-card-title',{staticClass:"pt-0 pb-0",staticStyle:{"border-bottom":"1px solid #e0e0e0"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"2"}},[_vm._v("Dashboard")]),_c('v-col',{attrs:{"cols":"2"}},[_c('v-dialog',{attrs:{"max-width":"500"},scopedSlots:_vm._u([(!_vm.isRedirecting)?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" Create ")])]}}:null],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[(_vm.editingDashboard == null)?_c('v-card-title',{staticClass:"headline secondary"},[_vm._v(" Create Dashboard ")]):_c('v-card-title',{staticClass:"headline secondary"},[_vm._v(" Edit Dashboard ")]),_c('v-card-text',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Dashboard Name","clearable":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"auto-grow":"","clearable":"","rows":"1","clear-icon":"mdi-close-circle","label":"Description"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),(_vm.editingDashboard == null)?_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12"}},[_c('v-radio-group',{model:{value:(_vm.checkbox),callback:function ($$v) {_vm.checkbox=$$v},expression:"checkbox"}},_vm._l(([
                              { i: 1, label: 'No preset' },
                              { i: 2, label: 'Personal preset' },
                              { i: 3, label: 'Default preset' } ]),function(item){return _c('v-radio',{key:item.i,attrs:{"label":item.label,"value":item.i}})}),1)],1):_vm._e(),(_vm.editingDashboard == null && _vm.checkbox == 2)?_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12"}},[(_vm.presets.length > 0)?[_c('v-select',{attrs:{"items":_vm.presets,"item-text":"name","item-value":"id","label":"Preset","clearable":""},model:{value:(_vm.choosenPreset),callback:function ($$v) {_vm.choosenPreset=$$v},expression:"choosenPreset"}})]:[_vm._v(" No preset registred")]],2):_vm._e(),(_vm.editingDashboard == null && _vm.checkbox == 3)?_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12"}},[(_vm.defaultPresets.length > 0)?[_c('v-select',{attrs:{"items":_vm.defaultPresets,"item-text":"name","item-value":"id","label":"Default preset","clearable":""},model:{value:(_vm.choosenPreset),callback:function ($$v) {_vm.choosenPreset=$$v},expression:"choosenPreset"}})]:[_vm._v(" No default preset registred")]],2):_vm._e(),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[(_vm.editingDashboard != null)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.duplicateDashboard}},[_vm._v(" Duplicate ")]):_vm._e()],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function () {
                          _vm.dialog = false;
                          _vm.name = '';
                          _vm.description = '';
                          _vm.editingDashboard = null;
                          _vm.choosenPreset = null;
                          _vm.checkbox = 1;
                        }}},[_vm._v(" Cancel ")]),_c('v-spacer'),(_vm.editingDashboard == null)?_c('v-btn',{attrs:{"color":"primary","text":"","disabled":_vm.name.length < 3},on:{"click":_vm.save}},[_vm._v(" Save ")]):_vm._e(),(_vm.editingDashboard != null)?_c('v-btn',{attrs:{"disabled":_vm.name.length < 3,"color":"primary","text":""},on:{"click":_vm.saveEditing}},[_vm._v(" Save ")]):_vm._e()],1)],1)],1)],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-card-text',{staticClass:"pl-0 pr-0",staticStyle:{"height":"74.1vh"}},[(_vm.dashboardLoading)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):_vm._e(),_c('v-virtual-scroll',{attrs:{"items":_vm.sortedDashboards.filter(function (dash) {
                return (
                  dash.id.toLowerCase().includes(this$1.search.toLowerCase()) ||
                  dash.name.toLowerCase().includes(this$1.search.toLowerCase())
                );
              }),"item-height":135},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var item = ref.item;
return [_c('v-card',{staticClass:"mt-3 mb-3 mr-3 ml-3",attrs:{"outlined":"","ripple":false},on:{"click":function($event){return _vm.selectDashboard(item)}}},[_c('v-app-bar',{staticClass:"pt-1",attrs:{"flat":"","dense":"","color":"rgba(0, 0, 0, 0)"}},[_c('v-toolbar-title',{staticClass:"title pl-0"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.chooseEditDashboard(item)}}},[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1),_c('confirm',{attrs:{"type":"dashboard","item":item},on:{"removeConfirmed":function($event){return _vm.removeDashboard(item)}}})],1),_c('v-card-subtitle',{staticClass:"mb-0 pt-0"},[_c('div',[_vm._v(_vm._s(item.id))]),_c('div',[_vm._v("Active widgets : "+_vm._s(item.widgets.length))])]),_c('v-card-text',[_c('div',[_vm._v(_vm._s(item.description))])])],1)]}}],null,false,400297268)})],1)],1)],1)],1):_vm._e()],1):_c('v-main',[_c('router-view',{attrs:{"selectedDashboard":_vm.selectedDashboard,"dashBoardList":_vm.dashboards},on:{"previous":_vm.previous,"redirectDashboard":_vm.redirectDashboard}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }