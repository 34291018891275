<template>
  <v-main>
    <div style="height: 700px">
      <mapcomp :markers="markers"></mapcomp>
    </div>
  </v-main>
</template>

<script>
import MapComponent from "./MapComponent";
import axios from "axios";

import tokenStore from "../store/TokenStore";
export default {
  components: {
    mapcomp: MapComponent,
  },
  data() {
    return {
      devices: [],
      markers:[]
    };
  },
  beforeMount() {
    axios
      .get(process.env.VUE_APP_API_ADDR + "/api/device/@me", {
        headers: {
          Authorization: tokenStore.getToken(),
        },
      })
      .then((res) => {
        res.data.devices.forEach((device) => {
          this.devices.push(device);
          this.markers.push([device.lat,device.long]);
        });
      });
  },
};
</script>
