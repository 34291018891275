<template>
  <div
    style="overflow-y: scroll;height: 100%"
    class="pr-2 pl-2 pt-2 pb-2"
    v-if="type == 'line' || type == 'area'"
  >
    <zingchart
      v-if="!isResizing"
        :height="h-40+'px'"
        :width="w*0.92+'px'"
      :data="data"
      :series="series"
    ></zingchart>
  </div>
  <div
    style="
      height: 100%;
      display: flex !important;
      flex-direction: column !important;
      overflow-y: scroll !important;
      overflow-x: hidden;
    "
    class="pr-2 pl-2 pb-2"
    v-else-if="type == 'line2'"
  >

    <div style="height: 60px">
      <v-row style="height: 100%" align="center" justify="center">
        <v-col cols="1"></v-col>
        <v-col cols="3">
          <v-select
            class="pt-1"
            dense
            v-model="dateType"
            :items="['day', 'range']"
            label="Select dateType"
            solo
            @change="changeDateType"
          ></v-select
        ></v-col>
        <v-col cols="1"></v-col>
        <v-col cols="1" v-if="dateType == 'day'">
          <v-btn icon class="mb-3" @click="yesterday">
            <v-icon>mdi-arrow-left-thick</v-icon>
          </v-btn></v-col
        >
        <v-col v-if="dateType == 'day'" cols="3">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                v-model="date"
                label="Date"
                prepend-icon="mdi-calendar"
                class="mt-0"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              :active-picker.sync="activePicker"
              @change="save"
            ></v-date-picker> </v-menu
        ></v-col>

        <v-col v-else-if="dateType == 'range'" cols="5">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                v-model="date"
                label="Date range"
                prepend-icon="mdi-calendar"
                class="mt-0"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              range
              :active-picker.sync="activePicker"
              @change="save"
            ></v-date-picker> </v-menu
        ></v-col>
        <v-col cols="1" v-if="dateType == 'day'">
          <v-btn icon class="mb-3" @click="tomorrow">
            <v-icon>mdi-arrow-right-thick</v-icon>
          </v-btn></v-col
        >
        <v-col cols="1"></v-col>
      </v-row>
    </div>
    <div style="overflow-y: scroll; flex-grow: 1">
      <zingchart
      v-if="!isResizing"
        :height="h-100+'px'"
        :width="w*0.92+'px'"
        :data="data"
        :series="series"
      ></zingchart>
    </div>
  </div>
  <div
    style="height: 100%; overflow: auto"
    class="pr-5 pl-5 pt-2 pb-2"
    v-else-if="type == 'text'"
  >
    <template v-for="(input, i) in data.inputs"
      ><div
        :style="'font-size:' + input.size + 'px;line-height: normal;'"
        :class="[input.center ? 'text-center' : 'text-left']"
        :key="i"
      >
        <pre
          v-if="
            typeof series[i].value === 'object' &&
            !Array.isArray(series[i].value) &&
            series[i].value !== null
          "
          >{{ input.name }} : {{
            series[i].value.value + " ( " + series[i].value.updateTime + " )"
          }}</pre
        >
        <pre v-else>{{ input.name }} : {{ series[i].value }} {{channelList[input.channel] != undefined ? channelList[input.channel].unité : ""}}</pre>
      </div></template
    >
  </div>

  <div
    style="height: 100%"
    class="pr-5 pl-5 pt-2 pb-2 text-center"
    v-else-if="type == 'linkDashboard'"
  >
    <template v-for="(input, i) in data.inputs"
      ><div
        :style="'font-size:20px;line-height: normal;height: 100%; overflow: auto'"
        :key="i"
      >
        <v-btn
          :width="input.width"
          :height="input.height"
          :fab="input.style == 'fab'"
          :rounded="input.style == 'rounded'"
          :color="input.color"
          @click="redirectDashboard(series[i].dashBoardId)"
          ><span :style="'color:' + input.fontColor + ';'">{{
            input.name
          }}</span></v-btn
        >
      </div></template
    >
  </div>

  <div
    style="height: 100%"
    class="pr-5 pl-5 pt-2 pb-2 text-center"
    v-else-if="type == 'image'"
  >
    <template v-for="(input, i) in data.inputs">
      <img
        :key="i"
        :src="input.imageUrl"
        :style="'max-width:100% !important;max-height:100% !important'"
    /></template>
  </div>
  <div
    style="height: 100%"
    class="pr-5 pl-5 pt-2 pb-2 text-center"
    v-else-if="type == 'downlink'"
  >
    <template v-for="(input, i) in data.inputs">
      <div
        :style="'font-size:20px;line-height: normal;height: 100%; overflow: auto'"
        :key="i"
      >
        <v-btn
          color="primary"
          depressed
          @click="input.type == 0 ? sendMQTT(input) : sendPOST(input)"
          >Send</v-btn
        >
      </div>
    </template>
  </div>

  <div
    style="height: 100%; overflow: auto"
    class="pr-5 pl-5 pt-2 pb-2"
    v-else-if="type == 'Alert'"
  >
    <template v-for="(input, i) in data.inputs">
      <v-row
        align="center"
        justify="center"
        style="height: 100%; overflow: auto"
        :key="i"
      >
        <v-col
          v-if="input.textPosition == 'left' || input.textPosition == 'top'"
        >
          <div
            :style="
              'font-size:' +
              input.fontSize +
              'px;line-height: normal;' +
              'color:' +
              input.fontColor +
              ';'
            "
          >
            <pre>Group : {{ series[0].groupName }}</pre>
            <pre>Alert : {{ series[0].alertName }}</pre>
            <pre>UpdateTime : {{ series[0].updateTime }}</pre>
          </div></v-col
        >
        <v-col
          class="text-center"
          :cols="
            input.textPosition != 'top' && input.textPosition != 'bottom'
              ? ''
              : 12
          "
        >
          <v-btn
            :width="input.size"
            :height="input.size"
            fab
            :style="
              series[0].dashboardId == 'null' ||
              series[0].dashboardId == null ||
              series[0].dashboardId == ''
                ? 'cursor:auto;'
                : 'cursor:pointer;'
            "
            :color="
              series[0].state == 'none'
                ? $vuetify.theme.themes.dark.success
                : series[0].state == 'warning'
                ? $vuetify.theme.themes.dark.warning
                : $vuetify.theme.themes.dark.error
            "
            @click="redirectDashboard(series[0].dashboardId)"
          >
            <div
              v-if="input.textPosition == 'inside'"
              :style="
                'font-size:' +
                input.fontSize +
                'px;line-height: normal;width: 100%;' +
                'color:' +
                input.fontColor +
                ';'
              "
            >
              <pre class="text-wrap">Group : {{ series[0].groupName }}</pre>
              <pre class="text-wrap">Alert : {{ series[0].alertName }}</pre>
              <pre class="text-wrap">
UpdateTime : {{ series[0].updateTime }}</pre
              >
            </div></v-btn
          >
        </v-col>
        <v-col
          v-if="input.textPosition == 'right' || input.textPosition == 'bottom'"
        >
          <div
            :style="
              'font-size:' +
              input.fontSize +
              'px;line-height: normal;' +
              'color:' +
              input.fontColor +
              ';'
            "
          >
            <pre>Group : {{ series[0].groupName }}</pre>
            <pre>Alert : {{ series[0].alertName }}</pre>
            <pre>UpdateTime : {{ series[0].updateTime }}</pre>
          </div></v-col
        >
      </v-row></template
    >
  </div>
  <div
    style="height: 100%"
    class="pr-5 pl-5 pt-2 pb-2"
    v-else-if="type == 'map'"
  >
    <mapcomp :markers="series"></mapcomp>
  </div>
  <div
    style="height: 100%"
    class="pr-5 pl-5 pt-2 pb-2"
    v-else-if="type == 'coloredIcon'"
  >
    <template v-for="(input, i) in data.inputs">
      <v-row
        align="center"
        justify="center"
        style="height: 100%; overflow: hidden"
        :key="i"
        v-if="!Array.isArray(input.condition)"
      >
        <div
          v-if="input.showUpdateTime && input.textPosition == 'top'"
          :style="
            'font-size:' +
            input.fontSize +
            'px;line-height: normal;width: 100%;' +
            'color:' +
            input.fontColor +
            ';'
          "
          :class="'text-center'"
          :key="i"
        >
          <pre
            v-if="
              typeof series[i].value === 'object' &&
              !Array.isArray(series[i].value) &&
              series[i].value !== null
            "
            >{{ series[i].value.updateTime }}</pre
          >
        </div>
        <v-btn
          :width="input.size"
          :height="input.size"
          icon
          @click="redirectDashboard(input.dashBoardId)"
        >
          <v-icon
            :color="series[i].value.value ? input.colorTrue : input.colorFalse"
            :style="'font-size:' + input.size + 'px;'"
          >
            {{ input.icon }}</v-icon
          >
        </v-btn>
      </v-row>
      <v-row
        align="center"
        justify="center"
        style="height: 100%; overflow: hidden"
        :key="i"
        v-else
      >
        <div
          v-if="input.showUpdateTime && input.textPosition == 'top'"
          :style="
            'font-size:' +
            input.fontSize +
            'px;line-height: normal;width: 100%;' +
            'color:' +
            input.fontColor +
            ';'
          "
          :class="'text-center'"
          :key="i"
        >
          <pre
            v-if="
              typeof series[i].value === 'object' &&
              !Array.isArray(series[i].value) &&
              series[i].value !== null
            "
            >{{ series[i].value.updateTime }}</pre
          >
        </div>
        <v-btn
          :width="input.size"
          :height="input.size"
          icon
          @click="redirectDashboard(input.dashBoardId)"
        >
          <v-icon
            :color="getIconColor(input, series[i].value.value)"
            :style="'font-size:' + input.size + 'px;'"
          >
            {{ input.icon }}</v-icon
          >
        </v-btn>
      </v-row>
    </template>
  </div>
</template>


<script>
import MapComponent from "../MapComponent.vue";

import channelList from "../../channelList.json";

import axios from "axios";
import tokenStore from "../../store/TokenStore";

export default {
  components: {
    mapcomp: MapComponent,
  },
  data() {
    return {
      markers: [],
      countRender: 0,
      activePicker: null,
      dateType: "day",
      date: null,
      menu: false,
      channelList : [],
    };
  },
  props: {
    i: String,
    type: String,
    data: Object,
    series: Array,
    w: Number,
    h: Number,
    isResizing: Boolean
  },
  watch: {},
  methods: {
    getIconColor(input, value) {
      for (let i = 0; i < input.condition.length; i++) {
        if (input.condition[i] == "is True") {
          if (value == true) return input.colorTrue[i];
        } else if (input.condition[i] == ">") {
          if (value > input.conditionValue[i]) return input.colorTrue[i];
        } else if (input.condition[i] == ">=") {
          if (value >= input.conditionValue[i]) return input.colorTrue[i];
        } else if (input.condition[i] == "<") {
          if (value < input.conditionValue[i]) return input.colorTrue[i];
        } else if (input.condition[i] == "<=") {
          if (value <= input.conditionValue[i]) return input.colorTrue[i];
        } else if (input.condition[i] == "=") {
          if (value == input.conditionValue[i]) return input.colorTrue[i];
        } else if (input.condition[i] == "!=") {
          if (value != input.conditionValue[i]) return input.colorTrue[i];
        } else if (input.condition[i] == "is False") {
          if (value == false) return input.colorTrue[i];
        }
      }
      return input.colorFalse;
    },
    sendMQTT(input) {
      if (input.downlinkAck) {
        let ackToSend = {
          url: input.url,
          port: input.port,
          topic: input.ackTopic,
          username: input.username,
          password: input.password,
          message: {
            channel_5: 1,
            channel_2:
              input.ackTopic.split("/")[input.ackTopic.split("/").length - 2],
          },
        };

        axios
          .post(process.env.VUE_APP_API_ADDR + "/api/mqtt", ackToSend, {
            headers: {
              Authorization: tokenStore.getToken(),
            },
          })
          .then((res) => {
            console.log(res.data);
            if (res.data.success) {
              setTimeout(() => {
                let toSend = {
                  url: input.url,
                  port: input.port,
                  topic: input.topic,
                  username: input.username,
                  password: input.password,
                  message: input.body,
                };

                axios
                  .post(process.env.VUE_APP_API_ADDR + "/api/mqtt", toSend, {
                    headers: {
                      Authorization: tokenStore.getToken(),
                    },
                  })
                  .then((res) => {
                    console.log(res.data);
                    if (res.data.success) {
                      this.$emit("snackbar", {
                        type: "success",
                        msg: "Message correctly posted on mqtt broker",
                      });
                    }
                  })
                  .catch((err) => {
                    this.$emit("snackbar", {
                      type: "error",
                      msg: "Err : " + JSON.stringify(err.response.data),
                    });
                  });
              }, 1000);
            }
          })
          .catch((err) => {
            this.$emit("snackbar", {
              type: "error",
              msg: "Err : " + JSON.stringify(err.response.data),
            });
          });
      } else {
        let toSend = {
          url: input.url,
          port: input.port,
          topic: input.topic,
          username: input.username,
          password: input.password,
          message: input.body,
        };

        axios
          .post(process.env.VUE_APP_API_ADDR + "/api/mqtt", toSend, {
            headers: {
              Authorization: tokenStore.getToken(),
            },
          })
          .then((res) => {
            console.log(res.data);
            if (res.data.success) {
              this.$emit("snackbar", {
                type: "success",
                msg: "Message correctly posted on mqtt broker",
              });
            }
          })
          .catch((err) => {
            this.$emit("snackbar", {
              type: "error",
              msg: "Err : " + JSON.stringify(err.response.data),
            });
          });
      }
    },
    sendPOST(input) {
      console.log("request gone");

      axios
        .post(input.url, input.body, {
          headers: {
            Authorization: input.authentificationy,
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.success) {
            this.$emit("snackbar", {
              type: "success",
              msg: res.data,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$emit("snackbar", {
            type: "error",
            msg:
              "Err " +
              err.response.status +
              " : " +
              err.response.config.url +
              " " +
              err.response.statusText,
          });
        });
    },
    test() {
      console.log("foo");
    },
    chartCompleted() {
      this.countRender++;
    },
    tomorrow() {
      const tomorrow = new Date(this.date);
      tomorrow.setDate(tomorrow.getDate() + 1);
      this.date = tomorrow.toISOString().substr(0, 10);
      this.$emit("line2ChangeDate", { i: this.i, date: this.date });
    },
    yesterday() {
      const tomorrow = new Date(this.date);
      tomorrow.setDate(tomorrow.getDate() - 1);
      this.date = tomorrow.toISOString().substr(0, 10);
      this.$emit("line2ChangeDate", { i: this.i, date: this.date });
    },

    redirectDashboard(dashboardId) {
      if (dashboardId != "null" && dashboardId != "" && dashboardId != null) {
        this.$emit("redirectDashboard", dashboardId);
      }
    },
    changeDateType(value) {
      if (value == "day" && Array.isArray(this.date)) {
        this.date = this.date[0];
        this.$emit("line2ChangeDate", { i: this.i, date: this.date });
      } else if (value == "range" && !Array.isArray(this.date)) {
        this.date = [this.date, this.date].sort();

        this.$emit("line2ChangeDateArray", { i: this.i, dateArray: this.date });
      }
    },
    save(date) {
      this.$refs.menu.save(date);
      if (this.dateType == "day") {
        this.$emit("line2ChangeDate", { i: this.i, date: this.date });
      } else if (this.dateType == "range") {
        this.$emit("line2ChangeDateArray", {
          i: this.i,
          dateArray: this.date.sort(),
        });
      }
    },
  },

  mounted() {
    console.log(this.data)
    this.channelList = channelList;
    if (this.type == "line2") {
      this.date = this.data.date;
      this.dateType = this.data.dateType;
    }
  },
};
</script>

<style scoped>
.v-btn__content {
  width: 100%;
  white-space: normal;
}
</style>