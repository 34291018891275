import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import vuetify from './plugins/vuetify';
import 'zingchart/es6';
import zingchartVue from 'zingchart-vue';

Vue.use(VueRouter)

Vue.component('zingchart', zingchartVue)

Vue.config.productionTip = false

//import Home from './components/Home.vue';
import Home2 from './components/Home2.vue';
import Menu from './components/Menu.vue';
//import Device from './components/Device.vue';
import Device2 from './components/Device2.vue';
//import Group from './components/Group.vue';
import Group2 from './components/Group2.vue';
//import SelectDashboard from './components/Dashboard/SelectDashboard.vue';
import SelectDashboard2 from './components/Dashboard/SelectDashboard2.vue';
import Dashboard from './components/Dashboard/Dashboard.vue';
import PageNotFound from './components/PageNotFound.vue';
import Login from './components/Login.vue';
import Map from './components/Map.vue';
import Log from './components/Log.vue';
import About from './components/About.vue';
import Test from './components/Test.vue';
import SelectAlert from './components/Alert/SelectAlert.vue';
import SelectPreset from './components/Preset/SelectPreset.vue';
import Settings from './components/Settings/Settings.vue';
import Export from './components/Export/Export.vue';
import Export2 from './components/Export/Export2.vue';

import 'leaflet/dist/leaflet.css';

const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  routes: [
    { path: '/', redirect: '/login' },
    { path: '/login', component: Login },
    {
      path: '/menu', redirect: '/dashboard', component: Menu, children: [

        { path: '/home', component: Home2 },
        //{ path: '/home2', component: Home2 },
        { path: '/device', component: Device2 },
        //{ path: '/device2', component: Device2 },
        { path: '/group', component: Group2 },
        // { path: '/group2', component: Group2 },
        { path: '/dashboard', component: SelectDashboard2, children: [{ path: '/dashboard/*', component: Dashboard },] },
        // { path: '/dashboard2', component: SelectDashboard2 },
        { path: '/map', component: Map },
        { path: '/log', component: Log },
        { path: '/alert', component: SelectAlert },
        { path: '/preset', component: SelectPreset },
        { path: '/about', component: About },
        { path: '/test', component: Test },
        { path: '/settings', component: Settings },
        { path: '/export', component: Export },
        { path: '/export2', component: Export2 },
      ]
    },
    { path: "*", redirect: '/404_Not_Found' },
    { path: '/404_Not_Found', component: PageNotFound },
  ]
});


window.popStateDetected = false
window.addEventListener('popstate', () => {
  window.popStateDetected = true
})


new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')

