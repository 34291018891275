import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({

    theme: {    options: {
        customProperties: true
      },
        dark: false,
        themes: {
            light:{
                primary: '#006FB7',
                secondary: colors.grey.lighten2,
                error: '#B71C1C',
                warning: '#FF9800',
                info: '#FDD835',
                success: '#4CAF50',
                widgetBackground:colors.shades.white,

            },
            dark: {

                
                primary: colors.grey.base,
                secondary: colors.grey.darken3,
                error: '#B71C1C',
                warning: '#FF9800',
                info: '#FDD835',
                success: '#4CAF50',
                widgetBackground:colors.grey.darken3,
            },
        },
    },
})
