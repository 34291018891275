<template>
  <v-main>
    <div v-if="loading" class="mt-5 text-center">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <template v-else>
      <v-container justify-center class="pa-0">
        <v-row justify="center">Auto Refresh</v-row>
        <v-row justify="center" align="center">
          <div
            :class="['mb-1', !switch1 ? 'primary--text font-weight-bold' : '']"
          >
            OFF
          </div>
          <v-switch v-model="switch1" inset class="pa-0 ml-4"></v-switch>
          <div
            :class="['mb-1', switch1 ? 'primary--text font-weight-bold' : '']"
          >
            ON
          </div>
        </v-row>
      </v-container>

      <v-row justify="center" class="mt-5 ml-3 mr-3 mb-5">
        <v-expansion-panels accordion v-model="panel" multiple>
          <v-expansion-panel v-for="(item, i) in data" :key="i">
            <v-expansion-panel-header>
              <v-row no-gutters  align="center"
                ><v-col cols="2">{{
                  new Date(item.date).toLocaleString()
                }}</v-col
                ><v-col cols="5">{{ item.deviceName }}</v-col></v-row
              >
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <vue-json-pretty :deep="3" :data="item.data"> </vue-json-pretty>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </template>
  </v-main>
</template>

<script>
import axios from "axios";

import tokenStore from "../store/TokenStore";

import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";

export default {
  components: {
    VueJsonPretty,
  },
  data: () => ({
    data: [],
    devices: [],
    interval: null,
    loading: true,
    panel: [],
    switch1: true,
  }),

  watch: {
    switch1(value) {
      if (value) {
        this.interval = setInterval(() => {
          axios
            .get(process.env.VUE_APP_API_ADDR + "/api/device/@me", {
              headers: {
                Authorization: tokenStore.getToken(),
              },
            })
            .then((res) => {
              res.data.devices.forEach((device) => {
                device.lastData.deviceName = device.name;
                device.lastData.data = JSON.parse(device.lastData.data);
                let i = this.devices.findIndex((dev) => {
                  return dev.id == device.id;
                });
                if (i < 0) {
                  this.devices.push(device);
                  this.data.push(device.lastData);
                } else {
                  let ii = this.data.findIndex((data) => {
                    return (
                      data.date == device.lastData.date &&
                      data.deviceId == device.lastData.deviceId
                    );
                  });
                  if (ii < 0) {
                    this.data.unshift(device.lastData);
                    for (let x = 0; x < this.panel.length; x++) {
                      this.panel[x]++;
                    }
                  }
                }
              });
              this.data.sort(this.compareJsonDate);
              this.loading = false;
            })
            .catch((err) => {
              console.log(err.response);
            });
        }, 1000);
      } else {
        clearInterval(this.interval);
      }
    },
  },
  beforeMount() {
    this.devices = [];
    this.data = [];
    this.loading = true;
    this.interval = setInterval(() => {
      axios
        .get(process.env.VUE_APP_API_ADDR + "/api/device/@me", {
          headers: {
            Authorization: tokenStore.getToken(),
          },
        })
        .then((res) => {
          res.data.devices.forEach((device) => {
            device.lastData.deviceName = device.name;
            device.lastData.data = JSON.parse(device.lastData.data);
            let i = this.devices.findIndex((dev) => {
              return dev.id == device.id;
            });
            if (i < 0) {
              this.devices.push(device);
              this.data.push(device.lastData);
            } else {
              let ii = this.data.findIndex((data) => {
                return (
                  data.date == device.lastData.date &&
                  data.deviceId == device.lastData.deviceId
                );
              });
              if (ii < 0) {
                this.data.unshift(device.lastData);
                for (let x = 0; x < this.panel.length; x++) {
                  this.panel[x]++;
                }
              }
            }
          });
          this.data.sort(this.compareJsonDate);
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.response);
        });
    }, 1000);
  },

  beforeDestroy() {
    clearInterval(this.interval);
    window.stop();
  },
  methods: {
    test() {
      console.log("test");
    },
    compareJsonDate(a, b) {
      let d1 = new Date(a.date);
      let d2 = new Date(b.date);
      if (d1 < d2) return 1;
      else if (d1 == d2) return 0;
      else return -1;
    },
  },
};
</script>

