<template>
  <v-card elevation="2" :width="!mini ? 256 : 56">
    <v-navigation-drawer :v-model="drawer" :mini-variant.sync="mini" :permanent="true"
     :color="($vuetify.theme.dark ? $vuetify.theme.themes.dark.secondary : '')"
      ><v-list dense nav>
        <v-list-item color="primary" @click="mini = !mini">
          <v-list-item-icon v-if="mini">
            <v-icon color="primary">mdi-arrow-right</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{
              !mini ? "Dashboard " + dashboardName : ""
            }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon v-if="!mini">
            <v-icon color="primary">mdi-arrow-left</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item
          :color="switch1 || item.icon=='mdi-arrow-left-bottom-bold' ? 'grey' : 'primary'"
          v-for="item in items"
          :key="item.title"
          @click="$emit('dashboardMenu', item.title)"
          :disabled="switch1 || item.icon=='mdi-arrow-left-bottom-bold'"
        >
        
          <v-list-item-icon>
            <v-icon :color=" switch1 || item.icon=='mdi-arrow-left-bottom-bold' ? 'grey' : 'primary'">{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list dense nav>
        <v-list-item :color="'primary'">
          <v-list-item-icon :style="'align-self: center !important'">
            <v-icon color="primary"> {{ !switch1 ? 'mdi-lock-open-variant-outline' : 'mdi-lock'}}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              <v-row justify="center" align="center">
                <div
                  :class="[
                    'mb-1',
                    !switch1 ? 'primary--text font-weight-bold' : '',
                  ]"
                >
                  OFF
                </div>
                <v-switch v-model="switch1" inset class="pa-0 ml-4" @change="lockChange()"></v-switch>
                <div
                  :class="[
                    'mb-1',
                    switch1 ? 'primary--text font-weight-bold' : '',
                  ]"
                >
                  ON
                </div>
              </v-row>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import axios from "axios";
import tokenStore from "../../store/TokenStore";

export default {
  data: () => ({
    mini: true,
    drawer: null,
    items: [
      { title: "Create widget", icon: "mdi-plus" },
      
      { title: "Configure", icon: "mdi-cog" },
      { title: "Save as preset", icon: "mdi-content-save" },
    ],
    right: null,
    switch1: false,
  }),
  props: {
    dashboardName: String,
    lock: Boolean,
  },

  watch: {
    lock(value){
      this.switch1 = value;
    }
  },
  beforeMount() {

    this.switch1 = this.lock;

    axios
      .get(
        process.env.VUE_APP_API_ADDR + "/api/auth/isRoot",

        {
          headers: {
            Authorization: tokenStore.getToken(),
          },
        }
      )
      .then((res) => {
        if (res.data.root) {
          this.items.push({
            title: "Save as default preset",
            icon: "mdi-content-save-outline",
          });
        }
      })
      .catch((error) => {
        console.log({ error });
      });
  },

  beforeDestroy() {},
  methods: {
    lockChange(){
      this.$emit('lockChange',this.switch1)
    },
    test(value) {
      console.log("test", value);
    },
  },
};
</script>

