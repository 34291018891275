var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',[_c('img',{ref:"img",style:({
      position: 'absolute',
      top: '30px',
      left: '50%',
      width: 1000 + 'px',
      transform: 'translate(-50%, 0)',
      'z-index': 1,
    }),attrs:{"src":"https://d1fmx1rbmqrxrr.cloudfront.net/cnet/optim/i/edit/2019/04/eso1644bsmall__w770.jpg"},on:{"load":_vm.test}}),_c('v-row',[_c('v-col',{staticStyle:{"z-index":"5"},attrs:{"id":"svg"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }