
<template>
  <v-main>
    <v-dialog
      v-model="dialogDeviceOption"
      scrollable
      width="600px"
      @click:outside="leaveDeviceOptionDialog"
    >
      <v-card>
        <v-card-title class="headline secondary">
          Device options
        </v-card-title>
        <v-card-text class="mt-5">
          <v-row>
            <v-col cols="12">
              <v-text-field
                hide-details
                dense
                label="Name"
                v-model="deviceName"
              >
                <v-btn icon slot="append" color="primary" @click="resetName">
                  <v-icon>mdi-cached</v-icon>
                </v-btn></v-text-field
              ></v-col
            >
            <v-col cols="6">
              <v-text-field
                hide-details
                v-model="deviceLat"
                label="Lat"
              ></v-text-field
            ></v-col>
            <v-col cols="6">
              <v-text-field
                hide-details
                v-model="deviceLong"
                label="Long"
              ></v-text-field
            ></v-col> </v-row></v-card-text
        ><v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="leaveDeviceOptionDialog">
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="saveDeviceOptionDialog">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row class="ml-5 mr-5">
      <v-col cols="12" class="pa-0">
        <v-card>
          <v-card-title>
            <v-row align="center">
              <v-col cols="4"> Registered Devices</v-col>
              <v-col cols="3">
                <v-checkbox label="Group By Group" v-model="groupBy"
                  >test</v-checkbox
                >
              </v-col>
              <v-col cols="5">
                <v-text-field
                  class="pt-0"
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field
              ></v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            fixed-header
            height="63vh"
            :headers="headers"
            :items="devicesLoading || groupsLoading ? [] : devices"
            :items-per-page="-1"
            :loading="devicesLoading || groupsLoading"
            :search="search"
            :group-by="groupBy ? 'group' : null"
            ref="table"
          >
            <template v-slot:item="{ item }">
              <tr
                style="
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: normal;
                "
              >
                <td style="max-width: 300px !important">{{ item.name }}</td>
                <td style="max-width: 300px !important">{{ item.id }}</td>
                <td v-if="!groupBy" style="max-width: 300px !important">
                  {{ item.group }}
                </td>
                <td style="max-width: 300px !important">
                  {{ new Date(item.lastData.date).toLocaleString() }}
                </td>
                <td>
                  <v-icon
                    class="ml-1"
                    small
                    color="primary"
                    @click="editItem(item)"
                    >mdi-pencil</v-icon
                  >
                  <check-data :item="item"></check-data>
                </td>
              </tr>
            </template>
            <template
              v-slot:[`group.header`]="{ group, headers, toggle, isOpen }"
            >
              <td :colspan="headers.length">
                <v-btn
                  @click="toggle"
                  small
                  icon
                  :ref="group"
                  :data-open="isOpen"
                >
                  <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
                  <v-icon v-else>mdi-chevron-down</v-icon>
                </v-btn>
                {{ group }}
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import axios from "axios";
import tokenStore from "../store/TokenStore";

import CheckDataDialog from "./CheckDataDialog.vue";

export default {
  components: {
    "check-data": CheckDataDialog,
  },
  data: () => ({
    dialogDeviceOption: false,
    deviceName: null,
    deviceId: null,
    deviceLat: null,
    deviceLong: null,
    selectedItem: null,
    groupBy: true,
    headers: [
      {
        text: "Name",
        sortable: true,
        value: "name",
        align: "start",
        groupable: true,
      },
      {
        text: "Id",
        value: "id",
        sortable: true,
        align: "start",
        groupable: true,
      },

      {
        text: "Group",
        value: "group",
        sortable: true,

        groupable: true,
        align: "start",
      },
      {
        text: "Last Communication",
        value: "lastData.date",
        sortable: true,
        align: "start",
        groupable: true,
        filterable: false,
      },
      {
        text: "Data",
        value: "",
        width: "1px",
        sortable: false,
        align: "center",
        filterable: false,
      },
    ],
    devices: [],
    devicesLoading: false,
    groups: [],
    groupsLoading: false,
    search: "",
  }),
  computed: {},

  beforeMount() {
    setTimeout(() => {
      this.devicesLoading = true;
      this.loadGroups();
    }, 1);
  },

  methods: {
    resetName() {
      this.deviceName = this.deviceId;
    },
    saveDeviceOptionDialog() {
      axios
        .post(
          process.env.VUE_APP_API_ADDR +
            "/api/device/" +
            this.deviceId +
            "/position",
          {
            lat: this.deviceLat,
            long: this.deviceLong,
          },
          {
            headers: {
              Authorization: tokenStore.getToken(),
            },
          }
        )
        .then(() => {
          axios
            .put(
              process.env.VUE_APP_API_ADDR +
                "/api/device/" +
                this.deviceId +
                "/name",
              {
                name: this.deviceName,
              },
              {
                headers: {
                  Authorization: tokenStore.getToken(),
                },
              }
            )
            .then(() => {
              let i = this.devices.findIndex((device) => {
                return device.id == this.deviceId;
              });
              if (i >= 0) {
                this.devices[i].name = this.deviceName;
                this.devices[i].lat = this.deviceLat;
                this.devices[i].long = this.deviceLong;
              } else {
                let a = this.registeredDevices.findIndex((device) => {
                  return device.id == this.deviceId;
                });

                let b = this.selectedRegisteredDevices.findIndex((device) => {
                  return device.id == this.deviceId;
                });

                this.registeredDevices[a].name = this.deviceName;
                this.registeredDevices[a].lat = this.deviceLat;
                this.registeredDevices[a].long = this.deviceLong;

                this.selectedRegisteredDevices[b].name = this.deviceName;
                this.selectedRegisteredDevices[b].lat = this.deviceLat;
                this.selectedRegisteredDevices[b].long = this.deviceLong;
              }

              this.leaveDeviceOptionDialog();
            });
        });
    },
    leaveDeviceOptionDialog() {
      this.deviceName = null;
      this.deviceLat = null;
      this.deviceLong = null;
      this.deviceId = null;
      this.dialogDeviceOption = false;
    },
    editItem(item) {
      //item is a group
      this.selectedItem = item;
      this.deviceName = item.name;
      this.deviceId = item.id;
      this.deviceLat = item.lat;
      this.deviceLong = item.long;
      this.dialogDeviceOption = true;
    },
    checkData(item) {
      // item is a device
      console.log(item.id);
    },
    closeAll() {
      Object.keys(this.$refs).forEach((k) => {
        if (this.$refs[k] && this.$refs[k].$attrs["data-open"]) {
          this.$refs[k].$el.click();
        }
      });
    },
    loadGroups() {
      this.groups = [];
      axios
        .get(process.env.VUE_APP_API_ADDR + "/api/group/@me", {
          headers: {
            Authorization: tokenStore.getToken(),
          },
        })
        .then((res) => {
          res.data.groups.forEach((group) => {
            if (group.groupLevel == 2) {
              this.groups.push(group);
            }
          });

          this.loadDevices();
        });
    },
    loadDevices() {
      this.devices = [];
      this.devicesLoading = true;
      axios
        .get(process.env.VUE_APP_API_ADDR + "/api/device/@me", {
          headers: {
            Authorization: tokenStore.getToken(),
          },
        })
        .then((res) => {
          res.data.devices.forEach((device) => {
            if (device.registered) {
              let i = this.groups.findIndex((group) => {
                return group.id == device.groups[0];
              });
              if (i >= 0) {
                device.group = this.groups[i].name;
                this.devices.push(device);
              } else {
                //device is lost in an anexisting group
                console.log("deviceLOST");
                console.log(device);
              }
            }
          });
          setTimeout(() => {
            let table = this.$refs.table;
            this.closeAll(table);
          }, 1);

          this.devicesLoading = false;
        });
    },
  },
};
</script>

