var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return ((_vm.mail != '' && _vm.dialog == false) || _vm.dialog == true)?_c('v-main',[_c('v-dialog',{attrs:{"max-width":"500"},on:{"click:outside":function () {
        _vm.dialog = false;
      }},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline secondary"},[_vm._v(" Change email ")]),_c('v-card-text',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12"}},[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"rules":[_vm.rules.required, _vm.rules.mail],"label":"Mail","clearable":""},model:{value:(_vm.mail),callback:function ($$v) {_vm.mail=$$v},expression:"mail"}})],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function () {
              _vm.dialog = false;
            }}},[_vm._v(" Cancel ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.saveMail}},[_vm._v(" Save ")])],1)],1)],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":"Username","readonly":true,"outlined":""},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1)],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":"Email","readonly":true,"outlined":""},model:{value:(_vm.mail),callback:function ($$v) {_vm.mail=$$v},expression:"mail"}})],1)],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.changeMail}},[_vm._v("Modify")])],1),_c('v-row',{staticClass:"text-center mt-5",attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" No alert "),_c('v-menu',{attrs:{"transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":_vm.colorBase}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-square-rounded")])],1)]}}],null,false,27071209)},[_c('v-card',{nativeOn:{"click":function($event){$event.stopPropagation();}}},[_c('v-color-picker',{attrs:{"canvas-height":"300","mode":'hexa'},model:{value:(_vm.colorBase),callback:function ($$v) {_vm.colorBase=$$v},expression:"colorBase"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" Warning "),_c('v-menu',{attrs:{"transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":_vm.colorWarning}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-square-rounded")])],1)]}}],null,false,2464993014)},[_c('v-card',{nativeOn:{"click":function($event){$event.stopPropagation();}}},[_c('v-color-picker',{attrs:{"canvas-height":"300","mode":'hexa'},model:{value:(_vm.colorWarning),callback:function ($$v) {_vm.colorWarning=$$v},expression:"colorWarning"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" Alarm "),_c('v-menu',{attrs:{"transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":_vm.colorAlarm}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-square-rounded")])],1)]}}],null,false,1282963215)},[_c('v-card',{nativeOn:{"click":function($event){$event.stopPropagation();}}},[_c('v-color-picker',{attrs:{"canvas-height":"300","mode":'hexa'},model:{value:(_vm.colorAlarm),callback:function ($$v) {_vm.colorAlarm=$$v},expression:"colorAlarm"}})],1)],1)],1)],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.updateColor}},[_vm._v("Save Colors")])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }