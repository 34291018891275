<template>
  <v-main
    :style="
      'background-color:' +
      ($vuetify.theme.dark ? $vuetify.theme.themes.dark.secondary : '') +
      ';'
    "
  >
    <v-snackbar v-model="snackbar" color="success">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-container v-if="!forgottenPassword" class="fill-height" fluid>
      <v-row justify="center">
        <v-col class="text-center" xl="2" lg="3" md="3" sm="6" cols="8">
          <img v-if="show" class="mb-5" style="width: 100%" :src="logo" />
          <br />
          <br />
          <br />
          <br />
          <br />
          <v-text-field
            class="my-1"
            label="Username"
            v-model="login"
            solo
          ></v-text-field>
          <v-text-field
            class="my-1"
            label="Password"
            type="password"
            v-model="password"
            solo
          ></v-text-field>

          <v-alert v-if="alert" class="mt-0 mb-0" :type="'error'" dense>{{
            alertMsg
          }}</v-alert>
          <div
            class="text-right"
            style="font-size: 12px; cursor: pointer"
            @click="forgottenPassword = true"
          >
            Forgotten password ?
          </div>
          <br />

          <v-btn color="primary" @click="submit" x-large> Connexion </v-btn>
          <br />
          <br />
          <br />
          <br />
          <br />
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="forgottenPassword" class="fill-height" fluid>
      <v-row justify="center">
        <v-col class="text-center" xl="2" lg="3" md="3" sm="6" cols="8">
          <v-text-field
            class="my-1"
            label="email"
            v-model="email"
            solo
          ></v-text-field>
          <v-btn color="primary" @click="askForNewPassword" x-large>
            Send
          </v-btn></v-col
        ></v-row
      >
    </v-container>
  </v-main>
</template>

<script>
import VueCookies from "vue-cookies";
import axios from "axios";

import tokenStore from "../store/TokenStore";

export default {
  data() {
    return {
      login: "",
      password: "",
      alert: false,
      alertMsg: "",
      logo: require("../assets/SicameNew.png"),
      show: false,
      forgottenPassword: false,
      snackbar: false,
      snackbarText: "Reset password process has been sent to the linked mail.",
    };
  },
  methods: {
    askForNewPassword() {
      console.log("askForNewPassword");
      this.snackbar = true;
      this.forgottenPassword = false;
    },
    submit: function () {
      axios
        .post(process.env.VUE_APP_API_ADDR + "/api/auth/login", {
          login: this.login,
          password: this.password,
        })
        .then((res) => {
          if (res.data.success) {
            tokenStore.setUsername(this.login);
            tokenStore.setToken(res.data.token);
            this.$router.push("/menu");
          }
        })
        .catch((err) => {
          try {
            switch (err.response.data.errorMessage) {
              default:
                //
                this.alert = true;
                this.alertMsg =
                  "Server error. It may be unavailable, try later !";
                break;
              case "Utilisateur incorrect":
                //
                this.alert = true;
                this.alertMsg = "Bad user !";
                break;
              case "Mail incorrect":
                //
                this.alert = true;
                this.alertMsg = "Bad user !";
                break;
              case "Mot de passse incorrect":
                //
                this.alert = true;
                this.alertMsg = "Incorrect password !";
                break;
            }
          } catch {
            //
            this.alert = true;
            this.alertMsg = "Server error. It may be unavailable, try later !";
          }
        });
    },
  },
  mounted() {
    this.show = true;
  },
  beforeMount() {
    let theme = VueCookies.get(
      "theme_cookie_" + process.env.VUE_APP_COOKIENAME
    );
    if (theme == "dark") {
      this.$vuetify.theme.dark = true;
    } else {
      this.$vuetify.theme.dark = false;
    }
  },
};
</script>
