var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',[_c('v-row',{staticClass:"ml-5 mr-5"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"4"}},[_vm._v(" Registered Devices")]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-checkbox',{attrs:{"label":"Group By Group"},model:{value:(_vm.groupBy),callback:function ($$v) {_vm.groupBy=$$v},expression:"groupBy"}},[_vm._v("test")])],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{ref:"table",attrs:{"fixed-header":"","height":"63vh","headers":_vm.headers,"items":_vm.devicesLoading || _vm.groupsLoading ? [] : _vm.devices,"items-per-page":-1,"loading":_vm.devicesLoading || _vm.groupsLoading,"search":_vm.search,"group-by":_vm.groupBy ? 'groupId' : null},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{staticStyle:{"overflow":"hidden","text-overflow":"ellipsis","white-space":"normal"}},[_c('td',{staticStyle:{"max-width":"300px !important"}},[_vm._v(_vm._s(item.name))]),_c('td',{staticStyle:{"max-width":"300px !important"}},[_vm._v(_vm._s(item.id))]),(!_vm.groupBy)?_c('td',{staticStyle:{"max-width":"300px !important"}},[_vm._v(" "+_vm._s(item.group)+" ")]):_vm._e(),_c('td',{staticStyle:{"max-width":"300px !important"}},[_vm._v(" "+_vm._s(new Date(item.lastData.date).toLocaleString())+" ")]),_c('td',[_c('export-data',{attrs:{"item":item}})],1)])]}},{key:"group.header",fn:function(ref){
var group = ref.group;
var headers = ref.headers;
var toggle = ref.toggle;
var isOpen = ref.isOpen;
return [_c('td',{attrs:{"colspan":headers.length-1}},[_c('v-btn',{ref:group,attrs:{"small":"","icon":"","data-open":isOpen},on:{"click":toggle}},[(isOpen)?_c('v-icon',[_vm._v("mdi-chevron-up")]):_c('v-icon',[_vm._v("mdi-chevron-down")])],1),_vm._v(" "+_vm._s(_vm.groups[_vm.groups.findIndex(function (g){ return g.id == group })].name)+" ")],1),_c('td',[_c('export-group-data',{attrs:{"group":_vm.groups[_vm.groups.findIndex(function (g){
                return g.id == group
              })]}})],1)]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }