var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('grid',{staticStyle:{"z-index":"10"},attrs:{"width":1920,"items":_vm.items,"dashboardId":_vm.selectedDashboard.id,"dashboardName":_vm.selectedDashboard.name}}),_c('img',{ref:"img",style:({
      position: 'absolute',
      top: '30px',
      left: '50%',
      width: _vm.imageWidth + 'px',
      transform: 'translate(-50%, 0)',
      'z-index': 1,
    }),attrs:{"src":_vm.imageUrl}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }