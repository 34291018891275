<template>
  <v-main>
    <v-row align="center" justify="center">
      <v-col class="text-center">
        <div>Front Version : {{ frontVersion }}</div>
        <div>API Version Used : {{ apiVersion }}</div></v-col
      >
    </v-row>
  </v-main>
</template>

<script>
import axios from "axios";

export default {
  components: {},
  data: () => ({
    apiVersion: null,
    frontVersion: process.env.VUE_APP_VERSION,
  }),
  beforeMount() {
    axios
      .get(process.env.VUE_APP_API_ADDR + "/api/version")
      .then((res) => {
        this.apiVersion = res.data.version;
      })
      .catch((err) => {
        console.log(err.response);
      });
  },
};
</script>

