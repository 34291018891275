<template>


  <v-main>
    
    <img
      ref="img"
      src="https://d1fmx1rbmqrxrr.cloudfront.net/cnet/optim/i/edit/2019/04/eso1644bsmall__w770.jpg"
      @load="test"
      :style="{
        position: 'absolute',
        top: '30px',
        left: '50%',
        width: 1000 + 'px',
        transform: 'translate(-50%, 0)',
        'z-index': 1,
      }"
    />
      <v-row>
        <v-col id="svg"  style="z-index: 5" />
      </v-row>
  </v-main>
</template>

<script>
import * as d3 from "d3";
import * as d3_save_svg from "d3-save-svg";

export default {
  data: () => ({
    line: null,
    svg: null,
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 0,
    drawing: false,
  }),
  mounted() {
    this.generateSvg();
  },

  

  
  methods: {
        test(){
      this.generateSvg();
    },
    save() {
      var config = {
        filename: "customFileName",
      };
      d3_save_svg.save(d3.select("svg").node(), config);
    },
    generateSvg() {
      const h = this.$refs.img.clientHeight;
      const w = this.$refs.img.clientWidth;
      console.log(w,h);
      let svg = d3
        .select("#svg")
        .append("svg")
        .attr("width", w)
        .attr("height", h)
        .style("background-color", "red")
        .on("mousedown", function (event) {

            let xy = d3.pointer(event);
            this.x1 = xy[0];
            this.y1 = xy[1];

            //arrow
            svg
              .append("svg:defs")
              .append("svg:marker")
              .attr("id", "triangle")
              .attr("refX", 6)
              .attr("refY", 6)
              .attr("markerWidth", 30)
              .attr("markerHeight", 30)
              .attr("orient", "auto")
              .append("path")
              .attr("d", "M 0 0 12 6 0 12 3 6")
              .style("fill", "black");

            //line
            this.line = svg
              .append("line")
              .attr("x1", this.x1)
              .attr("y1", this.y1)
              .attr("x2", xy[0])
              .attr("y2", xy[1])
              .attr("stroke-width", 1)
              .attr("stroke", "black")
              .attr("marker-end", "url(#triangle)");
            //this.lines.push("line" + this.lines.length);
            this.drawing = true;
          
        })
        .on("mousemove", function (event) {
          if (this.drawing) {
            let xy = d3.pointer(event);
            this.line.attr("x2", xy[0]).attr("y2", xy[1]);
          }
        })
        .on("mouseup", function () {
          this.drawing = false;
          this.line = null;
        });
    },
  },
};
</script>