<template>
  <v-app @mousemove.native="timeoutLogout">
    <v-navigation-drawer
      :v-model="drawer"
      :mini-variant.sync="mini"
      :permanent="true"
      :color="$vuetify.theme.dark ? $vuetify.theme.themes.dark.secondary : ''"
      app
      ref="navigation"
      style="z-index: 50"
      ><v-list dense nav>
        <v-list-item color="primary" @click="mini = !mini">
          <v-list-item-icon v-if="mini">
            <v-icon color="primary">mdi-arrow-right</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{
              !mini ? "Application" : ""
            }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon v-if="!mini">
            <v-icon color="primary">mdi-arrow-left</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item
          color="primary"
          v-for="item in filterAuthorized(items)"
          :key="item.title"
          :to="item.path"
          @click="forcePath(item.path)"
        >
          <v-list-item-icon
            :class="item.title == 'Alerts' && !mini ? 'pt-2' : 'pt-0'"
          >
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action v-if="item.title == 'Alerts'"
            ><v-chip v-if="newAlertCount > 0" small color="primary">
              {{ newAlertCount == 1 ? "New" : "News" }}</v-chip
            ></v-list-item-action
          >
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <div>
      <v-main>
        <v-row
          no-gutters
          align="center"
          justify="center"
          :style="
            'background-color:' +
            ($vuetify.theme.dark ? $vuetify.theme.themes.dark.secondary : '') +
            ';'
          "
        >
          <v-col cols="2" class="pt-5 pl-5"
            ><img :src="require('../assets/SicameNew.png')" width="50%"
          /></v-col>
          <v-col cols="6" class="pt-5"></v-col>
          <v-col cols="2" class="pt-5">
            <img height="50" :src="require('../assets/sicameiot.png')"
          /></v-col>
          <v-col cols="1" class="pt-5">
            <v-row justify="center" align="center" class="pt-1">
              <v-icon :color="!switch1 ? 'primary' : ''" :class="'mb-1'"
                >mdi-brightness-3</v-icon
              >

              <v-switch v-model="switch1" inset class="ml-4"></v-switch>

              <v-icon :color="switch1 ? 'primary' : ''" :class="'mb-1'"
                >mdi-brightness-5</v-icon
              >
            </v-row>
          </v-col>
          <v-col cols="1" class="text-right pt-5 pr-5"
            ><v-btn fab x-small elevation="1" color="primary" @click="logout">
              <v-icon>mdi-logout</v-icon>
            </v-btn></v-col
          >
        </v-row>
        <v-breadcrumbs
          :items="crumbs"
          :style="
            'background-color:' +
            ($vuetify.theme.dark ? $vuetify.theme.themes.dark.secondary : '') +
            ';'
          "
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
        <v-divider class="mb-5"></v-divider>

        <!-- <v-row no-gutters>
          <v-col class="ml-10">
            <v-row no-gutters class="mt-5 mb-5">
              <v-col sm="1" align-self="center">
                <img :src="require('../assets/sicame.svg')"
              /></v-col>
              <v-col sm="1" offset="6" align-self="center" class="text-center">
                <img height="50" :src="require('../assets/sicameiot.png')"
              /></v-col>
              <v-col sm="1" align-self="center" class="text-center ml-10">

                <v-btn fab x-small color="primary" @click="logout">
                  <v-icon>mdi-logout</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-breadcrumbs :items="crumbs">
              <template v-slot:divider>
                <v-icon>mdi-chevron-right</v-icon>
              </template>
            </v-breadcrumbs>
            <v-divider></v-divider>
            <v-card
              v-if="false"
              width="85%"
              class="pl-8 pr-8 pb-3 pt-3"
              style="
                box-shadow: 3px 0px 8px #00000029;
                border: 0.20000000298023224px solid #727f8e;
                border-radius: 10px;
              "
            >
              <v-row align="center">
                <v-text-field
                  hide-details
                  label="Regular"
                  placeholder="gui"
                ></v-text-field>
                <v-divider class="ml-5 mr-5" vertical></v-divider>
                <v-text-field
                  hide-details
                  label="Regular"
                  placeholder="gui"
                ></v-text-field>
                <v-divider class="ml-5 mr-5" vertical></v-divider>
                <v-text-field
                  hide-details
                  label="Regular"
                  placeholder="gui"
                ></v-text-field>
                <v-btn class="ml-5" depressed color="primary" to="/"
                  ><v-icon left> mdi-magnify </v-icon>
                  Primary
                </v-btn>
              </v-row>
            </v-card>

            <br />
          </v-col>
        </v-row> -->
      </v-main>

      <router-view
        :reloadDashboard="reloadDashboard"
        :reloadAlert="reloadAlert"
        :reloadPreset="reloadPreset"
        @dashboardReloaded="reloadDashboard = false"
        @presetReloaded="reloadPreset = false"
        @alertReloaded="reloadAlert = false"
        @dashboard="selectedDashboard"
        @alert="selectedAlert"
        @preset="selectedPreset"
      ></router-view>
    </div>

    <!-- <div >
      <v-app-bar>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

        <v-breadcrumbs :items="crumbs">
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>

        <v-spacer></v-spacer>

        <v-btn icon>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>

        <v-btn icon>
          <v-icon>mdi-filter</v-icon>
        </v-btn>

        <v-btn icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </v-app-bar>
    </div> -->
  </v-app>
</template>

<script>
import VueCookies from "vue-cookies";
import axios from "axios";
import tokenStore from "../store/TokenStore";

export default {
  name: "App",
  components: {},
  watch: {
    switch1(value) {
      this.$vuetify.theme.dark = !value;
      VueCookies.set(
        "theme_cookie_" + process.env.VUE_APP_COOKIENAME,
        value ? "light" : "dark"
      );
    },
  },
  computed: {
    breakpoint() {
      return this.$vuetify.breakpoint.name;
    },
    crumbs() {
      let pathArray = this.$route.path.split("/");
      if (
        this.selectedAlertName != "" &&
        pathArray[pathArray.length - 1] == "alert"
      ) {
        pathArray.push(this.selectedAlertName);
      } else if (
        this.selectedPresetName != "" &&
        pathArray[pathArray.length - 1] == "preset"
      ) {
        pathArray.push(this.selectedPresetName);
      }
      let crumbs = [];
      pathArray.shift();
      let tmpPath = "";
      for (let i = 0; i < pathArray.length; i++) {
        tmpPath += "/" + pathArray[i];
        if (this.breakpoint == "xs" && i < pathArray.length - 1) {
          crumbs.push({
            text: "...",
            disabled: i == pathArray.length - 1,
            href: tmpPath,
          });
        } else {
          if (pathArray[0] == "dashboard" && i == 1) {
            crumbs.push({
              text:
                this.selectedDashboardName.charAt(0).toUpperCase() +
                this.selectedDashboardName.slice(1),
              disabled: i == pathArray.length - 1,
              href: tmpPath,
            });
          } else {
            crumbs.push({
              text:
                pathArray[i].charAt(0).toUpperCase() + pathArray[i].slice(1),
              disabled: i == pathArray.length - 1,
              href: tmpPath,
            });
          }
        }
      }
      return crumbs;
    },
  },
  data: () => ({
    switch1: true,
    connected: false,
    username: null,
    timeOut: null,
    refreshTokenIntervall: null,
    drawer: null,
    mini: true,
    reloadDashboard: false,
    reloadAlert: false,
    reloadPreset: false,
    selectedDashboardName: "",
    selectedAlertName: "",
    selectedPresetName: "",
    newAlertCount: 0,
    items: [
      { title: "Dashboard", icon: "mdi-view-dashboard", path: "/dashboard" },
      //{ title: "Home", icon: "mdi-image", path: "/home" },
      { title: "Data", icon: "mdi-database-search-outline", path: "/home" },
      //{ title: "Device", icon: "mdi-network", path: "/device" },

      { title: "Device", icon: "mdi-network", path: "/device" },
      //{ title: "Group", icon: "mdi-animation", path: "/group" },
      { title: "Group", icon: "mdi-animation", path: "/group" },
      // { title: "Dashboard", icon: "mdi-view-dashboard", path: "/dashboard" },

      { title: "Preset", icon: "mdi-view-dashboard-outline", path: "/preset" },
      { title: "Alerts", icon: "mdi-alert", path: "/alert" },
      //{ title: "Logs", icon: "mdi-text-search", path: "/log" },
      { title: "Settings", icon: "mdi-cogs", path: "/settings" },
      /*{
        title: "Export",
        icon: "mdi-arrow-down-bold-box-outline",
        path: "/export",
      },*/
      {
        title: "Export",
        icon: "mdi-arrow-down-bold-box-outline",
        path: "/export2",
        authorized:["root","VibrationMonitoringIoT", "VibrationMonitoringDervaux", "WinMS","MarionIoT","SmartHv","Smart_HV_SBI","PortAdHoc"]
      },
      { title: "About", icon: "mdi-information-outline", path: "/about" },

      //{ title: "Test", icon: "mdi-information-outline", path: "/test" },
      // { title: "Map", icon: "mdi-map", path: "/map" },
    ],
  }),
  methods: {
    filterAuthorized(array){
      let arr = [];
      array.forEach((item)=>{
        if(item.authorized== undefined || item.authorized.indexOf(this.username)>=0){
          arr.push(item);
        }
      })
      return arr;
    },
    loadExistingAlert() {
      axios
        .get(process.env.VUE_APP_API_ADDR + "/api/alert/@me", {
          headers: {
            Authorization: tokenStore.getToken(),
          },
        })
        .then((res) => {
          res.data.alerts.forEach((alert) => {
            alert.devicesId.forEach((id) => {
              axios
                .get(process.env.VUE_APP_API_ADDR + "/api/device/" + id, {
                  headers: {
                    Authorization: tokenStore.getToken(),
                  },
                })
                .then((res2) => {
                  if (res2.data.device.alerts != null) {
                    res2.data.device.alerts.forEach((a) => {
                      if (a.new && a.name == alert.name + "_" + alert.id)
                        this.newAlertCount++;
                    });
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            });
          });
        })
        .catch(() => {
          //console.log(err.response.status)
        });
    },
    selectedDashboard(dashName) {
      this.selectedDashboardName = dashName;
    },
    selectedAlert(alertName) {
      this.selectedAlertName = alertName;
    },
    selectedPreset(presetName) {
      this.selectedPresetName = presetName;
    },
    forcePath(path) {
      if (path == "/dashboard") {
        this.reloadDashboard = true;
      } else if (path == "/alert") {
        this.reloadAlert = true;
      } else if (path == "/preset") {
        this.reloadPreset = true;
      }
    },
    log() {
      return console.log("test");
    },
    successConnected: function () {
      if (tokenStore.getToken() != null) {
        this.connected = true;
        this.$router.push("/menu");
      }
    },
    logout: function () {
      clearTimeout(this.timeOut);
      clearInterval(this.refreshTokenIntervall);
      tokenStore.removeToken();
      this.connected = false;
      this.$router.push("/login");
    },
    timeoutLogout: function () {
      /*clearTimeout(this.timeOut);
      this.timeOut = setTimeout(() => {
        clearInterval(this.refreshTokenIntervall);
        this.refreshTokenIntervall = null;
        this.logout();
      }, parseInt(process.env.VUE_APP_SESSION_TIMEOUT_SEC) * 1000);*/
    },
  },
  beforeMount: function () {
    let theme = VueCookies.get(
      "theme_cookie_" + process.env.VUE_APP_COOKIENAME
    );
    if (theme == "dark") {
      this.switch1 = false;
    } else {
      this.switch1 = true;
    }

    if (tokenStore.getToken() != null) {
      tokenStore.refreshToken();
      this.connected = true;
      // this.$router.push("/menu");
    } else {
      this.logout();
    }
  },
  mounted: function () {
    if (this.connected) {
      setTimeout(() => {
        this.refreshTokenIntervall = setInterval(() => {
          tokenStore.refreshToken();
        }, parseInt(Math.floor(process.env.VUE_APP_TOKEN_SESSION_SEC) * 0.3) * 1000);
        this.loadExistingAlert();
      }, 1);

      axios
        .get(process.env.VUE_APP_API_ADDR + "/api/user/@me", {
          headers: {
            Authorization: tokenStore.getToken(),
          },
        })
        .then((res) => {
          tokenStore.setUsername(res.data.user.username);
          this.username = res.data.user.username;
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  beforeDestroy() {
    clearInterval(this.refreshTokenIntervall);
    window.stop();
  },
};
</script>
