<template>
  <v-card elevation="2" :width="!mini ? 256 : 56">
    <v-navigation-drawer :v-model="drawer" :mini-variant.sync="mini"
      ><v-list dense nav>
        <v-list-item color="primary" @click="mini = !mini">
          <v-list-item-icon v-if="mini">
            <v-icon color="primary">mdi-arrow-right</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ !mini ? alertName : "" }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon v-if="!mini">
            <v-icon color="primary">mdi-arrow-left</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item
          :color="item.icon == 'mdi-content-save' ? 'grey' : 'primary'"
          v-for="item in items"
          :key="item.title"
          @click="$emit('actionMenu', item.icon)"
        >
          <v-list-item-icon>
            <v-icon color="primary">{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-if="!mini">
        <v-divider></v-divider><v-row class="mt-3" justify="center">Active</v-row>
        <v-row justify="center" align="center">
          <div
            :class="['mb-1', !switch2 ? 'primary--text font-weight-bold' : '']"
          >
            OFF
          </div>
          <v-switch v-model="switch2" inset class="pa-0 ml-4" @change="changeActive"></v-switch>
          <div
            :class="['mb-1', switch2 ? 'primary--text font-weight-bold' : '']"
          >
            ON
          </div>
        </v-row>
        <v-row class="mt-3" justify="center">Group by sensor</v-row>
        <v-row justify="center" align="center">
          <div
            :class="['mb-1', !switch1 ? 'primary--text font-weight-bold' : '']"
          >
            OFF
          </div>
          <v-switch v-model="switch1" inset class="pa-0 ml-4"></v-switch>
          <div
            :class="['mb-1', switch1 ? 'primary--text font-weight-bold' : '']"
          >
            ON
          </div>
        </v-row>
        </template
      >
    </v-navigation-drawer>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    switch1: false,
    switch2: false,
    mini: true,
    drawer: null,
    items: [
      { title: "Connect device to alert", icon: "mdi-link-variant-plus" },
      { title: "Show connected devices", icon: "mdi-help-circle-outline" },
      { title: "Remove device from alert", icon: "mdi-link-variant-minus" },
    ],
    right: null,
  }),
  props: {
    alertName: String,
    alertActive: Boolean,
  },
  watch: {
    switch1(value) {
      this.$emit("switch1", value);
    },
    mini(value) {
      this.$emit("miniMenu", value);
    },
  },

  beforeMount() {},
  mounted() {
    this.switch2 = this.alertActive;
  },
  beforeDestroy() {},
  methods: {
    test(value) {
      console.log("test", value);
    },
     changeActive(value) {
      this.$emit("changeActive", value);
    },
  },
};
</script>

