<template>
  <v-main v-if="(mail != '' && dialog == false) || dialog == true">
    <v-dialog
      v-model="dialog"
      max-width="500"
      @click:outside="
        () => {
          dialog = false;
        }
      "
    >
      <v-card>
        <v-card-title class="headline secondary">
          Change email
        </v-card-title>

        <v-card-text>
          <v-row justify="center"
            ><v-col cols="12" class="pt-0 pb-0">
              <v-form v-model="valid">
                <v-text-field
                  :rules="[rules.required, rules.mail]"
                  v-model="mail"
                  label="Mail"
                  clearable
                ></v-text-field></v-form
            ></v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click="
              () => {
                dialog = false;
              }
            "
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn color="primary" text @click="saveMail"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row align="center" justify="center">
      <v-col cols="4">
        <v-text-field
          label="Username"
          :readonly="true"
          v-model="username"
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="4">
        <v-text-field label="Email" :readonly="true" v-model="mail" outlined>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-btn color="primary" @click="changeMail">Modify</v-btn>
    </v-row>
    <v-row align="center" justify="center" class="text-center mt-5">
      <v-col cols="2">
        No alert
        <v-menu transition="slide-y-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon :color="colorBase" v-on="on" v-bind="attrs">
              <v-icon>mdi-square-rounded</v-icon>
            </v-btn>
          </template>
          <v-card @click.stop.native>
            <v-color-picker
              canvas-height="300"
              v-model="colorBase"
              :mode="'hexa'"
            ></v-color-picker
          ></v-card>
        </v-menu>
      </v-col>
      <v-col cols="2">
        Warning
        <v-menu transition="slide-y-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon :color="colorWarning" v-on="on" v-bind="attrs">
              <v-icon>mdi-square-rounded</v-icon>
            </v-btn>
          </template>
          <v-card @click.stop.native>
            <v-color-picker
              canvas-height="300"
              v-model="colorWarning"
              :mode="'hexa'"
            ></v-color-picker
          ></v-card>
        </v-menu>
      </v-col>
      <v-col cols="2">
        Alarm
        <v-menu transition="slide-y-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon :color="colorAlarm" v-on="on" v-bind="attrs">
              <v-icon>mdi-square-rounded</v-icon>
            </v-btn>
          </template>
          <v-card @click.stop.native>
            <v-color-picker
              canvas-height="300"
              v-model="colorAlarm"
              :mode="'hexa'"
            ></v-color-picker
          ></v-card>
        </v-menu>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-btn color="primary" @click="updateColor">Save Colors</v-btn>
    </v-row>
  </v-main>
</template>

<script>
import axios from "axios";
import tokenStore from "../../store/TokenStore";

export default {
  components: {},
  data: () => ({
    username: "",
    mail: "",
    dialog: false,
    valid: false,
    colorBase: "#4CAF50",
    colorWarning: "#FF9800",
    colorAlarm: "#F44336",
    rules: {
      required: (value) => !!value || "Required !",

      mail: (value) => {
        var re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(value).toLowerCase()) || "Wrong format mail";
      },
    },
  }),
  beforeMount() {
    axios
      .get(process.env.VUE_APP_API_ADDR + "/api/user/@me", {
        headers: {
          Authorization: tokenStore.getToken(),
        },
      })
      .then((res) => {
        this.username = res.data.user.username;
        this.mail = res.data.user.mail;
        this.colorBase = res.data.user.colorBase;
        this.colorWarning = res.data.user.colorWarning;
        this.colorAlarm = res.data.user.colorAlarm;
      })
      .catch((err) => {
        console.log(err.response);
      });
  },
  methods: {
    updateColor() {
      axios
        .post(
          process.env.VUE_APP_API_ADDR + "/api/user/@me/color",
          {
            colorBase: this.colorBase,
            colorWarning: this.colorWarning,
            colorAlarm: this.colorAlarm,
          },
          {
            headers: {
              Authorization: tokenStore.getToken(),
            },
          }
        )
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    changeMail() {
      this.dialog = true;
    },
    saveMail() {
      axios
        .post(
          process.env.VUE_APP_API_ADDR + "/api/user/@me/mail",
          { mail: this.mail },
          {
            headers: {
              Authorization: tokenStore.getToken(),
            },
          }
        )
        .then((res) => {
          this.username = res.data.user.username;
          this.mail = res.data.user.mail;
          this.dialog = false;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
  },
};
</script>

